import React from 'react';
import Loader from './loader';

class FeedbackForm extends React.Component {
    state = {
        Name: "",
        Email: "",
        UserType: "agent",
        Message: "",
        postingForm: false,
        ErrorMsg: "",
        csvData: {}
    }

    componentWillReceiveProps(nextProps) {
        if(JSON.stringify(this.props.profile) !== JSON.stringify(nextProps.profile) && 
        nextProps.profile && !nextProps.isAnonymous){
            this.setState({
                Name: nextProps.profile.FirstName + " " + nextProps.profile.LastName,
                Email: nextProps.profile.Email ? nextProps.profile.Email : "",
                UserType: nextProps.audience.type
            });
        }
                    
    }

    isValid = () => {
        const { Name, Email, Message} = this.state;
        var errMsg = "";
        if(!Name || Name === ""){
            errMsg = "Please enter your name";
        }
        else if(!Email || Email === ""){
            errMsg = "Please enter your email";
        }
        // eslint-disable-next-line
        else if(Email && !(/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(Email)){
            errMsg = "Please enter a valid email";
        }
        else if(!Message || Message === ""){
            errMsg = "Please enter your comments";
        }
        
        this.setState({ErrorMsg : errMsg});

        return(errMsg === "");
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({[name]: value, ErrorMsg: ""});

    }

    onSubmitForm = (e) => {
        if(e) e.preventDefault();

        if (this.isValid()) {
            var self = this;

            var url = window.sessionStorage.getItem("RootAPI") + "sendfeedback";

            var reqBody = {
                SenderName: self.state.Name,
                SenderEmail: self.state.Email,
                SenderType: self.state.UserType,
                Comment: self.state.Message,
            }
            
            self.setState({postingForm: true});
            fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + window.sessionStorage.getItem("APIKey")
                },
                method: "POST",
                body: JSON.stringify(reqBody)
            })
            .then(response => {
                if (response.ok) return response.json()
                else throw Error(response.statusText)
            })
            .then(json => {
                self.setState({Message: "", postingForm: false});
                if(self.props.showNotification) {
                    self.props.showNotification(
                        "success",
                        "",
                        "Thanks for your feedback. We will get back to you soon.",
                        5000);
                }    
            })
            .catch(function (data) {
                self.setState({postingForm: false});
                if(self.props.showNotification) {
                    self.props.showNotification(
                        "success",
                        "",
                        "Failed sending your feedback. Please try again later.",
                        5000);
                }    
            });
        }
    }    

    render() {
        const { Name, Email, UserType, Message, ErrorMsg, postingForm } = this.state;

        return (
            <div>
                <div className="row">
                    <div className="form-group col-xs-12 margin-bottom-15">
                        <h2>HELP US IMPROVE</h2>
                        <h4 style={{fontWeight: 500}}>Have an idea on how we can make our site better? Have you found a bug or an issue you’d like to report? Please use this form to let us know. We’ll get back to you as soon as possible. Thanks for your help!</h4>
                    </div>
                    {ErrorMsg && ErrorMsg !== "" &&
                        <div className="form-group col-xs-12 error_meg">{ErrorMsg}</div>
                    }
                    <div className="form-group col-sm-4 col-xs-12 margin-bottom-15">
                        <label>Your name (required)</label>
                        <input className={"form-control textbox textbox-large"} name="Name"
                               onChange={this.handleInputChange}
                               value={Name}/>
                    </div>
                    <div className="form-group col-sm-4 col-xs-12 margin-bottom-15">
                        <label>Your email (required)</label>
                        <input className={"form-control textbox textbox-large"} name="Email" type="Email"
                               onChange={this.handleInputChange}
                               value={Email}/>
                    </div>
                    <div className="form-group col-sm-4 col-xs-12 margin-bottom-15">
                        <label>Are you a</label>
                        <div>
                            <span>
                                <input type="radio" name="UserType" value="agent" text="Agent" checked={UserType === "client" ? false : true} onChange={this.handleInputChange} style={{marginRight: 5}}/>
                                Agent
                            </span>
                            <span style={{marginLeft: 25}}>
                                <input type="radio" name="UserType" value="client" text="Client" checked={UserType === "client" ? true : false} onChange={this.handleInputChange} style={{marginRight: 5}}/>
                                Client
                            </span>
                        </div>
                    </div>
                    
                    <div className="form-group col-xs-12 margin-bottom-15">
                        <label>Your Comments (required)</label>
                        <textarea className={"form-control textbox textbox-large"} rows="4" name="Message"
                               onChange={this.handleInputChange}
                               value={Message}
                               style={{resize: "none", padding: "5px 10px"}}/>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-xs-12 no-padding text-center">
                        <div className="profile_save_bt">
                            <button id="submit_bt" className="tru-btn tru-btn-theme" onClick={this.onSubmitForm} disabled={ postingForm } ref="btnSubmit" type="button">
                                {postingForm ?
                                <Loader inline="true" width="18" height="18" /> :
                                <span> Submit</span>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default FeedbackForm;
