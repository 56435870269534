import { CALL_API, Schemas } from '../store/api';
import { normalize, schema } from 'normalizr';

export const LISTING_REQUEST = 'LISTING_REQUEST';
export const LISTING_SUCCESS = 'LISTING_SUCCESS';
export const LISTING_FAILURE = 'LISTING_FAILURE';
export const LISTING_ADD = 'LISTING_ADD';
export const LISTING_REMOVE = 'LISTING_REMOVE';
export const LISTING_CLEAR = 'LISTING_CLEAR';
export const LISTING_ADD_ENTITIES = 'LISTING_ADD_ENTITIES';
export const HOMESTATS_REQUEST = 'HOMESTATS_REQUEST';
export const HOMESTATS_SUCCESS = 'HOMESTATS_SUCCESS';
export const HOMESTATS_FAILURE = 'HOMESTATS_FAILURE';
export const FLISTING_REQUEST = 'FLISTING_REQUEST';
export const FLISTING_SUCCESS = 'FLISTING_SUCCESS';
export const FLISTING_FAILURE = 'FLISTING_FAILURE';
export const LIBERTYM_REQUEST = 'LIBERTYM_REQUEST';
export const LIBERTYM_SUCCESS = 'LIBERTYM_SUCCESS';
export const LIBERTYM_FAILURE = 'LIBERTYM_FAILURE';

export const fetchRecentListings = (key, body) => ({
    [CALL_API]: {
        types: [LISTING_REQUEST, LISTING_SUCCESS, LISTING_FAILURE],
        method: 'POST',
        body: {
            LimitRecords: 6,
            OpenHouseOnly: 0,
            BedroomsMin: 1,
            BathroomsMin: 1,
            OrderBy: 'domdate',
            OrderByDirection: 'ASC',
            ...body
        },
        endpoint: 'recentlistings',
        schema: Schemas.LISTING_ARRAY
    },
    key: key
});

export const fetchFeaturedListings = (key) => ({
    [CALL_API]: {
        types: [FLISTING_REQUEST, FLISTING_SUCCESS, FLISTING_FAILURE],
        method: 'GET',
        endpoint: 'listings/' + key + '/get'
    },
    key: key
});

export const fetchListings = (key, body) => ({
    [CALL_API]: {
        types: [LISTING_REQUEST, LISTING_SUCCESS, LISTING_FAILURE],
        method: 'POST',
        body: {
            limitrecords: "100",
            ficoscore: "760-850",
            loantype: "30YrFixed",
            lifestyle: "couple-no-kids",
            orderby: "newest",
            orderbydirection: "DESC",
            skip: 0,
            propertytype: "sf",
            ...body
        },
        endpoint: 'listingsdata',
        schema: Schemas.LISTING_ARRAY
    },
    key: key
});

// export const fetchRemovedListings = (key, clientId) => (dispatch, getState) => {
//     const { Id, type } = getState().session.audience;
//     var endpoint;
//     if (type === 'agent') 
//         endpoint = `agents/${Id}/clients/${clientId}/removed`;
//     else 
//         endpoint = `clients/${Id}/removed`;

//     return dispatch({
//         [CALL_API]: {
//             types: [LISTING_REQUEST, LISTING_SUCCESS, LISTING_FAILURE],
//             method: 'GET',
//             endpoint: endpoint, 
//             schema: Schemas.LISTING_ARRAY
//         },
//         key: key
//     })
// };

export const addListingToEntities = (listing) => (dispatch, getState) => {
    const listingSchema = new schema.Entity('listings', {}, {
        idAttribute: listing => listing.Id
    });
    const listingArraySchema = { Listings: new schema.Array(listingSchema) };
    const response = normalize({Listings: [listing]} , listingArraySchema);
    return dispatch({type: LISTING_ADD_ENTITIES, response: response})
}

export const addListing = (key, listingId, listing) => (dispatch, getState) => {
    return dispatch({type: LISTING_ADD, listingId: listingId, listing: listing, key: key })
}

export const removeListing = (key, listingId) => (dispatch, getState) => {
    return dispatch({type: LISTING_REMOVE, listingId: listingId, key: key })
}

export const clearListings = (key) => (dispatch, getState) => {
    return dispatch({type: LISTING_CLEAR, key: key })
}

export const fetchAgentClientListings = (key, clientId) => (dispatch, getState) => {
    const { Id, type, AgentId } = getState().session.audience;
    var endpoint;
    if (key === 'bookmarks' || key === 'favorites' || key === 'removed'){
        endpoint =`clients/${Id}/${key}`;
        if (type === "agent")
            endpoint =`agents/${Id}/clients/${clientId}/${key}`;
    }
    else if (key === 'agentlistings') {
        var agentId = type === 'agent' ? Id : AgentId;
        endpoint = `agents/${agentId}/mylistings`
    }

     return dispatch({
         [CALL_API]: {
            types: [LISTING_REQUEST, LISTING_SUCCESS, LISTING_FAILURE],
            method: 'GET',
            endpoint: endpoint,
            schema: Schemas.LISTING_ARRAY
        }, key: key});
};

export const fetchHomeStats = () => ({
    [CALL_API]: {
        types: [HOMESTATS_REQUEST, HOMESTATS_SUCCESS, HOMESTATS_FAILURE],
        method: 'GET',
        endpoint: 'homepagestats',
    },
    key: 'homestats'
});

export const generateLibertyMutualLead = (body) => ({
    [CALL_API]: {
        types: [LIBERTYM_REQUEST, LIBERTYM_SUCCESS, LIBERTYM_FAILURE],
        method: 'POST',
        endpoint: 'listings/libertymutual/leadgen',
        body: body
    },
    key: 'libertymutual'
});