import { CALL_API, Schemas } from '../store/api';

export const SETTING_REQUEST = 'SETTING_REQUEST';
export const SETTING_SUCCESS = 'SETTING_SUCCESS';
export const SETTING_FAILURE = 'SETTING_FAILURE';

export const AUTH_MECH_REQUEST = 'AUTH_MECH_REQUEST';
export const AUTH_MECH_SUCCESS = 'AUTH_MECH_SUCCESS';
export const AUTH_MECH_FAILURE = 'AUTH_MECH_FAILURE';

export const PRINTPIECE_REQUEST = 'PRINTPIECE_REQUEST';
export const PRINTPIECE_SUCCESS = 'PRINTPIECE_SUCCESS';
export const PRINTPIECE_FAILURE = 'PRINTPIECE_FAILURE';

export const BASEDATA_REQUEST = 'BASEDATA_REQUEST';
export const BASEDATA_SUCCESS = 'BASEDATA_SUCCESS';
export const BASEDATA_FAILURE = 'BASEDATA_FAILURE';

export const FNEIGHBORHOODS_REQUEST = 'FNEIGHBORHOODS_REQUEST';
export const FNEIGHBORHOODS_SUCCESS = 'FNEIGHBORHOODS_SUCCESS';
export const FNEIGHBORHOODS_FAILURE = 'FNEIGHBORHOODS_FAILURE';
export const FNEIGHBORHOODD_REQUEST = 'FNEIGHBORHOODD_REQUEST';
export const FNEIGHBORHOODD_SUCCESS = 'FNEIGHBORHOODD_SUCCESS';
export const FNEIGHBORHOODD_FAILURE = 'FNEIGHBORHOODD_FAILURE';

export const fetchSettings = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [SETTING_REQUEST, SETTING_SUCCESS, SETTING_FAILURE],
            method: 'GET',
            endpoint: `basedata/settings`,
            schema: Schemas.SETTING_ARRAY
        }
    })
}

export const fetchPrintPieceURL = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [PRINTPIECE_REQUEST, PRINTPIECE_SUCCESS, PRINTPIECE_FAILURE],
            method: 'GET',
            endpoint: `basedata/printpieceurl/get`
        }
    })
}

export const fetchBaseData = (key, body) => (dispatch, getState) => {

    return dispatch({
        [CALL_API]: {
            types: [BASEDATA_REQUEST, BASEDATA_SUCCESS, BASEDATA_FAILURE],
            method: 'POST',
            body: body,
            endpoint: 'basedata/' + key + '/get'
        },
        key
    });
}

export const fetchAuthMechanism = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [AUTH_MECH_REQUEST, AUTH_MECH_SUCCESS, AUTH_MECH_FAILURE],
            method: 'GET',
            endpoint: `accesstokens/auth`
        }
    })
}

export const fetchFeaturedNeighborhoods = () => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [FNEIGHBORHOODS_REQUEST, FNEIGHBORHOODS_SUCCESS, FNEIGHBORHOODS_FAILURE],
            method: 'GET',
            endpoint: 'listings/featuredneighborhood/get'
        }
    });
}

export const fetchFeaturedNeighborhoodDetail = (key) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
            types: [FNEIGHBORHOODD_REQUEST, FNEIGHBORHOODD_SUCCESS, FNEIGHBORHOODD_FAILURE],
            method: 'GET',
            endpoint: 'listings/featuredneighborhood/getdetail/' + key
        }
    });
}