import union from 'lodash/union'
import {
    LISTING_REQUEST,
    LISTING_SUCCESS,
    LISTING_FAILURE,
    LISTING_ADD,
    LISTING_REMOVE,
    LISTING_CLEAR,
    HOMESTATS_REQUEST, 
    HOMESTATS_SUCCESS, 
    HOMESTATS_FAILURE,
    FLISTING_REQUEST, 
    FLISTING_SUCCESS, 
    FLISTING_FAILURE,
    LIBERTYM_REQUEST, 
    LIBERTYM_SUCCESS, 
    LIBERTYM_FAILURE,
    
} from 'actions/listings';

const updatePagination = (state = {
  isFetching: false,
  ids: [],
  error: undefined,
  listings: [],
  stats: {},
  isSuccess: false,

}, action) => {
  switch (action.type) {
    case LISTING_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case LISTING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        //ids: union(state.ids, action.response.result.Listings),
        ids: union(action.response.result.Listings),
        error: undefined
      }
    case LISTING_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case FLISTING_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case FLISTING_SUCCESS:

      /* let lst = action.response.Listings;
      lst.map((l,i) => {
        if(l.Id === 0) l.Id = (i + 1);
        return null;
      }) */

      return {
        ...state,
        isFetching: false,
        ids: action.key === "exteriorstyle" ? action.response : action.response.Listings,
        error: undefined
      }
    case FLISTING_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case LISTING_ADD:
      return {
        ...state,
        ids: union([...state.ids, action.listingId]),
        listings: union([...state.listings, action.listing])
      }
    case LISTING_REMOVE:
      return {
        ...state,
        ids: state.ids.filter(n => n !== action.listingId)
      }
    case LISTING_CLEAR:
      return {
        ...state,
        ids: []
      }
    case HOMESTATS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case HOMESTATS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        stats: action.response,
        error: undefined
      }
    case HOMESTATS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case LIBERTYM_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false
      };
    case LIBERTYM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        error: undefined
      }
    case LIBERTYM_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        error: action.error
      };
    default:
      return state;
  }
}

const listings = (state = {}, action) => {
  switch (action.type) {
    case LISTING_REQUEST:
    case LISTING_SUCCESS:
    case LISTING_FAILURE:
    case FLISTING_REQUEST:
    case FLISTING_SUCCESS:
    case FLISTING_FAILURE:
    case HOMESTATS_REQUEST:
    case HOMESTATS_SUCCESS:
    case HOMESTATS_FAILURE:
    case LISTING_ADD:
    case LISTING_REMOVE:
    case LISTING_CLEAR:
    case LIBERTYM_REQUEST:
    case LIBERTYM_SUCCESS:
    case LIBERTYM_FAILURE:
      const key = action.key;

      return {
        ...state,
        [key]: updatePagination(state[key], action)
      };
    default:
      return state;
  }
}

export default listings;

