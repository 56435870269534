import React, { Component } from 'react';
import TrueLogo from '../img/svg/truebytlc-logo.svg';

class TermsNConditions extends Component {

    render() {

        var siteLogo = TrueLogo;

        if (process.env.REACT_APP_WebsiteLogo !== undefined && process.env.REACT_APP_WebsiteLogo !== null &&
            process.env.REACT_APP_WebsiteLogo !== ""){
            siteLogo = process.env.REACT_APP_WebsiteLogo;
        }

        return (
            <div className="terms_condition_page">
                <div className="inner_logo"><img src={siteLogo} alt=""/></div>
                    <div className="content_part">
                        <div className="highlight_text">
                            <p>Please Read The Following Terms And Policies Carefully. When You Use Our Website You Acknowledge That You Have Read, Understood, And Agree To Be Bound By These Terms And Policies.</p>
                        </div>
                        <div className="highlight_title">Terms Of Service</div>
                        <p><strong>This website that you are visiting</strong>,  tripTLC (the "<strong>Website</strong>" or the "<strong>Site</strong>"), is owned by  TLCengine LLC (the "<strong>Company</strong>") and is being made available, pursuant to an Agreement (the "<strong>MLS Agreement</strong>") between the Company and Multiple Listing Service of current area. ("<strong>MLS</strong>"),  to real estate brokers and agents, appraisers, and other real estate professionals ("<strong>MLS Subscribers</strong>") who subscribe to the the proprietary online, interactive real estate information service that MLS operates ("<strong>MLS Subscribers</strong>") and to customers of MLS Subscribers to whom MLS Subscribers make this Website available ("<strong>Customers</strong>" or "<strong>End Users</strong>"),</p>
                       <p><strong>Electronic Communication</strong>: When you visit our website or send e-mails to us, you are communicating with us electronically.  You consent to receive communications from us electronically.  We will communicate with you by e-mail or by posting notices on this site.  You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
                        <p><strong>Copyright</strong>: All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Company protected by United States and international copyright laws. The compilation of all content on this site is the exclusive property of the Company and protected by U.S. and international copyright laws. All software used on this site is the property of Company or its software suppliers and protected by United States and international copyright laws.</p>
                        <p><strong>Trademarks</strong>: TLCengine and other Company graphics, logos, page headers, button icons, scripts, and service names are trademarks, registered trademarks or trade dress of Company in the U.S. and/or other countries. Company's trademarks and trade dress may not be used in connection with any product or service that is not Company's, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Company.  All other trademarks not owned by Company that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Company.</p>
                        <p><strong>License and Site Access</strong>: Company grants you a limited license to access and make personal use of this site and not to download (other than page caching) or modify it, or any portion of it, except with express written consent. This license does not include any resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this site or its contents; or any use of data mining, robots, or similar data gathering and extraction tools. This site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Company. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information including but not limited to images, text, page layout, or form of Company without express written consent. You may not use any meta tags or any other "hidden text" utilizing Company's name or trademarks without the express written consent. Any unauthorized use terminates the permission or license granted. You are granted a limited, revocable, and nonexclusive right to create a hyperlink to the home page of Company so long as the link does not portray Company, or its products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any Company logo or other proprietary graphic or trademark as part of the link without express written permission.</p>
                        <p className="uppercase_text">Right To Modify Terms And Terminate License.  Your License Is Subject To The Company's Reservation Of The Right, In The Company's Sole Discretion, To Modify These Terms Of Service At Any Time And/Or To Terminate Your License To Use This Website At Any Time Without Cause.</p>
                        <p><strong>Additional Conditions of License.</strong> Your license to use this site is subject to the following additional conditions of the MLS Agreement:</p>
                        <ul>
                            <li>The access of each MLS Subscriber and its End-Users to this Site and other databases and services of MLS are subject at all times to the rules and regulations and operating policies of MLS.  MLS may change its policies at any time. Such changes may result in changes to the Developer MLS Product, including a reduction or elimination of functionality or capabilities. </li>
                            <li>If at any time MLS informs the Company that a MLS Subscriber is no longer a subscriber in good standing with MLS, Company will terminate access to this site by such MLS Subscriber and its Customers.</li>
                            <li>No MLS Subscriber is permitted under any circumstances to make access to the this site to any person not authorized by MLS to have that access.</li>
                        </ul>
                        <p><strong>Your Account</strong>: If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. Company reserves the right to refuse service, terminate accounts, remove or edit content, or cancel orders in their sole discretion.</p>
                        <p><strong>Illegal or Abusive Usage is Strictly Prohibited</strong>: You must not abuse, harass, threaten, impersonate or intimidate other users of our website.  You may not use the Company's Website for any illegal or unauthorized purpose.  International users agree to comply with all local laws regarding online conduct and acceptable content. Should you be found to have engaged in illegal or abusive usage of our website, Company will suspend your account or usage as applicable.</p>
                        <p><strong>Disclaimer of Warranties and Limitation of Liability</strong></p>
                            <div className="uppercase_text">
                                <p>This Website And All Information, Content, Materials, Products (Including Software) And Services Included On Or Otherwise Made Available To You Through This Website Are Provided By Company On An "As Is" And "As Available" Basis, Unless Otherwise Specified In These Terms Of Service. The Company Makes No Representations Or Warranties Of Any Kind, Express Or Implied, As To The Operation Of This Site Or The Information, Content, Materials, Products (Including Software) Or Services Included On Or Otherwise Made Available To You Through This Site, Unless Otherwise Specified In Writing. You Expressly Agree That Your Use Of This Site Is At Your Sole Risk.</p>
                                <p>To The Full Extent Permissible By Applicable Law, Company Disclaims All Warranties, Express Or Implied, Including, But Not Limited To, Implied Warranties Of Merchantability And Fitness For A Particular Purpose. Company Does Not Warrant That This Site; Information, Content, Materials, Products (Including Software) Or Services Included On Or Otherwise Made Available To You Through This Site; Their Servers; Or E-Mail Sent From [Company] Are Free Of Viruses Or Other Harmful Components.  Company Will Not Be Liable For Any Damages Of Any Kind Arising From The Use Of This Site Or From Any Information, Content, Materials, Products (Including Software) Or Services Included On Or Otherwise Made Available To You Through This Site, Including, But Not Limited To Direct, Indirect, Incidental, Punitive, And Consequential Damages, Unless Otherwise Specified In Writing.</p>
                                <p>Certain State Laws Do Not Allow Limitations On Implied Warranties Or The Exclusion Or Limitation Of Certain Damages. If These Laws Apply To You, Some Or All Of The Above Disclaimers, Exclusions, Or Limitations May Not Apply To You, And You Might Have Additional Rights.</p>
                                <p>The Company Is Not mls's Agent And Has No Authority To Enter Into Any Agreement On mls's Behalf. These Terms Of Service Are An Agreement Between The Company And You, As An mls Subscriber Or Customer, Does Not Create Any Liability Or Obligations On The Part Of mls.  mls Subscriber's And The Sole Recourse For Damages Or Breach Of This Agreement Is To The Company.</p>
                                <p>The Company Makes No Warranties Of Any Kind On Behalf Of mls. The Entire Risk Arising Out Of The Use This Site And Any Database Or Service Of Remls Remains With You, As An mls Subscriber Or Customer.</p>
                                <p>Limitation Of Liability.  In No Event Shall mls Or The Company Be Held Liable For Any Injury To You, As An mls Subscriber Or Customer, Arising Out Of This Agreement Or Use Of This Site.</p>
                            </div>
                        <p><strong>Indemnification</strong>: MLS Subscriber shall indemnify, defend and hold MLS and the Company and ,their directors, staff, shareholders, licensors harmless from any and all claims, damages, losses, liabilities, costs and expenses (including reasonable fees of attorneys and other professionals) arising out of or in connection with this Agreement or use of the Site.</p>
                        <p>You acknowledge that the Company is an information provider and not a financial advisor.  The Company assembles information from various third party sources, relating to various expenses of an End User in purchasing and owning a residences in various specified locations, including commuting and other costs, based on information provided with respect to such end user.   The Company does not independently verify, and is not responsible for the accuracy of information provided by third party sources and cannot predict whether such information will change in the future.  The Company is not responsible for the application of information provided through this site to any End-User's particular circumstances or for decisions make by an End-User based on such application. The Company, in its limited role as an information provider, does not replace the services of a qualified financial advisor in analyzing the applicability of information to decisions made by an End-User</p>
                        <p><strong>Applicable Law</strong>: By visiting our website, you agree that the laws of the State of Delaware, without regard to principles of conflict of laws, will govern these Conditions of Use and any dispute of any sort that might arise between you and Company.</p>
                        <p><strong>Disputes</strong>: Any dispute relating in any way to your visit to our website or to products or services sold or distributed by Company in which the aggregate total claim for relief sought on behalf of one or more parties exceeds $7,500 shall be adjudicated in any state or federal court in the County of  in the State of Delaware, and you consent to exclusive jurisdiction and venue in such courts.</p>
                    </div>

            </div>


        );
    }
}

export default TermsNConditions;