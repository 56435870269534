import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import Dialog from 'react-bootstrap-dialog';
import TermsNConditions from '../components/termsconditions';
import PrivacyPolicy from '../components/privacypolicy';
import { insertDecimal, getFormatedCurrency } from '../components/util';
import '../css/Login.css'; 
import * as sessionActions from 'actions/session';
import LogoNavBar from '../components/logonavbar';
import SelectBox from '../components/select-box';
import ReactPixel from 'react-facebook-pixel';
import '../css/select-box.css';
import '../scss/_signup.scss';

// Check if Pixel is setup for logging
const useFacebookAnayltics = process.env.REACT_APP_FacebookPixelKey !== undefined;
if (useFacebookAnayltics) {
    //const advancedMatching = { em: 'some@email.com' };
    const advancedMatching = { };

    const options = {
        autoConfig: true, 	// set pixel's autoConfig
        debug: false, 		// enable logs
    };

    ReactPixel.init(process.env.REACT_APP_FacebookPixelKey, advancedMatching, options);
}

class SignUpPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            mlsId:'',
            memberNrdsId: '',
            errorMessage: '',
            successMessage: '',
            authType: 'Agent',
            fetchingResult: false,
            step: 1,
            stripePlan: "TrueByTLC_Yearly",
            discount: {id: ''}
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeAuthType = this.onChangeAuthType.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.openTermsDialog = this.openTermsDialog.bind(this);
        this.openPolicyDialog = this.openPolicyDialog.bind(this);        
        this.getCouponDetails = this.getCouponDetails.bind(this);
        this.selectYealyPlan = this.selectYealyPlan.bind(this);
        this.selectMonthlyPlan = this.selectMonthlyPlan.bind(this);
    }

    componentDidMount() {

        // Log component mounting
        if (useFacebookAnayltics)
        {
            ReactPixel.pageView();
            //ReactPixel.track('ViewContent', {content_ids:'Signup', content_type:'Signup', contents:'Initial page Load'});
            ReactPixel.track('ViewContent', null);
        }

        if (this.props.showHeader) {
            this.props.onShowHideHeader(false);
        }
        if (this.props.showFooter) {
            this.props.onShowHideFooter(false);
        }
    }

    loginCall() {
        // Call API creating subscription
        var that = this;
        var url = window.sessionStorage.getItem("RootAPI") + "createtruesubscription";
        var requestBody = {
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Email: this.state.email,
            MLSKey: this.state.mlsId,
            MemberNrdsId: this.state.memberNrdsId,
            StripePlan: this.state.stripePlan,
            CreditCard: this.state.creditCard,
            CreditCvc: this.state.creditCvc,
            CreditExpiration: this.state.creditExpiration,
            CouponId: this.state.discount.id
        };
        that.refs.btnSignup.setAttribute("disabled", "disabled");
        that.setState({ fetchingResult: true });
        fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(requestBody)
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                if (json) {
                    if (that.refs.btnSignup) that.refs.btnSignup.removeAttribute("disabled");
                    var accountCreated = false;
                    var successMessage = "", errorMessage = "";
                    if (json.AgentId) {
                        successMessage = "Your Account has been created successfully. A welcome email with your username and password has been sent, please check the same.";
                        accountCreated = true;
                    }
                    else if (json.Audience && json.Audience.Id) {
                        successMessage = "Your Account has been created successfully. A welcome email with your username and password has been sent, please check the same.";
                        accountCreated = true;
                    }
                    else if (json.length > 0 && json[0].ErrorMessage) {
                        errorMessage = json[0].ErrorMessage;
                    }
                    that.setState({
                        fetchingResult: false, accountCreated: accountCreated,
                        errorMessage: errorMessage, successMessage: successMessage
                    });
                    if (accountCreated) {
                        if (useFacebookAnayltics)
                            //ReactPixel.track('ViewContent', {content_ids:'Signup', content_type:'Signup', contents:'Initial page Load'});
                            ReactPixel.track('Purchase', {value:'99',currency:'USD', 
                                stripe_plan:this.state.stripePlan, 
                                stripe_coupon:this.state.discount.id,
                                //FirstName: this.state.firstName,
                                //LastName: this.state.lastName,
                                Email: this.state.email,
                                MemberNrdsId: this.state.memberNrdsId,
                                MLSKey: this.state.mlsId});

                        if (that.props.sessionActions) {
                            that.props.sessionActions.manualLogin(json);
                            if (that.props.history) that.props.history.push(`/`);
                        }
                    }
                }
                else {
                    if (that.refs.btnSignup) that.refs.btnSignup.removeAttribute("disabled");
                    that.setState({
                        fetchingResult: false,
                        errorMessage: "Your Account creation failed. Please contact support@truebytlc.com"
                    });
                }
            })
            .catch(function (data) {
                //console.log(data);
                if (that.refs.btnSignup) that.refs.btnSignup.removeAttribute("disabled");
                that.setState({ fetchingResult: false, errorMessage: "There was an error creating your account. Please try again later." });
            });
    }

    getCouponDetails() {
        var that = this;
        var url = window.sessionStorage.getItem("RootAPI") + "getcoupon/" + this.state.couponId;

        if (useFacebookAnayltics)
            //ReactPixel.track('ViewContent', {content_ids:'Signup', content_type:'Signup', contents:'Initial page Load'});
            ReactPixel.track('CustomizeProduct ', null);

        fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "GET"
            })
            .then(response => {
                if (response.ok) return response.json()
                else throw Error(response.statusText)
            })
            .then(json => {
                that.setState({ discount: json });
            })
            .catch(function (data) {
                console.log(data);
                that.setState({ discount: { id: '' }, errorMessage: 'Invalid Coupon Code' });
            });
    }

    handleInputChange(event) {
        this.setState({errorMessage: ""});

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleDropdownChange(val, name) {
        this.setState({
            [name]: val
        });
    }

    handleSubmit(event) {
        var errorMessage = '';
        if(this.state.mlsId === null || this.state.mlsId === '')
        errorMessage += "MLS is required. ";
        if(this.state.firstName === null || this.state.firstName === '')
            errorMessage += "First name is required. ";
        if(this.state.lastName === null || this.state.lastName === '')
            errorMessage += "Last name is required. ";
        if(this.state.email === null || this.state.email === '')
            errorMessage += "Email is required. ";
        // eslint-disable-next-line
        if (this.state.email !== null && this.state.email !== '' && !(/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.state.email)))
            errorMessage += "A valid email is required. ";
        if(this.state.memberNrdsId === null || this.state.memberNrdsId === '')
            errorMessage += "RE Member ID is required. ";

        if(this.state.stripePlan === undefined || this.state.stripePlan === null || this.state.stripePlan === '')
            errorMessage += "Choose your TRUE Plan. ";
        if(this.state.creditCard === undefined || this.state.creditCard === null || this.state.creditCard === '')
            errorMessage += "Credit card number is required. ";
        if(this.state.creditCard !== undefined && this.state.creditCard !== null && this.state.creditCard !== '' &&
            (!(/^[0-9]{16}$/.test(this.state.creditCard.replace(/-/g,''))) || (/^[0]{16}$/.test(this.state.creditCard.replace(/-/g,'')))))
            errorMessage += "A valid Credit card number is required. ";
        if(this.state.creditExpiration === undefined || this.state.creditExpiration === null || this.state.creditExpiration === '')
            errorMessage += "Credit card Expiration is required. ";

        if(this.state.creditExpiration !== undefined && this.state.creditExpiration !== null && this.state.creditExpiration !== '' &&
            (!(/^[0-1]{1}[0-9]{1}[0-9]{4}$/.test(this.state.creditExpiration.replace(/\//g,''))) ||
            (!(/^0[1-9][2-3][0-9]{3}|^(10)[2-3][0-9]{3}|^(11)[2-3][0-9]{3}|^(12)[2-3][0-9]{3}$/.test(this.state.creditExpiration.replace(/\//g,'')))) ||
            (/^[0]{6}$/.test(this.state.creditExpiration.replace(/\//g,''))) ||
            (/^[0]{2}[0-9]{4}$/.test(this.state.creditExpiration.replace(/\//g,''))) ||
            (/^[0-1]{1}[0-2]{1}[0-1]{1}[0-9]{3}$/.test(this.state.creditExpiration.replace(/\//g,'')))))
            errorMessage += "A valid Credit card Expiration is required. ";
        if(this.state.creditCvc === undefined || this.state.creditCvc === null || this.state.creditCvc === '')
            errorMessage += "Credit card Cvc is required. ";    
        if(this.state.creditCvc !== undefined && this.state.creditCvc !== null && this.state.creditCvc !== '' &&
            !(/^[0-9]{3}$/.test(this.state.creditCvc)))
            errorMessage += "A valid Credit card Cvc is required. ";

        this.setState({errorMessage: errorMessage});

        if (errorMessage.length === 0) {
            this.setState({errorMessage: ""});
            this.loginCall();
        }
        event.preventDefault();
        return false;
    }

    onChangeAuthType(authType) {
        this.setState({ authType: authType });
    }

    handleNext() {
        var errorMessage = '';
        if(this.state.mlsId === null || this.state.mlsId === '')
        errorMessage += "MLS is required. ";
        if(this.state.firstName === null || this.state.firstName === '')
            errorMessage += "First name is required. ";
        if(this.state.lastName === null || this.state.lastName === '')
            errorMessage += "Last name is required. ";
        if(this.state.email === null || this.state.email === '')
            errorMessage += "Email is required. ";
        // eslint-disable-next-line
        if (this.state.email !== null && this.state.email !== '' && !(/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.state.email)))
            errorMessage += "A valid email is required. ";
        if(this.state.memberNrdsId === null || this.state.memberNrdsId === '')
            errorMessage += "RE Member ID is required. ";

        this.setState({errorMessage: errorMessage});
        if (errorMessage.length === 0) {
            // Log component mounting
            if (useFacebookAnayltics){
                //ReactPixel.track('ViewContent', {content_ids:'Signup', content_type:'Signup', contents:'Initial page Load'});

                ReactPixel.track('InitiateCheckout', {
                    Email:this.state.email,
                    //FirstName:this.state.firstName,
                    //LastName:this.state.lastName,
                    MLSId:this.state.mlsId,
                    MemberNrdsId:this.state.memberNrdsId
                });
            };

            // Move to Step 2
            this.setState({step: 2});
        }
    }

    handlePrevious() {
        this.setState({step: 1, errorMessage: ''});
    }
    
    openTermsDialog() {
        Dialog.setOptions({
            defaultCancelLabel: 'Close'
        });
        this.refs.dialogRef.show({
            body: <TermsNConditions />,
            bsSize: 'large',
            actions: [
                Dialog.CancelAction(),
            ]
        })
    }

    openPolicyDialog() {
        Dialog.setOptions({
            defaultCancelLabel: 'Close'
        });
        this.refs.dialogRef.show({
            body: <PrivacyPolicy />,
            bsSize: 'large',
            actions: [
                Dialog.CancelAction(),

            ]
        })
    }

    selectYealyPlan() {
        // if (useFacebookAnayltics)
        //     //ReactPixel.track('ViewContent', {content_ids:'Signup', content_type:'Signup', contents:'Initial page Load'});
        //     ReactPixel.track('CustomizeProduct ', null);

        this.setState({stripePlan: "TrueByTLC_Yearly"});
    }

    selectMonthlyPlan() {
        // if (useFacebookAnayltics)
        //     //ReactPixel.track('ViewContent', {content_ids:'Signup', content_type:'Signup', contents:'Initial page Load'});
        //     ReactPixel.track('CustomizeProduct ', null);

        this.setState({ discount: {id: ''}, couponId: null, errorMessage: "" });
        this.setState({stripePlan: "TrueByTLC_Monthly"});
    }

    render() {
        return (
                <div className="position-relative">
                    <LogoNavBar />
                    <div className="blue_gd_bg">&nbsp;</div>          
                    <div className="container signup-container position-relative mb-pd-bot-30">
                        <div className="row no-gutters">
                            <div className="col-md-3 hidden-sm hidden-xs">
                                <h1 className="welcome">Welcome to a new era in home buying.</h1>
                            </div>
                            <div className="col-md-7" style={{ backgroundColor: 'white'}}>
                                     <div className="progress-tabs">
                                        <div className={this.state && this.state.step === 1 ? "active" : ""}>
                                           <div className="tru-bold pd-rt-5 step">Step 1:</div> 
                                           <div className="tru-med pd-lft-5">Your details</div>
                                        </div>
                                        <div className={this.state && this.state.step === 2 ? "active" : ""}>
                                           <div className="tru-bold step">Step 2:</div> 
                                           <div className="tru-med pd-lft-5">Payment info</div>
                                        </div>
                                    </div>
                                    {this.state.errorMessage && this.state.errorMessage !== "" && <div className="error_meg" style={{ marginBottom :'0'}}>{this.state.errorMessage}</div>}
                                    {this.state.successMessage && this.state.successMessage !== "" && <div className="success_meg">{this.state.successMessage}</div>}
                                    {this.state.authType === 'Agent' &&
                                    <form onSubmit={this.handleSubmit} className="col-md-12">
                                        <div>
                                            <div style={this.state && this.state.step === 1 ? {display: "block"} : {display: "none"}} className={this.state && this.state.step === 1 ? "input_mid" : ""}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <SelectBox 
                                                            className="tru-dropdown tru-dropdown-square mg-btm-15" 
                                                            value={this.state.mlsId} 
                                                            label="Choose your MLS"
                                                            onChange={(e) => this.handleDropdownChange(e, 'mlsId')}>
                                                            <option value="cjmls">CJMLS - Central Jersey(Middlesex) MLS</option>
                                                            <option value="gsmls">GSMLS - Garden State MLS</option>
                                                            <option value="mlspin">MLSPIN</option>
                                                            <option value="myfr">My Florida Regional MLS</option>
                                                            <option value="neren">New England Real Estate Network MLS</option>
                                                            <option value="NSMLS">NorthstarMLS</option>
                                                        </SelectBox>
                                                    </div>                                            
                                                    <div className="col-md-6">
                                                        <input name="memberNrdsId" className="tru-input tru-input-square mg-btm-15" id="user" value={this.state.memberNrdsId} type="text" placeholder="RE Member ID"  onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <input name="firstName" className="tru-input tru-input-square mg-btm-15" id="user" value={this.state.firstName} type="text" placeholder="First name" onChange={this.handleInputChange} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input name="lastName" className="tru-input tru-input-square mg-btm-15" id="user" value={this.state.lastName} type="text" placeholder="Last name" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <input name="email" className="tru-input tru-input-square mg-btm-15" id="user" value={this.state.email} type="email" placeholder="Email address" onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                               
                                                <div className="row"> 
                                                    <div className="col-md-6 col-md-offset-3 mg-top-40">
                                                        {this.state.fetchingResult &&
                                                            <button type="button" ref="btnNext" className="tru-btn tru-btn-big tru-btn-default full-width"> 
                                                                Processing...
                                                            </button>
                                                        }
                                                        {!this.state.fetchingResult &&
                                                            <button type="button" ref="btnNext" className="tru-btn tru-btn-big tru-btn-default full-width" value="Save" onClick={this.handleNext}>
                                                                Save<i className="icon-tru-arrowwhite flt-right" style={{ paddingTop: '3px'}}></i>
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ---------------------STEP 2 --------------------------- */}
                                            {this.state && this.state.step === 2 &&
                                            <div className="col-md-12" >
                                            {/* <div style={this.state && this.state.step === 2 ? {display: "block"} : {display: "none"}}  className={this.state && this.state.step === 2 ? "input_mid" : ""}> */}
                                                {/* <div className="group">
                                                    <div className="radio_group">
                                                        <div className={this.state.stripePlan === "TrueByTLC_Yearly" ? "radio_box active" : "radio_box"} onClick={this.selectYealyPlan}>
                                                            <div className="plan_title">Annual</div>  
                                                            <div className="annual_amount">
                                                                <div className="plan_amount">$83.25</div>
                                                                <div className="plan_month">/month</div>
                                                            </div>
                                                            <div className="plan_billed">$999 billed annually</div> 
                                                            <div className="plan_discount">
                                                                <span className="discount_name">save</span>
                                                                <span className="discount_amount">$189</span>
                                                                <span className="discount_pt">16%</span>
                                                            </div>
                                                        </div>
                                                        <div className={this.state.stripePlan === "TrueByTLC_Monthly" ? "radio_box active" : "radio_box"} onClick={this.selectMonthlyPlan}>
                                                            <div className="plan_title">Monthly</div>  
                                                            <div className="annual_amount">
                                                                <div className="plan_amount">$99</div>
                                                                <div className="plan_month">/month</div>
                                                            </div>
                                                            <div className="plan_billed">$99 billed monthly</div> 
                                                        </div>
                                                        <div className="plan_charge">{this.state.stripePlan === "TrueByTLC_Yearly" ? "Includes 7 day Free Trial" : "Includes 7 day Free Trial"}</div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className={`subscriptionType`}>
                                                            <h3 className="subscriptionType_text" style={{ paddingLeft:'0' }}>Free Trial</h3>
                                                        </div>
                                                        <div className="ft-sz-12 mg-top-15 mg-btm-40">
                                                            Your purchase includes a <div class="tru-med"> free 
                                                            14-day trial.</div> After 14 days we will bill you on the schedule you choose. Don’t worry, you’ll be hooked!
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className={`subscriptionType ${this.state.stripePlan === "TrueByTLC_Monthly" ? "active" : ""}`}>
                                                            <label className="tru-checkbox">
                                                                
                                                                <input type="checkbox" checked={this.state.stripePlan === "TrueByTLC_Monthly"} onChange={this.selectMonthlyPlan} />
                                                                <span className="tru-checkbox_checkmark"></span>
                                                            </label>
                                                            <h3 className="subscriptionType_text">Monthly</h3>
                                                        </div>
                                                        <div className="ft-sz-12 mg-top-15 mg-btm-40">
                                                           <div class="tru-med"> Pay just $99 a month</div>
                                                            Our most popular plan—get ready to increase your potential sales and help your clients make more informed decisions.
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className={`subscriptionType ${this.state.stripePlan === "TrueByTLC_Yearly" ? "active": ""}`}>
                                                            <label className="tru-checkbox">
                                                                <input type="checkbox" checked={this.state.stripePlan === "TrueByTLC_Yearly"} onChange={this.selectYealyPlan} />
                                                                <span className="tru-checkbox_checkmark"></span>
                                                            </label>
                                                            <h3 className="subscriptionType_text">Yearly</h3>
                                                        </div>
                                                        <div className="ft-sz-12 mg-top-15 mg-btm-40">
                                                           <div class="tru-med">Pay $999 a year </div>
                                                           Save $189 a year when you bill annually. Lock in for a year of TLC calculations, smart searches, and happier clients.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <InputMask name="creditCard"
                                                            autoComplete="cc-number"
                                                            onChange={this.handleInputChange}
                                                            className="tru-input tru-input-square mg-btm-15" 
                                                            placeholder="Credit card"
                                                            maxLength="16"
                                                            type="Number"
                                                            /*onBlur={this.validationCheck}*/
                                                            value={this.state.creditCard}
                                                            /*mask="9999-9999-9999-9999" maskChar="_"*//>
                                                    </div>
                                                    <div className="col-md-3">
                                                                <InputMask name="creditExpiration"
                                                                    autoComplete="cc-csc"
                                                                    onChange={this.handleInputChange}
                                                                    className="tru-input tru-input-square mg-btm-15" 
                                                                    placeholder="Exp"
                                                                    /*onBlur={this.validationCheck}*/
                                                                    value={this.state.creditExpiration}
                                                                    mask="99/9999" maskChar="_"/>
                                                    </div>
                                                    <div className="col-md-3">
                                                                <InputMask name="creditCvc"
                                                                    autoComplete="cc-csv"
                                                                    onChange={this.handleInputChange}
                                                                    className="tru-input tru-input-square mg-btm-15" 
                                                                    placeholder="Cvc"
                                                                    /*onBlur={this.validationCheck}*/
                                                                    value={this.state.creditCvc}
                                                                    mask="999" maskChar="_"/>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="group">
                                                        <div className="half_box">
                                                            <div className="col-xs-6">
                                                                <InputMask name="creditExpiration"
                                                                    autoComplete="cc-csc"
                                                                    onChange={this.handleInputChange}
                                                                    className="input" 
                                                                    placeholder="Expiration"
                                                                    value={this.state.creditExpiration}
                                                                    mask="99/9999" maskChar="_"/>
                                                            </div>
                                                        </div>
                                                        <div className="half_box">
                                                            <div className="col-xs-6">
                                                            <div className="">
                                                                <InputMask name="creditCvc"
                                                                    autoComplete="cc-csv"
                                                                    onChange={this.handleInputChange}
                                                                    className="input" 
                                                                    placeholder="Cvc"
                                                                    value={this.state.creditCvc}
                                                                    mask="999" maskChar="_"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div> */}
                                                {this.state.stripePlan === 'TrueByTLC_Yearly' &&
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <input className="tru-input tru-input-square mg-btm-15" name="couponId" placeholder="Coupon code" onChange={this.handleInputChange} />
                                                        </div>
                                                        <span className="col-md-3">
                                                            <button className="tru-btn tru-btn-theme mg-btm-15" type="button" onClick={this.getCouponDetails}>
                                                                <span>Apply</span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                }
                                                {this.state.discount.id &&
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="discount">
                                                                <div className="discount-row"><div className="discount-head">Subtotal</div><div className="discount-cell">$999</div></div>
                                                                <div className="discount-row"><div className="discount-head">Discount</div><div className="discount-cell">-{ getFormatedCurrency(insertDecimal(this.state.discount.amount_off)) }</div></div>
                                                                <div className="discount-row"><div className="discount-head">Total</div><div className="discount-cell">{ getFormatedCurrency(999 - insertDecimal(this.state.discount.amount_off)) }</div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row mg-top-50">
                                                    <div className="col-md-6">
                                                        {this.state.fetchingResult &&
                                                            <input type="button" ref="btnPrev" className="tru-btn tru-btn-theme previous" value="Previous" />
                                                        }
                                                        {!this.state.fetchingResult &&
                                                            <input type="button" ref="btnPrev" className="tru-btn tru-btn-theme previous" value="Previous" onClick={this.handlePrevious} />
                                                        }
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="submit" ref="btnSignup" className="tru-btn tru-btn-default complete-order" value={this.state.fetchingResult ? 'Processing...' : 'Complete order'} />
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            <div className="row">
                                            <div className="col-md-6 col-md-offset-3">
                                                <div className="disclaimer">
                                                    By signing in/up you agree to the <span className="agree_link" onClick={this.openTermsDialog}>Terms of Use</span> and <span className="agree_link" onClick={this.openPolicyDialog}>Privacy Policy</span>
                                                    {this.state.step && this.state.step === 2 &&
                                                        <div className="mg-top-20">
                                                            Cancel by midnight on day 30 [14 for trial] to avoid a monthly subscription.
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </form>
                                    }
                                <div className="login_footer">
                                    <div className="signup_footer login_footer_lt">&copy;{new Date().getFullYear()} TLCengine. All Rights Reserved.</div>
                                </div>
                            </div>
                            <Dialog ref='dialogRef' />
                            </div>
                        </div>
                     </div>
            //     </div>
            //     <Dialog ref='dialogRef' />
            // </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(null, mapDispatchToProps)(SignUpPage);