import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import DocumentTitle from 'react-document-title';
import { PageTitle, GetSectionCustomizationOptions } from '../components/util';
import '../scss/_login.scss';
//import LogoNavBar from '../components/logonavbar';
import * as sessionActions from 'actions/session';

const LoginPageCustomizationOptions = GetSectionCustomizationOptions("LoginPageOptions");

class SetPasswordPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        var params = queryString.parse(props.location.search);
        const token = (params && params.t) ? params.t.replace(/ /g,"+") : null;

        this.state = {
            title: 'Set Password',
            token: token,
            isCheckingToken: true,
            clientId: null,
            password: '',
            confirmPassword: '',
            isSettingPassword: false,
            errorMessage: '',
            successMessage: 'Processing, please wait...',            
            isPasswordSet: false,            
        };
    }

    componentDidMount() {
        if (process.env.REACT_APP_AllowAnonymous === "true"){
            if (!this.props.showHeader) {
                this.props.onShowHideHeader(true);
            }
        }
        else {
            if (this.props.showHeader) {
                this.props.onShowHideHeader(false);
            }
        }
        if (!this.props.showFooter) {
            this.props.onShowHideFooter(true);
        }
        
        if(!this.state.token || this.state.token === ""){
            if(this.props.history) this.props.history.push("/");
        }        
        else{
            this.checkToken();
        }        
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isAnonymous !== nextProps.isAnonymous && this.props.isAnonymous === undefined && nextProps.isAnonymous === false) {
            this.props.sessionActions.logout(true);
            /* this.props.sessionActions.anonymousLogin().then(r => {
                setTimeout(() => {
                    window.location.reload();
                },1000);
            }); */
            setTimeout(() => {
                window.location.reload();
            },1000);
        }
    }

    checkToken = () => {
        var self = this;
        var token = this.state.token;
        if(token && token !== ""){        
            var url = window.sessionStorage.getItem("RootAPI") + "clients/checkallowsetpassword";
            var requestBody = {
                token: token
            }            
            self.setState({isCheckingToken: true});
            fetch(url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify(requestBody)
                })
                .then(response =>  response.json())
                .then(json => {
                    if (json) {                        
                        if(self.refs.setPasswordRef){
                            self.setState({
                                isCheckingToken: false, clientId: json, successMessage: ""
                            });
                        }
                    }
                    else {
                        self.setState({
                            isCheckingToken: false, clientId: null, 
                            successMessage: "You have already set your password. Please sign in using the same. If you don't remember your password, try forgot password."
                        });
                    }
                })
                .catch(function (data) {
                    console.log(data);
                    self.setState({isCheckingToken: false });
                    if(self.props.history) self.props.history.push("/");
                }); 
        }
    }

    setPassword = () => {

        var self = this;

        if(self.state.clientId !== null && self.state.password && self.state.password !== ""){
         
            var url = window.sessionStorage.getItem("RootAPI") + "clients/" + self.state.clientId + "/setpassword";
            var requestBody = {
                password: self.state.password
            }
            
            if(self.refs.btnSetPassword) self.refs.btnSetPassword.setAttribute("disabled", "disabled");
            self.setState({isSettingPassword: true});
            fetch(url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify(requestBody)
                })
                .then(response =>  response.json())
                .then(json => {
                    if (json) {
                        if(self.refs.btnSetPassword) self.refs.btnSetPassword.removeAttribute("disabled");
                        if(self.refs.setPasswordRef){
                            self.setState({
                                isCheckingToken: false, isSettingPassword: false, isPasswordSet: true,
                                successMessage: "Your Password has been set successfully. Please sign in using the same."
                            });
                        }
                    }
                    else {
                        if(self.refs.btnSetPassword) self.refs.btnSetPassword.removeAttribute("disabled");
                        self.setState({
                            isCheckingToken: false, isSettingPassword: false,
                            errorMessage: "There was an error trying to set your password. Please try again later."
                        });
                    }
                })
                .catch(function (data) {
                    console.log(data);
                    if(self.refs.btnSignup) self.refs.btnSignup.removeAttribute("disabled");
                    self.setState({isCheckingToken: false, isSettingPassword: false, errorMessage: "There was an error trying to set your password. Please try again later." });
                }); 
        }
    }

    handleInputChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    validatePassword = (newPassword) => {
        return new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$').test(newPassword);
    }
    
    validateCapsPassword = (newPassword) => {
        return new RegExp('^(?=.*[A-Z])').test(newPassword);
    }
    
    validateNumericPassword = (newPassword) => {
        return new RegExp('^(?=.*\\d)').test(newPassword);
    }
    
    validateLowerPassword = (newPassword) => {
        return new RegExp('^(?=.*[a-z])').test(newPassword);
    }

    handleSubmit = (event) => {

        var errorMessage = '';
        if (!this.state.password || this.state.password === ''){
            errorMessage = "Please enter new password. ";
        }
        else if(this.state.password.length < 8){
            errorMessage = "New password should be at least 8 characters. ";
        }
        else if(!this.validateLowerPassword(this.state.password)){
            errorMessage = "New password should contain at least one lowercase letter. ";
        }
        else if(!this.validateCapsPassword(this.state.password)){
            errorMessage = "New password should contain at least one UPPERCASE letter. ";
        }
        else if(!this.validateNumericPassword(this.state.password)){
            errorMessage = "New password should contain at least one numeric digit. ";
        }
        else if (!this.state.confirmPassword || this.state.confirmPassword === ''){
            errorMessage = "Please confirm new password. ";
        }
        else if (this.state.password !== this.state.confirmPassword){
            errorMessage = "New password and confirm password should match. ";
        }

        if (errorMessage.length === 0) {
            this.setState({isSettingPassword: true}, function() {
                this.setPassword();
            });            
        }
        else {
            if(this.refs.setPasswordRef){
                this.setState({errorMessage: errorMessage});
            }
        }

        event.preventDefault();
        return false;
    }

    render() {
        return (
            <DocumentTitle title={ PageTitle.replace('{title}', this.state.title) } ref='setPasswordRef'>
                <div className="position-relative user_access_login">
                    {/* !process.env.REACT_APP_AllowAnonymous && <LogoNavBar /> */}
                    <div className="blue_gd_bg" style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageBackgroundColor) ? {background: LoginPageCustomizationOptions.LoginPageBackgroundColor} : {}}>&nbsp;</div> 
                    <div className="container position-relative pd-bot-50 mb-pd-bot-30">
                        <div className="row">
                            <div className="float-none col-md-5 col-sm-7 mx-auto">
                                {this.state.isCheckingToken && this.state.successMessage && this.state.successMessage !== "" &&
                                    this.state.clientId === null &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="pd-top-25 pd-bot-25 large-heading mb-pd-top-15 mb-pd-bot-15">
                                                <h2 style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageTitleTextColor) ? {color: LoginPageCustomizationOptions.LoginPageTitleTextColor} : {}}><span>{this.state.successMessage}</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {!this.state.isCheckingToken && !this.state.isPasswordSet && this.state.successMessage && 
                                    this.state.successMessage !== "" && this.state.clientId === null &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="pd-top-25 pd-bot-25 large-heading mb-pd-top-15 mb-pd-bot-15">
                                                <h3 style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageTitleTextColor) ? {color: LoginPageCustomizationOptions.LoginPageTitleTextColor} : {}}><span>{this.state.successMessage}</span></h3>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 text-left margin-top-section">
                                            <Link to={'/login'} className="tru-bold link-txt" style={{float: "none"}}>Sign in</Link>
                                        </div>
                                        <div className="col-xs-6 text-right margin-top-section">
                                            <Link to={'/forgotpassword'} className="tru-bold link-txt" style={{float: "none"}}>Forgot password?</Link>
                                        </div>
                                    </div>
                                }
                                {!this.state.isCheckingToken && this.state.isPasswordSet && this.state.successMessage && 
                                    this.state.successMessage !== "" &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="pd-top-25 pd-bot-25 large-heading mb-pd-top-15 mb-pd-bot-15">
                                                <h3 style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageTitleTextColor) ? {color: LoginPageCustomizationOptions.LoginPageTitleTextColor} : {}}><span>{this.state.successMessage}</span></h3>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 text-center margin-top-section">
                                            <Link to={'/login'} className="tru-bold link-txt" style={{float: "none"}}>Sign in</Link>
                                        </div>
                                    </div>
                                }
                                {this.state.clientId !== null && !this.state.isPasswordSet &&
                                    <div className="pd-top-25 pd-bot-25 large-heading mb-pd-top-15 mb-pd-bot-15">
                                        <h2 style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageTitleTextColor) ? {color: LoginPageCustomizationOptions.LoginPageTitleTextColor} : {}}><span>Set your password</span></h2>
                                    </div>
                                }
                                {this.state.clientId !== null && !this.state.isPasswordSet &&
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                {this.state.errorMessage && this.state.errorMessage !== "" && <div className="error_meg">{this.state.errorMessage}</div>}
                                                {this.state.successMessage && this.state.successMessage !== "" && <div className="success_meg">{this.state.successMessage}</div>}

                                                <div>
                                                    <div className="group">
                                                        <input name="password" className="tru-input large" id="password"
                                                            value={this.state.password}
                                                            type="password" placeholder="New Password" 
                                                            onChange={this.handleInputChange}/>
                                                    </div>
                                                    <div className="group mg-btm-25">
                                                        <input name="confirmPassword" className="tru-input large" id="confirmPassword"
                                                                value={this.state.confirmPassword}
                                                                type="password" placeholder="Confirm Password"
                                                                onChange={this.handleInputChange}/>                                                       
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12 col-xs-12">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-xs-12 mg-btm-20">
                                                                    <input type="submit" ref="btnSetPassword" className="tru-btn tru-btn-big tru-btn-default full-large" value={this.state.isSettingPassword ? 'Processing...' : 'Set Password'}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                                                                                
                                            </div>
                                        </div>
                                    </form>
                                } 
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state, props) => ({
    isAnonymous: state.session.isAnonymous,
});

const mapDispatchToProps = dispatch => ({
    sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordPage);