import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { PageTitle, GetSectionCustomizationOptions, isProfileComplete } from '../components/util';
import ReCAPTCHA from "react-google-recaptcha";
import '../scss/_login.scss';
import * as sessionActions from 'actions/session';
import * as profileActions from 'actions/profile';
import * as settingActions from 'actions/settings';
import * as listingActions from 'actions/listings';

const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");
const LoginPageCustomizationOptions = GetSectionCustomizationOptions("LoginPageOptions");
const SITE_KEY = process.env.REACT_APP_GoogleRecaptchaSiteKey;
const recaptchaRef = React.createRef();

class RegisterPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            title: 'Register',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            errorMessage: '',
            isRegistering: false,
            responseObj: null,
            isProfileFetched: false,
            isListingsFetched: false
            
        };        
    }

    componentDidMount() {
        if (process.env.REACT_APP_AllowAnonymous === "true"){
            if (!this.props.showHeader) {
                this.props.onShowHideHeader(true);
            }
        }
        else {
            if (this.props.showHeader) {
                this.props.onShowHideHeader(false);
            }
        }
        if (!this.props.showFooter) {
            this.props.onShowHideFooter(true);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.session && nextProps.session.isAuthenticated && !nextProps.isAnonymous) {
            if((this.props.profile === undefined || this.props.profile === null) &&
                (nextProps.profile === undefined || nextProps.profile === null) &&
                !this.state.isProfileFetched){
                if(this.props.settingActions){
                    this.props.settingActions.fetchSettings();
                    this.props.settingActions.fetchAuthMechanism();
                    if(CommonCustomizationOptions && CommonCustomizationOptions.FetchPrintPieceUrl){
                        this.props.settingActions.fetchPrintPieceURL();
                    }
                }
                if(this.props.profileActions){
                    this.props.profileActions.fetchProfile();
                }
                this.setState({isProfileFetched: true});
            }
            if(nextProps.profile !== undefined && nextProps.profile !== null){                
                if(nextProps.audience && nextProps.audience.type === "client"){
                    if(nextProps.profileActions && !this.state.isListingsFetched) { 
                        nextProps.profileActions.fetchMyAgentProfile();
                    }
                    var gotoHome = isProfileComplete(nextProps.profile);
                    if(!this.state.isListingsFetched) {
                        this.props.listingActions.fetchAgentClientListings("favorites");
                        this.props.listingActions.fetchAgentClientListings("bookmarks");
                        this.props.listingActions.fetchAgentClientListings("removed");
                        this.props.listingActions.fetchAgentClientListings("agentlistings");                        
                    }
                    this.setState({isListingsFetched: true});

                    if(window.sessionStorage.getItem("RedirectToHome")){
                        window.sessionStorage.removeItem("RedirectToHome");
                    } 

                    if(gotoHome){    
                        this.props.history.push('/');
                        document.location.reload();                        
                    }
                    else{
                        this.props.history.push('/personalize');
                        document.location.reload();
                    }
                }
                else{
                    if(nextProps.audience && nextProps.audience.type === "agent"){
                        if (process.env.REACT_APP_AnonymousUserId !== undefined && 
                            process.env.REACT_APP_AnonymousUserId !== null && 
                            process.env.REACT_APP_AnonymousUserId !== "" && 
                            nextProps.audience.Id && nextProps.audience.Id !== "" && 
                            process.env.REACT_APP_AnonymousUserId.toLowerCase() !== nextProps.audience.Id.toLowerCase()){
                            if(!this.state.isListingsFetched){
                                this.props.listingActions.fetchAgentClientListings("agentlistings");
                            }
                            window.sessionStorage.setItem("RedirectToHome",true);
                            this.setState({isListingsFetched: true});
                        }
                    }
                    if(window.sessionStorage.getItem("RedirectToHome")){
                        window.sessionStorage.removeItem("RedirectToHome");
                        
                        this.props.history.push('/');
                        document.location.reload();                        
                    }
                }
            }
        }
    }
    
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            errorMessage: '',
            [name]: value
        });
    }    

    handleSubmit = () => {
        const { purlPrefix } = this.props;
        const { firstname, lastname, email, phone } = this.state;
        
        var errorMessage = '';
        const recaptchaValue = recaptchaRef.current.getValue();

        if (!firstname || firstname === "")
            errorMessage = "First name is required";
        else if (!lastname || lastname === "")
            errorMessage = "Last name is required";
        else if (!email || !(/^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)))
            errorMessage = "A valid email address is required"
        else if(!recaptchaValue)
            errorMessage = "Oops, you have to check the captcha!";

        if(errorMessage !== "") {
            this.setState({errorMessage});
            return;
        }
        
        var self = this;
        var body = {
            Firstname: firstname,
            LastName: lastname,
            Email: email,
            CellPhone: phone
        }
        var agentId = process.env.REACT_APP_AnonymousUserId.toString();        
        //If there is default agent then client should be created under default agent else it should be created under anonymous agent
        if (process.env.REACT_APP_DefaultAgentId !== undefined && 
            process.env.REACT_APP_DefaultAgentId !== null && 
            process.env.REACT_APP_DefaultAgentId !== "")
        {
            agentId = process.env.REACT_APP_DefaultAgentId.toString();
            body.BrokerAgentId = process.env.REACT_APP_DefaultAgentId;
        }        
        if(purlPrefix && purlPrefix.agent && purlPrefix.agent.Id && purlPrefix.agent.Id !== ""){
            agentId = purlPrefix.agent.Id;
        }
        const url = window.sessionStorage.getItem("RootAPI") + `agents/${agentId}/clients`;        
        
        self.setState({ isRegistering: true, errorMessage: "" });
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.sessionStorage.getItem("APIKey")
            },
            method: "POST",
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) return response.json()
            //else throw Error(response.statusText)
            else return response.json()
        })
        .then(json => {
            var msg = "Registration failed, please try again later";
            var isSuccess = false;
            var responseObj = null;
            if(json && json.Audience && json.Audience.Id && json.AccessToken){
                msg = "Your account has been created and you will receive an email shortly. Click Ok to login";
                isSuccess = true;
                responseObj = {...json};
            }
            else {
                if(json && json.ErrorMessage && json.ErrorMessage !== ""){
                    msg = json.ErrorMessage;
                    isSuccess = false;
                }
                else if(json && json.length > 0 && json[0].ErrorMessage && json[0].ErrorMessage !== ""){
                    msg = json[0].ErrorMessage;
                    isSuccess = false;
                }
            }

            if(isSuccess){
                //self.redirectTimer = setInterval(self.loginUser, 7000);
                self.props.showNotification(
                    "success",
                    "",
                    msg.replace("Client is already added by you","This email is already registered"),
                    5000,
                    "Ok",
                    this.loginUser
                );
            }
            else {
                self.props.showNotification("success", "",
                    msg.replace("Client is already added by you","This email is already registered"),
                    5000);
            }            
            self.setState({ showModal: false, isRegistering: false, errorMessage: "", responseObj: responseObj });
        })
        .catch(function (data) {
            var msg = "An error has occured creating your account";
            if(data && data.ErrorMessage && data.ErrorMessage !== ""){
                msg =  data.ErrorMessage;
            }
            else if(data && data.length > 0 && data[0].ErrorMessage && data[0].ErrorMessage !== ""){
                msg =  data[0].ErrorMessage;
            }
            self.props.showNotification("error", "",
                msg.replace("Client is already added by you","This email is already registered"),
                5000);
            self.setState({ showRegisterModal : false, isRegistering: false, errorMessage: "" });
        });
    }

    loginUser = () => {
        console.log('loginUser');
        //clearInterval(this.redirectTimer);
        const { responseObj } = this.state;
        const { sessionActions } = this.props;
        if(sessionActions && responseObj) {
            this.setState({isProfileFetched: false, isListingsFetched: false});
            sessionActions.manualLogin(responseObj);
        }
    }

    render() {

        const { title, firstname, lastname, email, phone, isRegistering, errorMessage } = this.state;

        return (
            <DocumentTitle title={ PageTitle.replace('{title}', title) }>
                <div className="position-relative user_access_login">
                <div className="blue_gd_bg" style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageBackgroundColor) ? {background: LoginPageCustomizationOptions.LoginPageBackgroundColor} : {}}>&nbsp;</div> 
                    <div className="container position-relative pd-bot-50 mb-pd-bot-30">
                        <div className="row">
                            <div className="float-none col-md-5 col-sm-7 mx-auto">
                                <div className="pd-top-25 pd-bot-25 large-heading mb-pd-top-15 mb-pd-bot-15">
                                    <h2 style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageTitleTextColor) ? {color: LoginPageCustomizationOptions.LoginPageTitleTextColor} : {}}>Register</h2>
                                </div>
                                <div className="sign-up-htm">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {errorMessage && errorMessage !== "" && <div className="error_meg">{errorMessage}</div>}
                                            <div className="group mg-btm-25">
                                                <input name="firstname" value={firstname} type="text" placeholder="First Name" className="tru-input large" onChange={this.handleInputChange} />
                                            </div>
                                            <div className="group mg-btm-25">
                                                <input name="lastname" value={lastname} type="text" placeholder="Last Name" className="tru-input large" onChange={this.handleInputChange} />
                                            </div>
                                            <div className="group mg-btm-25">
                                                <input name="email" value={email} type="text" placeholder="Email Address" className="tru-input large" onChange={this.handleInputChange} />
                                            </div>
                                            <div className="group mg-btm-25">
                                                <input name="phone" value={phone} type="text" placeholder="Phone" className="tru-input large" onChange={this.handleInputChange} />
                                            </div>
                                            <div className="group mg-btm-25 reg_recaptcha">
                                                <ReCAPTCHA
                                                    sitekey={SITE_KEY}
                                                    ref={recaptchaRef}                                                    
                                                />
                                            </div>
                                            <div className="row"> 
                                                <div className="col-sm-12 col-xs-12">
                                                    <div className="row">
                                                        {isRegistering ?
                                                            (<div className="col-sm-12 col-xs-12 mg-btm-20"><input type="button" className="tru-btn tru-btn-big tru-btn-default full-large" value='Submiting...' /></div>) :
                                                            (<div className="col-sm-12 col-xs-12 mg-btm-20"><input type="button" className="tru-btn tru-btn-big tru-btn-default full-large" value='Submit' onClick={this.handleSubmit} /></div>)
                                                        }
                                                        <div className="col-sm-12 col-xs-12">Already have an account? <Link id="sign-link" className="ft-sz-12 tru-bold link-txt" to={`${this.props.purlPrefix.url}/login`}>Sign in</Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state, props) => ({
    purlPrefix: state.session.purlPrefix || { url: ''},
    session: state.session,
    audience: state.session.audience,
    profile: state.profile.mine,
    isFetchingProfile: state.profile.isFetching,
    isAnonymous: state.session.isAnonymous
});

const mapDispatchToProps = dispatch => ({
    sessionActions: bindActionCreators(sessionActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    listingActions: bindActionCreators(listingActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);