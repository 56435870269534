import React, { Component } from 'react';
import Loader from '../components/loader';

class PendingAuthentication extends Component{
    constructor(props) {
        super(props);
        this.state = {
            render: false
        }
    }

    componentDidMount() {
        // The delay is added to prevent the message from flashing on the screen
        setTimeout(function() { 
            //After 2 seconds, set render to true
            if(this.mainElement) {
                this.setState({render: true}); 
            }
        }.bind(this), 2000)
    }

    render(){
        if(this.state.render) { 
            return( 
            
                <div className="row" ref={(ref) => this.mainElement = ref}>
                    <div className="col-md-6 col-md-offset-3">
                        <h3>Our server is taking longer than expected to respond.</h3>
                        <Loader message={'Reconnecting to Server..'} />  
                    </div>
                </div>
   
            );
        }

        return(
            null
        )
    }
}

export default PendingAuthentication;