import union from 'lodash/union';
import {
    FEED_REQUEST,
    FEED_SUCCESS,
    FEED_FAILURE,
    SINGLE_FEED_REQUEST,
    SINGLE_FEED_SUCCESS,
    SINGLE_FEED_FAILURE
} from 'actions/feed';

const updatePagination = (state = {
  isFetching: false,
  ids: [],
  pageCount: 0,
  moreItems: true,
  error: undefined
}, action) => {
  switch (action.type) {
    case FEED_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case FEED_SUCCESS:
      const newIds = union(state.ids, action.response.result);

      return {
        ...state,
        isFetching: false,
        ids: newIds,
        pageCount: state.pageCount + 1,
        moreItems: state.ids.length < newIds.length,
        error: undefined
      }
    case FEED_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
}

const feed = (state = {}, action) => {
  switch (action.type) {
    case FEED_REQUEST:
    case FEED_SUCCESS:
    case FEED_FAILURE:
      const key = action.key;

      return {
        ...state,
        [key]: updatePagination(state[key], action)
      };
    case SINGLE_FEED_REQUEST:
      return {
        ...state,
        isSingleFetching: true,
        error: undefined,
        notificationMessage: undefined
      };  
    case SINGLE_FEED_SUCCESS:
      return {
        ...state,
        isSingleFetching: false,
        error: undefined
      };
    case SINGLE_FEED_FAILURE:
      return {
        ...state,
        isSingleFetching: false,
        error: action.error
      };
    default:
      return state;
  }
}

export default feed;

