import React from 'react';
import { connect } from 'react-redux';
import { IndexLinkContainer } from 'react-router-bootstrap'
import { Nav, Navbar, NavItem, NavDropdown} from 'react-bootstrap';
import { Link } from 'react-router-dom';


class NavbarBuilderSubSite extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isMobile: false, navExpanded: false }        

        this.truePlaceholder = 'http://truebytlc';
        this.trueCmsPlaceholder = 'http://cms.truebytlc';
    }

    componentDidMount() {
        if (window.innerWidth < 768) { 
            if(this.mainElement){
                this.setState({ isMobile: true });
            }
        }
        
        window.addEventListener("resize", this.updateIsMobile);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateIsMobile);
    }    

    updateIsMobile = () => {
        if(this.mainElement){
            this.setState({ isMobile: window.innerWidth < 768});
        }
    }

    hideAllSubmenus = () => {
        var submenus = document.getElementsByClassName('navbar-subsitemenu');
        for (var i = 0; i < submenus.length; i++)
            submenus[i].style.display = 'none';
    }

    closeNav = () => {
        if(this.mainElement){
            this.setState({ navExpanded: false });
        }
    }

    goTo = (url) => {
        this.props.history.push(url);
        this.closeNav();
    }

    contains = (url, key) => { return url.includes(key) }
    
    toUrl = (url) => { 
        var result = url.replace(this.truePlaceholder, '').replace(this.trueCmsPlaceholder, '/info');        
        if (this.props.purlPrefix && this.props.purlPrefix.url)
            result = this.props.purlPrefix.url + result;
        return result;
    }

    buildUrlWithMapping = (item) => {
        var result = item.url 
        if (item.url.includes(this.truePlaceholder) || item.url.includes(this.trueCmsPlaceholder)) {
            result = this.toUrl(item.url);
        }
        return result;
    }

    subMenuOnClick = (subChild) => {
        var url = this.buildUrlWithMapping(subChild);
        if (url !== subChild.url)
            this.goTo(url);
        else
            window.open(url)
    }

    buildDropDown = (item, i) => {
        const hasPermission = this.buildIndexLink(item, i) // if returns null means top level dropdown is true page that's hidden
        if (!hasPermission) return null;

       return (
           <NavDropdown key={i} id={"dropDown_" + i} className="dropDown" onMouseLeave={this.hideAllSubmenus} 
            title={item.title} onToggle={() => {}} // onToggle required to use open but we are using css hover
            >
                {item.children.map((child, i) => {
                    return this.buildIndexLink(child, i);                
                })}
            </NavDropdown>
        );
    }

    buildIndexLink = (item, i, isNestedLink) => {
        switch (true) {
            case this.contains(item.url, this.truePlaceholder):
                return (
                    <IndexLinkContainer key={i} to={this.toUrl(item.url)} className="menu-item-aboutus">
                        <NavItem>{item.title}</NavItem>
                    </IndexLinkContainer>
                );
            default:
                return <NavItem componentClass={isNestedLink ? 'span' : undefined} key={i}><span onClick={() => this.subMenuOnClick(item)}>{item.title}</span></NavItem>
        }
    }

    render() {

        const { purlPrefix } = this.props;
        var siteLogo = null;
        var menuItems = [];
        if(process.env.REACT_APP_WebsiteSubMenu !== undefined && process.env.REACT_APP_WebsiteSubMenu !== null &&
            process.env.REACT_APP_WebsiteSubMenu !== ""){
            const mJson = JSON.parse(process.env.REACT_APP_WebsiteSubMenu);
            siteLogo = mJson.logo || null;
            menuItems = mJson.menu || [];
        }

        return (
            <div className="top_multi_menu" ref={(ref) => this.mainElement = ref}>
                <Navbar inverse collapseOnSelect>
                    <Navbar.Header>
                        {siteLogo &&
                        <Navbar.Brand>
                            <Link to={`${purlPrefix.url}/`}><img alt="" src={siteLogo}/></Link>
                        </Navbar.Brand>
                        }
                        <Navbar.Toggle /> 
                    </Navbar.Header>
                    <Navbar.Collapse className="othersite_nav">
                        <Nav>
                            {menuItems && menuItems.map((item, i) => {
                                if (item.children && item.children.length > 0){
                                    return this.buildDropDown(item, i)
                                }
                                else {
                                    return this.buildIndexLink(item, i)
                                }
                            })}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    purlPrefix: state.session.purlPrefix || { url: ''},
});

export default connect(mapStateToProps)(NavbarBuilderSubSite);