import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProfilePhoto from 'img/profile_photo.jpg';
import { IndexLinkContainer } from 'react-router-bootstrap'
import { Popover, OverlayTrigger, NavItem, Button, Badge, MenuItem, DropdownButton } from 'react-bootstrap';
import SelectClient, {SelectedClientName} from 'components/selectclient';
import { GetSectionCustomizationOptions } from 'components/util';
import NavbarBuilderSubSite from 'components/navbarbuildersubsite';
import QuickBar from 'components/quickbar';
import NavbarBuilder from 'components/navbarbuilder';
import base64 from 'base-64';
import utf8 from 'utf8';
import 'css/navbar.css';
import * as sessionActions from 'actions/session';
import * as settingActions from 'actions/settings';
import * as profileActions from 'actions/profile';
import * as clientActions from 'actions/clients';
import * as listingActions from 'actions/listings';
import * as activityActions from 'actions/activity';
import * as agentActions from 'actions/agents';
import ReactTooltip from 'react-tooltip'
import ClientEULAText from 'components/clienteulatext';
import Dialog from 'react-bootstrap-dialog';
import Cookies from 'universal-cookie';
import 'scss/_wordpressMenu.scss';
import 'scss/_loginButton.scss';
import 'scss/_navbar.scss';


const ThemeCustomizationOptions = GetSectionCustomizationOptions("ThemeOptions");
const AdminCustomizationOptions = GetSectionCustomizationOptions("AdminOptions");
const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");
const ProfilePageCustomizationOptions = GetSectionCustomizationOptions("ProfilePageOptions");


class NavBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showQuickBar: false, 
            nExpanded: false, 
            activityFiltered: false,
            isMobile: false
        };        
    }

    componentDidMount() {
        this.componentInit(this.props.audience, this.props.profile);
        this.eulaCheck();
        this.checkForActivities();
        window.addEventListener("resize", this.updateIsMobile);
    }

    componentInit = (audience, profile) => {        
        /* const { profileActions, clientActions, settingActions  } = this.props;

        if(audience && audience.Id && !profile){
            profileActions.fetchProfile();
            settingActions.fetchSettings();
            settingActions.fetchAuthMechanism();
            if(CommonCustomizationOptions && CommonCustomizationOptions.FetchPrintPieceUrl){
                settingActions.fetchPrintPieceURL();
            }            
        }
        if (audience && audience.type === 'client' && !this.props.agent) {
            profileActions.fetchMyAgentProfile();
            if(CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers && this.props.agentActions){
                this.props.agentActions.fetchTeamAgents();
            }
        }
        if (audience && audience.type === 'agent') {
            if(!this.props.isAnonymous){
                clientActions.fetchClients();
                this.props.listingActions.fetchAgentClientListings("agentlistings");
            }
            if(CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers && this.props.agentActions){
                this.props.agentActions.fetchTeamAgents();
            }
        } */

        if (window.innerWidth < 768) { 
            this.setState({ isMobile: false });
        }
    }

    checkForActivities = () => {
        var self = this;
        setTimeout(function isActivityLoaded() {
            if (!self.props.selectedActivity || !self.props.selectedActivity.ids.length || !self.props.profile) {
                setTimeout(isActivityLoaded, 5000);
            }
            else {
                self.filterUnreadIds();
                self.setState({activityFiltered: true});
            }
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateIsMobile);
    }

    updateIsMobile = () => {
        //this.setState({ isMobile: window.innerWidth < 768});
    }

    filterUnreadIds() {
        const { activityActions, profile, selectedClient} = this.props;

        const cookies = new Cookies();
        var lastReadId = cookies.get('lastReadId');
        if (lastReadId && selectedClient && lastReadId[selectedClient.Id]) {
            activityActions.filterUnreadIds(selectedClient.Id, lastReadId[selectedClient.Id]);
        }
        else if (lastReadId && profile && lastReadId[profile.Email]) {
            activityActions.filterUnreadIds('mine', lastReadId[profile.Email]);
        }
        else {
            console.log('no cookie found. selectedClient:' + selectedClient + ' , profile: ' + profile);
        }
    }

    componentDidUpdate(){
        this.eulaCheck();
    }

    componentWillReceiveProps(nextProps) {
        if(JSON.stringify(this.props.audience) !== JSON.stringify(nextProps.audience)){
            this.componentInit(nextProps.audience, nextProps.profile);
        }
        if(JSON.stringify(this.props.profile) !== JSON.stringify(nextProps.profile)){
            this.eulaCheck();
        }

        const thisClient = this.props.selectedClient;
        const nextClient = nextProps.selectedClient;

        // Deselect client
        if (thisClient && !nextClient) {
            const cName = thisClient.FirstName + " " + thisClient.LastName;
            this.props.showNotification(
                "info",
                "",
                "Now you are not working with " + cName + ".",
                4000);

            if(this.refs.navBar)
            {
                this.setState({nExpanded: false});
            }
        }

        // Select client
        if (!thisClient && nextClient) {
            const cName = nextClient.FirstName + " " + nextClient.LastName;
            if(this.props.showNotification) {
                this.props.showNotification(
                    "success",
                    "",
                    "Now you are working with " + cName +
                    ". All bookmarks and saved searches will be assigned to " + cName + ".",
                    5000);
            }
            this.props.listingActions.fetchAgentClientListings("favorites", nextClient.Id);
            this.props.listingActions.fetchAgentClientListings("bookmarks", nextClient.Id);
            this.props.listingActions.fetchAgentClientListings("removed", nextClient.Id);
            this.props.listingActions.fetchAgentClientListings("agentlistings", nextClient.Id);
            if(this.refs.navBar)
            {
                this.setState({nExpanded: false});
            }
        }
        else if (thisClient && !nextClient){
            this.props.listingActions.clearListings("favorites");
            this.props.listingActions.clearListings("bookmarks");
            this.props.listingActions.clearListings('removed');
            this.props.listingActions.clearListings("agentlistings");
            // if you're on the clients page when deselecting go back to clients
            if (this.props.location.pathname.indexOf('/clients/') >= 0)
                this.props.history.push('/dashboard/clients');
        }
    }

    openEULADialog() {
        let self = this;
        Dialog.setOptions({
            defaultCancelLabel: 'Close'
        });
        this.refs.dialogRef.show({
            body: <ClientEULAText EULAText={self.props.EULAText} />,
            bsClass: 'modal',
            bsSize: 'large',
            actions: [
                Dialog.Action(
                    'Accept',
                    () => {
                        self.acceptEULA();
                    }
                  )
            ],
            onHide: (dialog) => { }
        })
    }

    acceptEULA() {
        let self = this;

        const cookies = new Cookies();
        var eulaCookie = cookies.get('EULAUserAcceptance');
        var objEULA = {};

        if(eulaCookie !== undefined && 
            eulaCookie !== null && 
            eulaCookie !== "" && 
            this.props && 
            this.props.profile)
        {
            objEULA = eulaCookie;
        }

        objEULA[self.props.profile.Email] = 'true';
        var ninetyDays = new Date();
        ninetyDays.setDate(new Date().getDate()+90);
        cookies.set('EULAUserAcceptance', JSON.stringify(objEULA), { expires: ninetyDays });
    }

    toggleQuickBar = () => {
        if(this.refs.navBar){
            this.setState({showQuickBar: !this.state.showQuickBar});
        }
        if (this.props.selectedActivity) {
            const cookies = new Cookies();
            const lastReadId = Math.max.apply(Math, this.props.selectedActivity.ids);
            var objLastRead = {};
            var lastReadCookie = cookies.get('lastReadId');
            if (lastReadCookie)
                objLastRead = lastReadCookie;
            if (this.props.selectedClient)
                objLastRead[this.props.selectedClient.Id] = lastReadId;
            else
                objLastRead[this.props.profile.Email] = lastReadId;
                
            var ninetyDays = new Date();
            ninetyDays.setDate(new Date().getDate()+90);
            cookies.set('lastReadId', JSON.stringify(objLastRead), { expires: ninetyDays, path: '/'});
            this.filterUnreadIds();
        }
    }   
   

    closeSelectClientPopup = () => {
        if(this.overlayTriggerRef){
            this.overlayTriggerRef.hide();
        }

        if(this.refs.navBar)
        {
            this.setState({nExpanded: false});
        }
    }    

    eulaCheck() {
        if(this.refs.dialogRef !== undefined && this.props.audience && this.props.audience.type === 'client'){
            if(this.props.EULAText !== undefined && this.props.EULAText !== null && this.props.EULAText !== ""){
                if (this.props === undefined || this.props.profile === undefined || this.props.profile === null){ 
                    return;
                }

                const cookies = new Cookies();
                var eulaCookie = cookies.get('EULAUserAcceptance');
                if(eulaCookie !== undefined && eulaCookie !== null && eulaCookie !== ""){
                    if(eulaCookie[this.props.profile.Email] && eulaCookie[this.props.profile.Email] === 'true'){                        
                        return;
                    }
                }   
                // Eula wasn't found in cookie
                if (window.location.pathname !== '/personalize' && window.location.pathname.indexOf('/login') === -1 && window.location.pathname.indexOf('/register') === -1){
                    this.openEULADialog();
                }
            }
        }
    }

    onSubmenuDisplay = (a) => {
        this.setState({ isMobile: !this.state.isMobile});
        a.preventDefault();
        a.stopPropagation();
    }

    handleLogout = () => {
        this.props.sessionActions.logout();
        this.props.history.push(this.props.purlPrefix.url + '/');
    }

    getNotificationBadge = () => {
        const { isAnonymous, purlPrefix, selectedActivity, profile } = this.props;
        const { activityFiltered } = this.state;

        if(!isAnonymous && profile){
            return (                
                <div className= {(purlPrefix && purlPrefix.agent && purlPrefix.agent.Profile && purlPrefix.url) ? "header-list-right with-purl" : "header-list-right"}>
                    <Button onClick={this.toggleQuickBar} className="quick-bar-btn menu-item-quickbar">
                        <i className="icon-chatortimelineicon"></i>
                        {selectedActivity && selectedActivity.unreadIds && selectedActivity.unreadIds.length > 0 && activityFiltered &&
                            <Badge className="notification-badge">{selectedActivity.unreadIds.length}</Badge>
                        }
                    </Button>
                </div>                
            );
        }

        return null;
    }

    getUserMenu = () => {
        const { isAnonymous, purlPrefix, profile, audience } = this.props;        
        if(!isAnonymous && profile){

            var userName = null;
            var userEmail = null;        
            var uPhoto = <img alt="" className="img-circle" src={ProfilePhoto}/>;        
            if(profile && audience){
                if(audience.type === 'agent') {
                    uPhoto = (
                        <div className="tru-oval primary-oval">
                            <div className="tru-oval_text">{profile.FirstName.substring(0, 1) + profile.LastName.substring(0, 1)}</div>
                        </div>
                    );
                    userName = profile.FirstName + ' ' + profile.LastName;
                    userEmail = profile.Email;
                }
                else if(audience.type === 'client' && profile.Profile && profile.Profile.BasicData){
                    uPhoto = (
                        <div className="tru-oval primary-oval">
                            <div className="tru-oval_text">{profile.Profile.BasicData.FirstName.substring(0, 1) + profile.Profile.BasicData.LastName.substring(0, 1)}</div>
                        </div>
                    );
                    userName = profile.Profile.BasicData.FirstName + ' ' + profile.Profile.BasicData.LastName;
                    userEmail = profile.Profile.BasicData.Email;
                }
                if(audience.type === 'agent') {

                    if(ProfilePageCustomizationOptions && ProfilePageCustomizationOptions.AllowAgentToUpdateProfile){
                        if(profile && profile.UseMemberPhotoURL) {
                            if(profile && profile.MemberPhotoURL !== undefined &&
                                profile.MemberPhotoURL !== null && profile.MemberPhotoURL !== "") {
                                uPhoto = <img alt="" className="img-circle" src={profile.MemberPhotoURL}/>;
                            }
                        }
                        else{
                            if(profile && profile.Profile &&
                                profile.Profile.ProfileImageName !== undefined &&
                                profile.Profile.ProfileImageName !== null &&
                                profile.Profile.ProfileImageName !== "") {
                                uPhoto = <img alt="" className="img-circle" src={profile.Profile.ProfileImageName}/>;
                            }
                        }
                    }
                    else{
                        if(profile && profile.Profile &&
                            profile.Profile.ProfileImageName !== undefined &&
                            profile.Profile.ProfileImageName !== null &&
                            profile.Profile.ProfileImageName !== "") {
                            uPhoto = <img alt="" className="img-circle" src={profile.Profile.ProfileImageName}/>;
                        }
                    }
                }
                else if(audience.type === 'client' &&
                    profile.Profile &&
                    profile.Profile.BasicData &&
                    profile.Profile.BasicData.ProfileImageUrl !== undefined &&
                    profile.Profile.BasicData.ProfileImageUrl !== null &&
                    profile.Profile.BasicData.ProfileImageUrl !== "") {
                        uPhoto = <img alt="" className="img-circle" src={profile.Profile.BasicData.ProfileImageUrl}/>;
                }
            }

            return (
                <NavItem componentClass='div' className="menu-item-profile profile_drop_menu  profile_image">
                    {(window.innerWidth < 1026) ?
                        <DropdownButton title={uPhoto} id="dd-user-menu" open={this.state.isMobile} onToggle={() => {}} onClick={(e) => this.onSubmenuDisplay(e)}>
                            <div className="agent_pro_name">
                                <span className="display-bk agent_name"><span className="font-bold ft-sz-13 mg-rt-5"></span>{userName}</span>
                                <span className="display-bk agent_email">{userEmail}</span>
                            </div>
                            <IndexLinkContainer to={`${purlPrefix.url}/profile`}>
                                <MenuItem>Profile</MenuItem>
                            </IndexLinkContainer>
                            {(AdminCustomizationOptions && AdminCustomizationOptions.AllowManage && profile && profile.IsSuperAdmin) &&
                                <IndexLinkContainer to={`${purlPrefix.url}/manage`}>
                                    <MenuItem>Manage</MenuItem>
                                </IndexLinkContainer>
                            }
                            <div className="logout_btn" onClick={this.handleLogout}>
                                <div>Logout</div>
                            </div>
                        </DropdownButton>
                        :
                        <DropdownButton title={uPhoto} id="dd-user-menu">
                            <div className="agent_pro_name">
                                {/* <span className="display-bk agent_name"><span className="font-bold ft-sz-13 mg-rt-5">Myself : </span>{userName}</span> */}
                                <span className="display-bk agent_name"><span className="font-bold ft-sz-13 mg-rt-5"></span>{userName}</span>
                                <span className="display-bk agent_email">{userEmail}</span>
                            </div>
                            <IndexLinkContainer to={`${purlPrefix.url}/profile`}>
                                <MenuItem>Profile</MenuItem>
                            </IndexLinkContainer>
                            {(AdminCustomizationOptions && AdminCustomizationOptions.AllowManage && profile && profile.IsSuperAdmin) &&
                                <IndexLinkContainer to={`${purlPrefix.url}/manage`}>
                                    <MenuItem>Manage</MenuItem>
                                </IndexLinkContainer>
                            }
                            <div className="logout_btn" onClick={this.handleLogout}>
                                <div>Logout</div>
                            </div>
                        </DropdownButton>
                    }
                </NavItem>
            );
        }

        return null;
    }

    getLoginButton = () => {
        const cookies = new Cookies();
        const authToken = cookies.get('authDetails');
        const { isAnonymous, purlPrefix } = this.props;

        if(!authToken && isAnonymous){
            return(
                ((CommonCustomizationOptions && CommonCustomizationOptions.ShowAgentClientLoginInMenu) ?
                    <NavItem componentClass='div' className="menu-item-profile user_button_login">
                        {(window.innerWidth < 1026) ?
                            <DropdownButton title="Login" id="nested-login" open={this.state.isMobile} onToggle={() => {}} onClick={(e) => this.onSubmenuDisplay(e)}>
                                <IndexLinkContainer to={(CommonCustomizationOptions && CommonCustomizationOptions.IsAgentSSOEnabled) ? purlPrefix.url + '/sso' : `${purlPrefix.url}/login`} 
                                    onClick={() => {
                                        if(CommonCustomizationOptions && CommonCustomizationOptions.IsAgentSSOEnabled){
                                            window.sessionStorage.setItem("ComingForSSO",true);
                                        }
                                        else{
                                            if(window.location.pathname !== '/' && window.location.pathname !== '/profile'){
                                                window.sessionStorage.setItem("RedirectLocation",base64.encode(utf8.encode(window.location.pathname)));
                                            }
                                        }
                                        return true;
                                    }}>
                                    <NavItem>Agent</NavItem>
                                </IndexLinkContainer>
                                <IndexLinkContainer to={`${purlPrefix.url}/login`}
                                    onClick={() => {
                                        if(window.location.pathname !== '/' && window.location.pathname !== '/profile'){
                                            window.sessionStorage.setItem("RedirectLocation",base64.encode(utf8.encode(window.location.pathname)));
                                        }
                                        return true;
                                    }}>
                                    <NavItem>Client</NavItem>
                                </IndexLinkContainer>
                            </DropdownButton>
                            :
                            <DropdownButton title="Login" id="nested-login">
                                <IndexLinkContainer to={(CommonCustomizationOptions && CommonCustomizationOptions.IsAgentSSOEnabled) ? purlPrefix.url + '/sso' : `${purlPrefix.url}/login`} 
                                    onClick={() => {
                                        if(CommonCustomizationOptions && CommonCustomizationOptions.IsAgentSSOEnabled){
                                            window.sessionStorage.setItem("ComingForSSO",true);
                                        }
                                        else{
                                            if(window.location.pathname !== '/' && window.location.pathname !== '/profile'){
                                                window.sessionStorage.setItem("RedirectLocation",base64.encode(utf8.encode(window.location.pathname)));
                                            }
                                        } 
                                        return true;
                                    }}>
                                    <NavItem>Agent</NavItem>
                                </IndexLinkContainer>
                                <IndexLinkContainer to={`${purlPrefix.url}/login`}
                                    onClick={() => {
                                        if(window.location.pathname !== '/' && window.location.pathname !== '/profile'){
                                            window.sessionStorage.setItem("RedirectLocation",base64.encode(utf8.encode(window.location.pathname)));
                                        }
                                        return true;
                                    }}>
                                    <NavItem>Client</NavItem>
                                </IndexLinkContainer>
                            </DropdownButton>
                        }
                    </NavItem>
                    :
                    <IndexLinkContainer to={`${purlPrefix.url}/login`} className="menu-item-profile user_button_login"
                        onClick={() => {
                            if(window.location.pathname !== '/' && window.location.pathname !== '/profile'){
                                window.sessionStorage.setItem("RedirectLocation",base64.encode(utf8.encode(window.location.pathname)));
                            }
                            return true;
                        }}>
                        <NavItem className="user_button_box_text">Login</NavItem>
                    </IndexLinkContainer>
                )
            );
        }

        return null;
    }

    getRegisterButton = () => {
        const cookies = new Cookies();
        const authToken = cookies.get('authDetails');
        const { isAnonymous, purlPrefix } = this.props;
        if(!authToken && isAnonymous){
            return (
                <IndexLinkContainer to={`${purlPrefix.url}/register`} className="menu-item-profile user_button_register">
                    <NavItem className="user_button_box_text">Register</NavItem>
                </IndexLinkContainer>
            );
        }

        return null;
    }

    getClientMenu = () => {
        const { isAnonymous, audience, location, agent, purlPrefix, 
            selectedClient, isFetchingClients, clients, profile } = this.props;
        if(!isAnonymous && profile){

            var showMyAgent = true;
            if (process.env.REACT_APP_AllowAnonymous === "true" && process.env.REACT_APP_AnonymousUserId !== undefined && 
                process.env.REACT_APP_AnonymousUserId !== null && agent && agent.AgentId && 
                agent.AgentId === process.env.REACT_APP_AnonymousUserId){
                showMyAgent = false;
            }

            var divClient = [];
            var isSearch = (location && location.pathname.indexOf('/search') !== -1);
            var menuItemSelect = isSearch ? 'menu-item-selectclient selectclient_search': 'menu-item-selectclient'
            if(audience.type === 'agent') {

                var scPopCls = "top-select-client";
                if(ThemeCustomizationOptions && ThemeCustomizationOptions.MainMenuBarClass && ThemeCustomizationOptions.MainMenuBarClass !== ""){
                    scPopCls = scPopCls + " " + ThemeCustomizationOptions.MainMenuBarClass;
                }
                else {
                    scPopCls = scPopCls + " menu-standard";
                }
                if(process.env.REACT_APP_WebsiteSubMenu !== undefined && process.env.REACT_APP_WebsiteSubMenu !== null && process.env.REACT_APP_WebsiteSubMenu !== ""){
                    scPopCls = scPopCls + " othersite_menubox";
                }
                var selectClientPopover = (
                    <Popover id="popover-select-client" className={scPopCls}>
                        <div className={`select-client-popover add-select-client${selectedClient ? '' : ' no-selection'}`}>
                            <SelectClient
                                clientActions={ this.props.clientActions }
                                clients={ clients }
                                isFetching={ isFetchingClients }
                                selectedClient={ selectedClient }
                                closeSelectClientPopup={this.closeSelectClientPopup} />
                        </div>
                    </Popover>
                );

                divClient = (
                    <OverlayTrigger trigger="click" placement={window.innerWidth < 768 ? "bottom" : "left"} rootClose overlay={selectClientPopover} ref={(ref) => this.overlayTriggerRef = ref}>
                        <li className={menuItemSelect}>
                            <SelectedClientName selectedClient={selectedClient}></SelectedClientName>
                        </li>
                    </OverlayTrigger>
                );
                
            }
            else if (audience.type === 'client' && agent && showMyAgent){

                var myAgentPhoto = <img alt="" className="img-circle" src={ProfilePhoto}/>;        
                var myAgentName = null;
                var myAgentEmail = null;
                if(agent && agent.FirstName && agent.LastName){
                    myAgentPhoto = (
                        <div className="tru-oval">
                            <div className="tru-oval_text">{agent.FirstName.substring(0, 1) + agent.LastName.substring(0, 1)}</div>
                        </div>
                    );
                    myAgentName = agent.FirstName + ' ' + agent.LastName;
                    myAgentEmail = agent.Email;
                }
                if(ProfilePageCustomizationOptions && ProfilePageCustomizationOptions.AllowAgentToUpdateProfile){
                    if(agent.UseMemberPhotoURL) {
                        if(agent.MemberPhotoURL !== undefined && agent.MemberPhotoURL !== null && agent.MemberPhotoURL !== "") {
                            myAgentPhoto = <img alt="" className="img-circle" src={agent.MemberPhotoURL} />;
                        }
                    }
                    else{
                        if(agent.Profile && agent.Profile.ProfileImageName !== undefined &&
                            agent.Profile.ProfileImageName !== null && agent.Profile.ProfileImageName !== "") {
                            myAgentPhoto = <img alt="" className="img-circle" src={agent.Profile.ProfileImageName} />;
                        }
                    }
                }
                else{
                    if(agent.Profile && agent.Profile.ProfileImageName !== undefined &&
                        agent.Profile.ProfileImageName !== null && agent.Profile.ProfileImageName !== "") {
                        myAgentPhoto = <img alt="" className="img-circle" src={agent.Profile.ProfileImageName} />;
                    }
                }                

                divClient = (
                    (window.innerWidth < 768) ?
                    (<li className={menuItemSelect}>
                        <Link to={`${purlPrefix.url}/myagent`} className="select-client">
                            <div className="display-bk text-center">My Agent: 
                                <span className="pd-lft-5 tru-bold">{myAgentName}</span>
                            </div>
                        </Link>
                    </li>) :
                    (<li className="profile_drop_menu myagent_drop_menu profile_image">
                        <DropdownButton title={myAgentPhoto} id="myagent-login">
                            <div className="agent_pro_name">
                                <span className="display-bk agent_name"><span className="font-bold ft-sz-13 mg-rt-5">My Agent : </span>{myAgentName}</span>
                                <span className="display-bk agent_email">{myAgentEmail}</span>
                            </div>
                            <IndexLinkContainer to={`${purlPrefix.url}/myagent`}>
                                <NavItem>Agent's Profile</NavItem>
                            </IndexLinkContainer>
                            <IndexLinkContainer to={`${purlPrefix.url}/myagent/suggestions`}>
                                <NavItem>Agent's Suggestions</NavItem>
                            </IndexLinkContainer>
                            <IndexLinkContainer to={`${purlPrefix.url}/myagent/listings`}>
                                <NavItem>Agent's Listings</NavItem>
                            </IndexLinkContainer>
                            <IndexLinkContainer to={`${purlPrefix.url}/myagent/contact`}>
                                <NavItem>Contact Agent</NavItem>
                            </IndexLinkContainer>
                        </DropdownButton>
                    </li>)
                );
            }

            return (
                <ul className="select_client_menu">
                    {divClient}
                </ul>
            );
        }

        return null;
    }

    buildMenuBar = () => {
        const { isAnonymous, audience, history, clients } = this.props;
        return (
            <div>
                <div className="header-top-bar">
                    <div className="fluid row">                        
                        {this.getNotificationBadge()}
                    </div>
                </div>
                {(process.env.REACT_APP_WebsiteSubMenu !== undefined && process.env.REACT_APP_WebsiteSubMenu !== null && process.env.REACT_APP_WebsiteSubMenu !== "") &&
                    <NavbarBuilderSubSite history={history} />
                }
                <div className="header-content">
                    <div className="fluid row">
                        <NavbarBuilder 
                            audience={audience} 
                            isAnonymous={isAnonymous} 
                            history={history} 
                            clients={clients} 
                            uPhoto={this.getUserMenu()}
                            loginLink={this.getLoginButton()}
                            registerLink={this.getRegisterButton()}>
                            {this.getClientMenu()}
                        </NavbarBuilder>                    
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { isAnonymous } = this.props;
        
        var headerClass = "header animated header-nonfixed";
        if (this.props.isHeaderFixed) {
            headerClass = "header animated header-fixed";
        }
        if(process.env.REACT_APP_WebsiteSubMenu !== undefined && process.env.REACT_APP_WebsiteSubMenu !== null &&
            process.env.REACT_APP_WebsiteSubMenu !== ""){
            headerClass = headerClass + " othersite_menubox";
        }

        return (
            <header className={headerClass} ref="navBar">
                <ReactTooltip />
                {this.buildMenuBar()}
                {!isAnonymous &&
                    <QuickBar showQuickBar={this.state.showQuickBar} onQuickBarClose={this.toggleQuickBar} />
                }
                <Dialog ref='dialogRef' style={{zIndex: 99999}} />
            </header>
        );
    }
}

const mapStateToProps = (state, props) => {
    
    const { feed, entities, clients, activity } = state;
    const selectedClient = entities.clients[clients.selectedClient];
    
    let selectedFeed;
    if (selectedClient) {
        selectedFeed = feed[selectedClient.Id];
    }
    else {
        selectedFeed = feed['mine'];
    }

    let selectedActivity;
    if (selectedClient) {
        selectedActivity = activity[selectedClient.Id];
    }
    else {
        selectedActivity = activity['mine'];
    }

    return {
        audience: state.session.audience,
        purlPrefix: state.session.purlPrefix || { url: ''},
        mlsDisplayName: state.settings.data['MLSDisplayName'],
        isFetchingProfile: state.profile.isFetching,
        profile: state.profile.mine,
        clients: state.clients.ids.map(id => state.entities.clients[id]) || [],
        selectedClient: state.entities.clients[state.clients.selectedClient],
        isFetchingClients: state.clients.isFetching,
        agent: state.profile.agent,
        selectedFeed,
        selectedActivity,
        EULAText: state.settings.data['EULAText'],
        isAnonymous: state.session.isAnonymous
    };
};

const mapDispatchToProps = dispatch => ({
    sessionActions: bindActionCreators(sessionActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
    clientActions: bindActionCreators(clientActions, dispatch),
    listingActions: bindActionCreators(listingActions, dispatch),
    activityActions: bindActionCreators(activityActions, dispatch),
    agentActions: bindActionCreators(agentActions, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));