//import { CALL_API, Schemas } from '../store/api';
//import { normalize, schema } from 'normalizr';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const UPDATE_PARAMS_REQUEST = 'UPDATE_PARAMS_REQUEST';
export const RESET_PARAMS_REQUEST = 'RESET_PARAMS_REQUEST';

// export const fetchListings = (key, body) => ({
//     [CALL_API]: {
//         types: [SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE],
//         method: 'POST',
//         body: {
//             limitrecords: "100",
//             ficoscore: "760-850",
//             loantype: "30YrFixed",
//             lifestyle: "couple-no-kids",
//             orderby: "lastupdated",
//             orderbydirection: "ASC",
//             skip: 0,
//             propertytype: "sf",
//             ...body
//         },
//         endpoint: 'listingsdata'//,
//         //schema: Schemas.LISTING_ARRAY
//     },
//     key: key
// });

export const updateParameters = (parameters, skipFlag) => (dispatch, getState) => {
    return dispatch({type: UPDATE_PARAMS_REQUEST, parameters: parameters, skipFlag: skipFlag ? skipFlag : false })
}

export const resetParameters = (params) => (dispatch, getState) => {
    return dispatch({type: RESET_PARAMS_REQUEST, params })
}

