import React from 'react';
import { connect } from 'react-redux';
import { GetSectionCustomizationOptions } from 'components/util';
import facebookIcon from '../img/svg/facebook.svg'
import linkedinIcon from '../img/svg/linkedin.svg'
import twitterIcon from '../img/svg/twitter.svg';
import instagramIcon from '../img/InstagramC.png';
import youtubeIcon from '../img/youtubeC.png';
import 'css/contact_agent.css';

const FooterCustomizationOptions = GetSectionCustomizationOptions("FooterOptions");
const AdminCustomizationOptions = GetSectionCustomizationOptions("AdminOptions");

class FooterStyleCompact extends React.Component{

    constructor(props) {
        super(props);

        this.state = { };
    }

    render(){

        var crText = null;
        if(FooterCustomizationOptions && FooterCustomizationOptions.CopyRightTextInFooter && FooterCustomizationOptions.CopyRightTextInFooter !== ""){
            crText = FooterCustomizationOptions.CopyRightTextInFooter;
            crText = crText.replace(/COPYRYEAR/g,new Date().getFullYear());
            var crArr = crText.split('COPYRSYMBOL');
            if(crArr && crArr.length > 0){
                crText = [];
                crArr.map((s, i) => {
                    if(s && s !== ""){
                        crText.push(<span key={i}>{s}</span>);
                    }
                    if(i !== (crArr.length - 1)){
                        crText.push(<span key={'sq_' + i}>&copy;</span>);
                    }
                    return null;
                });
            }
        }
        
        return(
            <div className="footerstyle_cnt">
                <div className="fluid row footer-content">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="footer_social text-center">
                                    {FooterCustomizationOptions && FooterCustomizationOptions.FacebookPageUrl &&
                                        <a href={FooterCustomizationOptions.FacebookPageUrl} target="_blank" rel="noopener noreferrer"><img src={facebookIcon} alt="Facebook"/></a>
                                    }
                                    {FooterCustomizationOptions && FooterCustomizationOptions.TwitterPageUrl && 
                                        <a href={FooterCustomizationOptions.TwitterPageUrl} target="_blank" rel="noopener noreferrer"><img src={twitterIcon} alt="Twitter"/></a>
                                    }
                                    {FooterCustomizationOptions && FooterCustomizationOptions.LinkedInPageUrl && 
                                        <a href={FooterCustomizationOptions.LinkedInPageUrl} target="_blank" rel="noopener noreferrer"><img src={linkedinIcon} alt="LinkedIn"/></a>
                                    }
                                    {FooterCustomizationOptions && FooterCustomizationOptions.InstagramPageUrl && 
                                        <a href={FooterCustomizationOptions.InstagramPageUrl} target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="Instagram"/></a>
                                    }
                                    {FooterCustomizationOptions && FooterCustomizationOptions.YouTubePageUrl && 
                                        <a href={FooterCustomizationOptions.YouTubePageUrl} target="_blank" rel="noopener noreferrer"><img src={youtubeIcon} alt="Youtube"/></a>
                                    }
                                </div>                                
                                {FooterCustomizationOptions && FooterCustomizationOptions.FooterLogoUrl && FooterCustomizationOptions.FooterLogoUrl !== "" &&
                                    <div className="footer_logo">
                                        <a href="/"><img src={FooterCustomizationOptions.FooterLogoUrl} alt="Re Max Revolution"/></a>
                                    </div>
                                }                                
                                <div className="copyright">
                                    <div className="fluid row copyright-text">
                                        {FooterCustomizationOptions && FooterCustomizationOptions.FooterAddress &&
                                            <div className="comp_address">{FooterCustomizationOptions.FooterAddress}</div>
                                        }
                                        {FooterCustomizationOptions && FooterCustomizationOptions.FooterSupportEmail && FooterCustomizationOptions.FooterSupportEmail !== "" &&
                                            <a className="support-item-email mg-btm-5 display-ib" href={"mailto:" + FooterCustomizationOptions.FooterSupportEmail}>{FooterCustomizationOptions.FooterSupportEmail}</a>
                                        }
                                        {FooterCustomizationOptions && FooterCustomizationOptions.ShowFeedbackLinkInFooter &&
                                            <span className="mg-btm-5" style={{marginLeft: 5, paddingLeft: 5, borderLeft: "1px solid #fff"}}><a className="support-item-email" href="/feedback">Feedback</a></span>
                                        }
                                        {(AdminCustomizationOptions && AdminCustomizationOptions.AllowManage && this.props.profile && this.props.profile.IsSuperAdmin) &&
                                            <span className="mg-btm-5" style={{marginLeft: 5, paddingLeft: 5, borderLeft: "1px solid #fff"}}><a className="support-item-email" href="/manage">Manage</a></span>
                                        }
                                        <br/>
                                        {<span>{crText}</span>}
                                        {FooterCustomizationOptions && FooterCustomizationOptions.LicenseTextInFooter && FooterCustomizationOptions.LicenseTextInFooter !== "" &&
                                            <div>{FooterCustomizationOptions.LicenseTextInFooter}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                          
                </div>                    
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    profile: state.profile.mine || {},
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterStyleCompact);