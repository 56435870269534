import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import logo from '../img/login_logo.png';
import { Link } from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';
import TermsNConditions from '../components/termsconditions';
import PrivacyPolicy from '../components/privacypolicy';
import queryString from 'query-string';
import Loader from '../components/loader';
import DocumentTitle from 'react-document-title';
import { PageTitle } from '../components/util';
import '../css/Login.css';
import * as sessionActions from 'actions/session';


class CreateAccountPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        var params = queryString.parse(props.location.search);
        const subId = (params && params.id) ? params.id : null;
        const verifCode = (params && params.code) ? params.code : null;

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            mlsId: '',
            memberNrdsId: '',
            subscriptionId: subId,
            verificationCode: verifCode,
            errorMessage: '',
            successMessage: '',
            fetchingDetails: false,
            creatingAccount: false,
            accountCreated: false,
            title: 'Create Account'
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSubscriptionDetails = this.getSubscriptionDetails.bind(this);
        this.createAccount = this.createAccount.bind(this);
    }

    componentDidMount() {
        if (this.props.showHeader) {
            this.props.onShowHideHeader(false);
        }
        if (this.props.showFooter) {
            this.props.onShowHideFooter(false);
        }
        this.getSubscriptionDetails();
    }

    openTermsDialog = () => {
        Dialog.setOptions({
            defaultCancelLabel: 'Close'
        });
        this.refs.dialogRef.show({
            body: <TermsNConditions />,
            bsSize: 'large',
            actions: [
                Dialog.CancelAction(),
            ]
        })
    }
    openPolicyDialog = () => {
        Dialog.setOptions({
            defaultCancelLabel: 'Close'
        });
        this.refs.dialogRef.show({
            body: <PrivacyPolicy />,
            bsSize: 'large',
            actions: [
                Dialog.CancelAction(),
            ]
        })
    }

    getSubscriptionDetails() {

        var self = this;

        // Call API to get subscription details
        var url = window.sessionStorage.getItem("RootAPI") + "subscriptionrequest/detail/" + this.state.subscriptionId + "/" +this.state.verificationCode;

        if(self.refs.btnSignup) self.refs.btnSignup.setAttribute("disabled", "disabled");
        self.setState({fetchingDetails: true});
        fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "GET"
            })
            .then(response =>  response.json())
            .then(json => {
                //console.log(json);
                if (json) {
                    if(self.refs.btnSignup) self.refs.btnSignup.removeAttribute("disabled");
                    self.setState({
                        fetchingDetails: false, firstName: json.FirstName, lastName: json.LastName, 
                                    email: json.Email, memberNrdsId:json.MemberNrdsId
                    });

                    if (json.MLSKey !== undefined)  {
                        self.setState({mlsId:json.MLSKey.toLowerCase()});
                    }
                    //self.props.history.push(`/`);
                }
                else {
                    if(self.refs.btnSignup) self.refs.btnSignup.removeAttribute("disabled");
                    self.setState({
                        fetchingDetails: false,
                        errorMessage: "This link is invalid or expired. Please contact support@truebytlc.com"
                    });
                }
            })
            .catch(function (data) {
                //console.log(data);
                if(self.refs.btnSignup) self.refs.btnSignup.removeAttribute("disabled");
                self.setState({fetchingDetails: false, errorMessage: "There was an error fetching your details. Please try again later."});
            });
    }

    createAccount() {

        var self = this;

        // Call API to get subscription details
        var url = window.sessionStorage.getItem("RootAPI") + "subscriptionrequest/" + this.state.subscriptionId + "/" +this.state.verificationCode;

        var requestBody = {
            MLSKey: self.state.mlsId,
            MemberNrdsId: self.state.memberNrdsId
        }

        if(self.refs.btnSignup) self.refs.btnSignup.setAttribute("disabled", "disabled");
        self.setState({creatingAccount: true});
        fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(requestBody)
            })
            .then(response =>  response.json())
            .then(json => {
                //console.log(json);
                if (json) {
                    if(self.refs.btnSignup) self.refs.btnSignup.removeAttribute("disabled");
                    var accountCreated = false;
                    var successMessage = "", errorMessage = "";
                    if(json.AgentId){
                        successMessage = "Your Account has been created successfully. A welcome email with your username and password has been sent, please check the same.";
                        accountCreated = true;
                    }
                    else if(json.Audience && json.Audience.Id){
                        successMessage = "Your Account has been created successfully. A welcome email with your username and password has been sent, please check the same.";
                        accountCreated = true;
                    }
                    else if(json.length > 0 && json[0].ErrorMessage){
                        errorMessage = json[0].ErrorMessage;
                    }
                    self.setState({
                        creatingAccount: false, accountCreated: accountCreated,
                        errorMessage: errorMessage, successMessage: successMessage
                    });
                    if(accountCreated){
                        if(self.props.sessionActions){
                            self.props.sessionActions.manualLogin(json);
                            if(self.props.history) self.props.history.push(`/`);
                        }
                    }
                }
                else {
                    if(self.refs.btnSignup) self.refs.btnSignup.removeAttribute("disabled");
                    self.setState({
                        creatingAccount: false,
                        errorMessage: "Your Account creation failed. Please contact support@truebytlc.com"
                    });
                }
            })
            .catch(function (data) {
                //console.log(data);
                if(self.refs.btnSignup) self.refs.btnSignup.removeAttribute("disabled");
                self.setState({creatingAccount: false, errorMessage: "There was an error creating your account. Please try again later."});
            });
    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        var errorMessage = '';
        if (this.state.mlsId === null || this.state.mlsId === '')
            errorMessage += "MLS is required. ";
        if (this.state.firstName === null || this.state.firstName === '')
            errorMessage += "First name is required. ";
        if (this.state.lastName === null || this.state.lastName === '')
            errorMessage += "Last name is required. ";
        if (this.state.email === null || this.state.email === '')
            errorMessage += "Email is required. ";
        if (this.state.memberNrdsId === null || this.state.memberNrdsId === '')
            errorMessage += "Member NRDS ID is required. ";

        this.setState({errorMessage: errorMessage});

        if (errorMessage.length === 0) {
            this.createAccount();
        }

        event.preventDefault();
        return false;
    }

    render() {
        return (
            <DocumentTitle title={ PageTitle.replace('{title}', this.state.title) }>
                <div className="login_page">

                    <div className="login-wrap">
                        <div className="login_bgimg">
                            <div className="login_mid">
                                <div className="login_formbox signup_formbox">
                                    <div className="login_roof">
                                        <div className="login_logo"><img src={logo} alt=""/></div>
                                    </div>
                                    <div className="login_form create_form">
                                        <div className="mb_login_logo"><img src={logo} alt=""/></div>
                                        <form onSubmit={this.handleSubmit} className="sign-up-htm">
                                            <div className="fields_box">
                                                <div className="createform_mid_box">
                                                    {this.state.errorMessage && this.state.errorMessage !== "" && <div className="error_meg">{this.state.errorMessage}</div>}
                                                    {this.state.successMessage && this.state.successMessage !== "" && <div className="success_meg">{this.state.successMessage}</div>}

                                                    {this.state.fetchingDetails &&
                                                    <div style={{paddingTop: "25%"}}>
                                                        <Loader message={'Processing...'} />
                                                    </div>
                                                    }

                                                    {!this.state.fetchingDetails && !this.state.accountCreated &&
                                                    this.state.firstName && this.state.firstName !== "" &&
                                                    this.state.lastName && this.state.lastName !== "" &&
                                                    this.state.email && this.state.email !== "" &&
                                                    <div>
                                                        <div className="group">
                                                            <span className="user_icon"></span>

                                                            <div className="user_input">
                                                                <select className="input" name="mlsId"
                                                                        value={this.state.mlsId} type="text"
                                                                        onChange={this.handleInputChange}>
                                                                    <option value="">Choose your MLS</option>
                                                                    <option value="cjmls">CJMLS - Central Jersey(Middlesex) MLS</option>
                                                                    <option value="gsmls">GSMLS - Garden State MLS</option>
                                                                    <option value="mlspin">MLSPIN</option>
                                                                    <option value="myfr">My Florida Regional MLS</option>
                                                                    <option value="neren">New England Real Estate Network MLS</option>
                                                                    <option value="nsmls">Northstar MLS</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="group">
                                                                <div className="half_box">
                                                                    <div className="col-xs-6">
                                                                        <input name="firstName" id="firstName"
                                                                            value={this.state.firstName}
                                                                            type="text" placeholder="First Name"
                                                                            className="input"
                                                                            readOnly onChange={this.handleInputChange}/>
                                                                    </div>
                                                                </div>
                                                                <div className="half_box">
                                                                    <div className="col-xs-6">
                                                                        <div className="">
                                                                            <input name="lastName" id="lastName"
                                                                                value={this.state.lastName}
                                                                                type="text" placeholder="Last Name"
                                                                                className="input "
                                                                                readOnly onChange={this.handleInputChange}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="group">
                                                            <span className="user_icon"></span>

                                                            <div className="user_input">
                                                                <input name="email" id="email" value={this.state.email}
                                                                    type="text" placeholder="Email Address" className="input"
                                                                    readOnly onChange={this.handleInputChange}/></div>
                                                        </div>
                                                        <div className="group">
                                                            <span className="user_icon"></span>

                                                            <div className="user_input">
                                                                <input name="memberNrdsId" id="memberNrdsId"
                                                                    value={this.state.memberNrdsId}
                                                                    type="text" placeholder="Member NRDS ID" className="input"
                                                                    onChange={this.handleInputChange}/></div>
                                                        </div>
                                                        <div className="group">
                                                            <div className="user_helpbox">
                                                                <div className="user_help_lt"><Link to={'/login'}>Sign in</Link></div>
                                                            </div>
                                                            <div className="submit_btn" style={{ float: "right" }}>
                                                                <input type="submit" ref="btnSignup" className="login-button "
                                                                    value={this.state.creatingAccount ? 'Processing...' : 'Sign Up'}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }

                                                    {this.state.accountCreated &&
                                                    <div className="col-xs-12 text-center margin-top-section">
                                                        <Link to={'/login'} className="comn_button" style={{float: "none"}}>Sign in</Link>
                                                    </div>
                                                    }
                                                </div>

                                                <div className="agree_meg">
                                                    By signing in/up you agree to the <span className="agree_link" onClick={this.openTermsDialog}>Terms of Use</span> and <span className="agree_link" onClick={this.openPolicyDialog}>Privacy Policy</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="login_footer">
                                        <div className="signup_footer login_footer_lt">&copy;{new Date().getFullYear()} 2FindYourHome. All Rights Reserved.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog ref='dialogRef'/>
                </div>
            </DocumentTitle>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(null, mapDispatchToProps)(CreateAccountPage);