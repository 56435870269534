import {
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
    PROFILE_UPDATE_SUCCESS,
    PROFILE_FAILURE,
    AGENT_PROFILE_REQUEST,
    AGENT_PROFILE_SUCCESS,
    AGENT_PROFILE_FAILURE
} from 'actions/profile';

export default (state = {
    isFetching: false,
    mine: null,
    agent: null,
    error: undefined,
    notificationMessage: undefined,
    //isAnonymous: false,
}, action) => {
  switch (action.type) {
    case PROFILE_REQUEST:
        return {
            ...state,
            isFetching: true,
            error: undefined,
            notificationMessage: undefined
        };
    case PROFILE_SUCCESS:
        window.sessionStorage.setItem("UserProfile", JSON.stringify(action.response));

        /* var isAnonymous = false;
        if (process.env.REACT_APP_AnonymousUserId !== undefined)
        {
            if(action.response && action.response.AgentId && !action.response.Id &&
                action.response.AgentId !== "" && action.response.AgentId.toLowerCase() === process.env.REACT_APP_AnonymousUserId.toLowerCase()){
                isAnonymous = true;
            }
        } */

        return {
            ...state,
            isFetching: false,
            mine: action.response,
            error: undefined,
            //isAnonymous: isAnonymous
        };
    case PROFILE_UPDATE_SUCCESS:
        return {
            ...state,
            isFetching: false,
            notificationMessage: action.response.Message
          };
    case PROFILE_FAILURE:
        return {
            ...state,
            isFetching: false,
            error: action.error
        };
    case AGENT_PROFILE_REQUEST:
        return {
            ...state,
            isFetchingAgentProfile: true
        };
    case AGENT_PROFILE_SUCCESS:
        return {
            ...state,
            isFetchingAgentProfile: false,
            agent: action.response
        };
    case AGENT_PROFILE_FAILURE:
        return {
            ...state,
            isFetchingAgentProfile: false,
            agent: null,
            agentError: action.error
        };
    default:
        return state;
  }
};
