import { CALL_API } from '../store/api';

export const SEND_AGENT_EMAIL_REQUEST = 'SEND_AGENT_EMAIL_REQUEST';
export const SEND_AGENT_EMAIL_SUCCESS = 'SEND_AGENT_EMAIL_SUCCESS';
export const SEND_AGENT_EMAIL_FAILURE = 'SEND_AGENT_EMAIL_FAILURE';
export const AGENT_REQUEST = 'AGENT_REQUEST';
export const AGENT_SUCCESS = 'AGENT_SUCCESS';
export const AGENT_FAILURE = 'AGENT_FAILURE';
export const FAGENT_REQUEST = 'FAGENT_REQUEST';
export const FAGENT_SUCCESS = 'FAGENT_SUCCESS';
export const FAGENT_FAILURE = 'FAGENT_FAILURE';
export const TAGENT_REQUEST = 'TAGENT_REQUEST';
export const TAGENT_SUCCESS = 'TAGENT_SUCCESS';
export const TAGENT_FAILURE = 'TAGENT_FAILURE';

export const contactMyAgent = (body) => (dispatch, getState) => {

    var resMsg = null;
    if(body && body.ResponseMessage && body.ResponseMessage !== ""){
        resMsg = body.ResponseMessage;        
    }
    delete body["ResponseMessage"];

    return dispatch({
        [CALL_API]: {
            types: [SEND_AGENT_EMAIL_REQUEST, SEND_AGENT_EMAIL_SUCCESS, SEND_AGENT_EMAIL_FAILURE],
            method: 'POST',
            body,
            endpoint: 'agents/emailagent'
        },
        ResponseMessage: resMsg
    });
};

export const addDeleteClientAVM = (method, body, clientId) => (dispatch, getState) => {
    const { Id/* , type */ } = getState().session.audience;
    var url = window.sessionStorage.getItem("APIAudience");
    url += `${Id}/clients/${clientId}/avm`;

    return dispatch({
        [CALL_API]: {
            types: [AGENT_REQUEST, AGENT_SUCCESS, AGENT_FAILURE],
            method: method,
            body: body,
            endpoint: url 
        }
    })
};

export const fetchFeaturedAgents = () => (dispatch, getState) => {
    
    return dispatch({
        [CALL_API]: {
            types: [FAGENT_REQUEST, FAGENT_SUCCESS, FAGENT_FAILURE],
            method: 'GET',
            endpoint: 'agents/featuredagent/get' 
        }
    })
};

export const fetchTeamAgents = () => (dispatch, getState) => {
    
    return dispatch({
        [CALL_API]: {
            types: [TAGENT_REQUEST, TAGENT_SUCCESS, TAGENT_FAILURE],
            method: 'GET',
            endpoint: 'agents/teamagents/get' 
        }
    })
};
