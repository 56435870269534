import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import qs from "query-string";
import { Link } from 'react-router-dom';
import {isProfileComplete} from '../components/util';
import DocumentTitle from 'react-document-title';
import { PageTitle } from '../components/util';
import { GetSectionCustomizationOptions } from 'components/util';
import base64 from 'base-64';
import utf8 from 'utf8';
import * as sessionActions from 'actions/session';
import * as profileActions from 'actions/profile';
import * as settingActions from 'actions/settings';
import * as listingActions from 'actions/listings';
import * as agentActions from 'actions/agents';
import '../scss/_login.scss';
import 'css/Login.css';


const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");
const LoginPageCustomizationOptions = GetSectionCustomizationOptions("LoginPageOptions");

class LoginPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }

    state = {
        username: '',
        password: '',
        rememberMe: false,
        authType: 'Agent',
        title: 'Login'
    }

    componentDidMount() {     
        
        if(this.props.sessionActions) {
            this.props.sessionActions.setAuthError('');
        }

        window.scrollTo(0, 0);

        if (process.env.REACT_APP_AllowAnonymous === "true"){
            if (!this.props.showHeader) {
                this.props.onShowHideHeader(true);
            }
        }
        else {
            if (this.props.showHeader) {
                this.props.onShowHideHeader(false);
            }
        }
        if (!this.props.showFooter) {
            this.props.onShowHideFooter(true);
        }
        
        const obj = qs.parse(this.props.location.query);
        var socialScript = obj.audienceId ? 'social-link.js': 'social-login.js';
        // if weclome client email. wire up social to link and verify
        if (obj.audienceId) {
            window.sessionStorage.setItem("SocialLinkAndVerify", JSON.stringify({ Id: obj.audienceId, audienceType: obj.audienceType, mlsKey: obj.mlsKey }));
        } 
        else if (obj.socialSuccess === 'false') {
            //social login failed
            this.props.sessionActions.setAuthError('Error with social login. For the first time, you must use the link from your welcome email.');
        }
        else if (obj.socialLinkAndVerify === 'false') {
            //social link failed
            this.props.sessionActions.setAuthError('Error with social login. This social account already exists.');
        }
        const script = document.createElement("script");
        const scriptsPath = window.sessionStorage.getItem('BaseAPI').replace('/api/', '/scripts/');
        script.src = scriptsPath + socialScript; 
        script.async = true;

        document.body.appendChild(script);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.session.isAuthenticated) {            
            if((this.props.profile === undefined || this.props.profile === null) &&
                (nextProps.profile === undefined || nextProps.profile === null)){
                if(this.props.settingActions){
                    this.props.settingActions.fetchSettings();
                    this.props.settingActions.fetchAuthMechanism();
                    if(CommonCustomizationOptions && CommonCustomizationOptions.FetchPrintPieceUrl){
                        this.props.settingActions.fetchPrintPieceURL();
                    }                    
                }
                if(this.props.profileActions){
                    this.props.profileActions.fetchProfile();
                }
            }
            if(nextProps.profile !== undefined && nextProps.profile !== null){                
                if(nextProps.audience && nextProps.audience.type === "client"){
                    if(nextProps.profileActions) nextProps.profileActions.fetchMyAgentProfile();
                    
                    var gotoHome = isProfileComplete(nextProps.profile);
                    this.props.listingActions.fetchAgentClientListings("favorites");
                    this.props.listingActions.fetchAgentClientListings("bookmarks");
                    this.props.listingActions.fetchAgentClientListings("removed");
                    this.props.listingActions.fetchAgentClientListings("agentlistings");

                    if(window.sessionStorage.getItem("RedirectToHome")){
                        window.sessionStorage.removeItem("RedirectToHome");
                    } 

                    if(window.sessionStorage.getItem("ComingFromCampaign")){
                        window.sessionStorage.removeItem("ComingFromCampaign");
                        this.props.history.push('/onboarding');
                        document.location.reload();
                    }
                    else if(gotoHome){
                        window.sessionStorage.removeItem("ComingFromCampaign");
                        if(window.sessionStorage.getItem("RedirectLocation")){                
                            const redirectUrl = utf8.decode(base64.decode(window.sessionStorage.getItem("RedirectLocation")));
                            window.sessionStorage.removeItem("RedirectLocation");
                            this.props.history.push(redirectUrl);
                            document.location.reload();
                        }
                        else{
                            this.props.history.push('/');
                            document.location.reload();
                        }
                    }
                    else{
                        window.sessionStorage.removeItem("ComingFromCampaign");
                        this.props.history.push('/personalize');
                        document.location.reload();
                    }
                }
                else{
                    if(nextProps.audience && nextProps.audience.type === "agent"){
                        if (process.env.REACT_APP_AnonymousUserId !== undefined && 
                            process.env.REACT_APP_AnonymousUserId !== null && 
                            process.env.REACT_APP_AnonymousUserId !== "" && 
                            nextProps.audience.Id && nextProps.audience.Id !== "" && 
                            process.env.REACT_APP_AnonymousUserId.toLowerCase() !== nextProps.audience.Id.toLowerCase()){
                            this.props.listingActions.fetchAgentClientListings("agentlistings");
                            window.sessionStorage.setItem("RedirectToHome",true);
                        }
                    }

                    if(window.sessionStorage.getItem("ComingFromCampaign")){
                        window.sessionStorage.removeItem("ComingFromCampaign");
                    }

                    if(window.sessionStorage.getItem("RedirectToHome")){
                        window.sessionStorage.removeItem("RedirectToHome");
                        if(window.sessionStorage.getItem("RedirectLocation")){                
                            const redirectUrl = utf8.decode(base64.decode(window.sessionStorage.getItem("RedirectLocation")));
                            window.sessionStorage.removeItem("RedirectLocation");
                            this.props.history.push(redirectUrl);
                            document.location.reload();
                        }
                        else {
                            this.props.history.push('/');
                            document.location.reload();
                        }
                    }                  
                }                
            }
        }
    }

    handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit = event => {
        event.preventDefault();

        let authType;
        if (this.state.authType.toString().toLowerCase() === 'client') {
            authType = "clients";
        }
        else {
            authType = "agents";
        }

        //this.props.sessionActions.logout();
        window.sessionStorage.setItem("RedirectToHome",true);
        this.props.sessionActions.login(authType, {
            Application: "MOBILE",
            Username: this.state.username,
            Password: this.state.password,
            RememberMe: this.state.rememberMe
        });

        
    }

    onChangeAuthType = authType => {
        this.setState({ authType: authType });
    }

    render() {
        const { session, isFetchingProfile } = this.props;

        return (            
            <DocumentTitle title={ PageTitle.replace('{title}', this.state.title) }> 
            <div className="position-relative user_access_login">
                <div className="blue_gd_bg" style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageBackgroundColor) ? {background: LoginPageCustomizationOptions.LoginPageBackgroundColor} : {}}>&nbsp;</div>          
                <div className="container position-relative pd-bot-50 mb-pd-bot-30">
                    <div className="row">
                        <div className="float-none col-md-5 col-sm-7 mx-auto">
                            <div className="pd-top-25 pd-bot-25  large-heading mb-pd-top-15 mb-pd-bot-15">
                                <h2 style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageTitleTextColor) ? {color: LoginPageCustomizationOptions.LoginPageTitleTextColor} : {}}><span>A new home search</span> experience—starting with what really matters to you.</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-12">
                                    {session.error && session.error !== "" && <div className="error_meg">{session.error}</div>}
                                    <div className="group">
                                        <input className="tru-input large" name="username" id="user" value={this.state.username} type="text" placeholder="Username"  onChange={this.handleInputChange} />
                                    </div>
                                    <div className="group mg-btm-25">
                                        <input name="password" value={this.state.password} id="pass" type="password" placeholder="Password" className="tru-input large" data-type="password" onChange={this.handleInputChange} />
                                    </div>
                                    <div className="group mg-btm-25">
                                        <input type="submit"  id="submit-btn" ref="btnSubmit" className="tru-btn tru-btn-big tru-btn-default full-large" value={(session.isAuthenticating || isFetchingProfile) ? 'Signing in...' : 'Sign in'} />
                                    </div>
                                    <div className="row mg-btm-10 mp-mg-btm-15">
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-sm-7 col-xs-6">
                                                    <div className="tru-checkbox-area">
                                                        <label className="tru-checkbox">
                                                            <input id="remember-check" type="checkbox" name="rememberMe" onChange={this.handleInputChange} value={(this.state.rememberMe) ? "true" : "false"} checked={(this.state.rememberMe === true) ? "checked" : ""} />
                                                            <span className="tru-checkbox_checkmark"></span>
                                                        </label>
                                                        <div className="tru-checkbok-name">Remember me</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5 col-xs-6 text-right"><Link id="forgot-link" className="ft-sz-12 tru-bold link-txt" to={`${(this.props.session && this.props.session.purlPrefix && this.props.session.purlPrefix.url) ? this.props.session.purlPrefix.url : ""}/forgotpassword`}>Forgot password?</Link></div>  
                                            </div>
                                        </div>
                                    </div>                                    
                                    {process.env.REACT_APP_AllowAnonymous &&
                                        <div className="row" style={{marginTop: "-18px"}}>
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-sm-12 text-right">
                                                        <Link id="register-link" className="ft-sz-12 tru-bold link-txt" to={`${(this.props.session && this.props.session.purlPrefix && this.props.session.purlPrefix.url) ? this.props.session.purlPrefix.url : ""}/register`}>Register</Link>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/*
                                        <div className="row">
                                            <div className="col-sm-12 ft-sz-12">
                                                <div className="mg-btm-10 ft-sz-12">Or login with your social</div>
                                                <div id="oa_social_login_container"></div>
                                            </div>
                                        </div>
                                    */}
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state, props) => ({
    session: state.session,
    audience: state.session.audience,
    profile: state.profile.mine,
    isFetchingProfile: state.profile.isFetching
});

const mapDispatchToProps = dispatch => ({
    sessionActions: bindActionCreators(sessionActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    listingActions: bindActionCreators(listingActions, dispatch),
    agentActions: bindActionCreators(agentActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);