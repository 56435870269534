import union from 'lodash/union';
import {
  INVITATIONS_REQUEST,
  INVITATIONS_SUCCESS,
  INVITATIONS_DELETE_REQUEST,
  INVITATIONS_DELETE_SUCCESS,
  INVITATIONS_FAILURE
} from 'actions/invitations';

export default (state = {
  isFetching: false,
  isDeleting: false,
  ids: [],
  error: undefined,
  notificationMessage: undefined
}, action) => {
  switch (action.type) {
    case INVITATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
        notificationMessage: undefined
      };
    case INVITATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        //ids: union(state.ids, action.response.result)
        ids: union([], action.response.result)
      };
    case INVITATIONS_DELETE_REQUEST:
      return {
        ...state,
        isDeleting: true,
        error: undefined,
        notificationMessage: undefined
      };
    case INVITATIONS_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ids: state.ids.filter(n => n !== action.id)
      };
    case INVITATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isDeleting: false,
        error: action.error
      };
    default:
      return state;
  }
};
