import union from 'lodash/union';
import difference from 'lodash/difference';
import {
    ACTIVITY_REQUEST,
    ACTIVITY_SUCCESS,
    ACTIVITY_FAILURE,
    ACTIVITY_FILTER
} from 'actions/activity';

const updatePagination = (state = {
  isFetching: false,
  ids: [],
  unreadIds: [],
  updateDashboardIds: [],
  pageCount: 0,
  moreItems: true,
  error: undefined
}, action) => {
  switch (action.type) {
    case ACTIVITY_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case ACTIVITY_SUCCESS:
      let unreadIds = [];
      let updateDashboardIds = [];
      //TODO what is checkForNewActivity for?
      //if(window.sessionStorage.getItem("checkForNewActivity") === "true"){
        unreadIds = union(state.unreadIds, difference(action.response.result, state.ids));
        updateDashboardIds = difference(action.response.result, state.ids);
      //}
      const newIds = union(state.ids, action.response.result);
      const moreItms = unreadIds.length > 0 ? true : state.ids.length <= newIds.length;//This needs to be changed

      return {
        ...state,
        isFetching: false,
        ids: newIds,
        unreadIds: unreadIds,
        updateDashboardIds : updateDashboardIds,
        pageCount: state.pageCount + 1,
        moreItems: moreItms,
        error: undefined
      }
    case ACTIVITY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case ACTIVITY_FILTER:
      return {
        ...state,
        unreadIds: state.unreadIds.filter((urid) => parseInt(urid, 10) > action.lastReadId)
      };
    default:
      return state;
  }
}

const activity = (state = {}, action) => {
  switch (action.type) {
    case ACTIVITY_REQUEST:
    case ACTIVITY_SUCCESS:
    case ACTIVITY_FAILURE:
    case ACTIVITY_FILTER:
      const key = action.key;

      return {
        ...state,
        [key]: updatePagination(state[key], action)
      };
    default:
      return state;
  }
}

export default activity;

