import React, { Component } from 'react';
import TrueLogo from '../img/svg/truebytlc-logo.svg';

class ClientEULAText extends Component {

    render() {

        var siteLogo = TrueLogo;

        if (process.env.REACT_APP_WebsiteLogo !== undefined && process.env.REACT_APP_WebsiteLogo !== null &&
            process.env.REACT_APP_WebsiteLogo !== ""){
            siteLogo = process.env.REACT_APP_WebsiteLogo;
        }

        return (
            <div className="terms_condition_page">
                <div className="inner_logo"><img src={siteLogo} alt=""/></div>
                <div className="content_part" dangerouslySetInnerHTML={{ __html: this.props.EULAText}} />
            </div>
        );
    }
}

export default ClientEULAText;