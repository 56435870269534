import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
//import logo from '../img/login_logo.png';
import { Link } from 'react-router-dom';
//import Dialog from 'react-bootstrap-dialog';
//import TermsNConditions from '../components/termsconditions';
//import PrivacyPolicy from '../components/privacypolicy';
import DocumentTitle from 'react-document-title';
import { PageTitle, GetSectionCustomizationOptions } from '../components/util';
import '../scss/_login.scss';
//import LogoNavBar from '../components/logonavbar';
//import SecondaryFooter from 'components/secondaryfooter';

const LoginPageCustomizationOptions = GetSectionCustomizationOptions("LoginPageOptions");

class ForgotPasswordPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            errorMessage: '',
            authType: 'Agent',
            fetchingResult: false,
            title: 'Forgot Password'
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.forgotPasswordCall = this.forgotPasswordCall.bind(this);
        this.onChangeAuthType = this.onChangeAuthType.bind(this);
        //this.openTermsDialog = this.openTermsDialog.bind(this);
        //this.openPolicyDialog = this.openPolicyDialog.bind(this);
    }

    componentDidMount() {
        if (process.env.REACT_APP_AllowAnonymous === "true"){
            if (!this.props.showHeader) {
                this.props.onShowHideHeader(true);
            }
        }
        else {
            if (this.props.showHeader) {
                this.props.onShowHideHeader(false);
            }
        }
        if (!this.props.showFooter) {
            this.props.onShowHideFooter(true);
        }
    }

    forgotPasswordCall() {
        // Call API for open houses
        var self = this;
        var url = window.sessionStorage.getItem("RootAPI") + "accesstokens/";

        var requestType = {};
        // Check if Client or Agent Login        
        url += "forgotpassword";
        // if (this.state.authType.toString().toLowerCase() === 'client')
        //     url += "clients/forgotpassword";
        // else
        //     url += "agents/forgotpassword";

        requestType.email = this.state.username;

        //If there is default agent then pass default agent id to use default agent specific email template
        if (process.env.REACT_APP_DefaultAgentId !== undefined && 
            process.env.REACT_APP_DefaultAgentId !== null && 
            process.env.REACT_APP_DefaultAgentId !== "")
        {
            requestType.BrokerAgentId = process.env.REACT_APP_DefaultAgentId;
        }
        //---

        self.refs.btnSubmit.setAttribute("disabled", "disabled");
        self.setState({ fetchingResult: true });
        fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(requestType)
            })
            .then(response =>  (response.ok) ? response : response.json())
            .then(json => {    
                if (json.ok) {            
                    if(self.props.showNotification)
                    {
                        self.props.showNotification(
                            "success",
                            "",
                            "A link to update your password has been emailed. Please check your email.",
                            5000);
                    }
                    self.props.history.push(`/`);
                }
                else
                {
                    var emsg = (json && json[0] && json[0].ErrorMessage) ? json[0].ErrorMessage : "The username you entered was not found.";
                    
                    // Override API error message for user not found
                    if (json && json[0] && json[0].ErrorCode && json[0].ErrorCode === "412") 
                    {
                        emsg = "The username you entered was not found."
                    }

                    self.refs.btnSubmit.removeAttribute("disabled");
                    this.setState({fetchingResult: false, errorMessage: emsg});
                }
            })
            .catch(function (data) {
                console.log(data);
                self.refs.btnSubmit.removeAttribute("disabled");
                var emsg = (data && data[0] && data[0].ErrorMessage) ? data[0].ErrorMessage : "The username you entered was not found.";
                self.setState({fetchingResult: false, errorMessage: emsg});
            });
    }
    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    handleSubmit(event) {

        if(this.state.username === null || this.state.username === '')
        {
            this.setState({errorMessage: "Username is required."});
        }
        else
        {
            this.setState({errorMessage: ""});
            this.forgotPasswordCall();
        }

        event.preventDefault();
        return false;
    }
    onChangeAuthType(authType) {
        this.setState({ authType: authType });
    }

    /*openTermsDialog() {
        Dialog.setOptions({
            defaultCancelLabel: 'Close'
        });
        this.refs.dialogRef.show({
            body: <TermsNConditions />,
            bsSize: 'large',
            actions: [
                Dialog.CancelAction(),

            ]
        })
    }
    openPolicyDialog() {
        Dialog.setOptions({
            defaultCancelLabel: 'Close'
        });
        this.refs.dialogRef.show({
            body: <PrivacyPolicy />,
            bsSize: 'large',
            actions: [
                Dialog.CancelAction(),

            ]
        })
    }*/

    render() {
        return (
            <DocumentTitle title={ PageTitle.replace('{title}', this.state.title) }>
                <div className="position-relative user_access_login">
                {/* !process.env.REACT_APP_AllowAnonymous && <LogoNavBar /> */}
                <div className="blue_gd_bg" style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageBackgroundColor) ? {background: LoginPageCustomizationOptions.LoginPageBackgroundColor} : {}}>&nbsp;</div> 
                    <div className="container position-relative pd-bot-50 mb-pd-bot-30">
                        <div className="row">
                            <div className="float-none col-md-5 col-sm-7 mx-auto">
                                <div className="pd-top-25 pd-bot-25 large-heading mb-pd-top-15 mb-pd-bot-15">
                                    <h2 style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageTitleTextColor) ? {color: LoginPageCustomizationOptions.LoginPageTitleTextColor} : {}}><span>A new home search</span> experience—starting with what really matters to you.</h2>
                                </div>
                                    <form onSubmit={this.handleSubmit} className="sign-up-htm">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                {this.state.errorMessage && this.state.errorMessage !== "" && <div className="error_meg">{this.state.errorMessage}</div>}
                                                <div className="group mg-btm-25">
                                                    <input name="username" id="forgot-user" value={this.state.username} type="text" placeholder="Username" className="tru-input large" onChange={this.handleInputChange} />
                                                </div>
                                                <div className="row"> 
                                                    <div className="col-sm-12 col-xs-12">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-xs-12 mg-btm-20"><input type="submit" ref="btnSubmit" id="forgot-btn" className="tru-btn tru-btn-big tru-btn-default full-large" value={this.state.fetchingResult ? 'Processing...' : 'Forgot password'} /></div>
                                                            <div className="col-sm-12 col-xs-12">Already have an account? <Link id="sign-link" className="ft-sz-12 tru-bold link-txt" to={`${this.props.purlPrefix.url}/login`}>Sign in</Link></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                {/* <div className="login_footer">
                        <div className="row fluid">
                            <LargeAdComponent classname="footer_ads_frame hidden-xs hidden-sm" index={1} />
                            <div className="col-sm-12 col-xs-12 mg-btm-5">By signing in/up you agree to the <span className="tru-med text-decor text-cursor" onClick={this.openTermsDialog}>Terms of Use</span> and <span className="tru-med text-decor text-cursor " onClick={this.openPolicyDialog}>Privacy Policy</span></div>
                            <div className="col-sm-12 col-xs-12">&copy;{new Date().getFullYear()} 2FindYourHome. All Rights Reserved.</div>
                        </div>    
                    </div>
                    <Dialog ref='dialogRef' /> */} 
                    {/*<SecondaryFooter />*/}
                </div>
            </DocumentTitle>

        );
    }
}

const mapStateToProps = (state, props) => ({
    purlPrefix: state.session.purlPrefix || { url: ''}
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);