import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import qs from "query-string";
import DocumentTitle from 'react-document-title';
import { PageTitle, GetSectionCustomizationOptions } from '../components/util';
import store from '../store/store';
import '../scss/_login.scss';
import * as sessionActions from 'actions/session';
import * as settingActions from 'actions/settings';
import * as profileActions from 'actions/profile';
import * as clientActions from 'actions/clients';
import * as listingActions from 'actions/listings';
import * as agentActions from 'actions/agents';
import { verifyAuthToken } from 'actions/session';

const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");

class SSOPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }

    state = {
        title: 'Login',
        SSOAuthDetails: null
    }

    componentDidMount() {
        if(window.sessionStorage.getItem("ComingFromCampaign")){
            window.sessionStorage.removeItem("ComingFromCampaign");
        }
        const obj = qs.parse(this.props.location.search);
        if(obj && obj.t && obj.audienceType && obj.audienceId){
            setTimeout(() => {
                store.dispatch(verifyAuthToken({
                    AudienceType: obj.audienceType,
                    AudienceId: obj.audienceId,
                    AccessToken: obj.t,
                    skip: true
                })).then(r => {
                    if (r.response && r.response.AudienceType === "MLSAGENT") {
                        var self = this;
                        this.props.profileActions.fetchProfile().then((res) => { 
                            self.props.listingActions.fetchAgentClientListings("agentlistings");
                        });
                        this.props.settingActions.fetchSettings();
                        if(CommonCustomizationOptions && CommonCustomizationOptions.FetchPrintPieceUrl){
                            this.props.settingActions.fetchPrintPieceURL();
                        }
                        this.props.settingActions.fetchAuthMechanism();
                        this.props.clientActions.fetchClients();
                        if(CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers && this.props.agentActions){
                            this.props.agentActions.fetchTeamAgents();
                        }
                        if(this.props.history) this.props.history.push(this.props.purlPrefix.url + "/");
                    }
                });                
            },500);
        }
        else{
            var authDt = window.sessionStorage.getItem("AuthMechanism");
            if(authDt){
                if(window.sessionStorage.getItem("ComingForSSO") || (obj && obj.rm && obj.rm === "1")){
                    window.sessionStorage.removeItem("ComingForSSO");
                    authDt = JSON.parse(authDt);
                    if(authDt && authDt.Idp && authDt.Idp !== "" && authDt.SAMLRequest && authDt.SAMLRequest !== ""){
                        window.sessionStorage.setItem("SubmitSSORequest", "true");
                        this.props.settingActions.fetchAuthMechanism();                        
                    }
                }
                else{
                    if(this.props.history) this.props.history.push(this.props.purlPrefix.url + "/");
                }
            }
            else{
                if(this.props.history) this.props.history.push(this.props.purlPrefix.url + "/login");
            }
        }
    }    

    componentWillReceiveProps(nextProps) {
        if(this.props.isAuthMechFetching === true && nextProps.isAuthMechFetching === false){
            var authDt = window.sessionStorage.getItem("AuthMechanism");
            if(authDt){
                authDt = JSON.parse(authDt);
                if(authDt && authDt.Idp && authDt.Idp !== "" && authDt.SAMLRequest && authDt.SAMLRequest !== ""){
                    if(window.sessionStorage.getItem("SubmitSSORequest")){
                        window.sessionStorage.removeItem("SubmitSSORequest");
                        this.setState({SSOAuthDetails: authDt}, () => {
                            setTimeout(() => {
                                if(this.ssoForm) this.ssoForm.submit();
                            },500);
                        });
                    }
                }
            }
        }
    }

    render() {
        const { SSOAuthDetails } = this.state;
        if(SSOAuthDetails !== undefined && SSOAuthDetails !== null){
            return (
                <DocumentTitle title={ PageTitle.replace('{title}', this.state.title) }> 
                    <form method="POST" action={SSOAuthDetails.Idp} ref={(ref) => this.ssoForm = ref}>
                        <input type="hidden" name="SAMLRequest" value={SSOAuthDetails.SAMLRequest} />
                    </form>
                </DocumentTitle>    
            )
        }

        return (            
            <DocumentTitle title={ PageTitle.replace('{title}', this.state.title) }> 
                <div></div>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state, props) => ({
    session: state.session,
    audience: state.session.audience,
    profile: state.profile.mine,
    isFetchingProfile: state.profile.isFetching,
    isAuthMechFetching: state.settings.isAuthMechFetching,
    purlPrefix: state.session.purlPrefix || { url: ''},
});

const mapDispatchToProps = dispatch => ({
    sessionActions: bindActionCreators(sessionActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
    clientActions: bindActionCreators(clientActions, dispatch),
    listingActions: bindActionCreators(listingActions, dispatch),
    agentActions: bindActionCreators(agentActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SSOPage);
