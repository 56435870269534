import React from 'react';
import { Link } from 'react-router-dom';
//import logo from '../img/logo.png';
import logo from '../img/svg/truebytlc-logo.svg';
import { Navbar} from 'react-bootstrap';
import '../css/navbar.css';
import ReactTooltip from 'react-tooltip'
import '../scss/_wordpressMenu.scss';
import '../scss/_loginButton.scss';
import '../scss/_navbar.scss';

class LogoNavBar extends React.Component {

    removeTrailingSlash = (site) => site.replace(/\/$/, "");

    getLastPartOfURL = (url) => url.substr(url.lastIndexOf('/') + 1);

    prettyURL = url => this.getLastPartOfURL(this.removeTrailingSlash(url));

    render() {
        var headerClass = "header header-nonfixed logoheader";

        var siteLogo = logo;

        if (process.env.REACT_APP_WebsiteLogo !== undefined && process.env.REACT_APP_WebsiteLogo !== null &&
            process.env.REACT_APP_WebsiteLogo !== ""){
            siteLogo = process.env.REACT_APP_WebsiteLogo;
        }

        var logoClass = "logo-img";
        if (process.env.REACT_APP_LargeLogo === "true"){
            logoClass = "large-logo-img";
        }

        return (
            <header className={headerClass} ref="navBar">
                <ReactTooltip />
                <div className="header-top-bar"></div>
                <div className="header-content">
                    <div className="fluid row">
                       <div>
                            <Navbar inverse collapseOnSelect className="new_stylemenu">
                                <Navbar.Header>
                                    <Navbar.Brand>
                                        <Link  to={'/'} ><img className={logoClass} id="logo" src={siteLogo} alt="" /></Link>
                                    </Navbar.Brand>
                                </Navbar.Header>
                            </Navbar>
                        </div>
                      
                    </div>
                </div>                

            </header>
            
        );
    }
}

export default LogoNavBar;