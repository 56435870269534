import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NavBar from 'components/navbar';
import Footer from 'components/footer';
import { GetSectionCustomizationOptions } from 'components/util';

import asyncComponent from "components/AsyncComponent";

import FeedbackPage from 'containers/FeedbackPage';
import LoginPage from 'containers/LoginPage';
import SSOPage from 'containers/SSOPage';
import CampaignPage from 'containers/CampaignPage';
import NotificationSystem from 'react-notification-system';
import ForgotPasswordPage from 'containers/ForgotPasswordPage';
import SignUpPage from 'containers/SignUpPage';
import CreateAccountPage from 'containers/CreateAccount';
import ResetPasswordPage from 'containers/ResetPasswordPage';
import SetPasswordPage from 'containers/SetPasswordPage';
import PendingAuthentication from 'containers/PendingAuthentication';
import RegisterPage from 'containers/RegisterPage';
import base64 from 'base-64';
import utf8 from 'utf8';
import qs from "query-string";
import { hubConnection } from 'signalr-no-jquery';

import * as sessionActions from 'actions/session';
import * as feedActions from 'actions/feed';
import * as activityActions from 'actions/activity';
import * as listingActions from 'actions/listings';

const AsyncLandingPage = asyncComponent(() => import("containers/LandingPage"));
const AsyncProfilePage = asyncComponent(() => import("containers/ProfilePage"));
const AsyncAgentClient = asyncComponent(() => import("components/agent/client"));
const AsyncSearchPage = asyncComponent(() => import("containers/SearchPage"));
const AsyncPropertyDetailPage = asyncComponent(() => import("containers/PropertyDetailPage"));
const AsyncDashboardPage = asyncComponent(() => import("containers/DashboardPage"));
const AsyncAddClientPage = asyncComponent(() => import("containers/AddClientPage"));
const AsyncMyAgentPage = asyncComponent(() => import("containers/MyAgentPage"));
const AsyncPersonalizePage = asyncComponent(() => import("containers/PersonalizePage"));
const AsyncOnBoardPage = asyncComponent(() => import("containers/OnBoardPage"));
const AsyncOnCostOfLivingPage = asyncComponent(() => import("containers/CostOfLivingPage"));
const AsyncSiteTour = asyncComponent(() => import("components/sitetour"));
const AsyncMyOffers = asyncComponent(() => import("components/myoffers"));
const AsyncMyAPOffers = asyncComponent(() => import("components/myapoffers"));
const AsyncCompareAlert = asyncComponent(() => import("components/PropertiesCompare"));
const AsyncGoogleAnalytics = asyncComponent(() => import("components/GoogleAnalytics"));
const AsyncApplicationInsights = asyncComponent(() => import("components/ApplicationInsights"));
const AsyncAboutUsPage = asyncComponent(() => import("containers/AboutUsPage"));
const AsyncPages = asyncComponent(() => import("components/pages"));
const AsyncNeighborhood = asyncComponent(() => import("components/neighborhood"));
const AsyncNeighborhoodsList = asyncComponent(() => import("components/neighborhoodslist"));
const AsyncCommunitiesList = asyncComponent(() => import("components/communitieslist"));
const AsyncAgentProfile = asyncComponent(() => import("components/agentProfile"));
const AsyncCategories = asyncComponent(() => import("components/categories"));
const AsyncBlogs = asyncComponent(() => import("components/blogs"));
const AsyncTitleToolBox = asyncComponent(() => import("containers/TitleToolBox"));
const AsyncAgentOfficePage = asyncComponent(() => import("containers/AgentOfficePage"));
const AsyncPurlWrapper = asyncComponent(() => import("containers/PurlWrapper"));
const AsyncAboutPage = asyncComponent(() => import("containers/AboutPage"));
const AsyncJourneyPage = asyncComponent(() => import("containers/JourneyPage"));
const AsyncJourneyPhase1IntroPage = asyncComponent(() => import("containers/JourneyPhase1IntroPage"));
const AsyncJourneyPhase1YourLifeTodayPage = asyncComponent(() => import("containers/JourneyPhase1YourLifeTodayPage"));
const AsyncJourneyPhase1YourFinancesTodayPage = asyncComponent(() => import("containers/JourneyPhase1YourFinancesTodayPage"));
const AsyncJourneyPhase1HopesForTomorrowPage = asyncComponent(() => import("containers/JourneyPhase1HopesForTomorrowPage"));
const AsyncJourneyPhase1MovingWherePage = asyncComponent(() => import("containers/JourneyPhase1MovingWherePage"));
const AsyncJourneyPhase1NeighborhoodQualitiesPage = asyncComponent(() => import("containers/JourneyPhase1NeighborhoodQualitiesPage"));
const AsyncJourneyPhase1StartCollectingListingsPage = asyncComponent(() => import("containers/JourneyPhase1StartCollectingListingsPage"));
const AsyncJourneyPhase1YourTimingPage = asyncComponent(() => import("containers/JourneyPhase1YourTimingPage"));
const AsyncJourneyPhase2IntroPage = asyncComponent(() => import("containers/JourneyPhase2IntroPage"));
const AsyncJourneyPhase2WhatYouCanAffordPage = asyncComponent(() => import("containers/JourneyPhase2WhatYouCanAffordPage"));
const AsyncJourneyPhase2GetYourCreditScorePage = asyncComponent(() => import("containers/JourneyPhase2GetYourCreditScorePage"));
const AsyncJourneyPhase2BestWaysConnectPage = asyncComponent(() => import("containers/JourneyPhase2BestWaysConnectPage"));
const AsyncJourneyPhase2MortgagesPage = asyncComponent(() => import("containers/JourneyPhase2MortgagesPage"));
const AsyncJourneyPhase2TypesMortgagesPage = asyncComponent(() => import("containers/JourneyPhase2TypesMortgagesPage"));
const AsyncJourneyPhase2CalculatingPaymentPage = asyncComponent(() => import("containers/JourneyPhase2CalculatingPaymentPage"));
const AsyncJourneyPhase2Lenders101Page = asyncComponent(() => import("containers/JourneyPhase2Lenders101Page"));
const AsyncJourneyPhase2ComparePotentialLendersPage = asyncComponent(() => import("containers/JourneyPhase2ComparePotentialLendersPage"));
const AsyncJourneyPhase2PreApproval101Page = asyncComponent(() => import("containers/JourneyPhase2PreApproval101Page"));
const AsyncJourneyPhase2GetPreApprovedPage = asyncComponent(() => import("containers/JourneyPhase2GetPreApprovedPage"));
const AsyncJourneyPhase3IntroPage = asyncComponent(() => import("containers/JourneyPhase3IntroPage"));
const AsyncJourneyPhase3FutureNeighborhoodPage = asyncComponent(() => import("containers/JourneyPhase3FutureNeighborhoodPage"));
const AsyncJourneyPhase3MyFutureHomePage = asyncComponent(() => import("containers/JourneyPhase3MyFutureHomePage"));
const AsyncJourneyPhase3NarrowDownYourListingsPage = asyncComponent(() => import("containers/JourneyPhase3NarrowDownYourListingsPage"));
const AsyncJourneyPhase3FindGreatAgentPage = asyncComponent(() => import("containers/JourneyPhase3FindGreatAgentPage"));
const AsyncJourneyPhase3ShareTouringAvailabilityPage = asyncComponent(() => import("containers/JourneyPhase3ShareTouringAvailabilityPage"));
const AsyncJourneyPhase3TourHousesLikeProPage = asyncComponent(() => import("containers/JourneyPhase3TourHousesLikeProPage"));
const AsyncJourneyPhase3DecideonHousePage = asyncComponent(() => import("containers/JourneyPhase3DecideonHousePage"));
const AsyncJourneyPhase4IntroPage = asyncComponent(() => import("containers/JourneyPhase4IntroPage"));
const AsyncJourneyPhase4Closings101Page = asyncComponent(() => import("containers/JourneyPhase4Closings101Page"));
const AsyncJourneyPhase4FindAnEscrowAgentPage = asyncComponent(() => import("containers/JourneyPhase4FindAnEscrowAgentPage"));
const AsyncJourneyPhase4MakingAnOffer101Page = asyncComponent(() => import("containers/JourneyPhase4MakingAnOffer101Page"));
const AsyncJourneyPhase4CompletePurchaseOfferPage = asyncComponent(() => import("containers/JourneyPhase4CompletePurchaseOfferPage"));
const AsyncJourneyPhase4WritePersonalNotePage = asyncComponent(() => import("containers/JourneyPhase4WritePersonalNotePage"));
const AsyncJourneyPhase4SubmitPurchaseOfferPage = asyncComponent(() => import("containers/JourneyPhase4SubmitPurchaseOfferPage"));
const AsyncJourneyPhase4ReviewGoodFaithEstimatePage = asyncComponent(() => import("containers/JourneyPhase4ReviewGoodFaithEstimatePage"));
const AsyncJourneyPhase4GetHomeInspectionPage = asyncComponent(() => import("containers/JourneyPhase4GetHomeInspectionPage"));
const AsyncJourneyPhase4ConfirmHomeAppraisalPage = asyncComponent(() => import("containers/JourneyPhase4ConfirmHomeAppraisalPage"));
const AsyncJourneyPhase4ClearTitleOrderInsurancePage = asyncComponent(() => import("containers/JourneyPhase4ClearTitleOrderInsurancePage"));
const AsyncJourneyPhase4ArrangeFinalWalkThruPage = asyncComponent(() => import("containers/JourneyPhase4ArrangeFinalWalkThruPage"));
const AsyncJourneyPhase5IntroPage = asyncComponent(() => import("containers/JourneyPhase5IntroPage"));
const AsyncJourneyPhase5ClosingMoveInDatesPage = asyncComponent(() => import("containers/JourneyPhase5ClosingMoveInDatesPage"));
const AsyncJourneyPhase5HomeownerInsurance101Page = asyncComponent(() => import("containers/JourneyPhase5HomeownerInsurance101Page"));
const AsyncJourneyPhase5OrderHomeownerInsurancePage = asyncComponent(() => import("containers/JourneyPhase5OrderHomeownerInsurancePage"));
const AsyncJourneyPhase5PrepareToMovePage = asyncComponent(() => import("containers/JourneyPhase5PrepareToMovePage"));
const AsyncJourneyPhase5ChangeAddressUtilitiesPage = asyncComponent(() => import("containers/JourneyPhase5ChangeAddressUtilitiesPage"));
const AsyncJourneyPhase5RequestHUDFromLenderPage = asyncComponent(() => import("containers/JourneyPhase5RequestHUDFromLenderPage"));
const AsyncJourneyPhase5ArrangeToPayClosingCostsPage = asyncComponent(() => import("containers/JourneyPhase5ArrangeToPayClosingCostsPage"));
const AsyncJourneyPhase5GatherClosingDocsPage = asyncComponent(() => import("containers/JourneyPhase5GatherClosingDocsPage"));
const AsyncJourneyPhase5PerformFinalWalkThruPage = asyncComponent(() => import("containers/JourneyPhase5PerformFinalWalkThruPage"));
const AsyncJourneyPhase6IntroPage = asyncComponent(() => import("containers/JourneyPhase6IntroPage"));
const AsyncJourneyPhase6ConfirmClosingDetailsPage = asyncComponent(() => import("containers/JourneyPhase6ConfirmClosingDetailsPage"));
const AsyncJourneyPhase6ReviewHUD1Page = asyncComponent(() => import("containers/JourneyPhase6ReviewHUD1Page"));
const AsyncJourneyPhase6CompareGFEToHUD1Page = asyncComponent(() => import("containers/JourneyPhase6CompareGFEToHUD1Page"));
const AsyncJourneyPhase6AttendClosingPage = asyncComponent(() => import("containers/JourneyPhase6AttendClosingPage"));
const AsyncJourneyPhase6WelcomeToYourHomePage = asyncComponent(() => import("containers/JourneyPhase6WelcomeToYourHomePage"));

const AsyncJourneyPhase1Page = asyncComponent(() => import("containers/JourneyPhase1Page"));
const AsyncJourneyPhase2Page = asyncComponent(() => import("containers/JourneyPhase2Page"));
const AsyncJourneyPhase3Page = asyncComponent(() => import("containers/JourneyPhase3Page"));
const AsyncJourneyPhase4Page = asyncComponent(() => import("containers/JourneyPhase4Page"));
const AsyncJourneyPhase5Page = asyncComponent(() => import("containers/JourneyPhase5Page"));
const AsyncJourneyPhase6Page = asyncComponent(() => import("containers/JourneyPhase6Page"));
const AsyncInfoPage = asyncComponent(() => import("containers/InfoPage"));

const AsyncManagePage = asyncComponent(() => import("containers/ManagePage"));
const AsyncOpenHousesPage = asyncComponent(() => import("containers/OpenHousesPage"));
const AsyncHotPropsPage = asyncComponent(() => import("containers/HotPropsPage"));
const AsyncBuiltForYouPage = asyncComponent(() => import("containers/BuiltForYouPage"));
const AsyncPrintPiecePage = asyncComponent(() => import("containers/PrintPiecePage"));
const AsyncFeaturedPropsPage = asyncComponent(() => import("containers/FeaturedPropsPage"));

const AsyncPlaceAdPage = asyncComponent(() => import("containers/PlaceAdPage"));

const AsyncNewOnBoardingPage = asyncComponent(() => import("containers/NewOnBoardingPage"));

const ThemeCustomizationOptions = GetSectionCustomizationOptions("ThemeOptions");
const FooterCustomizationOptions = GetSectionCustomizationOptions("FooterOptions");
const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");
const AdCustomizationOptions = GetSectionCustomizationOptions("AdOptions");

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFooter: true,
            showHeader: true,
            isHeaderFixed: false
        };
        this.onShowHideHeader = this.onShowHideHeader.bind(this);
        this.onShowHideFooter = this.onShowHideFooter.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.onClientSelection = this.onClientSelection.bind(this);
        this.connection = hubConnection(window.sessionStorage.getItem('BaseAPI').replace('/api/', ''));
        this.hubProxy = this.connection.createHubProxy('tlchub');
      }

    onShowHideHeader(flag) {
        this.setState({showHeader: flag});
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.ssoLogoutRedirectUrl === null && nextProps.ssoLogoutRedirectUrl !== null && nextProps.ssoLogoutRedirectUrl !== ""){
            if(CommonCustomizationOptions && CommonCustomizationOptions.IsAgentSSOEnabled){
                window.location = nextProps.ssoLogoutRedirectUrl;                
            }
        }
        if (!this.props.sessionNotification && nextProps.sessionNotification) {
            if(this.showNotification) {
                this.showNotification("success", "", nextProps.sessionNotification, 5000);
            }
        }
        else if (JSON.stringify(this.props.audience) !== JSON.stringify(nextProps.audience)) {            
            if (nextProps.audience && nextProps.audience.Id && 
                nextProps.session && nextProps.session.isAuthenticated && !nextProps.session.isAnonymous) { // logging in
                this.setState({signalRConnecting: true});
                this.hubInitialize(nextProps.audience.Id, nextProps.audience.AgentId); 
            }
            else { // logging out
                if(this.props.audience && this.props.audience.Id){
                    this.disconnectFromHub(this.props.audience.Id);
                }
            }
        }
        else if (JSON.stringify(this.props.selectedClient) !== JSON.stringify(nextProps.selectedClient)){
            var self = this;
            // this was added because opening new tab sometimes called hub before connection was established
            setTimeout(function isSignalRConnecting() {
                if (self.state.signalRConnecting) {
                    setTimeout(isSignalRConnecting, 1000);
                }
                else {
                    self.hubProxy.invoke('selectedClient', self.props.audience.Id, nextProps.selectedClient ? nextProps.selectedClient.Id : null);
                }
            }, 1000);
        }
        //Setting theme colors---
        const root = document.documentElement;
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.PrimaryColor && ThemeCustomizationOptions.PrimaryColor !== ""){            
            root.style.setProperty('--theme-primary-color', ThemeCustomizationOptions.PrimaryColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.PrimaryDarkColor && ThemeCustomizationOptions.PrimaryDarkColor !== ""){            
            root.style.setProperty('--theme-primary-dark-color', ThemeCustomizationOptions.PrimaryDarkColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.PrimaryLightColor && ThemeCustomizationOptions.PrimaryLightColor !== ""){            
            root.style.setProperty('--theme-primary-light-color', ThemeCustomizationOptions.PrimaryLightColor);
        }

        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SubPrimaryColor && ThemeCustomizationOptions.SubPrimaryColor !== ""){            
            root.style.setProperty('--theme-subprimary-color', ThemeCustomizationOptions.SubPrimaryColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SubPrimaryDarkColor && ThemeCustomizationOptions.SubPrimaryDarkColor !== ""){            
            root.style.setProperty('--theme-subprimary-dark-color', ThemeCustomizationOptions.SubPrimaryDarkColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SubPrimaryLightColor && ThemeCustomizationOptions.SubPrimaryLightColor !== ""){            
            root.style.setProperty('--theme-subprimary-light-color', ThemeCustomizationOptions.SubPrimaryLightColor);
        }

        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SecondaryColor && ThemeCustomizationOptions.SecondaryColor !== ""){            
            root.style.setProperty('--theme-secondary-color', ThemeCustomizationOptions.SecondaryColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SecondaryDarkColor && ThemeCustomizationOptions.SecondaryDarkColor !== ""){            
            root.style.setProperty('--theme-secondary-dark-color', ThemeCustomizationOptions.SecondaryDarkColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SecondaryLightColor && ThemeCustomizationOptions.SecondaryLightColor !== ""){            
            root.style.setProperty('--theme-secondary-light-color', ThemeCustomizationOptions.SecondaryLightColor);
        }

        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SubSecondaryColor && ThemeCustomizationOptions.SubSecondaryColor !== ""){            
            root.style.setProperty('--theme-subsecondary-color', ThemeCustomizationOptions.SubSecondaryColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SubSecondaryDarkColor && ThemeCustomizationOptions.SubSecondaryDarkColor !== ""){            
            root.style.setProperty('--theme-subsecondary-dark-color', ThemeCustomizationOptions.SubSecondaryDarkColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SubSecondaryLightColor && ThemeCustomizationOptions.SubSecondaryLightColor !== ""){            
            root.style.setProperty('--theme-subsecondary-light-color', ThemeCustomizationOptions.SubSecondaryLightColor);
        }

        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.TertiaryColor && ThemeCustomizationOptions.TertiaryColor !== ""){            
            root.style.setProperty('--theme-tertiary-color', ThemeCustomizationOptions.TertiaryColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.TertiaryDarkColor && ThemeCustomizationOptions.TertiaryDarkColor !== ""){            
            root.style.setProperty('--theme-tertiary-dark-color', ThemeCustomizationOptions.TertiaryDarkColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.TertiaryLightColor && ThemeCustomizationOptions.TertiaryLightColor !== ""){            
            root.style.setProperty('--theme-tertiary-light-color', ThemeCustomizationOptions.TertiaryLightColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.TourButtonColor && ThemeCustomizationOptions.TourButtonColor !== ""){            
            root.style.setProperty('--theme-tour-button-color', ThemeCustomizationOptions.TourButtonColor);
        }
        if(root && FooterCustomizationOptions && FooterCustomizationOptions.FooterBackgroundColor && FooterCustomizationOptions.FooterBackgroundColor !== ""){            
            root.style.setProperty('--theme-footer-background-color', FooterCustomizationOptions.FooterBackgroundColor);
        }
        if(root && FooterCustomizationOptions && FooterCustomizationOptions.FooterSubBackgroundColor && FooterCustomizationOptions.FooterSubBackgroundColor !== ""){            
            root.style.setProperty('--theme-footer-sub-background-color', FooterCustomizationOptions.FooterSubBackgroundColor);
        }
        if(root && FooterCustomizationOptions && FooterCustomizationOptions.FooterBorderColor && FooterCustomizationOptions.FooterBorderColor !== ""){            
            root.style.setProperty('--theme-footer-border-color', FooterCustomizationOptions.FooterBorderColor);
        }
        if(root && FooterCustomizationOptions && FooterCustomizationOptions.FooterFontColor && FooterCustomizationOptions.FooterFontColor !== ""){            
            root.style.setProperty('--theme-footer-font-color', FooterCustomizationOptions.FooterFontColor);
        }
        if(root && FooterCustomizationOptions && FooterCustomizationOptions.FooterSubFontColor && FooterCustomizationOptions.FooterSubFontColor !== ""){            
            root.style.setProperty('--theme-footer-sub-font-color', FooterCustomizationOptions.FooterSubFontColor);
        }
        
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SubSiteMenuFontColor && ThemeCustomizationOptions.SubSiteMenuFontColor !== ""){
            root.style.setProperty('--theme-subsitemenu-font-color', ThemeCustomizationOptions.SubSiteMenuFontColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SubSiteMenuFontHoverColor && ThemeCustomizationOptions.SubSiteMenuFontHoverColor !== ""){
            root.style.setProperty('--theme-subsitemenu-fonthover-color', ThemeCustomizationOptions.SubSiteMenuFontHoverColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SubSiteMenuBackgroundColor && ThemeCustomizationOptions.SubSiteMenuBackgroundColor !== ""){
            root.style.setProperty('--theme-subsitemenu-background-color', ThemeCustomizationOptions.SubSiteMenuBackgroundColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.SubSiteMenuFontSubColor && ThemeCustomizationOptions.SubSiteMenuFontSubColor !== ""){
            root.style.setProperty('--theme-subsitemenu-font-subcolor', ThemeCustomizationOptions.SubSiteMenuFontSubColor);
        }
        if(root && ThemeCustomizationOptions && ThemeCustomizationOptions.OnBoardingSecondaryColor && ThemeCustomizationOptions.OnBoardingSecondaryColor !== ""){
            root.style.setProperty('--theme-onboard-color', ThemeCustomizationOptions.OnBoardingSecondaryColor);
        }
        //------
    }

    onShowHideFooter(flag) {
        this.setState({showFooter: flag, isHeaderFixed: !flag});
    }

    componentDidMount(){

        // Refresh the auth token every minute
        this.refreshAuthTimer = setInterval(this.refreshAuth, 120000);

        // SignalR Heartbeat
        this.signalrHeartbeatTimer = setInterval(this.signalrHeartbeat, 15000);

        /* include oneall script */
        var oneall_subdomain = 'tlc-social-login';
        var oneAll = document.createElement('script');
        oneAll.type = 'text/javascript'; oneAll.async = true;
        oneAll.src = '//' + oneall_subdomain + '.api.oneall.com/socialize/library.js';
        var script = document.getElementsByTagName('script')[0];
        script.parentNode.insertBefore(oneAll, script);

        window.addEventListener("beforeunload", () => {
            this.disconnectFromHub(this.props.audience.Id);
        }, false)
    }

    hubInitialize = (id, agentId) => {
        var self = this;
        // set up event listeners i.e. for incoming "message" event
        this.hubProxy.on('message', function (message) {
            console.log(message);
        });

        this.hubProxy.on('agentConnected', function (message) {
            console.log(message);
        });

        this.hubProxy.on('agentDisconnected', function (message) {
            console.log(message);
        });

        this.hubProxy.on('clientsConnected', function (clientIds) {
            console.log('clients connected: ' + clientIds.join(','));
        });
        
        // TODO store agent clientids in redux to avoid this hub response
        this.hubProxy.on('isSelectedClientConnected', function (isConnected) {
        });

        this.hubProxy.on('loadActivity', function (result) {
            self.props.activityActions
                .addActivitiesToEntities(self.props.selectedClient ? self.props.selectedClient.Id: 'mine', result)
                .then(self.refreshFeedItems);
            console.log('selectedClient: ' + self.props.selectedClient + JSON.stringify(result));
        });

        this.connection.connectionSlow(() => {
                // The Socket is still connect but there is connection issues
                // We will attempt to reconnect
                console.log('We are currently experiencing difficulties with the connection. Will reattempt. ');
                
            });

        // connect
        self.connectToHub(id, agentId);
    }

    connectToHub = (id, agentId) => {
        var self = this;
        
        // connect
        this.connection.start()
            .done(function () {
                console.log('Now connected, connection ID=' + self.connection.id);
                self.hubProxy.invoke('connectToHub', id, agentId);
                self.getMyActivity();
                self.setState({signalRConnecting: false});

                self.connection.disconnected(() => {
                    // Socket is closed there will be no more attempt to reconnect
                    // Try to reconnect in 5 seconds
                    // setTimeout(self.signalrReconnect, 5000);
                });

            })
            .fail(function () { 
                console.log('SignalR Connection failed.'); 
        });
    }

    disconnectFromHub = (id) => {
        this.connection.disconnected(() => {         
        });

        // Disconnect
        if (this.connection !== undefined && this.connection.id !== undefined) {
            this.hubProxy.invoke('disconnectFromHub', id);
        }
        this.connection.stop();
    }

    // Update the server with a Heartbeat
    signalrHeartbeat = () => {
        if (this.connection !== undefined && this.connection.id !== undefined) { 
            this.hubProxy.invoke('HeartBeat');
        } 
    }

    signalrReconnect = () => {
        if ((!this.connection || !this.connection.id) && this.props.audience &&
            this.props.audience.Id !== undefined && this.props.session && !this.props.session.isAnonymous) {
            this.connectToHub(this.props.audience.Id, this.props.audience.AgentId); 
        }
    }

    componentWillUnmount(){
        clearInterval(this.refreshAuthTimer);
        clearInterval(this.signalrHeartbeatTimer);
        clearInterval(this.feedTimer);
    }

    refreshAuth = () => {
        const { session, sessionActions } = this.props;
        if (session.isAuthenticated && session.isAuthenticating === false) {
            sessionActions.refreshAuthToken();
        }
    }
    
    getMyActivity = () => { 
        this.props.activityActions.loadActivity('mine', false, true).then(this.refreshFeedItems); 
    }

    checkForNewActivity = () => {
        const { session, selectedClient, activityActions, isAnonymous} = this.props;

        if (session.isAuthenticated && session.isAuthenticating === false && !isAnonymous) {

            if (selectedClient) {
                activityActions.loadActivity(selectedClient.Id, false, true).then(this.refreshFeedItems);
            }
            else {
                activityActions.loadActivity('mine', false, true).then(this.refreshFeedItems);
            }
        }
    }

    // Update any items in the dashboard if they exist
    refreshFeedItems = () => {
        const {feedActions, feedActivity, feedActivityEntities, listingActions, selectedClient, audience } = this.props;
        if (feedActivity === undefined || feedActivity.updateDashboardIds === undefined || feedActivity.updateDashboardIds.length === 0) {
            return;
        }
        var updateBookmarks = false;
        var updateFavorites = false;
            
        const lastReadId = Math.max.apply(Math, feedActivity.updateDashboardIds);
        this.hubProxy.invoke('setLastReadId', audience.Id, lastReadId);

        feedActivity.updateDashboardIds.forEach(function (feedId) {
            var item = feedActivityEntities[feedId]

            // Update the entity for each updated FeedItem
            if (item.ListingId !== null) {
                feedActions.fetchListingFeed(item.ClientId, item.ListingId)
                if ((item.ActionName === 'CreatedBookmark' || item.ActionName === 'DeletedBookmark') &&
                    (audience.type !== 'agent' || (selectedClient && selectedClient.Id === item.ClientId)))
                    updateBookmarks = true;
                else if ((item.ActionName === 'CreatedFavorite' || item.ActionName === 'DeletedFavorite') &&
                    (audience.type !== 'agent' || (selectedClient && selectedClient.Id === item.ClientId)))
                    updateFavorites = true;
            }
        });
        if (updateBookmarks)
            listingActions.fetchAgentClientListings('bookmarks', selectedClient ? selectedClient.Id : 0);
        if (updateFavorites)
            listingActions.fetchAgentClientListings('favorites', selectedClient ? selectedClient.Id : 0);
    }

    showNotification(type, title, message, duration, button1Text, button1Function, button1FunctionParam, button2Text, button2Function,button2FunctionParam){
        if(this.notificationSystem) {
            var nProps = {
                message: message,
                level: type,
                autoDismiss: 8,
                dismissible: true,
                position: 'tc'
            };
            if(title && title !== ''){
                nProps.title = title;
            }
            if(button1Text || button2Text){
                var btn1 = null;
                if(button1Text && button1Function && button1FunctionParam){
                    btn1 = <button className="tru-btn tru-btn-default" onClick={() => button1Function(button1FunctionParam)}>{button1Text}</button>
                }
                else if(button1Text && button1Function && !button1FunctionParam){
                    btn1 = <button className="tru-btn tru-btn-default" onClick={button1Function}>{button1Text}</button>
                }
                else if(button1Text && !button1Function){
                    btn1 = <button className="tru-btn tru-btn-default">{button1Text}</button>
                }
                var btn2 = null;
                if(button2Text && button2Function && button2FunctionParam){
                    btn2 = <button className="tru-btn tru-btn-default" onClick={() => button2Function(button2FunctionParam)}>{button2Text}</button>
                }
                else if(button2Text && button2Function && !button2FunctionParam){
                    btn2 = <button className="tru-btn tru-btn-default" onClick={button2Function}>{button2Text}</button>
                }
                else if(button2Text && !button2Function){
                    btn2 = <button className="tru-btn tru-btn-default">{button2Text}</button>
                }
                var child = (<div className="notification_btn">{btn1} {btn2}</div>);
                nProps.children = child;
                nProps.autoDismiss = 0;
            }

            this.notificationSystem.addNotification(nProps);
        }
    }

    onClientSelection(selectedClient){
        if(this.propertyDetailPageRef){
            if(this.propertyDetailPageRef.workingWithClient){
                this.propertyDetailPageRef.workingWithClient(selectedClient);
            }
        }
        if(this.dashboardPageRef){
            if(this.dashboardPageRef.workingWithClient){
                this.dashboardPageRef.workingWithClient(selectedClient);
            }
        }
        if(this.searchPageRef){
            if(this.searchPageRef.workingWithClient){
                this.searchPageRef.workingWithClient(selectedClient);
            }
        }
    }

    render() {

        const { session, sessionActions } = this.props;

        var mainElementClass = "main-nonfixed";
        if(this.state.isHeaderFixed) {
            mainElementClass = "main-fixed";
        }
        if(ThemeCustomizationOptions && ThemeCustomizationOptions.MainMenuBarClass && ThemeCustomizationOptions.MainMenuBarClass !== ""){
            mainElementClass = mainElementClass + " " + ThemeCustomizationOptions.MainMenuBarClass;
        }
        else{
            mainElementClass = mainElementClass + " menu-standard";
        }
        if(FooterCustomizationOptions && FooterCustomizationOptions.ShowCustomAds){
            mainElementClass = mainElementClass + " admargin";
        }

        if(FooterCustomizationOptions && FooterCustomizationOptions.FooterStyle && FooterCustomizationOptions.FooterStyle !== ""){
            mainElementClass = mainElementClass + " " + FooterCustomizationOptions.FooterStyle;
        }

        if(ThemeCustomizationOptions && ThemeCustomizationOptions.MainSearchBarClass && ThemeCustomizationOptions.MainSearchBarClass !== ""){
            mainElementClass = mainElementClass + " " + ThemeCustomizationOptions.MainSearchBarClass;
        }

        if(!this.props.isAnonymous) mainElementClass = mainElementClass + " loggedin";        

        var header = null;

        // Do not load the Nav Bar until the user is authenticated or bad things will happen
        //if(this.state.showHeader && session.isAuthenticated ) {
        if(this.state.showHeader) {
            header = <NavBar isHeaderFixed={this.state.isHeaderFixed} showNotification={this.showNotification} onClientSelection={this.onClientSelection} />
        }

        var footer = null;
        if(this.state.showFooter) {
            footer = <Footer showNotification={this.showNotification} />
        }

        return (
            <div className="App">
                <div className={mainElementClass}>
                    <Router>
                        <div>
                            {/*<ReactNotify ref={(ref) => this.notificator = ref} />*/}
                            <NotificationSystem ref={(ref) => this.notificationSystem = ref} />

                            {/* if is purl don't render header but pass as param to be rendered inside */}
                            <Route render={({ location }) => {
                                return location.pathname.indexOf('/agenturl/') === -1 ? header : null 
                            }} />
                            <Route path="/agenturl/:memberMlsId" render={(props) =>
                                <AsyncPurlWrapper
                                    session={session}
                                    refreshAuth={sessionActions.refreshAuthToken}
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    header={header}
                                    { ...props } />}
                            />
                            <Route path="/login" render={(props) =>
                                <LoginPage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    { ...props } />}
                            />
                            <Route path="/sso" render={(props) =>
                                <SSOPage 
                                    { ...props } />}
                            />
                            <Route path="/signup" render={(props) => (
                                <SignUpPage
                                    ref={(ref) => this.signUpPageRef = ref}
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/register" render={(props) => (
                                <RegisterPage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/forgotpassword" render={(props) => (
                                <ForgotPasswordPage
                                    ref={(ref) => this.forgotPasswordPageRef = ref}
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/createaccount" render={(props) => (
                                <CreateAccountPage
                                    ref={(ref) => this.createAccountPageRef = ref}
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/resetpassword" render={(props) => (
                                <ResetPasswordPage
                                    ref={(ref) => this.resetPasswordPageRef = ref}
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/set-my-password" render={(props) => (
                                <SetPasswordPage
                                    ref={(ref) => this.setPasswordPageRef = ref}
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/feedback" render={(props) => (
                                <FeedbackPage
                                    ref={(ref) => this.feedbackPageRef = ref}
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/" exact={true} render={(props) => (
                                <AsyncLandingPage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}                                
                                    {...props} />
                            )} />
                            <Route path="/costofliving" render={(props) => (
                                <AsyncOnCostOfLivingPage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}                                
                                    {...props} />
                            )} />
                            <Route path="/search/:searchparams?" render={(props) => (
                                <AsyncSearchPage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/propertydetail/:propertydetailId/:propertyname" render={(props) => (
                                <AsyncPropertyDetailPage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/printpiece" render={(props) => (
                                <AsyncPrintPiecePage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showNotification={this.showNotification}
                                    showHeader={this.state.showHeader}
                                    {...props} />
                            )} />
                            <Route path="/openhouse" render={(props) => (
                                <AsyncOpenHousesPage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showNotification={this.showNotification}
                                    showHeader={this.state.showHeader}
                                    {...props} />
                            )} />
                            <Route path="/hotprops" render={(props) => (
                                <AsyncHotPropsPage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showNotification={this.showNotification}
                                    showHeader={this.state.showHeader}
                                    {...props} />
                            )} />
                            <Route path="/featuredprops" render={(props) => (
                                <AsyncFeaturedPropsPage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showNotification={this.showNotification}
                                    showHeader={this.state.showHeader}
                                    {...props} />
                            )} />
                            <Route path="/newbuilds" render={(props) => (
                                <AsyncBuiltForYouPage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/aboutus" render={(props) => (
                                <AsyncAboutUsPage                                
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/(posts|pages)/:objectId" render={(props) => (
                                <AsyncPages
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/categories/:objectId" render={(props) => (
                                <AsyncCategories
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/blog/:objectId/:pageId?" render={(props) => (
                                <AsyncBlogs
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/neighborhood/:name" render={(props) => (
                                <AsyncNeighborhood
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/neighborhoods/" render={(props) => (
                                <AsyncNeighborhoodsList
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/communities/" render={(props) => (
                                <AsyncCommunitiesList
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/agentprofile/:MemberMlsId" render={(props) => (
                                <AsyncAgentProfile
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/realtor" render={(props) => (
                                <AsyncAgentOfficePage
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/about/:id?" render={(props) => (
                                <AsyncAboutPage                                
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />
                            <Route path="/placead" render={(props) => (
                                <AsyncPlaceAdPage                                
                                    onShowHideFooter={this.onShowHideFooter}
                                    showFooter={this.state.showFooter}
                                    onShowHideHeader={this.onShowHideHeader}
                                    showHeader={this.state.showHeader}
                                    showNotification={this.showNotification}
                                    {...props} />
                            )} />


                            <PrivateRoute
                                path="/personalize"
                                session={ session }
                                refreshAuth={ sessionActions.refreshAuthToken }
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                showNotification={this.showNotification}                                
                                component={AsyncPersonalizePage} />
                            <PrivateRoute
                                path="/onboard"
                                session={ session }
                                refreshAuth={ sessionActions.refreshAuthToken }
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                showNotification={this.showNotification}                                
                                component={AsyncOnBoardPage} />
                            <PrivateRoute
                                path="/profile"
                                session={ session }
                                refreshAuth={ sessionActions.refreshAuthToken }
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                showNotification={this.showNotification}
                                component={ AsyncProfilePage } />
                            <PrivateRoute
                                path="/dashboard/:activeTab?/:activityFeedId?"
                                session={ session }
                                refreshAuth={ sessionActions.refreshAuthToken }
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showNotification={this.showNotification}
                                showHeader={this.state.showHeader}
                                component={ AsyncDashboardPage } />
                            <PrivateRoute
                                path="/manage/:activeTab?"
                                session={ session }
                                refreshAuth={ sessionActions.refreshAuthToken }
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showNotification={this.showNotification}
                                showHeader={this.state.showHeader}
                                component={ AsyncManagePage } />
                            <Route path="/favorites" render={() => (<h1>Favorties</h1>)}/>
                            <PrivateRoute
                                path="/myagent/:activeTab?"
                                session={ session }
                                refreshAuth={ sessionActions.refreshAuthToken }
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                showNotification={this.showNotification}
                                component={ AsyncMyAgentPage } />
                            <PrivateRoute
                                path="/agent/clients/:clientId/:section?"
                                session={ session }
                                refreshAuth={ sessionActions.refreshAuthToken }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncAgentClient } />
                            <PrivateRoute
                                path="/agent/client/add"
                                session={ session }
                                refreshAuth={ sessionActions.refreshAuthToken }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncAddClientPage } />
                            <PrivateRoute
                                path="/titletoolbox" 
                                session={ session }
                                refreshAuth={ sessionActions.refreshAuthToken }
                                component={ AsyncTitleToolBox }
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                showNotification={this.showNotification} />
                            <PrivateRoute
                                exact={true}
                                path="/journey/phase1"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase1Page } />
                            <PrivateRoute
                                exact={true}
                                path="/journey/Phase-1-intro"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase1IntroPage } />  
                            <PrivateRoute
                                exact={true}
                                path="/journey/your-life-today"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase1YourLifeTodayPage } />
                            <PrivateRoute
                                exact={true}
                                path="/journey/your-finances-today"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase1YourFinancesTodayPage } />
                            <PrivateRoute
                                exact={true}
                                path="/journey/hopes-for-tomorrow"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase1HopesForTomorrowPage } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/moving-where"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase1MovingWherePage } />  
                            <PrivateRoute
                                exact={true}
                                path="/journey/neighborhood-qualities-you-love"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase1NeighborhoodQualitiesPage } /> 
                             <PrivateRoute
                                exact={true}
                                path="/journey/start-collecting-listings"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase1StartCollectingListingsPage } />
                            <PrivateRoute
                                exact={true}
                                path="/journey/moving-when"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase1YourTimingPage } />
                            <PrivateRoute
                                exact={true}
                                path="/journey/Phase-2-intro"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2IntroPage } />    
                            <PrivateRoute
                                exact={true}
                                path="/journey/what-can-you-afford"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2WhatYouCanAffordPage } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/credit-score"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2GetYourCreditScorePage } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/best-ways-to-connect"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2BestWaysConnectPage } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/mortgage-basics"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2MortgagesPage } />     
                            <PrivateRoute
                                exact={true}
                                path="/journey/types-of-mortgages"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2TypesMortgagesPage } />     
                            <PrivateRoute
                                exact={true}
                                path="/journey/calculating-down-payment"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2CalculatingPaymentPage } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/lenders-101"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2Lenders101Page } />
                            <PrivateRoute
                                exact={true}
                                path="/journey/compare-lenders"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2ComparePotentialLendersPage } />   
                            <PrivateRoute
                                exact={true}
                                path="/journey/pre-approval-101"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2PreApproval101Page } />   
                             <PrivateRoute
                                exact={true}
                                path="/journey/get-pre-approved"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2GetPreApprovedPage } />                                                        
                            <PrivateRoute
                                exact={true}
                                path="/journey/phase2"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase2Page } />  
                            <PrivateRoute
                                exact={true}
                                path="/journey/Phase-3-intro"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase3IntroPage } />
                            <PrivateRoute
                                exact={true}
                                path="/journey/my-future-neighborhood"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase3FutureNeighborhoodPage } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/my-future-home"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase3MyFutureHomePage } />  
                            <PrivateRoute
                                exact={true}
                                path="/journey/narrow-down-your-listings"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase3NarrowDownYourListingsPage } />   
                            <PrivateRoute
                                exact={true}
                                path="/journey/find-a-great-agent"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase3FindGreatAgentPage } />  
                            <PrivateRoute
                                exact={true}
                                path="/journey/share-availability"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase3ShareTouringAvailabilityPage } />   
                            <PrivateRoute
                                exact={true}
                                path="/journey/tour-homes"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase3TourHousesLikeProPage } />
                            <PrivateRoute
                                exact={true}
                                path="/journey/decide-on-a-house"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase3DecideonHousePage } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/phase3"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase3Page } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/Phase-4-intro"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4IntroPage } />  
                            <PrivateRoute
                                exact={true}
                                path="/journey/closings-101"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4Closings101Page} /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/find-an-escrow-agent"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4FindAnEscrowAgentPage} />
                            <PrivateRoute
                                exact={true}
                                path="/journey/making-an-offer-101"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4MakingAnOffer101Page} /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/complete-purchase-offer"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4CompletePurchaseOfferPage} />
                            <PrivateRoute
                                exact={true}
                                path="/journey/write-a-personal-note"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4WritePersonalNotePage} />   
                            <PrivateRoute
                                exact={true}
                                path="/journey/submit-purchase-offer"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4SubmitPurchaseOfferPage} /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/good-faith-estimate"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4ReviewGoodFaithEstimatePage} />
                            <PrivateRoute
                                exact={true}
                                path="/journey/get-home-inspection"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4GetHomeInspectionPage} />  
                            <PrivateRoute
                                exact={true}
                                path="/journey/confirm-home-appraisal"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4ConfirmHomeAppraisalPage} />   
                            <PrivateRoute
                                exact={true}
                                path="/journey/clear-title-order-insurance"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4ClearTitleOrderInsurancePage} /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/arrange-final-walk-thru"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4ArrangeFinalWalkThruPage} />                                                                
                            <PrivateRoute
                                exact={true}
                                path="/journey/phase4"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase4Page } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/Phase-5-intro"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5IntroPage } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/confirm-closing-and-move-in-date"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5ClosingMoveInDatesPage } />
                            <PrivateRoute
                                exact={true}
                                path="/journey/homeowners-insurance-101"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5HomeownerInsurance101Page } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/order-homeowners-insurance"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5OrderHomeownerInsurancePage} />
                            <PrivateRoute
                                exact={true}
                                path="/journey/prepare-to-move"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5PrepareToMovePage} /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/change-address-and-utilities"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5ChangeAddressUtilitiesPage} /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/request-hud-1"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5RequestHUDFromLenderPage} /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/arrange-closing-costs"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5ArrangeToPayClosingCostsPage} /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/gather-closing-docs"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5GatherClosingDocsPage} /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/perform-final-walk-thru"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5PerformFinalWalkThruPage} />                     
                            <PrivateRoute
                                exact={true}
                                path="/journey/phase5"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase5Page } />
                            <PrivateRoute
                                exact={true}
                                path="/journey/Phase-6-intro"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase6IntroPage } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/confirm-closing-details"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase6ConfirmClosingDetailsPage } />   
                            <PrivateRoute
                                exact={true}
                                path="/journey/review-hud-1"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase6ReviewHUD1Page } /> 
                            <PrivateRoute
                                exact={true}
                                path="/journey/compare-gfe-to-hud-1"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase6CompareGFEToHUD1Page} />  
                            <PrivateRoute
                                exact={true}
                                path="/journey/attend-closing"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase6AttendClosingPage} />   
                            <PrivateRoute
                                exact={true}
                                path="/journey/welcome-to-your-home"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase6WelcomeToYourHomePage} />                      
                            <PrivateRoute
                                exact={true}
                                path="/journey/phase6"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPhase6Page } />                
                            <PrivateRoute
                                exact={true}
                                path="/journey"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncJourneyPage } />
                            <PrivateRoute
                                path="/info/:subtitle"
                                session={ session }
                                showNotification={this.showNotification}
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                component={ AsyncInfoPage } />                            
                            <PrivateRoute
                                path="/onboarding"
                                session={ session }
                                refreshAuth={ sessionActions.refreshAuthToken }
                                onShowHideFooter={this.onShowHideFooter}
                                showFooter={this.state.showFooter}
                                onShowHideHeader={this.onShowHideHeader}
                                showHeader={this.state.showHeader}
                                showNotification={this.showNotification}                                
                                component={AsyncNewOnBoardingPage} />
                            <Route path="/campaign" render={(props) => <CampaignPage { ...props } />} />

                            <AsyncGoogleAnalytics />
                            <AsyncApplicationInsights />
                            <AsyncCompareAlert />
                            <AsyncSiteTour isAnonymous={this.props.isAnonymous} audience={this.props.audience} selectedClient={this.props.selectedClient} />
                            {(AdCustomizationOptions && AdCustomizationOptions.ShowMyAPOffers) ?
                                (<AsyncMyAPOffers session={ session } showNotification={this.showNotification} />) :
                                ((AdCustomizationOptions && AdCustomizationOptions.MyOffersURL && AdCustomizationOptions.MyOffersURL !== "") ?
                                <AsyncMyOffers /> : null)
                            }                          
                        </div>
                    </Router>
                </div>
                {footer}
                {(CommonCustomizationOptions && CommonCustomizationOptions.WebsiteErrorMessage && CommonCustomizationOptions.WebsiteErrorMessage !== "") &&
                    <div className="site-error-msg-wrapper"><div className="site-error-msg"><i className="fa fa-exclamation-triangle"></i> {CommonCustomizationOptions.WebsiteErrorMessage}</div></div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {

    const { entities, clients, activity } = state;
    const selectedClient = entities.clients[clients.selectedClient];

    let feedActivity;
    if (selectedClient) {
        feedActivity = activity[selectedClient.Id];
    }
    else {
        feedActivity = activity['mine'];
    }

    var session = state.session;

    return {
        audience: state.session.audience,
        session,
        sessionNotification: state.session.notificationMessage,
        feedItems: state.entities.feedItems,
        selectedClient,        
        feedActivity,
        feedActivityEntities: state.entities.activities,
        isAnonymous: state.session.isAnonymous,
        ssoLogoutRedirectUrl: state.session.ssoLogoutRedirectUrl
    }
};

const mapDispatchToProps = dispatch => ({
    sessionActions: bindActionCreators(sessionActions, dispatch),
    feedActions: bindActionCreators(feedActions, dispatch),
    activityActions: bindActionCreators(activityActions, dispatch),
    listingActions: bindActionCreators(listingActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

// Redirect to the component only if the user is logged in, otherwise send to login page
const PrivateRoute = ({component: Component, session, refreshAuth, ...rest }) => (
    <Route {...rest} render={props => {

        const { isAuthenticating, isAuthenticated, isAnonymous } = session;

        // We are not Authenticated and there is no open Authentication requests
        // Redirect to login
        if (isAuthenticating === false && (isAuthenticated === false || (isAuthenticated && isAnonymous))) {
            // check if using social login
            if (props.location && props.location.search) {
                const obj = qs.parse(props.location.search);
                if (obj.oaToken) {
                    var socialResponse = JSON.parse(base64.decode(obj.oaToken));
                    rest.sessionActions.manualLogin(socialResponse);
                    sessionStorage.setItem("LoggedInWithSocialLogins","true");
                    return <Redirect to={{pathname: '/', query: props.location.search, state: {from: props.location}}}/>;
                }
            }
        }

        if (isAuthenticated === false && isAuthenticating === false) {
            if(props.location && props.location.pathname && props.location.pathname !== '/' && 
                props.location.pathname !== '/profile' && props.location.pathname !== '/register'){
                window.sessionStorage.setItem("RedirectLocation",base64.encode(utf8.encode(props.location.pathname)));
            }
            return <Redirect to={{pathname: '/login', query: props.location.search, state: {from: props.location}}}/>;
        }

        //Waiting on Authentication to Complete
        if (isAuthenticating && isAuthenticated === false) {
            return <PendingAuthentication />;
        }

        if(window.sessionStorage.getItem("RedirectLocation")){                
            window.sessionStorage.removeItem("RedirectLocation");
        }


        if(!isAuthenticating && isAuthenticated && isAnonymous){
            if(rest.path && (rest.path.indexOf("/profile") > -1 || rest.path.indexOf("/dashboard") > -1 || 
                rest.path.indexOf("/agent/clients/") > -1 || rest.path.indexOf("/myagent") > -1 || 
                rest.path.indexOf("/agent/client/add") > -1)){
                return <Redirect to={{pathname: '/', query: props.location.search, state: {from: props.location}}}/>;
            }
        }

        return <Component {...props} {...rest} />;

    }}/>
)
