import React, { Component } from 'react';
import TrueLogo from '../img/svg/truebytlc-logo.svg';

class PrivacyPolicy extends Component {

    render() {

        var siteLogo = TrueLogo;

        if (process.env.REACT_APP_WebsiteLogo !== undefined && process.env.REACT_APP_WebsiteLogo !== null &&
            process.env.REACT_APP_WebsiteLogo !== ""){
            siteLogo = process.env.REACT_APP_WebsiteLogo;
        }

        return (
            <div className="terms_condition_page">
                <div className="inner_logo"><img src={siteLogo} alt=""/></div>
                    <div className="content_part">
                        <div className="highlight_title">Online Privacy Policy</div>
                        <p>The Company respects the privacy rights of our online visitors and recognizes the importance of protecting the information collected from you. We have adopted an Online Privacy Policy that guides how we collect, store and use the information you provide online.  Please note that this policy applies only to sites maintained by Company, and not to web sites maintained by other companies or organizations to which we link.</p>
                        <p>If you have questions or concerns regarding this statement, you should first contact Company at the email address or postal address specified on this Website.</p>
                        <p><strong>Information Collection And Use</strong></p>
                        <p>Our primary goals in collecting personally identifiable information are to provide you with the product and services made available through our Website, including, but not limited, our services, to communicate with you, and to manage your registered user account, if you have one.</p>
                        <p><strong>Information Collected Upon Registration</strong>: If you desire to have access to certain restricted sections of our web site, you will be required to become a registered user, and to submit certain personally identifiable information to Company. This happens in a number of instances, such as when you sign up for our services, or if you desire to receive marketing materials and information or if you chose to obtain information tailored based on your personal situation. Personally identifiable information that we may collect in such instances may include your IP address, full user name, password, email address, city, time zone, telephone number, and other information that you decide to provide us with, or that you decide to include in your public profile.</p>
                        <p><strong>Use of Contact Information</strong>: In addition, we may use your contact information to market to you, and provide you with information about, our products and services or the products and services of third parties. If you decide at any time that you no longer wish to receive such information or communications from us, please follow the unsubscribe instructions provided in any of the communications.</p>
                        <p><strong>Log Data</strong>: When you visit our Website, our servers automatically record information that your browser sends whenever you visit a Website as Log Data. This Log Data may include information such as your IP address, browser type or the domain from which you are visiting, the websites you visit, the search terms you use, and any advertisements on which you click. For most users accessing the Internet from an Internet service provider the IP address will be different every time you log on. We use Log Data to monitor the use of the Site and of our Service, and for the Site's technical administration. We do not associate your IP address with any other personally identifiable information to identify you personally, except in case of violation of the Terms of Service.</p>
                        <h4>Cookies and Other Technologies</h4>
                        <p><strong>Cookies</strong>: We use cookies and other technologies to passively collect demographic information, personalize your experience on our site and monitor advertisements and other activities. Cookies are small files downloaded to your computer to track movements within web sites. We may link cookie information to personal information. Cookies link to information regarding what  transactions you have conducted or pages you have viewed on our Website. This information is used to keep track of your ongoing transactions and make sure you don't see the same ad repeatedly. Also, we use cookies to deliver content, including advertising specific to your interest and to monitor website usage. Some of our sites use an outside ad company to display ads. These ads may contain cookies. Our ad companies collect cookies received with banner ads, and Company does not have access to this information. Most browsers are automatically set to accept cookies whenever you visit a website. You can disable cookies or set your browser to alert you when cookies are being sent. However some areas of our sites will not function properly if you do so. Please note that this privacy policy covers the use of cookies by Company only and does not cover the use of cookies by any advertisers.</p>
                        <p><strong>Other Technologies</strong>: Other technologies used include clear GIFs and IP address logging. Clear GIFs also known as web bugs, beacons or tags, are small graphic images placed on a web page, web-based document, or in an e-mail message. Clear GIFs are invisible to the user because they are typically very small (only 1-by-1 pixel) and the same color as the background of the web page, document or e-mail message. We do not use clear GIFs to collect personal information about you. However, we may use clear GIFs to capture statistical usage information for our web pages, features or other elements on a web page. We may correlate this information to a user to personalize user experience and for statistical analysis of user experiences on our web pages.</p>
                        <h4>Third Parties</h4>
                        <p><strong>Third Party Services</strong>: Users may register for other services from our website. Certain products and/or services available on our site are provided to you in partnership with third party(s) and may require you to disclose personally identifiable information in order to register for and access such products and/or services. Such products and/or services shall identify the third party partners at the point of registration. If you elect to register for such products and/or services your personally identifiable information will be transferred to such third party(s) and will be subject to the privacy policy and practices of such third party(s). We are not responsible for the privacy practices and policies of such third party(s) and, therefore, you should review the privacy practices and policies of such third party(s) prior to providing your personally identifiable information in connection with such products and/or services.</p>
                        <p><strong>Service Providers</strong>: We engage certain trusted third parties to perform functions and provide services to us, including, without limitation, hosting and maintenance, customer relationship, database storage and management, and direct marketing campaigns. We will share your personally identifiable information with these third parties, but only to the extent necessary to perform these functions and provide such services, and only pursuant to binding contractual obligations requiring such third parties to maintain the privacy and security of your data.</p>
                        <p><strong>Business Transfers</strong>: We may sell, transfer or otherwise share some or all of its assets, including your personally identifiable information, in connection with a merger, acquisition, reorganization or sale of assets or in the event of bankruptcy. You will have the opportunity to opt out of any such transfer if the new entity's planned processing of your information differs materially from that set forth in this Privacy Policy.</p>
                    </div>

            </div>


        );
    }
}

export default PrivacyPolicy;