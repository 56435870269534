import { CALL_API } from '../store/api';
import Cookies from 'universal-cookie';

export const MO_FETCH_REQUEST = 'MO_FETCH_REQUEST';
export const MO_FETCH_SUCCESS = 'MO_FETCH_SUCCESS';
export const MO_FETCH_FAILURE = 'MO_FETCH_FAILURE';

export const fetchMyOffers = (key) => (dispatch, getState) => {
    var endpoint = "ads/myofferads?type=" + key; 
    
    if (process.env.REACT_APP_AllowAnonymous === "true"){
        const cookies = new Cookies();
        const authToken = cookies.get('authDetails');
        if (authToken && authToken.Audience && authToken.Audience.Id &&
            authToken.Audience.Id === process.env.REACT_APP_AnonymousUserId){
            var DeviceUID = window.localStorage.getItem("DeviceUID");
            if(DeviceUID && DeviceUID !== ""){
                endpoint = endpoint + "&duid=" + DeviceUID;
            }
        }        
    }

    return dispatch({
        [CALL_API]: {
            types: [MO_FETCH_REQUEST, MO_FETCH_SUCCESS, MO_FETCH_FAILURE],
            method: 'GET',
            endpoint: endpoint,
        },
        key
    });
}

export const setCurrentOfferDetail = (key, id, currentAdd) => (dispatch, getState) => {
    return dispatch({
        type: MO_FETCH_SUCCESS,
        key: key,
        currentAdd: currentAdd
    });
}