import { CALL_API, Schemas } from '../store/api';

export const FEED_REQUEST = 'FEED_REQUEST';
export const FEED_SUCCESS = 'FEED_SUCCESS';
export const FEED_FAILURE = 'FEED_FAILURE';

export const SINGLE_FEED_REQUEST = 'SINGLE_FEED_REQUEST';
export const SINGLE_FEED_SUCCESS = 'SINGLE_FEED_SUCCESS';
export const SINGLE_FEED_FAILURE = 'SINGLE_FEED_FAILURE';

const fetchFeedItems = (key, endpoint, body) => ({
    [CALL_API]: {
        types: [FEED_REQUEST, FEED_SUCCESS, FEED_FAILURE],
        method: 'POST',
        body,
        endpoint,
        schema: Schemas.FEED_ITEM_ARRAY
    },
    key
})

export const loadFeed = (key, nextPage) => (dispatch, getState) => {
    const { session, feed, entities } = getState();
    const { type, Id } = session.audience;
    const {
        pageCount = 0,
        ids = [],
        moreItems = true
    } = feed[key] || {};

    // Cached results, don't fetch new items
    if (pageCount > 0 && !nextPage) {
        return null;
    }
    // Don't fetch more when there are no more items
    if (nextPage && !moreItems) {
        return null;
    }

    let count = 5;
    let lastDate;
    const feedItems = ids.map(id => entities.feedItems[id]);

    // Calculate count and lastDate for pagination
    if (feedItems.length > 0) {
        //lastDate = new Date(Math.min(...feedItems.map(item => new Date(item.LastActionOn.replace('T',' ') + ' UTC'))));
        //const totalwithDate = feedItems.filter(item => (new Date(item.LastActionOn.replace('T',' ') + ' UTC')).valueOf() === lastDate.valueOf());
        lastDate = new Date(Math.min(...feedItems.map(item => new Date(item.LastActionOn + 'Z'))));
        const totalwithDate = feedItems.filter(item => (new Date(item.LastActionOn + 'Z')).valueOf() === lastDate.valueOf());
        count += totalwithDate.length;
    }

    let endpoint;

    // Determine endpoint by checking session audience type and the 'key' passed into action
    if (type === 'agent') {
        endpoint = `agents/${Id}`;

        if (key !== 'mine') {
            endpoint +=  `/clients/${key}`;
        }
    }
    else {
        endpoint = `clients/${Id}`;
    }

    endpoint += '/activityfeed';

    const body = {
        Count: count,
        startDate: lastDate,
        Comments: true
    };

    return dispatch(fetchFeedItems(key, endpoint, body));
}

export const fetchListingFeed = (clientId, listingId) => (dispatch, getState) => {
    const { Id, type } = getState().session.audience;
    var endpoint = (type === "agent" ? "agents/" + Id + "/" : "") + "clients/" + clientId + "/activityfeed/" + listingId;
    return dispatch({
        [CALL_API]: {
            types: [SINGLE_FEED_REQUEST, SINGLE_FEED_SUCCESS, SINGLE_FEED_FAILURE],
            method: 'GET',
            endpoint: endpoint,
            schema: Schemas.FEED_ITEM
        }
    });
};

export const fetchActivityFeed = (clientId, listingId, activityId) => (dispatch, getState) => {
    const { Id, type } = getState().session.audience;
    var endpoint = (type === "agent" ? "agents/" + Id + "/" : "") + "clients/" + clientId + "/activityfeed/" + listingId + "/activity/" + activityId;
    return dispatch({
        [CALL_API]: {
            types: [SINGLE_FEED_REQUEST, SINGLE_FEED_SUCCESS, SINGLE_FEED_FAILURE],
            method: 'GET',
            endpoint: endpoint,
            schema: Schemas.FEED_ITEM
        }
    });
};