import React from 'react';
import { connect } from 'react-redux';
import { GetSectionCustomizationOptions } from 'components/util';
import 'css/contact_agent.css';
// import { toLength } from 'lodash';

const FooterCustomizationOptions = GetSectionCustomizationOptions("FooterOptions");
const AdminCustomizationOptions = GetSectionCustomizationOptions("AdminOptions");
const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");

class FooterStyleList extends React.Component{

    constructor(props) {
        super(props);

        this.state = { };
    }

    render(){

        var crText = null;        
        if(FooterCustomizationOptions && FooterCustomizationOptions.CopyRightTextInFooter && FooterCustomizationOptions.CopyRightTextInFooter !== ""){
            crText = FooterCustomizationOptions.CopyRightTextInFooter;
            crText = crText.replace(/COPYRYEAR/g,new Date().getFullYear());
            var crArr = crText.split('COPYRSYMBOL');
            if(crArr && crArr.length > 0){
                crText = [];
                crArr.map((s, i) => {
                    if(s && s !== ""){
                        crText.push(<span key={i}>{s}</span>);
                    }
                    if(i !== (crArr.length - 1)){
                        crText.push(<span key={'sq_' + i}>&copy;</span>);
                    }
                    return null;
                });
            }
        }
        var showSI = false;
        var crClass = "", pbClass = "col-md-12 col-sm-12 col-xs-12 mb_footer_full text-center", siClass = "";
        if(FooterCustomizationOptions && 
            (FooterCustomizationOptions.FacebookPageUrl || FooterCustomizationOptions.TwitterPageUrl || 
            FooterCustomizationOptions.InstagramPageUrl || FooterCustomizationOptions.LinkedInPageUrl || 
            FooterCustomizationOptions.YouTubePageUrl)){
            showSI = true;
        }
        if(crText && showSI){
            crClass = "col-md-6 col-sm-5 col-xs-12 mb_footer_full";
            pbClass = "col-md-3 col-sm-4 col-xs-12 mb_footer_full";
            siClass = "col-md-3 col-sm-3 col-xs-12 mb_footer_full";
        }
        else if(crText && !showSI){
            crClass = "col-md-9 col-sm-8 col-xs-12 mb_footer_full";
            pbClass = "col-md-3 col-sm-4 col-xs-12 mb_footer_full text-right";
        }
        else if(!crText && showSI){
            pbClass = "col-md-6 col-sm-6 col-xs-12 mb_footer_full";
            siClass = "col-md-6 col-sm-6 col-xs-12 mb_footer_full";
        }


        var cols = [];
        if(FooterCustomizationOptions && FooterCustomizationOptions.FooterColumnsList && FooterCustomizationOptions.FooterColumnsList.length > 0) {
            FooterCustomizationOptions.FooterColumnsList.map((t, i) => {
                var title = "";
                var lnks = [];
                if(t && t.Links && t.Links && t.Links.length > 0){
                    t.Links.map((l, j) => {

                        if(l && l.Type === "companylogo" && FooterCustomizationOptions && 
                            FooterCustomizationOptions.FooterLogoUrl && FooterCustomizationOptions.FooterLogoUrl !== ""){
                            lnks.push(<div key={"logo-" + i + "-" + (j+1)} className="mg-btm-15"><a href="/"><img className="footer_logo" src={FooterCustomizationOptions.FooterLogoUrl} alt="Logo"/></a></div>);
                        }
                        else if(l && l.Type === "companyaddress" && FooterCustomizationOptions && 
                            FooterCustomizationOptions.FooterAddress && FooterCustomizationOptions.FooterAddress !== ""){
                            lnks.push(<div key={"addr-" + i + "-" + (j+1)} className="comp_address mg-btm-25">{FooterCustomizationOptions.FooterAddress}</div>);
                        }
                        else if(l && l.Type === "textline" && l.Text && l.Text !== ""){
                            lnks.push(<div key={"tline-" + i + "-" + (j+1)} className="comp_address">{l.Text}</div>);
                        }
                        else if(l && l.Type === "phoneline" && l.Text && l.Text !== ""){
                            lnks.push(<div key={"tline-" + i + "-" + (j+1)} className="comp_address"><a href={"tel:"+ l.Text.replace(/ /g,"").replace(/-/g,"").replace(/\(/g,"").replace(/\)/g,"").replace(/\./g,"")} rel="noopener noreferrer" className="flink">{l.Text}</a></div>);
                        }
                        else if(l && l.Type === "emailline" && l.Text && l.Text !== ""){
                            lnks.push(<div key={"tline-" + i + "-" + (j+1)} className="comp_address"><a href={"mailto:" + l.Text} rel="noopener noreferrer" className="flink">{l.Text}</a></div>);
                        }
                        else if(l && l.Type === "imageline" && l.Url){
                            if(Array.isArray(l.Url) && l.Url.length > 0){
                                var imgArr = [];
                                l.Url.map((img, imgidx) => {
                                    if(img && img !== ""){
                                        imgArr.push(<img key={"tlimg-" + imgidx} src={img} alt='' className="comp_address_image" />);
                                    }
                                    return null;
                                });
                                if(imgArr.length > 0){
                                    lnks.push(<div key={"tline-" + i + "-" + (j+1)} className="comp_address">{imgArr}</div>);    
                                }
                            }
                            else if(l.Url !== ""){
                                lnks.push(<div key={"tline-" + i + "-" + (j+1)} className="comp_address"><img src={l.Url} alt='' className="comp_address_image" /></div>);
                            }                            
                        }
                        else if(l && l.Type === "manage" && AdminCustomizationOptions && AdminCustomizationOptions.AllowManage && 
                            this.props.profile && this.props.profile.IsSuperAdmin){
                            title = "Manage";
                            if(l && l.Title && l.Title !== ""){
                                title = l.Title;
                            }
                            lnks.push(<li key={i + "-" + (j+1)}><a href="/manage" rel="noopener noreferrer">{title}</a></li>);
                        }
                        else if(l && l.Type === "placeanad" && CommonCustomizationOptions && CommonCustomizationOptions.PlaceAdContactDetails && 
                            CommonCustomizationOptions.PlaceAdContactDetails.Name && CommonCustomizationOptions.PlaceAdContactDetails.Email){
                            title = "Place an Ad";
                            if(l && l.Title && l.Title !== ""){
                                title = l.Title;
                            }
                            lnks.push(<li key={i + "-" + (j+1)}><span className="flink anchor" onClick={this.props.openPAAModal}>{title}</span></li>);
                        }
                        else if(l && l.Type === "contactus"){
                            title = "Contact Us";
                            if(l && l.Title && l.Title !== ""){
                                title = l.Title;
                            }
                            lnks.push(<li key={i + "-" + (j+1)}><span className="flink anchor support-item-email" onClick={this.props.openCUModal}>{title}</span></li>);
                        }
                        else if(l && l.Title && l.Title !== "" && !l.Type){
                            if(l.Url && l.Url !== "" && l.Url.indexOf("/") === 0){
                                lnks.push(<li key={i + "-" + (j+1)}><a href={l.Url} rel="noopener noreferrer">{l.Title}</a></li>);
                            }
                            else if(l.Url && l.Url !== ""){
                                lnks.push(<li key={i + "-" + (j+1)}><a href={l.Url} rel="noopener noreferrer" target="_blank">{l.Title}</a></li>);
                            }
                            else{
                                lnks.push(<li key={i + "-" + (j+1)}>{l.Title}</li>);
                            }
                        }                        
                        
                        return null;
                    });
                }     
                cols.push(
                    <div className={t.Class || ''} key={i}>
                        {(t.Title && t.Title !== "") && <h4>{t.Title}</h4>}
                        {(lnks && lnks.length > 0) && <nav className="footer_nav"><ul>{lnks}</ul></nav>}
                    </div>
                );

                return null;
            })
        } 
        
        return(            
            <div className="footerstyle_cnt">
                <div className="fluid row footer-content">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="row">
                            {cols}                            
                        </div>
                    </div>  
                    {FooterCustomizationOptions && FooterCustomizationOptions.LicenseTextInFooter && FooterCustomizationOptions.LicenseTextInFooter !== "" &&
                        <div className="col-md-12 col-sm-12 col-xs-12 text-center">{FooterCustomizationOptions.LicenseTextInFooter}</div>
                    }
                </div>
                <div className="copyright_bg">
                    <div className="fluid row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="row">
                                {crText && <div className={crClass}>{crText}</div>}
                                <div className={pbClass}>
                                    <div className="ft_powerby_logo">
                                        <span className="display-ib mg-rt-5">Powered by</span>
                                        <span className="display-ib">
                                            <a href="https://www.tlcengine.com" rel="noopener noreferrer" target="_blank"><img className="powerby_logo" src="https://true-cms.tlcengine.com/wp-content/uploads/2020/03/tlclogo_small.png" alt="Tlcengine"/></a>
                                        </span>
                                    </div>                                    
                                </div>
                                {showSI && 
                                <div className={siClass}>
                                    <div className="ft_social_icons">
                                        <ul>
                                            {FooterCustomizationOptions && FooterCustomizationOptions.FacebookPageUrl &&
                                                <li><a href={FooterCustomizationOptions.FacebookPageUrl} rel="noopener noreferrer" target="_blank"> <i className="fa fa-facebook" ></i></a></li>
                                            }
                                            {FooterCustomizationOptions && FooterCustomizationOptions.TwitterPageUrl && 
                                                <li><a href={FooterCustomizationOptions.TwitterPageUrl} rel="noopener noreferrer" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                            }
                                            {FooterCustomizationOptions && FooterCustomizationOptions.InstagramPageUrl && 
                                                <li><a href={FooterCustomizationOptions.InstagramPageUrl} rel="noopener noreferrer" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                            }
                                            {FooterCustomizationOptions && FooterCustomizationOptions.LinkedInPageUrl && 
                                                <li><a href={FooterCustomizationOptions.LinkedInPageUrl} rel="noopener noreferrer" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                            }
                                            {FooterCustomizationOptions && FooterCustomizationOptions.YouTubePageUrl && 
                                                <li><a href={FooterCustomizationOptions.YouTubePageUrl} rel="noopener noreferrer" target="_blank"><i className="fa fa-youtube-play"></i></a></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state, props) => ({
    profile: state.profile.mine || {},
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterStyleList);