import React from 'react';
import queryString from 'query-string';
import base64 from 'base-64';
import dateFormat from 'dateformat';
import moment from 'moment';

export const AgentPath = 'agents/';
export const ClientPath = 'clients/';
export const PageTitle = (process.env.REACT_APP_WebsiteTitle && process.env.REACT_APP_WebsiteTitle !== "") ? '{title} | ' + process.env.REACT_APP_WebsiteTitle : '{title} | TRUE by TLCengine';

export function encodeSearchParams(props) {
    if(props.PlaceSearch){
        props.PlaceSearch = "<" + encodeSearchParams(props.PlaceSearch) + ">";
    }
    if(props.place){
        props.place =  "<" + encodeSearchParams(props.place) + ">";
    }
    return queryString.stringify(props);
    // return JSON.stringify(props)    
    // .replace(/\["/g, "_sarr_").replace(/"\]/g, "_earr_")
    // .replace(/{"/g, "").replace(/"}/g, "")
    // .replace(/{\["/g, "").replace(/"\]}/g, "")
    // .replace(/":"/g, ":")
    // .replace(/","/g, ";")
    // .replace(/":/g, ":").replace(/",/g, ";")
    // .replace(/:"/g, ":").replace(/,"/g, ";");
}

export function decodeSearchParams(props) {
    // var searchParams = props
    // .replace(/_sarr_/g, "\[\"").replace(/_earr_/g, "\"\]")
    // .replace(/</g, "{\"").replace(/>/g, "\"}")
    // .replace(/:/g, "\":\"").replace(/;/g, "\",\"")
    // .replace(/"{/g, "{").replace(/}"/g, "}");
    // searchParams = "{\"" + searchParams + ((searchParams.endsWith("}")) ? "}" : "\"}");
    // searchParams = searchParams.replace(/"\[/g,"\[").replace(/\]"/g, "\]"); // remove when array is in last
    // console.log(searchParams);
    // searchParams = JSON.parse(searchParams);

    var decodedSearch = queryString.parse(props);

    if(decodedSearch.PlaceSearch){
        decodedSearch.PlaceSearch = queryString.parse(decodedSearch.PlaceSearch.substr(1).slice(0, -1));
    }
    if(decodedSearch.place){
        decodedSearch.place = queryString.parse(decodedSearch.place.substr(1).slice(0, -1));
    }

    if(decodedSearch.polygon && typeof decodedSearch.polygon === 'string') {
        var polyString = decodedSearch.polygon.toString();
        decodedSearch.polygon = [];
        decodedSearch.polygon.push(polyString);
    }

    return decodedSearch;
    //return searchParams;
}

export function getDefaultSearchParams(props) {
    return {
        limitrecords: "100",
        ficoscore: "760-850",
        loantype: "30YrFixed",
        lifestyle: "couple-no-kids",
        orderby: "ListPrice",
        orderbydirection: "DESC",
        skip: 0,
        propertytype: "sf",
        page_number: 1,
        view: "maplist"
    };
}

export function getFormatedCurrency(num) {
    if (isNaN(num)) return "";
    var n = Math.round(num);
    return "$" + n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getFormatedNumber(num) {    
    if (isNaN(num)) return "";
    var n = Math.round(num);
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getFormatedDecimal(num, dec) {    
    if (isNaN(num)) return "";
    var i = parseInt(num, 10);
    var n = parseFloat(num, 10);
    n = i !== n ? parseFloat(num, 10).toFixed(dec ? dec : 2) : parseFloat(num, 10);
    return n.toString();
}

export function insertDecimal(num) {
    return Number((num / 100).toFixed(2));
 }

export function convertToDate(props, format) {
    var date = new Date(props);

    var formatDate = format.replace('wwww', getWeekDayName(date.getDay()));
    formatDate = formatDate.replace('www', getWeekDayShortName(date.getDay()));
    formatDate = formatDate.replace('mmmm', getMonthName(date.getMonth()));
    formatDate = formatDate.replace('mmm', getMonthShortName(date.getMonth()));
    formatDate = formatDate.replace('mm', ('0' + (date.getMonth() + 1)).slice(-2));
    formatDate = formatDate.replace('dd', ('0'+ date.getDate()).slice(-2));
    formatDate = formatDate.replace('yyyy', date.getFullYear());

    if(formatDate.search('tt')) {
        formatDate = formatDate.replace('h:m tt', getTimeInTwentyFourHour(date.getHours(), date.getMinutes()));
    } else {
        formatDate = formatDate.replace('h:m', ('0'+ date.getHours()).slice(-2) + ':' + ('0'+ date.getMinutes()).slice(-2));
    }    

    return formatDate.toString();
}

export function getOpenHouseFormatedDate(startDate, endDate) {
    if(startDate && endDate){
        var dtStart = moment(startDate).toDate();
        var dtEnd = moment(endDate).toDate();

        return dateFormat(dtStart, "dddd, mmmm dS, h:MM TT") + " - " + dateFormat(dtEnd, "h:MM TT");
    }
    else{
        return "";
    }
}

export function convertToDateTime(props, format) {

    if(props){
        //var date = new Date(props);
        var date = moment(props).toDate();
        format = format.toLowerCase();

        var formatDate = format.replace('mm', ('0' + (date.getMonth() + 1)).slice(-2));
        formatDate = formatDate.replace('dd', ('0' + date.getDate()).slice(-2));
        formatDate = formatDate.replace('yyyy', date.getFullYear());
        formatDate += ' ' + ('0' + date.getHours()).slice(-2);
        formatDate += ':' + ('0' + date.getMinutes()).slice(-2);

        return formatDate.toString();
    }
    else{
        return "";
    }
}

export function getMonthName(digitMonth) {
    var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    return monthNames[digitMonth];
}

export function getMonthShortName(digitMonth) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    return monthNames[digitMonth];
}

export function getWeekDayName(digitDay) {
    var weekNames = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    return weekNames[digitDay];
}

export function getWeekDayShortName(digitDay) {
    var weekNames = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    return weekNames[digitDay];
}

export function getTimeInTwentyFourHour(hour, min) {
    var h = hour % 12 || 12;
    //h = ('0' + h).slice(-2);
    min = ('0' + min).slice(-2);
    var ampm = hour < 12 ? 'AM' : 'PM';
    return h + ':' + min + ' ' + ampm;
}

export function getDefaultAddress() {
    return "New York, NY, United States";
}

export function getFormattedOptions(array) {
    return array.map(function(item, i) {
        return <option key={i} value={item.value}>{item.text}</option>
    });
}

export function getDefaultTLCParameters() {
    return {
        DownPercentage: 20,
        FicoScore: "760-850",
        LoanType: "30YrFixed",
        LifeStyle: "couple-no-kids",
        FilingStatus: "Single",
        NetMonthlyIncome: 6704,
        MonthlyDebt: 0,
        TaxBracket: "24",
        GrossMonthlyIncome: 10000,
        TransportationType: "transit",
        WorkAddressList: [{ TransportationType: "transit", WorkAddress: getDefaultAddress() }]
    };
}

export function getFamilyTypes() {
    return [
        {value: "single", text: "1 Adult"},
        {value: "couple-no-kids", text: "2 Adults"},
        {value: "couple-kids-lt5", text: "2 Adults w/ kids"},
        {value: "couple-kids-gt5", text: "2 Adults w/ teens"},
        {value: "single-parent", text: "1 Adult w/ kids"},
        {value: "empty-nester", text: "Empty nester"}
    ];
}

export function getFamilyTypeName(familyType) {
    var lst = getFamilyTypes();
    lst = lst.filter(t => t.value === familyType);
    return ((lst && lst.length > 0) ? lst[0].text : "");
}

export function getLoanTypes() {
    return [
        {value: "30YrFixed", text: "30 Year"},
        {value: "15YrFixed", text: "15 Year"},
        {value: "7By1YrARM", text: "7/1 ARM"},
        {value: "5By1YrARM", text: "5/1 ARM"}
    ];
}

export function getLoanTypeName(loanType) {
    var lst = getLoanTypes();
    lst = lst.filter(t => t.value === loanType);
    return ((lst && lst.length > 0) ? lst[0].text : "");
}

export function getDownPaymentPercentage() {

    var flst = getDDList('downpaymentpercentage');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "5", text: "5%"},
        {value: "10", text: "10%"},
        {value: "15", text: "15%"},
        {value: "20", text: "20%"},
        {value: "25", text: "25%"},
        {value: "30", text: "30%"},
        {value: "35", text: "35%"},
        {value: "40", text: "40%"},
        {value: "45", text: "45%"},
        {value: "50", text: "50%"},
        {value: "55", text: "55%"},
        {value: "60", text: "60%"},
        {value: "65", text: "65%"},
        {value: "70", text: "70%"}
    ];
}

export function getFicoScores() {
    return [
        {value: "760-850", text: "760-850 - Excellent"},
        {value: "700-759", text: "700-759 - Good"},
        {value: "680-699", text: "680-699 - Average"},
        {value: "660-679", text: "660-679 - Fair"},
        {value: "640-659", text: "640-659 - Not great"},
        {value: "620-639", text: "620-639 - Poor"}
    ];
}

export function getStages() {
    return [
        {value: "client", text: "Client"},
        {value: "evangelist", text: "Evangelist"},        
        {value: "lead", text: "Lead"},
        {value: "marketing-qualified-lead", text: "Marketing qualified lead"},        
        {value: "opportunity", text: "Opportunity"},
        {value: "other", text: "Other"},        
        {value: "sales-qualified-lead", text: "Sales qualified lead"},
        {value: "subscriber", text: "Subscriber"},        
        {value: "archive", text: "Archive"}        
    ];
}

export function getOptionTextFromValue(list, val) {
    var returnValue = "";
    var filtered = list.filter(a => a.value === val);
    if(filtered && filtered.length > 0) return filtered[0].text;
    return returnValue;
}

export function getFilingStatuses() {
    return [
        {value: "Single", text: "Single"},
        {value: "Married", text: "Married"}
    ];
}

export function getTaxBrackets() {
    return [
        {value: "0", text: "0%"},
        {value: "10", text: "10%"},
        {value: "12", text: "12%"},
        {value: "15", text: "15%"},
        {value: "22", text: "22%"},
        {value: "24", text: "24%"},
        {value: "25", text: "25%"},
        {value: "28", text: "28%"},
        {value: "33", text: "33%"},
        {value: "35", text: "35%"},
        {value: "37", text: "37%"},
        {value: "39.6", text: "39.6%"}
    ];
}

export function getDefaultIncomeDetail() {
    return {
        NetPay: 6704,
        GrossPay: 10000,
        PayPeriod: 'Monthly',
        NetPayPeriod: 'Monthly',
        FilingStatus: 'Single',
        TaxDeferralPlan: 5,
        StateLocalTaxes: 5.30,
        NumberOfAllowances: 2,
        NetTaxBracket: '24',
        OtherPreTaxDeduction: 0,
        OtherPostTaxDeduction: 0,
        PostTaxReimbursement: 0
    };
}

export function calculateNetGrossIncome(type, filingStatus, taxBracket, netMonthlyIncome, grossMonthlyIncome) {

    // These are from IRS Website and are updated each year
    // Updated 2018
    var IncomeDetail = getDefaultIncomeDetail();
    var taxWithholding = {
        "single": [
            { "excessover": 0, "withhold": 0, "percent": 0 },
            { "excessover": 3700, "withhold": 0, "percent": 10 },
            { "excessover": 13225, "withhold": 952.50, "percent": 12 },
            { "excessover": 42400, "withhold": 4453.50, "percent":22 },
            { "excessover": 86200, "withhold": 14089.50, "percent": 24 },
            { "excessover": 161200, "withhold": 32089.50, "percent": 32 },
            { "excessover": 203700, "withhold": 45689.50, "percent": 35 },
            { "excessover": 503700, "withhold": 150689.50, "percent": 37 }
        ],
        "married": [
            { "excessover": 0, "withhold": 0, "percent": 0 },
            { "excessover": 11550, "withhold": 0, "percent": 10 },
            { "excessover": 30600, "withhold": 1905, "percent": 12 },
            { "excessover": 88950, "withhold": 8907, "percent": 22 },
            { "excessover": 176550, "withhold": 28179, "percent": 24 },
            { "excessover": 326550, "withhold": 64179, "percent": 32 },
            { "excessover": 411550, "withhold": 91379, "percent": 35 },
            { "excessover": 611550, "withhold": 161379, "percent": 37 }
        ],
        "allowance": [
            { "payperiod": "Weekly", "periodsperyear": 52, "allowance": 79.80 },
            { "payperiod": "Biweekly", "periodsperyear": 26, "allowance": 159.60 },
            { "payperiod": "Semimonthly", "periodsperyear": 24, "allowance": 172.90 },
            { "payperiod": "Monthly", "periodsperyear": 12, "allowance": 345.80 },
            { "payperiod": "Quarterly", "periodsperyear": 4, "allowance": 1037.50 },
            { "payperiod": "Semiannually", "periodsperyear": 2, "allowance": 2075 },
            { "payperiod": "Annually", "periodsperyear": 1, "allowance": 4150 }
        ]
    };
    //var _defaultTaxDeferralPlanValue = 5, _defaultStateLocalTaxesValue = 5.3, _defaultAllowances = 2;
    var _FICASocialSecurity = 6.2, _FICAMedicare = 1.45;

    if(filingStatus !== undefined && filingStatus !== null) {
        IncomeDetail.FilingStatus = filingStatus;
    }
    if(taxBracket !== undefined && taxBracket !== null) {
        IncomeDetail.NetTaxBracket = taxBracket;
    }
    var _netPay = 0, _grossPay = 0;
    var _periodPerYear = 0, _oneAllowances = 0, _periodPerYearForMonthly = 0, _federalTaxableGross = 0;
    var _excessOver = 0, _withHold = 0, _plusX = 0;
    var _multiplier = 0, _federalTax = 0;

    if(type === 'g') {
        if (netMonthlyIncome !== undefined && netMonthlyIncome !== null && netMonthlyIncome !== "") {
            IncomeDetail.NetPay = netMonthlyIncome;

            _netPay = IncomeDetail.NetPay;
            _periodPerYear = 0;
            _oneAllowances = 0;
            _periodPerYearForMonthly = 0;
            _federalTaxableGross = 0;
            _excessOver = 0;
            _withHold = 0;
            _plusX = 0;

            taxWithholding.allowance.map(function (val) {
                if ("Monthly".toLowerCase() === val.payperiod.toLowerCase()) {
                    _periodPerYear = val.periodsperyear;
                    _periodPerYearForMonthly = val.periodsperyear;
                    _oneAllowances = val.allowance;
                    return false;
                }
                return <span />;
            });

            if (IncomeDetail.FilingStatus.toLowerCase() === "single") {
                taxWithholding.single.map(function (val) {
                    if (IncomeDetail.NetTaxBracket === val.percent) {
                        _excessOver = val.excessover;
                        _withHold = val.withhold;
                        _plusX = val.percent;
                        return false;
                    }
                    return <span />;
                });
            }
            else/* if (IncomeDetail.FilingStatus.toLowerCase() == "married")*/ {
                taxWithholding.married.map(function (val) {
                    if (IncomeDetail.NetTaxBracket === val.percent) {
                        _excessOver = val.excessover;
                        _withHold = val.withhold;
                        _plusX = val.percent;
                        return false;
                    }
                    return <span />;
                });
            }
            _multiplier = (_periodPerYear / 12).toFixed(2);
            _netPay = _netPay * _multiplier;
            var _TDP = 0, _NOA = 0, _OA = 0, _PPY = 0, _PLUS = 0, _W = 0, _EO = 0, _SS = 0, _MED = 0, _SLT = 0, _PRE = 0, _POST = 0, _REIMBUS = 0;
            _TDP = getPercentageValue(getValue(IncomeDetail.TaxDeferralPlan));
            _NOA = Number(getValue(IncomeDetail.NumberOfAllowances));
            _OA = _oneAllowances;
            _PPY = _periodPerYearForMonthly;
            _PLUS = getPercentageValue(_plusX);
            _W = _withHold;
            _EO = _excessOver;
            _SS = getPercentageValue(_FICASocialSecurity);
            _MED = getPercentageValue(_FICAMedicare);
            _SLT = getPercentageValue(getValue(IncomeDetail.StateLocalTaxes));
            _PRE = getOnlyNumber(getValue(IncomeDetail.OtherPreTaxDeduction));
            _POST = getOnlyNumber(getValue(IncomeDetail.OtherPostTaxDeduction));
            _REIMBUS = getOnlyNumber(getValue(IncomeDetail.PostTaxReimbursement));
            var _NG = 0, _NA = 0;
            _NG = 1 - _SS - _MED - (((1 - _TDP) * _PPY * _PLUS) / 12) - _TDP - (_SLT * (1 - _TDP));
            _NA = (_W + (((-1 * (_NOA * _OA) * _PPY) - _EO) * _PLUS)) / 12 + Number(_PRE) + Number(_POST) - Number(_REIMBUS) + (_SLT * -1 * _NOA * _OA);
            _grossPay = (_netPay + _NA) / _NG;

            IncomeDetail.GrossPay = Number(_grossPay);
            IncomeDetail.PayPeriod = 'Monthly';
            IncomeDetail.ProfileEstGrossPay = _grossPay * _periodPerYearForMonthly;

            _federalTaxableGross = _grossPay
                - Number(getValue(IncomeDetail.NumberOfAllowances) * _oneAllowances)
                - Number(getPercentageValue(getValue(IncomeDetail.TaxDeferralPlan)) * _grossPay)
                - getOnlyNumber(getValue(IncomeDetail.OtherPreTaxDeduction));
            if (_federalTaxableGross < 0) {
                _federalTaxableGross = 0;
            }
            IncomeDetail.ProfileFederalTaxableGross = Number(_federalTaxableGross);
            _multiplier = (_periodPerYearForMonthly / 12).toFixed(2);
            _grossPay = _grossPay * _multiplier;

            _federalTax = ((_withHold + (getPercentageValue(_plusX) * ((_federalTaxableGross * _periodPerYearForMonthly) - _excessOver))) / _periodPerYearForMonthly) * _multiplier;

            IncomeDetail.ProfileFICASocialSecurity = _grossPay * getPercentageValue(_FICASocialSecurity);
            IncomeDetail.ProfileFICAMedicare = _grossPay * getPercentageValue(_FICAMedicare);
            IncomeDetail.ProfileFederalTaxValue = _federalTax;
            IncomeDetail.ProfileTaxDeferralPlanValue = getPercentageValue(getValue(IncomeDetail.TaxDeferralPlan)) * _grossPay;
            IncomeDetail.ProfilePreTaxDeductionsValue = getOnlyNumber(getValue(IncomeDetail.OtherPreTaxDeduction)) * _multiplier;
            IncomeDetail.ProfileStateLocalTaxesValue = _federalTaxableGross * getPercentageValue(getValue(IncomeDetail.StateLocalTaxes)) * _multiplier;
            IncomeDetail.ProfilePostTaxDeductionsValue = getOnlyNumber(getValue(IncomeDetail.OtherPostTaxDeduction)) * _multiplier;
            IncomeDetail.ProfilePostTaxReimbursementsValue = getOnlyNumber(getValue(IncomeDetail.PostTaxReimbursement)) * _multiplier;
        }
    }
    else if(type === 'n') {
        if (grossMonthlyIncome !== undefined && grossMonthlyIncome !== null && grossMonthlyIncome !== "") {
            IncomeDetail.GrossPay = grossMonthlyIncome;

            _grossPay = IncomeDetail.GrossPay;
            _periodPerYear = 0;
            _oneAllowances = 0;
            _federalTaxableGross = 0;
            _excessOver = 0;
            _withHold = 0;
            _plusX = 0;

            taxWithholding.allowance.map(function (val) {
                if ("monthly".toLowerCase() === val.payperiod.toLowerCase()) {
                    _periodPerYear = val.periodsperyear;
                    _oneAllowances = val.allowance;
                    return false;
                }
                return <span />;
            });
            IncomeDetail.ProfileEstGrossPay = _grossPay * _periodPerYear;

            _federalTaxableGross = _grossPay
                - Number(getValue(IncomeDetail.NumberOfAllowances) * _oneAllowances)
                - Number(getPercentageValue(getValue(IncomeDetail.TaxDeferralPlan)) * _grossPay)
                - getOnlyNumber(getValue(IncomeDetail.OtherPreTaxDeduction));

            if (_federalTaxableGross < 0) {
                _federalTaxableGross = 0;
            }
            IncomeDetail.ProfileFederalTaxableGross = Number(_federalTaxableGross);

            var _annualFTG = 0;
            if (IncomeDetail.FilingStatus.toLowerCase() === "single") {
                _annualFTG = _federalTaxableGross * _periodPerYear;
                taxWithholding.single.map(function (val) {
                    if (_annualFTG > val.excessover) {
                        _excessOver = val.excessover;
                        _withHold = val.withhold;
                        _plusX = val.percent;
                    }
                    else {
                        return false;
                    }
                    return <span />;
                });
            }
            else/* if (IncomeDetail.FilingStatus.toLowerCase() == "married")*/ {
                _annualFTG = _federalTaxableGross * _periodPerYear;
                taxWithholding.married.map(function (val) {
                    if (_annualFTG > val.excessover) {
                        _excessOver = val.excessover;
                        _withHold = val.withhold;
                        _plusX = val.percent;
                    }
                    else {
                        return false;
                    }
                    return <span />;
                });
            }

            IncomeDetail.NetTaxBracket = "" + _plusX;
            //_plusX = getPercentageValue(IncomeDetail.NetTaxBracket);
            _multiplier = (_periodPerYear / 12).toFixed(2);
            _grossPay = _grossPay * _multiplier;

            _federalTax = ((_withHold + (getPercentageValue(_plusX) * ((_federalTaxableGross * _periodPerYear) - _excessOver))) / _periodPerYear) * _multiplier;
            IncomeDetail.ProfileFICASocialSecurity = _grossPay * getPercentageValue(_FICASocialSecurity);
            IncomeDetail.ProfileFICAMedicare = _grossPay * getPercentageValue(_FICAMedicare);
            IncomeDetail.ProfileFederalTaxValue = _federalTax;
            IncomeDetail.ProfileTaxDeferralPlanValue = getPercentageValue(getValue(IncomeDetail.TaxDeferralPlan)) * _grossPay;
            IncomeDetail.ProfilePreTaxDeductionsValue = getOnlyNumber(getValue(IncomeDetail.OtherPreTaxDeduction)) * _multiplier;
            IncomeDetail.ProfileStateLocalTaxesValue = _federalTaxableGross * getPercentageValue(getValue(IncomeDetail.StateLocalTaxes)) * _multiplier;
            IncomeDetail.ProfilePostTaxDeductionsValue = getOnlyNumber(getValue(IncomeDetail.OtherPostTaxDeduction)) * _multiplier;
            IncomeDetail.ProfilePostTaxReimbursementsValue = getOnlyNumber(getValue(IncomeDetail.PostTaxReimbursement)) * _multiplier;

            var _deductions = (getOnlyNumber(getValue(IncomeDetail.ProfileFICASocialSecurity)) + getOnlyNumber(getValue(IncomeDetail.ProfileFICAMedicare))
            + getOnlyNumber(getValue(IncomeDetail.ProfileFederalTaxValue)) + getOnlyNumber(getValue(IncomeDetail.ProfileTaxDeferralPlanValue))
            + getOnlyNumber(getValue(IncomeDetail.ProfilePreTaxDeductionsValue)) + getOnlyNumber(getValue(IncomeDetail.ProfileStateLocalTaxesValue))
            + getOnlyNumber(getValue(IncomeDetail.ProfilePostTaxDeductionsValue)));
            var _reimbursement = getOnlyNumber(getValue(IncomeDetail.ProfilePostTaxReimbursementsValue));
            var _netTakeHome = getOnlyNumber(_grossPay) - getOnlyNumber(_deductions) + getOnlyNumber(_reimbursement);
            IncomeDetail.NetPay = _netTakeHome;
            IncomeDetail.NetPayPeriod = 'Monthly';
        }
    }

    return IncomeDetail;
}

export function getPercentageValue(_val) {
    return _val / 100;
}

export function getValue(_val, _defaultVal) {
    if (!_val) {
        if (!_defaultVal) {
            return 0;
        }
        else {
            return (_defaultVal);
        }
    }
    else {
        return (_val);
    }
}

export function getOnlyNumber(_val) {
    return Number(parseFloat(_val.toString().replace(/[^0-9.]+/g, ""), 10).toFixed(2));
}

export function getCurrencySymbol() {
    return "$";
}

export function getDefaultNewClientObject() {
    return {
        Id: 0,
        AgentId: "",
        Profile: {
            BasicData: {
                MStatus: "couple-no-kids",
                CellPhone: "",
                WorkingWithTCOAgent: false,
                HidePersonalDetailFromAgent: false,
                HomePhone: "",
                FirstName: "",
                LastName: "",
                Email: "",
                FilingStatus: "Single",
                NumberOfAdults: 1,
                OlderChildren: 0,
                YoungChildren: 0,
                ChildDetail: [],
                InterestedInBuying: true,
                InterestedInSelling: false,
                AdultDetail: [],
                CreditScore: "760-850",
                LoanType: "30YrFixed"
            },
            IncomeData: {IncomeDetail: [{FilingStatus: "Single"}]},
            CommuteData: {CommuteDetail: []},
            CurrentResidenceData: {},
            DebtData: {DebtDetail: []},
            AssetData: {
                CashOnHand: 0,
                CheckingAccounts: [],
                SavingAccounts: [],
                CD: [],
                RetirementAccounts: []
            },
            FinancialData: {},
            LifeStyleData: {
                AddGrociers: false,
                AddSuperStores: false,
                AddPharmacies: false,
                AddHousehold: false,
                AddMovieTheaters: false,
                AddPublicTransport: false,
                AddAirport: false,
                AddGolf: false,
                AddSkiing: false,
                AddBeach: false,
                FamilyDetail: []
            },
            EntertainmentData: { Other: {} },
            OtherTLC: {},
            OtherTLCData: {},
            SearchPreferenceses: {
                DownPayment: "",
                DownPaymentPercentage: "20",
                CustomFields: []
            }
        },
        SupportingInformation: {
            ProfileCompletionPercentage: 25
        },
        FirstName: "",
        LastName: "",
        Email: ""
    };
}

export function getCommuteLocationPolygon(lat, lng) {
    var clPolygon = null;
    if(lat && lng) {
        var clLat = parseFloat(lat);
        var clLng = parseFloat(lng);
        var latFactor = 0.41;
        var lngFactor = 0.58;
        clPolygon = (clLng - lngFactor) + " " + (clLat - latFactor);
        clPolygon += "," + (clLng + lngFactor) + " " + (clLat - latFactor);
        clPolygon += "," + (clLng + lngFactor) + " " + (clLat + latFactor);
        clPolygon += "," + (clLng - lngFactor) + " " + (clLat + latFactor);
        clPolygon += "," + (clLng - lngFactor) + " " + (clLat - latFactor);
    }
    return clPolygon;
}

export function convertPolygoneFormat(unFormatted){
    if(unFormatted && unFormatted.length > 0) {

        var formattedPoly = '';

        unFormatted.map((eachPoly) => {
            if(eachPoly) {
                formattedPoly += eachPoly.lng + ' ' + eachPoly.lat + ',';
            }

            return <div/>;
        });

        formattedPoly += unFormatted[0].lng + ' ' + unFormatted[0].lat + ',';
        formattedPoly = formattedPoly.replace(/,\s*$/, "");
        
        return formattedPoly;
    }
}

export function convertPolygonUnformat(formated){
    if(formated && formated.length > 0) {

        var unformatedPoly = [];

        formated.map((eachStringPoly, curIndex) => {
            if(eachStringPoly && eachStringPoly !== '') {
                var eachPoly = eachStringPoly.split(',');
                var internalArray = [];              

                eachPoly.map((singlePoly) => {
                    var LatLng = {};
                    var poly = singlePoly.split(' ');

                    LatLng.lat = parseFloat(poly[1]);
                    LatLng.lng = parseFloat(poly[0]);

                    internalArray.push(LatLng);

                    return <div />;
                });

                unformatedPoly.push(internalArray);
            }
            else {
                if(unformatedPoly.length > curIndex) {
                    unformatedPoly.splice(curIndex, 1);
                }
            }

            return <div/>;
        });
        
        return unformatedPoly;
    }
}

export function convertCommuteLocationPolygon(polygon) {
    var clPolygon = "";
    if(polygon && polygon.Polygon && polygon.Polygon.length > 0) {
        clPolygon += (clPolygon !== "") ? "," : "" + polygon.Polygon.map(poly => poly.toString().replace(',',' '));
    }
    return clPolygon;
}

export function getCommuteLocationDriveTime() {
    return "45";
}

export function getUserCommuteLocation(data) {
    var cLoation = "";
    /* if (data && data.Profile && data.Profile.CommuteData &&
        data.Profile.CommuteData.CommuteDetail && data.Profile.CommuteData.CommuteDetail.length > 0 &&
        data.Profile.CommuteData.CommuteDetail[0].WorkAddress &&
        data.Profile.CommuteData.CommuteDetail[0].WorkAddress !== "" &&
        data.Profile.CommuteData.CommuteDetail[0].WorkAddressLatitude &&
        data.Profile.CommuteData.CommuteDetail[0].WorkAddressLongitude) {
        cLoation = data.Profile.CommuteData.CommuteDetail[0].WorkAddress;
    } */
    if (data && data.Profile && data.Profile.CommuteData &&
        data.Profile.CommuteData.CommuteDetail && data.Profile.CommuteData.CommuteDetail.length > 0 &&
        data.Profile.CommuteData.CommuteDetail[0].WorkAddress &&
        data.Profile.CommuteData.CommuteDetail[0].WorkAddress !== "") {
        cLoation = data.Profile.CommuteData.CommuteDetail[0].WorkAddress;
    }
    return cLoation;
}

export function ImageRewrite(url, isThumbnail)
{

    if(url.indexOf("//") === 0) url = "https:" + url;

    // Directly using the actual image url only for NJ and Buffalo.
    // Temporary fix for caching buffer issue with image proxy
    //if(process.env.REACT_APP_PortalKey === "nj" || process.env.REACT_APP_PortalKey === "buffalo"){
    //    return url;
    //}

    var CDN = process.env.REACT_APP_THUMBNAIL_PROXY
    ? process.env.REACT_APP_THUMBNAIL_PROXY
    : "https://tlcengine-img-cdn.azureedge.net";

    // Our Image server needs an image service that will resize on the fly
    if (isThumbnail)
    {
        
        url = url.replace('Size=5', 'Size=3');
        url = url.replace('Size=4', 'Size=3');

        // Dynamic resize if doesn't exist
        // Matrixlinx URL's do not work
        if (!url.includes('Size=3') && !url.includes('mlsmatrix.com'))
        {
            //return "https://images.truebytlc.com/img2/?key=" + base64.encode(url) + "&width=350";
            // https://tlcengine-img-cdn.azureedge.net -> CDN for Azure VM
            return CDN + "/img/" + base64.encode(url) + "?width=350";
        }
    }
    //console.log(url);

    // If HTTPS is available then don't use images.truebytlc.com
    // there is a queueing issue with the image server with requests that take longer.
    // if (url.toLowerCase().indexOf("https://"))
    // {
    //     return url;
    // }

    //return "https://images.truebytlc.com/img_asp/" + base64.encode(url);
    //return "https://images.truebytlc.com/img/?key=" + base64.encode(url);
    return CDN + "/img/" + base64.encode(url);

}

export function isProfileComplete(profile) {
    var isComplete = true;
    
    /* if(!profile.Profile ||
        (profile.Profile && !profile.Profile.CommuteData) ||
        (profile.Profile && profile.Profile.CommuteData && !profile.Profile.CommuteData.CommuteDetail) ||
        (profile.Profile && profile.Profile.CommuteData && profile.Profile.CommuteData.CommuteDetail &&
            profile.Profile.CommuteData.CommuteDetail.length === 0) ||
        (profile.Profile && profile.Profile.CommuteData && profile.Profile.CommuteData.CommuteDetail &&
            profile.Profile.CommuteData.CommuteDetail.length > 0 && 
            (!profile.Profile.CommuteData.CommuteDetail[0].WorkAddress || 
                profile.Profile.CommuteData.CommuteDetail[0].WorkAddress === ""))){
        isComplete = false;
    } */
    if(!profile.Profile ||
        (profile.Profile && !profile.Profile.BasicData) ||
        (profile.Profile && profile.Profile.BasicData && 
            (!profile.Profile.BasicData.PreferredLocation || profile.Profile.BasicData.PreferredLocation === ""))){
        isComplete = false;
    }
    if(!profile.Profile ||
        (profile.Profile && !profile.Profile.BasicData) ||
        (profile.Profile && profile.Profile.BasicData && 
            (!profile.Profile.BasicData.MStatus || profile.Profile.BasicData.MStatus === ""))){
        isComplete = false;
    }
    if(!profile.Profile ||
        (profile.Profile && !profile.Profile.BasicData) ||
        (profile.Profile && profile.Profile.BasicData && 
            (!profile.Profile.BasicData.CreditScore || profile.Profile.BasicData.CreditScore === ""))){
        isComplete = false;
    }
    if(!profile.Profile ||
        (profile.Profile && !profile.Profile.BasicData) ||
        (profile.Profile && profile.Profile.BasicData && 
            (!profile.Profile.BasicData.LoanType || profile.Profile.BasicData.LoanType === ""))){
        isComplete = false;
    }
    if(!profile.Profile ||
        (profile.Profile && !profile.Profile.IncomeData) ||
        (profile.Profile && profile.Profile.IncomeData && !profile.Profile.IncomeData.IncomeDetail) ||
        (profile.Profile && profile.Profile.IncomeData && profile.Profile.IncomeData.IncomeDetail &&
            profile.Profile.IncomeData.IncomeDetail.length === 0) ||
        (profile.Profile && profile.Profile.IncomeData && profile.Profile.IncomeData.IncomeDetail &&
            profile.Profile.IncomeData.IncomeDetail.length > 0 && 
            (!profile.Profile.IncomeData.IncomeDetail[0].FilingStatus || 
                profile.Profile.IncomeData.IncomeDetail[0].FilingStatus === ""))){
        isComplete = false;
    }
    /* if(!profile.Profile ||
        (profile.Profile && !profile.Profile.IncomeData) ||
        (profile.Profile && profile.Profile.IncomeData && !profile.Profile.IncomeData.IncomeDetail) ||
        (profile.Profile && profile.Profile.IncomeData && profile.Profile.IncomeData.IncomeDetail &&
            profile.Profile.IncomeData.IncomeDetail.length === 0) ||
        (profile.Profile && profile.Profile.IncomeData && profile.Profile.IncomeData.IncomeDetail &&
            profile.Profile.IncomeData.IncomeDetail.length > 0 && 
            (!profile.Profile.IncomeData.IncomeDetail[0].GrossPay || 
                profile.Profile.IncomeData.IncomeDetail[0].GrossPay === ""))){
        isComplete = false;
    } */
    if(!profile.Profile ||
        (profile.Profile && !profile.Profile.IncomeData) ||
        (profile.Profile && profile.Profile.IncomeData && !profile.Profile.IncomeData.IncomeDetail) ||
        (profile.Profile && profile.Profile.IncomeData && profile.Profile.IncomeData.IncomeDetail &&
            profile.Profile.IncomeData.IncomeDetail.length === 0) ||
        (profile.Profile && profile.Profile.IncomeData && profile.Profile.IncomeData.IncomeDetail &&
            profile.Profile.IncomeData.IncomeDetail.length > 0 && 
            (!profile.Profile.IncomeData.IncomeDetail[0].GrossPay || 
                profile.Profile.IncomeData.IncomeDetail[0].GrossPay === ""))){
        isComplete = false;
    }
    /* if(!profile.Profile ||
        (profile.Profile && !profile.Profile.DebtData) ||
        (profile.Profile && profile.Profile.DebtData && !profile.Profile.DebtData.DebtDetail) ||
        (profile.Profile && profile.Profile.DebtData && profile.Profile.DebtData.DebtDetail &&
            profile.Profile.DebtData.DebtDetail.length === 0) ||
        (profile.Profile && profile.Profile.DebtData && profile.Profile.DebtData.DebtDetail &&
            profile.Profile.DebtData.DebtDetail.length > 0 && 
            (!profile.Profile.DebtData.DebtDetail[0].MonthlyPayment || 
                profile.Profile.DebtData.DebtDetail[0].MonthlyPayment === ""))){
        isComplete = false;
    } */

    return isComplete;
}

export function getDashboardTabs() {
    return ["timeline", "clients", "savedsearches", "favorites", "bookmarks", "invitations", "removedlistings"];
}

export function getDashboardTabText(index) {
    var lst = getDashboardTabs();
    if(index < 1 || index > lst.length) return "";
    return getDashboardTabs()[index - 1];
}

export function getDashboardTabIndex(tab) {
    var idx = getDashboardTabs().findIndex((e) => {return e === tab});
    if(idx < 0) return 1;
    return (idx + 1);
}

/* export function getSaveSearchPrePopulatedName(loc, params) {
    var ssName = [];
    if(loc && loc !== ""){
        if(loc.indexOf("#") === 0){
            ssName.push("MLS # " + loc.replace("#",""));
        }
        else{
            ssName.push(loc);
        }
    }
    if(params.tlcmin && params.tlcmax){
        ssName.push(params.tlcmin + "-" + params.tlcmax + " TLC");
    }
    else if(params.tlcmin){
        ssName.push(params.tlcmin + " TLC Min");
    }
    else if(params.tlcmax){
        ssName.push(params.tlcmax + " TLC Max");
    }
    if(params.beds){
        ssName.push(params.beds.replace("g","+") + " Beds");
    }
    if(params.baths){
        ssName.push(params.baths.replace("g","+") + " Baths");
    }
    if(params.garages){
        ssName.push(params.garages.replace("g","+") + " Garages");
    }
    if(params.livingareamin){
        ssName.push(params.livingareamin + " Home Size");
    }
    if(params.acresmin){
        ssName.push(params.acresmin + " Lot Size");
    }
    if(params.yearbuiltmin){
        ssName.push(params.yearbuiltmin + " Built Home");
    }
    if(params.listingpricemin && params.listingpricemax){
        ssName.push(params.listingpricemin + "-" + params.listingpricemax + " Price");
    }
    else if(params.listingpricemin){
        ssName.push(params.listingpricemin + " Price Min");
    }
    else if(params.listingpricemax){
        ssName.push(params.listingpricemax + " Price Max");
    }
    if(params.openHouseOnly){
        ssName.push("Open Houses");
    }
    if(ssName.length > 0) return ssName.join(', ');
    return "";
} */

export function getFixedNumber(num, digits) {
    var numS = num.toString(),
        decPos = numS.indexOf('.'),
        substrLength = decPos === -1 ? numS.length : 1 + decPos + digits,
        trimmedResult = numS.substr(0, substrLength),
        finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

    return parseFloat(finalResult);
}

export function getTextForSearchParameters(params, propTypeFlag, isFormatted, includeDefault, highlightValues) {
    var ssName = [];
    if(params.location && params.location !== ""){
        if(highlightValues){
            ssName.push(<span key="location" className="highlight_amount">{params.location.toString().replace(/ , /g," ").replace(/, /g," ").replace(/ ,/g," ").replace(/,/g," ")}, </span>);
        }
        else{
            ssName.push(params.location.toString().replace(/ , /g," ").replace(/, /g," ").replace(/ ,/g," ").replace(/,/g," "));
        }        
    }
    if(params.locationtext && params.locationtext !== ""){
        if(highlightValues){
            ssName.push(<span key="location" className="highlight_amount">{params.locationtext.toString().replace(/ , /g," ").replace(/, /g," ").replace(/ ,/g," ").replace(/,/g," ")}, </span>);
        }
        else{
            ssName.push(params.locationtext.toString().replace(/ , /g," ").replace(/, /g," ").replace(/ ,/g," ").replace(/,/g," "));
        }        
    }
    if(params.mlsnumber && params.mlsnumber !== ""){
        ssName.push("MLS# " + params.mlsnumber);
    }
    if(params.MLSNumber && Array.isArray(params.MLSNumber) && params.MLSNumber.length > 0){
        ssName.push("MLS# " + params.MLSNumber[0]);
    }
    if(params.MLSNumber && !Array.isArray(params.MLSNumber) && params.MLSNumber !== ""){
        ssName.push("MLS# " + params.MLSNumber);
    }
    if(params.commuteaddress && params.commuteaddress !== ""){
        if(highlightValues){
            ssName.push(<span key="commute" className="highlight_amount">{params.commuteaddress.toString().replace(/ , /g," ").replace(/, /g," ").replace(/ ,/g," ").replace(/,/g," ")}, </span>);
        }
        else{
            ssName.push(params.commuteaddress.toString().replace(/ , /g," ").replace(/, /g," ").replace(/ ,/g," ").replace(/,/g," "));
        }        
    }
    else if(params.commutes && params.commutes.length > 0 && 
        params.commutes[0].commuteaddress && params.commutes[0].commuteaddress !== ""){
        if(highlightValues){
            ssName.push(<span key="commute" className="highlight_amount">{params.commutes[0].commuteaddress.toString().replace(/ , /g," ").replace(/, /g," ").replace(/ ,/g," ").replace(/,/g," ")} </span>);
        }
        else{
            ssName.push(params.commutes[0].commuteaddress.toString().replace(/ , /g," ").replace(/, /g," ").replace(/ ,/g," ").replace(/,/g," "));
        }        
    }
    if(params.tlcmin && params.tlcmax){
        if(highlightValues){
            ssName.push(<span key="tlc"><span className="highlight_amount">{getFormatedCurrency(params.tlcmin) + "-" + getFormatedCurrency(params.tlcmax)}</span> TLC, </span>);
        }
        else if(isFormatted){
            ssName.push(getFormatedCurrency(params.tlcmin) + "-" + getFormatedCurrency(params.tlcmax) + " TLC");    
        }
        else{
            ssName.push(params.tlcmin + "-" + params.tlcmax + " TLC");
        }        
    }
    else if(params.tlcmin){
        if(highlightValues){
            ssName.push(<span key="tlcmin">Min <span className="highlight_amount">{getFormatedCurrency(params.tlcmin)}</span> TLC, </span>);    
        }
        else if(isFormatted){
            ssName.push("Min " + getFormatedCurrency(params.tlcmin) + " TLC");    
        }
        else{
            ssName.push("Min " + params.tlcmin + " TLC");
        }
    }
    else if(params.tlcmax){
        if(highlightValues){
            ssName.push(<span key="tlcmax">Max <span className="highlight_amount">{getFormatedCurrency(params.tlcmax)}</span> TLC, </span>);    
        }
        else if(isFormatted){
            ssName.push("Max " + getFormatedCurrency(params.tlcmax) + " TLC");    
        }
        else{
            ssName.push("Max " + params.tlcmax + " TLC");
        }
    }
    if(params.beds){
        ssName.push(params.beds.replace("g","+").replace("e","") + " Beds");
    }
    if(params.baths){
        ssName.push(params.baths.replace("g","+").replace("e","") + " Baths");
    }
    if(params.garages){
        ssName.push(params.garages.replace("g","+") + " Garages");
    }
    if(params.livingareamin && params.livingareamax){
        ssName.push(params.livingareamin + "-" + params.livingareamax + " Sq.Ft. Home Size");
    }
    else if(params.livingareamin){
        ssName.push("Min " + params.livingareamin + " Sq.Ft. Home Size");
    }
    else if(params.livingareamax){
        ssName.push("Max " + params.livingareamax + " Sq.Ft. Home Size");
    }
    if(params.acresmin && params.acresmax){
        ssName.push(params.acresmin + "-" + params.acresmax + " Acres Lot Size");
    }
    else if(params.acresmin){
        ssName.push("Min " + params.acresmin + " Acres Lot Size");
    }
    else if(params.acresmax){
        ssName.push("Max " + params.acresmax + " Acres Lot Size");
    }
    if(params.yearbuiltmin){
        ssName.push(params.yearbuiltmin + "+ Built Home");
    }
    if(params.listingpricemin && params.listingpricemax){
        if(isFormatted){
            ssName.push(getFormatedCurrency(params.listingpricemin) + "-" + getFormatedCurrency(params.listingpricemax) + " Price");    
        }
        else{
            ssName.push(params.listingpricemin + "-" + params.listingpricemax + " Price");
        }
    }
    else if(params.listingpricemin){
        if(isFormatted){
            ssName.push("Min " + getFormatedCurrency(params.listingpricemin) + " Price");    
        }
        else{
            ssName.push("Min " + params.listingpricemin + " Price");
        }
    }
    else if(params.listingpricemax){
        if(isFormatted){
            ssName.push("Max " + getFormatedCurrency(params.listingpricemax) + " Price");    
        }
        else{
            ssName.push("Max " + params.listingpricemax + " Price");
        }
    }
    if(params.openHouseOnly){
        ssName.push("Open Houses");
    }
    if(params.hoafeemax){
        if(isFormatted){
            ssName.push("Max " + getFormatedCurrency(params.hoafeemax) + " HOA Fee");    
        }
        else{
            ssName.push("Max " + params.hoafeemax + " HOA Fee");
        }
    }
    if(params.haswaterfront !== undefined && params.haswaterfront !== null){
        ssName.push(params.haswaterfront.toString().toLowerCase() === "true" ? "Waterfront" : "No Waterfront");
    }
    if(params.haswaterview !== undefined && params.haswaterview !== null){
        ssName.push(params.haswaterview.toString().toLowerCase() === "true" ? "WaterView" : "No WaterView");
    }
    if(params.hasbasement !== undefined && params.hasbasement !== null){
        ssName.push(params.hasbasement.toString().toLowerCase() === "true" ? "Basement" : "No Basement");
    }
    if(params.haspool !== undefined && params.haspool !== null){
        ssName.push(params.haspool.toString().toLowerCase() === "true" ? "Pool" : "No Pool");
    }
    if(params.hasfireplace !== undefined && params.hasfireplace !== null){
        ssName.push(params.hasfireplace.toString().toLowerCase() === "true" ? "Fireplace" : "No Fireplace");
    }
    if(params.isshortsale !== undefined && params.isshortsale !== null){
        ssName.push(params.isshortsale.toString().toLowerCase() === "true" ? "Short Sale" : "Not Short Sale");
    }
    if(params.status !== undefined && params.status !== null && params.status.toString().toLowerCase() !== "active"){
        ssName.push(params.status + " Status");
    }
    if(params.waterfeatures){
        ssName.push(params.waterfeatures + " Water Front Type");
    }
    if(params.isbankowned !== undefined && params.isbankowned !== null){
        ssName.push(params.isbankowned.toString().toLowerCase() === "true" ? "Banked Owned" : "Not Banked Owned");
    }
    if(params.haswateraccess !== undefined && params.haswateraccess !== null){
        ssName.push(params.haswateraccess.toString().toLowerCase() === "true" ? "Water Access" : "No Water Access");
    }
    if(params.hasgolfcourse !== undefined && params.hasgolfcourse !== null){
        ssName.push(params.hasgolfcourse.toString().toLowerCase() === "true" ? "Golf Course" : "No Golf Course");
    }
    if(params.hasgreenfeatures !== undefined && params.hasgreenfeatures !== null){
        ssName.push(params.hasgreenfeatures.toString().toLowerCase() === "true" ? "Green Features" : "No Green Features");
    }
    if(params.dpr){
        ssName.push("Downpayment Assistance");
    }
    if(params.hasgym){
        ssName.push("Gym");
    }
    if(params.hasairconditioning){
        ssName.push("Air Conditioning");
    }
    if(params.hasdoorman){
        ssName.push("Doorman");
    }
    if(params.hasdishwasher){
        ssName.push("Dishwasher");
    }
    if(params.hasbalcony){
        ssName.push("Balcony");
    }
    if(params.hasroofaccess){
        ssName.push("Roof Access");
    }
    if(params.haskeylessentry){
        ssName.push("Keyless Entry");
    }
    if(params.keywords){
        ssName.push(params.keywords.split(',').join(', '));
    }
    if(params.postalcode){
        ssName.push((Array.isArray(params.postalcode) ? params.postalcode.join(', ') : params.postalcode.split(',').join(', ')) + " Zip Codes");
    }
    if(params.accessibility){
        ssName.push((Array.isArray(params.accessibility) ? params.accessibility.join(', ') : params.accessibility.split(',').join(', ')) + " Accessibility");
    }
    if(params.condotype){
        ssName.push(params.condotype.replace("Condominium","Condo Only").replace("Townhouse","Townhomes Only").replace("Patio Home Detached","Patio Homes Only"));
    }
    if(params.regionalboardcode && params.regionalboardcode.indexOf("rochester") >= 0){
        ssName.push("Include Rochester");
    }
    if(params.regionalboardcode && params.regionalboardcode.indexOf("syracuse") >= 0){
        ssName.push("Include Syracuse");
    }
    if(params.isnewconstruction){
        ssName.push("New Construction");
    }
    if(params.isforeclosure){
        ssName.push("Foreclosure");
    }
    if(params.storiesmin && params.storiesmin === 2){
        ssName.push("Greater than Single Story");
    }
    if(params.storiesexact && params.storiesexact === 1){
        ssName.push("Single Story");
    }
    if(params.lotfeature){
        ssName.push((Array.isArray(params.lotfeature) ? params.lotfeature.join(', ') : params.lotfeature.split(',').join(', ')) + " Lot Feature");
    }
    if(params.neighborhood){
        ssName.push(getNeighborhoodItemText(params.neighborhood) + " Neighborhoods");
    }
    if(params.propertystyle){
        ssName.push((Array.isArray(params.propertystyle) ? params.propertystyle.join(', ') : params.propertystyle.split(',').join(', ')) + " Style");
    }
    if(params.schooldistrictname){
        ssName.push(params.schooldistrictname.split(',').join(', ') + " School District");
    }
    if(params.roompref){
        ssName.push((Array.isArray(params.roompref) ? params.roompref.join(', ') : params.roompref.split(',').join(', ')) + " Rooms");
    }
    if(params.streetaddress && params.streetaddress !== ""){
        ssName.push(params.streetaddress + " Street Address");
    }
    if(propTypeFlag && params.propertytype){
        ssName.push(getPropertyTypeName(params.propertytype.toLowerCase()));
        /* if(params.propertytype.toLowerCase() === "sf"){
            ssName.push("Single Family Home");
        }
        if(params.propertytype.toLowerCase() === "mf"){
            ssName.push("Multi Family Home");
        }
        if(params.propertytype.toLowerCase() === "rnt"){
            ssName.push("Rentals");
        } */
    }
    if(includeDefault){
        if(highlightValues && params.lifestyle){
            ssName.push(<span key="lifestyle" className="highlight_amount">{getOptionTextFromValue(getFamilyTypes(), params.lifestyle)}, </span>);
        }
        else if(params.lifestyle){
            ssName.push(getOptionTextFromValue(getFamilyTypes(), params.lifestyle));
        }
        if(highlightValues && params.ficoscore){
            ssName.push(<span key="fico"><span className="highlight_amount">{params.ficoscore}</span> Fico Score, </span>);
        }
        else if(params.ficoscore){
            ssName.push(params.ficoscore + " Fico Score");
        }
        if(highlightValues && params.loantype){
            ssName.push(<span key="loantype"><span className="highlight_amount">{getOptionTextFromValue(getLoanTypes(), params.loantype)}</span> Loan Type</span>);
        }
        else if(params.loantype){
            ssName.push(getOptionTextFromValue(getLoanTypes(), params.loantype) + " Loan Type");
        }
    }
    if(ssName.length > 0){
        if(highlightValues){
            return ssName;
        }
        else{
            return ssName.join(', ');
        }
    }
    return "";
}

export function getParametersForMatchingProperties(profile) {
    var params = getDefaultSearchParams();
    if(profile && profile.Profile && profile.Profile.BasicData){
        if(profile.Profile.BasicData.TLCMin && profile.Profile.BasicData.TLCMin !== ""){
            params.tlcmin = profile.Profile.BasicData.TLCMin;
        }
        if(profile.Profile.BasicData.TLCMax && profile.Profile.BasicData.TLCMax !== ""){
            params.tlcmax = profile.Profile.BasicData.TLCMax;
        }
        /* if(profile.Profile.CommuteData && profile.Profile.CommuteData.CommuteDetail && 
            profile.Profile.CommuteData.CommuteDetail.length > 0 &&
            profile.Profile.CommuteData.CommuteDetail[0].WorkAddress &&
            profile.Profile.CommuteData.CommuteDetail[0].WorkAddress !== ""){
                params.commutes = [{
                commuteaddress: profile.Profile.CommuteData.CommuteDetail[0].WorkAddress,
                commutemode: "car",
                commutetimemins: "45"
            }];
        } */
        if(profile.Profile.BasicData.PreferredLocation && profile.Profile.BasicData.PreferredLocation !== ""){
            params.location = profile.Profile.BasicData.PreferredLocation;
        }
        if(profile.Profile.BasicData.MStatus && profile.Profile.BasicData.MStatus !== ""){
            params.lifestyle = profile.Profile.BasicData.MStatus;
        }
        if(profile.Profile.BasicData.CreditScore && profile.Profile.BasicData.CreditScore !== ""){
            params.ficoscore = profile.Profile.BasicData.CreditScore;
        }
        if(profile.Profile.BasicData.LoanType && profile.Profile.BasicData.LoanType !== ""){
            params.loantype = profile.Profile.BasicData.LoanType;
        }        
    }
    return JSON.parse(JSON.stringify(params));
}

export function getDefaultTLCToggleSwitches() {
    return {
        Transportation: true,
        GasCost: true,
        Parking: true,
        Tolls: true,
        TransitCost: true,
        CarMaintenance: true,
        CarInsurance: true,
        Utilities: true,
        HeatingCooling: true,
        WaterSewer: true,
        Garbage: true,
        LawnSnowCare: true,
        OtherUtilities: true,
        Entertainment: true,
        Phone: true,
        Internet: true,
        TV: true,
        Cell: true,
        OtherCharge: true
    };
}

export function getBeds() {
    
    var flst = getDDList('beds');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "1e", text: "1 Bed"},
        {value: "1g", text: "1+ Beds"},
        {value: "2e", text: "2 Beds"},
        {value: "2g", text: "2+ Beds"},
        {value: "3e", text: "3 Beds"},
        {value: "3g", text: "3+ Beds"},
        {value: "4e", text: "4 Beds"},
        {value: "4g", text: "4+ Beds"},
        {value: "5e", text: "5 Beds"},
        {value: "5g", text: "5+ Beds"},
        {value: "6e", text: "6 Beds"},
        {value: "6g", text: "6+ Beds"},
        {value: "7e", text: "7 Beds"},
        {value: "7g", text: "7+ Beds"}
    ];
}

export function getBaths() {

    var flst = getDDList('baths');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "1e", text: "1 Bath"},
        {value: "1g", text: "1+ Bath"},
        //{value: "1.5e", text: "1.5 Bath"},
        //{value: "1.5g", text: "1.5+ Bath"},
        {value: "2e", text: "2 Bath"},
        {value: "2g", text: "2+ Bath"},
        //{value: "2.5e", text: "2.5 Bath"},
        //{value: "2.5g", text: "2.5+ Bath"},
        {value: "3e", text: "3 Bath"},
        {value: "3g", text: "3+ Bath"},
        //{value: "3.5e", text: "3.5 Bath"},
        //{value: "3.5g", text: "3.5+ Bath"},
        {value: "4e", text: "4 Bath"},
        {value: "4g", text: "4+ Bath"},
        {value: "5e", text: "5 Bath"},
        {value: "5g", text: "5+ Bath"},
        {value: "6e", text: "6 Bath"},
        {value: "6g", text: "6+ Bath"},
        {value: "7e", text: "7 Bath"},
        {value: "7g", text: "7+ Bath"}
    ];
}

export function getGarages() {    
    var flst = getDDList('garages');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "1g", text: "1+ Car"},
        {value: "2g", text: "2+ Car"},
        {value: "3g", text: "3+ Car"},
        {value: "4g", text: "4+ Car"},
        {value: "5g", text: "5+ Car"},
        {value: "6g", text: "6+ Car"},
        {value: "7g", text: "7+ Car"}
    ];
}

export function getHomeSizes() {    
    var flst = getDDList('livingareamin');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "500", text: "500+ Sqr Ft"},
        {value: "1000", text: "1000+ Sqr Ft"},
        {value: "1500", text: "1500+ Sqr Ft"},
        {value: "2000", text: "2000+ Sqr Ft"},
        {value: "2500", text: "2500+ Sqr Ft"},
        {value: "3000", text: "3000+ Sqr Ft"},
        {value: "4000", text: "4000+ Sqr Ft"}
    ];
}

export function getLotSizes() {
    var flst = getDDList('acresmin');
    if(flst && flst.length > 0) return flst;

    return [
        {value: ".25", text: "1/4+ Acre"},
        {value: ".5", text: "1/2+ Acre"},
        {value: ".75", text: "3/4+ Acre"},
        {value: "1", text: "1+ Acre"},
        {value: "1.5", text: "1.5+ Acres"},
        {value: "2", text: "2+ Acres"},
        {value: "4", text: "4+ Acres"}
    ];
}

export function getBuiltYears() {    
    var flst = getDDList('yearbuiltmin');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "1900", text: "1900+"},
        {value: "1930", text: "1930+"},
        {value: "1950", text: "1950+"},
        {value: "1970", text: "1970+"},
        {value: "1980", text: "1980+"},
        {value: "1990", text: "1990+"},
        {value: "2000", text: "2000+"},
        {value: "2005", text: "2005+"},
        {value: "2010", text: "2010+"},
        {value: "2015", text: "2015+"}
    ];
}

export function getMaxHOAFees() {    
    var flst = getDDList('hoafeemax');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "100", text: "100"},
        {value: "200", text: "200"},
        {value: "300", text: "300"},
        {value: "400", text: "400"},
        {value: "500", text: "500"}
    ];
}

export function getMinTLCCalculationCriteria() {
    //return 0.50;
    return 0.45;
}

export function getMaxTLCCalculationCriteria() {
    //return 0.80;
    return 0.65;
}

export function getMinPriceRange() {
    return [0, 1000, 5000, 10000, 50000, 75000, 100000, 125000, 150000, 175000, 200000, 250000, 300000, 400000, 500000, 1000000];
}

export function getMaxPriceRange() {
    return [0, 5000, 10000, 50000, 75000, 100000, 125000, 150000, 175000, 200000, 250000, 300000, 400000, 500000, 1000000, 2000000];
}

export function getMinTLCRange() {
    return [0, 500, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 7500, 10000, 15000, 20000];
}

export function getMaxTLCRange() {
    return [0, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 7500, 10000, 15000, 20000, 25000];
}

export function getDefaultPageTourParams() {
    return {
        allowClicksThruHole: true,
        style: {
            mainColor: '#f7941e',
            beacon: {
              inner: '#f7941e',
              outer: '#f7941e',
            }
        }
    };
}

export function getPageTourParams(page, user, selectedClient) {

    var sites = [];
    var filteredPage = page;
    if(filteredPage.indexOf('/search') === 0){
        filteredPage = '/search'
    }
    if(filteredPage.indexOf('/propertydetail') === 0){
        filteredPage = '/propertydetail'
    }

    var basicNavItems = [];
    var tourText='';

    var menuText;
    if(user === 'anonymous'){
        menuText = '<strong>Find Homes</strong> - This is where you create personalized Saved Searches for homes by adjusting your criteria filters. </br></br>'
        menuText += '<strong>Find a Realtor</strong> - This is where you will be able to search Realtors </br></br>'
        menuText += '<strong>Neighborhoods</strong> - Check out Neighborhoods and details about them'
    }
    else if(user === 'client'){
        menuText = '<strong>Dashboard</strong> - View your Timeline including property comments with your agent. View your Saved Searches, Favorites and properties suggested by your Agent.</br></br>';
        menuText += '<strong>Find Homes</strong> - This is where you create personalized Saved Searches for homes by  adjusting your criteria filters. </br></br>'
        menuText += '<strong>Find a Realtor</strong> - This is where you will be able to search Realtors </br></br>'
        menuText += '<strong>Neighborhoods</strong> - Check out Neighborhoods and details about them'

    } 
    else {
        menuText = '<strong>Dashboard</strong> - View your Timeline including property comments with your client. View Saved Searches and properties you suggested to your client.</br></br>';
        menuText += '<strong>Find Homes</strong> - This is where you create personalized searches for homes by adjusting your criteria filters. </br></br>'
        menuText += '<strong>Find a Realtor</strong> - This is where you will be able to search Realtors </br></br>'
        menuText += '<strong>Neighborhoods</strong> - Check out Neighborhoods and details about them'
    }

    menuText = "Explore and navigate through out the site using various Menu links";
    
    if(window.innerWidth < 768){
        basicNavItems.push(
            Object.assign({},{
                title: 'Menu Items',
                text: menuText,
                selector: '.navbar-toggle',
                position: 'bottom',
            },getDefaultPageTourParams())
        );    
    }
    else{
        basicNavItems.push(
            Object.assign({},{
                title: 'Menu Items',
                text: menuText,
                selector: '.new_leftemenu',
                position: 'bottom',
            },getDefaultPageTourParams())
        );
    }

    if(user === 'anonymous'){
        basicNavItems.push(
            Object.assign({},{
                title: 'Login',
                text: 'Login, if already registered, and start using the advanced features of the site.',
                selector: '.user_button_login',
                position: 'left',
            },getDefaultPageTourParams())
        );
        basicNavItems.push(
            Object.assign({},{
                title: 'Register',
                text: 'Register to access advanced features of the site.',
                selector: '.user_button_register',
                position: 'left',
            },getDefaultPageTourParams())
        );
    }    
    else if(user === 'client'){
        basicNavItems.push(
            Object.assign({},{
                title: 'View/Update your Profile',
                text: 'Update your basic information, password, TLC financial factors, family details, commute information, and logout.',
                selector: '.menu-item-profile',
                position: 'left',
            },getDefaultPageTourParams())
        );
    } 
    else {
        basicNavItems.push(
            Object.assign({},{
                title: 'View your Profile',
                text: 'View your saved searches, your listings, change your password and logout.',
                selector: '.menu-item-profile',
                position: 'left',
            },getDefaultPageTourParams())
        );
    }

    if(user === 'agent'){
        basicNavItems.push(
            Object.assign({},{
                title: 'Recent Activity Alerts',
                text: 'View interactions between you and your Clients. Click on each activity to view it on your Timeline or click the Property Address to go directly to the property page',
                selector: '.menu-item-quickbar',
                position: 'left',
            },getDefaultPageTourParams())
        );        
    }
    else if(user === 'client'){
        basicNavItems.push(
            Object.assign({},{
                title: 'Recent Activity Alerts',
                text: 'View interactions between you and your Agent. Click on each activity to view it on your Timeline or click the Property Address to go directly to the property page.',
                selector: '.menu-item-quickbar',
                position: 'left',
            },getDefaultPageTourParams())
        );
    }

    if(user === 'agent'){    
        basicNavItems.push(
            Object.assign({},{
                title: 'Select a Client',
                text: 'Select a client to work with to Bookmark properties and Save Searches.',
                selector: '.menu-item-selectclient',
                position: 'left',
            },getDefaultPageTourParams())
        );           
    } 
    else if(user === 'client'){
        basicNavItems.push(
            Object.assign({},{
                title: 'My agent ',
                text: 'View details about your agent, saved searches and properties suggested by your agent.',
                selector: '.menu-item-activeagent, .menu-item-selectclient, .select_client_menu',
                position: 'left',
            },getDefaultPageTourParams())
        );
    }    
   

    // Add navbar to the Tour
    sites = sites.concat(basicNavItems)

    switch (filteredPage.toLowerCase()){
        case "":
        case "/": {

            sites.push(
                Object.assign({},{
                    title: 'Quick Search',
                    //text: 'Start a new search using a specific location, beds, baths, and monthly TLC costs.',
                    text: 'Start a new search using various search parameters.',
                    selector: '.search-item-searchbar',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            if(user === 'anonymous'){

                /* sites.push(
                    Object.assign({},{
                        title: 'Featured properties',
                        text: 'View top Featured properties.',
                        selector: '.tour-featured-properties',
                        position: 'top',
                    },getDefaultPageTourParams())
                ); */

                /*sites.push(
                    Object.assign({},{
                        title: 'Find the Perfect fit',
                        text: 'Find the perfect home based on personalized preferences.',
                        selector: '.home-item-find-clients',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );*/
                
                /* sites.push(
                    Object.assign({},{
                        title: 'Featured open houses',
                        text: 'Top Featured open houses coming up',
                        selector: '.tour-featured-openhouse',
                        position: 'top',
                    },getDefaultPageTourParams())
                ); */

                /* sites.push(
                    Object.assign({},{
                        title: 'Last MLS Update',
                        text: 'Our comprehensive listings are constantly refreshed with the latest updates so you can move quickly and confidently.',
                        selector: '.latest_update_mls',
                        position: 'top',
                    },getDefaultPageTourParams())
                ); */

                /* sites.push(
                    Object.assign({},{
                        title: 'The Very Latest Listings',
                        text: 'The most recently updated/added properties.',
                        selector: '.latest-properties-title',
                        position: 'top',
                    },getDefaultPageTourParams())
                ); */

                /* sites.push(
                    Object.assign({},{
                        title: 'What Can I Truly Afford?',
                        text: 'Understand the true cost of owning your new home.',
                        selector: '.home_small_tlc',
                        position: 'top',
                    },getDefaultPageTourParams())
                ); */

                /*sites.push(
                    Object.assign({},{
                        title: 'Open Houses',
                        text: 'Upcoming open houses.',
                        selector: '.openhouse-properties-title',
                        position: 'top',
                    },getDefaultPageTourParams())
                );*/
            }
            if(user === 'agent'){

                /* sites.push(
                    Object.assign({},{
                        title: 'Featured properties',
                        text: 'View top Featured properties.',
                        selector: '.tour-featured-properties',
                        position: 'top',
                    },getDefaultPageTourParams())
                ); */

                /*sites.push(
                    Object.assign({},{
                        title: 'Add a client',
                        text: 'From this you will be able to add new client to work with.',
                        selector: '.home-item-addclient',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );

                sites.push(
                    Object.assign({},{
                        title: 'How it works',
                        text: 'Get knowledge about what different components are included in TLC and how it works.',
                        selector: '.home-item-how-works',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );

                sites.push(
                    Object.assign({},{
                        title: 'Shared listings',
                        text: 'View the list of clients you are working with.',
                        selector: '.home-item-Shared-listings',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );

                sites.push(
                    Object.assign({},{
                        title: 'Find the Perfect fit',
                        text: 'Find the perfect home for your clients based on their preferences.',
                        selector: '.home-item-find-clients',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );*/
                
                /* sites.push(
                    Object.assign({},{
                        title: 'Featured open houses',
                        text: 'Top Featured open houses coming up',
                        selector: '.tour-featured-openhouse',
                        position: 'top',
                    },getDefaultPageTourParams())
                ); */

                /* sites.push(
                    Object.assign({},{
                        title: 'Last MLS Update',
                        text: 'Our comprehensive listings are constantly refreshed with the latest updates so you can move quickly and confidently.',
                        selector: '.latest_update_mls',
                        position: 'top',
                    },getDefaultPageTourParams())
                ); */

                /* sites.push(
                    Object.assign({},{
                        title: 'The Very Latest Listings',
                        text: 'The most recently updated/added properties.',
                        selector: '.latest-properties-title',
                        position: 'top',
                    },getDefaultPageTourParams())
                ); */

                /* sites.push(
                    Object.assign({},{
                        title: 'What Can I Truly Afford?',
                        text: 'Understand the true cost of owning your new home.',
                        selector: '.home_small_tlc',
                        position: 'top',
                    },getDefaultPageTourParams())
                ); */

                /*sites.push(
                    Object.assign({},{
                        title: 'Open Houses',
                        text: 'Upcoming open houses.',
                        selector: '.openhouse-properties-title',
                        position: 'top',
                    },getDefaultPageTourParams())
                );*/
            }
            if(user === 'client'){

                /*sites.push(
                    Object.assign({},{
                        title: 'Go to profile',
                        text: 'This is where you will be able to see/update all your profile information.',
                        selector: '.home-item-profile-button',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );

                sites.push(
                    Object.assign({},{
                        title: 'How it works',
                        text: 'Get knowledge about what different components are included in TLC and how it works.',
                        selector: '.home-item-how-works',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );

                sites.push(
                    Object.assign({},{
                        title: 'Shared listings',
                        text: 'View all the properties suggested by your agent.',
                        selector: '.home-item-Shared-listings',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );

                sites.push(
                    Object.assign({},{
                        title: 'Find the Perfect fit',
                        text: 'Find your perfect home based on your preferences.',
                        selector: '.home-item-find-clients',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );*/

                sites.push(
                    Object.assign({},{
                        title: 'Matching Properties',
                        text: 'These are properties that match your personalized profile selections.',
                        selector: '.matching-properties-title',
                        position: 'top',
                    },getDefaultPageTourParams())
                );
                
                sites.push(
                    Object.assign({},{
                        title: 'Your Personalized Factors',
                        text: 'The Matching Properties are based on your personalized factors that you provided.',
                        selector: '.change-factors-link',
                        position: 'top',
                    },getDefaultPageTourParams())
                );
                sites.push(
                    Object.assign({},{
                        title: 'Show More Matches',
                        text: 'View more properties like these on the Search Results page where you can add additional filters.',
                        selector: '.more-properties-results-top',
                        position: 'top',
                    },getDefaultPageTourParams())
                );                
            }

            /* sites.push(
                Object.assign({},{
                    title: 'Contact us',
                    text: "Get in touch with us in case you have any questions or issues with the site.",
                    selector: '.support-item-email',
                    position: 'top-right',
                },getDefaultPageTourParams())
            ); */

            /* sites.push(
                Object.assign({},{
                    title: 'Terms of Use',
                    text: "View the Terms of Use for this website",
                    selector: '.tour-terms-use',
                    position: 'top-right',
                },getDefaultPageTourParams())
            ); */
            
            break;
        }

        case "/dashboard/timeline":
        case "/dashboard": {

            tourText = '<strong>Timeline</strong> - See all recent activity and add new comments. <br/><br/>';

            if (user === 'agent') {
                tourText += '<strong>Clients</strong> - Manage Clients who you are working with. <br/><br/>';
                tourText += '<strong>Pending Invitations</strong> - Invitations that need to be accepted by your Client so you can work with them. <br/><br/>';
                tourText += '<strong>My Saved Searches</strong> - Your personal Saved Searches and Alerts.  Here you can assign these to your Clients.';
            }
            else if (user === 'client') {
                tourText += '<strong>My Saved Searches</strong> - View and manage your Saved Searches and Alerts. <br/><br/>';
                tourText += '<strong>My Favorites</strong> - Favorited properties. <br/><br/>';
                tourText += '<strong>Suggested by Agent</strong> - Properties suggested by your Agent. <br/><br/>';
                tourText += '<strong>Removed listings</strong> - View and manage the listings that you have marked to be hidden. <br/><br/>';
            }

            sites.push(
                Object.assign({},{
                    title: 'Dashboard Menu',
                    text: tourText,
                    selector: '#dashboardTabs .nav-tabs',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            if (user === 'agent') {
                sites.push(
                    Object.assign({},{
                        title: 'Property Interaction Card',
                        text: 'An interaction with a single client on a specific property.  The card will show Bookmarks, Favorites, and Comments.',
                        selector: '.timeline-row',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );
            }
            else if (user === 'client') {
                sites.push(
                    Object.assign({},{
                        title: 'Property Interaction Card',
                        text: 'An interaction with your Agent on specific property.  The card will show Favorites, Agent Bookmarks, and Comments.',
                        selector: '.timeline-row',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );
            }

            break;
        }

        case "/dashboard/clients": {

            if (user === 'agent') {
                tourText = '<strong>Timeline</strong> - See all recent activity and add new comments. <br/><br/>';
                tourText += '<strong>Clients</strong> - Manage Clients who you are working with. <br/><br/>';
                tourText += '<strong>Pending Invitations</strong> - Invitations that need to be accepted by your Client so you can work with them. <br/><br/>';
                tourText += '<strong>My Saved Searches</strong> - Your personal Saved Searches and Alerts.  Here you can assign these to your Clients.';
            }
 
            sites.push(
                Object.assign({},{
                    title: 'Dashboard Menu',
                    text: tourText,
                    selector: '#dashboardTabs .nav-tabs',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Add a new client',
                    text: 'From this you will be able to add new client to work with',
                    selector: '.client_saved_searches .tru-white-link',
                    position: 'bottom',
                },getDefaultPageTourParams())
            ); 

            sites.push(
                Object.assign({},{
                    title: 'Filter By Stage',
                    text: 'Filter the list of clients based on the following stages.',
                    selector: '.tour-clients-table .list-item-stage',
                    position: 'bottom',
                },getDefaultPageTourParams())
            ); 

            sites.push(
                Object.assign({},{
                    title: 'Sorting',
                    text: 'You can sort the list by clicking on specific column.',
                    selector: '.tour-clients-table .react-bs-container-header',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Client list',
                    text: 'List of Clients who you are working with.',
                    selector: '.active .tour-clients-table .react-bs-container-body',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            break;
        }

        case "/dashboard/invitations": {

            if (user === 'agent') {
                tourText = '<strong>Timeline</strong> - See all recent activity and add new comments. <br/><br/>';
                tourText += '<strong>Clients</strong> - Manage Clients who you are working with. <br/><br/>';
                tourText += '<strong>Pending Invitations</strong> - Invitations that need to be accepted by your Client so you can work with them. <br/><br/>';
                tourText += '<strong>My Saved Searches</strong> - Your personal Saved Searches and Alerts.  Here you can assign these to your Clients.';
            }
 
            sites.push(
                Object.assign({},{
                    title: 'Dashboard Menu',
                    text: tourText,
                    selector: '#dashboardTabs .nav-tabs',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Sorting',
                    text: 'You can sort the list by clicking on specific column.',
                    selector: '.tour-invitations-table .react-bs-container-header',
                    position: 'bottom',
                },getDefaultPageTourParams())
            ); 

            sites.push(
                Object.assign({},{
                    title: 'List of Invitations',
                    text: 'These are clients that are currently working with someone else whom you have invited to work with you.',
                    selector: '.tour-invitations-table .react-bs-container-body',
                    position: 'top',
                },getDefaultPageTourParams())
            );

            
            /*sites.push(
                Object.assign({},{
                    title: 'Delete',
                    text: 'Delete a specific invitation.',
                    selector: '.tour-invitations-table .fa-trash',
                    position: 'left',
                },getDefaultPageTourParams())
            );*/
          
            break;
        }

        case "/dashboard/savedsearches": {

            if (user === 'agent') {
                tourText = '<strong>Timeline</strong> - See all recent activity and add new comments. <br/><br/>';
                tourText += '<strong>Clients</strong> - Manage Clients who you are working with. <br/><br/>';
                tourText += '<strong>Pending Invitations</strong> - Invitations that need to be accepted by your Client so you can work with them. <br/><br/>';
                tourText += '<strong>My Saved Searches</strong> - Your personal Saved Searches and Alerts.  Here you can assign these to your Clients.';
            }
            else if (user === 'client') {
                tourText = '<strong>Timeline</strong> - See all recent activity and add new comments. <br/><br/>';
                tourText += '<strong>My Saved Searches</strong> - View and manage your Saved Searches and Alerts. <br/><br/>';
                tourText += '<strong>My Favorites</strong> - Favorited properties. <br/><br/>';
                tourText += '<strong>Suggested by Agent</strong> - Properties suggested by your Agent. <br/><br/>';
                tourText += '<strong>Removed listings</strong> - View and manage the listings that you have marked to be hidden. <br/><br/>';
            }
 
            sites.push(
                Object.assign({},{
                    title: 'Dashboard Menu',
                    text: tourText,
                    selector: '#dashboardTabs .nav-tabs',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            if(user === 'agent'){
                sites.push(
                    Object.assign({},{
                        title: 'Assign to client',
                        text: 'Assign a selected Saved Search to a Client.',
                        selector: '.assign-item-client',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                ); 
            }

            sites.push(
                Object.assign({},{
                    title: 'Sorting',
                    text: 'You can sort the list by clicking on specific columns.',
                    selector: '.tour-savesearch-table .react-bs-container-header',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Saved Searches',
                    text: 'View and Manage your Saved Searches and Alerts',
                    selector: '.tour-savesearch-table .react-bs-container-body',
                    position: 'top',
                },getDefaultPageTourParams())
            );

            /*sites.push(
                Object.assign({},{
                    title: 'Daily Alerts',
                    text: 'Turn daily email alerts on/off for a saved search.',
                    selector: '.tour-savesearch-table .tour-bell-icon',
                    position: 'left',
                },getDefaultPageTourParams())
            );
            
            sites.push(
                Object.assign({},{
                    title: 'Delete',
                    text: 'Delete a saved search.',
                    selector: '.tour-savesearch-table .fa-trash',
                    position: 'left',
                },getDefaultPageTourParams())
            );*/
           
            break;
        }
        case "/dashboard/favorites": {
            
            if (user === 'client') {
                tourText = '<strong>Timeline</strong> - See all recent activity and add new comments. <br/><br/>';
                tourText += '<strong>My Saved Searches</strong> - View and manage your Saved Searches and Alerts. <br/><br/>';
                tourText += '<strong>My Favorites</strong> - View and Manage properties you marked as Favorite. <br/><br/>';
                tourText += '<strong>Suggested by Agent</strong> - Properties suggested by your Agent. <br/><br/>';
                tourText += '<strong>Removed listings</strong> - View and manage the listings that you have marked to be hidden. <br/><br/>';
            }
 
            sites.push(
                Object.assign({},{
                    title: 'Dashboard Menu',
                    text: tourText,
                    selector: '#dashboardTabs .nav-tabs',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );
 
            sites.push(
                Object.assign({},{
                    title: 'Favorites',
                    text: 'View and Manage properties you marked as Favorite.',
                    selector: '.tour-favorites-list',
                    position: 'top ',
                },getDefaultPageTourParams())
            );
            
            break;    
        }

        case "/dashboard/bookmarks": {

            if (user === 'client') {
                tourText = '<strong>Timeline</strong> - See all recent activity and add new comments. <br/><br/>';
                tourText += '<strong>My Saved Searches</strong> - View and manage your Saved Searches and Alerts. <br/><br/>';
                tourText += '<strong>My Favorites</strong> - Favorited properties. <br/><br/>';
                tourText += '<strong>Suggested by Agent</strong> - Properties suggested by your Agent. <br/><br/>';
                tourText += '<strong>Removed listings</strong> - View and manage the listings that you have marked to be hidden. <br/><br/>';
            }
 
            sites.push(
                Object.assign({},{
                    title: 'Dashboard Menu',
                    text: tourText,
                    selector: '#dashboardTabs .nav-tabs',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Remove Bookmark',
                    text: 'Once a Bookmark is selected you can remove it from the Bookmark list.',
                    selector: '.tour-bookmark-table .btn-group-warning',
                    position: 'bottom ',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Sorting',
                    text: 'You can sort the list by clicking on eact column.',
                    selector: '.tour-bookmark-table .react-bs-container-header',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Bookmarks',
                    text: 'Properties suggested by your Agent.',
                    selector: '.tour-bookmark-table .react-bs-container-body',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );
            
            break;    
        }
        case "/dashboard/removedlistings": {

            if (user === 'client') {
                tourText = '<strong>Timeline</strong> - See all recent activity and add new comments. <br/><br/>';
                tourText += '<strong>My Saved Searches</strong> - View and manage your Saved Searches and Alerts. <br/><br/>';
                tourText += '<strong>My Favorites</strong> - Favorited properties. <br/><br/>';
                tourText += '<strong>Suggested by Agent</strong> - Properties suggested by your Agent. <br/><br/>';
                tourText += '<strong>Removed listings</strong> - View and manage the listings that you have marked to be hidden. <br/><br/>';
            }

            sites.push(
                Object.assign({},{
                    title: 'Dashboard Menu',
                    text: tourText,
                    selector: '#dashboardTabs .nav-tabs',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Removed Listings',
                    text: 'View and manage the listings that you have marked to be hidden.',
                    selector: '.tour-removed-list',
                    position: 'bottom ',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Restore Property',
                    text: 'Restore property that you have marked to be hidden',
                    selector: '.tour-restore-icon',
                    position: 'bottom ',
                },getDefaultPageTourParams())
            );
            
            break;    
        }

        case "/search": {
            sites.push(
                Object.assign({},{
                    title: 'Search by Location',
                    text: 'Type in a MLS#, City, Zipcode to narrow your search results',
                    selector: '.search-item-location',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );
            if(window.innerWidth >= 768){
                sites.push(
                    Object.assign({},{
                        title: 'Filter by your Monthly True Lifestyle Cost',
                        text: 'Look for properties based on your personalized True Lifestyle Cost range. <br/></br> This should include everything including your Mortgage, Taxes, Insurance, Car costs, and utilities. <br/><br/>Once you find the perfect home we will break it all down for you on the property page!',
                        selector: '.lifestyle-item-cost',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );
            }

            sites.push(
                Object.assign({},{
                    title: 'Beds',
                    text: 'Filter properties based on Beds',
                    selector: '.beds-item-filter',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Baths',
                    text: 'Filter properties based on Baths',
                    selector: '.baths-item-filter',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );
           
            sites.push(
                Object.assign({},{
                    title: 'More filters',
                    text: 'These are additional search filters that are available for you to use. Open Houses, Fireplace, Pool, etc...',
                    selector: '.more-item-filter',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            /*if(user === 'agent'){
                sites.push(
                    Object.assign({},{
                        title: 'Create a Saved Search',
                        text: 'Save the current search criteria and receive daily updates. If you wish to assign this search to a specific client be sure to first use the Select Client feature above.  You can also review these and assign them to a Client at a later time from your Dashboard.',
                        selector: '.save-item-search',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );
            }
            else{
                sites.push(
                    Object.assign({},{
                        title: 'Created a Saved Search',
                        text: 'Save the current search criteria and receive daily updates.  You can review these later in your Dashboard.',
                        selector: '.save-item-search',
                        position: 'bottom',
                    },getDefaultPageTourParams())
                );
            }*/
            
            sites.push(
                Object.assign({},{
                    title: 'Matching Properties Count',
                    text: 'Number of matching properties for selected filters and map location.',
                    selector: '.sorting_option_total',
                    position: 'right',
                },getDefaultPageTourParams())
            );
            
            sites.push(
                Object.assign({},{
                    title: 'Draw on Map',
                    text: 'Draw an area on the map to search in.',
                    selector: '.leaflet_icon_draw',
                    position: 'right',
                },getDefaultPageTourParams())
            );
            
            sites.push(
                Object.assign({},{
                    title: 'Commute Location',
                    text: 'Add Commute Location to filter properties that are within a certain driving area.',
                    selector: '.includecommute-wrapper',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );
            sites.push(
                Object.assign({},{
                    title: 'Adjust Map Size',
                    text: 'Allows you to extend or shrink the map area.',
                    selector: '.map_arrow_btn',
                    position: 'left',
                },getDefaultPageTourParams())
            );
            sites.push(
                Object.assign({},{
                    title: 'Current Search Filters',
                    text: 'Search parameters applied to get filtered list of properties. You can quickly remove a filter by clicking on the X next to each',
                    selector: '.tag-item-location',
                    position: 'left',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Initial Image Filter',
                    text: 'Choose which image type you would like to see in your search results and Saved Search results like Kitchen, Bedroom, etc...',
                    selector: '.image-item-category',
                    position: 'left',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Property Card',
                    text: 'You can interact with the property card by flipping through photos and saving the property for later.',
                    selector: '.prop_card',
                    position: 'left',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: user === 'agent' ? 'Bookmark this Property' : 'Favorite this Property',
                    text: user === 'agent' ? 'Bookmark this specific property for a Selected Client.' : 'Add this property to your Favorite list.',
                    selector: '.property-left-icon',
                    position: 'left',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Compare property',
                    text: 'Compare this specific property with other properties',
                    selector: '.tour_compare_icon',
                    position: 'left',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Remove property',
                    text: "Hide or remove this specific property which doesn't seem relevant to you",
                    selector: '.remove_clsoe_icon',
                    position: 'left',
                },getDefaultPageTourParams())
            );

        break;
        }
        case "/propertydetail": {

            sites.push(
                Object.assign({},{
                    title: 'Listing Agent',
                    text: 'Check out details about the listing agent',
                    selector: '.tour-contact-box',
                    position: 'left'
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Contact Agent',
                    text: 'Get in touch with the agent by sending an email',
                    selector: '.ture-send-email',
                    position: 'left',
                    isFixed: true
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Property Price & TLC',
                    text: 'The Price and Monthly True LifeStyle Cost for this Listing. The TLC will include everything including your Mortgage, Taxes, Insurance, Car costs, and utilities based on your Lifestyle selections.',
                    selector: '.tour-details-cost',
                    position: 'left',
                    isFixed: true
                },getDefaultPageTourParams())
            );

            

            /*if(user === 'agent'){
                tourText = '<strong>Available Funds</strong> - Net monthly income based a default value, or your selected Client</br></br>';
            }
            else{
                tourText = '<strong>Available Funds</strong> - Net monthly income from your profile</br></br>';
            }
            tourText += '<strong>Mortgage/Taxes</strong> - Mortgage, Taxes, Homeowners insurance also called (PITI = Principal, Interest, Taxes, Insurance). HOA Fees, PMI are shown if included.</br></br>';
            tourText += '<strong>Transportation</strong> - Car Maintenance, Insurance and Gas Cost (if applicable).</br></br>';
            tourText += '<strong>Utilities</strong> - Heating, Cooling, Water, Sewer, Garbage, Lawn.</br></br>';
            tourText += '<strong>Entertainment</strong> - Phone, Internet, TV, Cell and Others (if any).</br></br>';
            tourText += '<strong>Total Monthly TLC</strong> - Total Monthly TLC for this property.</br></br>';
            
            sites.push(
                Object.assign({},{
                    title: 'True Lifestyle Cost Breakdown',
                    text: tourText,
                    selector: '.tlcSummary',
                    position: 'left',
                    isFixed: true
                },getDefaultPageTourParams())
            );           
            // sites.push(
            //     Object.assign({},{
            //         title: 'Selected Factors',
            //         text: 'These are the adjustable assumptions that were used to calculate the TLC for this property.',
            //         selector: '.tlcAssumptions',
            //         position: 'left',
            //         isFixed: true
            //     },getDefaultPageTourParams())
            // );
            sites.push(
                Object.assign({},{
                    title: 'Total True Lifestyle Cost',
                    text: 'This will be the total monthly True Lifestyle Cost for this property. ',
                    selector: '.tour-lifestyle-cost',
                    position: 'top'
                },getDefaultPageTourParams())
            );
            */

            sites.push(
                Object.assign({},{
                    title: 'Property Photos',
                    text: 'Check out property photos and also the location of the listing on the map',
                    selector: '.tour-slider',
                    position: 'top',
                },getDefaultPageTourParams())
            ); 
            
            sites.push(
                Object.assign({},{
                    title: user === 'agent' ? 'Bookmark this Property' : 'Favorite this Property',
                    text: user === 'agent' ? 'Bookmark this specific property for a Selected Client.' : 'Add this property to your Favorite list.',
                    selector: '.tour-fav-bookmark',
                    position: 'left',
                },getDefaultPageTourParams())
            );            

            /* sites.push(
                Object.assign({},{
                    title: 'Featured Mortgage Services',
                    text: 'Check out featured home mortgage rates',
                    selector: '.tour-mortgage-rate',
                    position: 'top',
                },getDefaultPageTourParams())
            ); */
            
            sites.push(
                Object.assign({},{
                    title: 'Mortgage',
                    text: 'This is the monthly Mortgage portion of the TLC which includes various configurable Lifestyle factors.',
                    selector: '.tour-mortgage-toggle',
                    position: 'top',
                },getDefaultPageTourParams())
            );

            /* sites.push(
                Object.assign({},{
                    title: 'Start An Offer',
                    text: 'Tell us about the offer you have in mind',
                    selector: '.tour-start-offer',
                    position: 'left',
                },getDefaultPageTourParams())
            ); */

            sites.push(
                Object.assign({},{
                    title: 'Transportation / Utilities / Entertainment',
                    text: 'This is the monthly Transportation, Utilities and Entertainment cost portion of the TLC for this specific property including the adjustable Lifestyle factors',
                    selector: '.tour-utilities-toggle',
                    position: 'top',
                },getDefaultPageTourParams())
            );

            sites.push(
                Object.assign({},{
                    title: 'Walkscore',
                    text: 'Measure of walkability of a Neighborhood.',
                    selector: '.tour-walkscore-tab',
                    position: 'bottom',
                },getDefaultPageTourParams())
            );

            /* sites.push(
                Object.assign({},{
                    title: 'Nearby listings',
                    text: 'Check out the nearby listings for the current listing',
                    selector: '.tour-nearby-listing',
                    position: 'top',
                },getDefaultPageTourParams())
            ); */

            // Soundscore is not always present
            // sites.push(
            //     Object.assign({},{
            //         title: 'Soundscore',
            //         text: 'This is the personalized Soundscore for this property that will tell you how quiet the neighborhood is.',
            //         selector: '.tour-soundscore-tab',
            //         position: 'top',
            //     },getDefaultPageTourParams())
            // );
            /*if(window.innerWidth >= 768){
                sites.push(
                    Object.assign({},{
                        title: 'Sub Sections',
                        text: 'You can navigate quickly to sections of the property listing by clicking each Sub Section.',
                        selector: '.tour-details-item-tab',
                        position: 'bottom',
                        isFixed: true
                    },getDefaultPageTourParams())
                );
            } */  
            
            if(user !== 'anonymous'){
                sites.push(
                    Object.assign({},{
                        title:'Realtime Collaboration',
                        text: user === 'agent' ? "Allows you to send/receive messages with your client." : "Allows you to send/receive messages with your agent.",
                        selector:'.tour-chat-window',
                        position: 'top-right',
                        isFixed: true
                    },getDefaultPageTourParams())
                );
            }
            
        break;
    }
        default :{
            break;
        }
    }
    return sites;
}

export function showSiteTour(page, user) {
    var returnVal = false;
    var filteredPage = page;
    if(filteredPage.indexOf('/search') === 0){
        filteredPage = '/search'
    }
    if(filteredPage.indexOf('/propertydetail') === 0){
        filteredPage = '/propertydetail'
    }    
    switch (filteredPage.toLowerCase()){
        //case "/realtor":
        case "":
        case "/propertydetail":
        case "/search":        
        case "/dashboard":
        case "/dashboard/bookmarks":
        case "/dashboard/removedlistings":
        case "/dashboard/favorites":
        case "/dashboard/savedsearches":
        case "/dashboard/invitations":
        case "/dashboard/timeline":
        case "/dashboard/clients":
        case "/": {
            returnVal = true;
            break;
        }
        default :{
            break;
        }
    }
    return returnVal;
}

export function getCommutTimes() {
    return [
        {value: "15", text: "15 mins"},
        {value: "30", text: "30 mins"},
        {value: "45", text: "45 mins"},
        {value: "60", text: "60 mins"},
    ];
}

export function getCommutModes() {
    return [
        {value: "car", text: "Car"},
        
    ];
}

export function getOrdinalSuffix(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export function isFiltersSelected(searchParameters){

    // Use a deep copy so we don't manipulate searchParameters
    var parametersCopy = JSON.parse(JSON.stringify(searchParameters));
    delete parametersCopy["mapbound"];

    if(JSON.stringify(parametersCopy) !== JSON.stringify(getDefaultSearchParams())){
        return true;
    }

    return false;
}

// Check for Customization options Env Variable
export function GetSiteCustomizationOptions () {
    return (process.env.REACT_APP_SiteCustomizationOptions !== undefined && process.env.REACT_APP_SiteCustomizationOptions !== null && process.env.REACT_APP_SiteCustomizationOptions !== "") ? JSON.parse(process.env.REACT_APP_SiteCustomizationOptions) : {};
}

// Check for Customization options Env Variable
export function GetSectionCustomizationOptions (section) {
    if(process.env.REACT_APP_SiteCustomizationOptions !== undefined && process.env.REACT_APP_SiteCustomizationOptions !== null && process.env.REACT_APP_SiteCustomizationOptions !== ""){
        var jsonStr = JSON.parse(process.env.REACT_APP_SiteCustomizationOptions);
        if(jsonStr[section] !== undefined && jsonStr[section] !== null && jsonStr[section] !== ""){
            return jsonStr[section];
        }
    } 
    return({});
}

export function GetMetaTagsProperties(){
    return (process.env.REACT_APP_MetaTags !== undefined && process.env.REACT_APP_MetaTags !=null && process.env.REACT_APP_MetaTags !== "") ? JSON.parse(process.env.REACT_APP_MetaTags) : {};
}

export function getSearchStatuses() {    
    var flst = getDDList('status');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "Active", text: "Active listings"},
        {value: "Pending", text: "Pending listings"},
        {value: "Sold", text: "Sold listings"},
    ];
}

export function getWaterFrontTypes() {    
    var flst = getDDList('waterfeatures');
    if(flst && flst.length > 0) return flst;
    
    return [
        {value: "Bay", text: "Bay/Harbor"},
        {value: "Beach", text: "Beach"},
        {value: "Canal", text: "Canal"},
        {value: "Creek", text: "Creek"},
        {value: "Gulf", text: "Gulf/Ocean"},
        {value: "Intracoastal Waterway", text: "Intracoastal Waterway"},
        {value: "Lagoon", text: "Lagoon"},
        {value: "Lake", text: "Lake"},
        {value: "Marina", text: "Marina"},
        {value: "Pond", text: "Pond"},
    ];
}

export function getManageTabs() {
    return ["featured-properties", "featured-openhouses", "featured-hot-properties", "featured-agents", "featured-built-for-you", "print-piece", "analytics", "ads", "clients"];
}

export function getManageTabText(index) {
    var lst = getManageTabs();
    if(index < 1 || index > lst.length) return "";
    return getManageTabs()[index - 1];
}

export function getManageTabIndex(tab) {
    var idx = getManageTabs().findIndex((e) => {return e === tab});
    if(idx < 0) return 1;
    return (idx + 1);
}

export function getNeighborhoodItemText(obj) {
    var txt = obj;
    
    if(Array.isArray(obj)){
        txt = [];
        obj.map(t => txt.push(getNeighborhoodItemText(t)));
        txt = txt.join(', ');
    }
    else if(obj.split(',').length > 1){
        var t1 = obj.split(',');
        txt = [];
        t1.map(t => txt.push(getNeighborhoodItemText(t)));
        txt = txt.join(', ');
    }
    else{
        if(obj.lastIndexOf("|") > -1){
            txt = obj.substring(0, obj.lastIndexOf("|"));
        }
    }
    
    return txt;
}

export function getPropertyTypes() {

    var flst = getDDList('propertytype');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "sf", text: "Single Family Home"},
        {value: "cnd", text: "Condos & Townhomes"},
        {value: "mf", text: "Multiple Unit"},
        {value: "lot", text: "Vacant Land"},
        {value: "apt", text: "Apartment"},
        {value: "com", text: "Commercial"},
        {value: "rnt", text: "Rental"}
    ];
}

export function getPropertyTypeName(pType, isText) {
    var rval = "";
    var val = [];
    var lst = getPropertyTypes();
    var flst = [];
    if(Array.isArray(pType)){
        val = [].concat(pType);
    }
    else if(!Array.isArray(pType)){
        val = pType.split(",");
    }
    val.map(v => {
        if(isText){
            flst = lst.filter(t => t.text.toLowerCase() === v.toLowerCase());
        }
        else{
            flst = lst.filter(t => t.value.toLowerCase() === v.toLowerCase());
        }
        if(flst && flst.length > 0){
            if(isText){
                rval = rval + (rval === "" ? flst[0].value : ", " + flst[0].value);
            }
            else{
                rval = rval + (rval === "" ? flst[0].text : ", " + flst[0].text);
            }
        }
        return null;        
    })
    
    //lst = lst.filter(t => t.value === pType.toLowerCase());
    //return ((lst && lst.length > 0) ? lst[0].text : "");

    return rval;
}

export function getFormattedOptionsForReactSelect(array) {
    return array.map(function(item) {
        return { value: item.value, label: item.text }
    });
}

export function getFormattedValueForReactSelect(array, val, isMulti) {
    if(isMulti){
        var aVal = null;
        if(val && val.length > 0){
            aVal = [];
            array.map(function(item) {
                if(val.indexOf(item.value) >= 0){
                    aVal.push({ value: item.value, label: item.text});
                }
                return null;
            });
        }
        return aVal;
    }
    else{
        var iVal = null;
        if(val){
            array.map(function(item) {
                if(val === item.value){
                    iVal = { value: item.value, label: item.text};
                    return null;
                }
                return null;
            });
        }
        return iVal;
    }
}

export function getStories() {
    var flst = getDDList('stories');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "Single Story", text: "Single Story"},
        {value: "Greater than Single Story", text: "Greater than Single Story"}
    ];
}

export function getCondoTypes() {    
    var flst = getDDList('condotype');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "Condominium", text: "Condo Only"},
        {value: "Townhouse", text: "Townhomes Only"},
        {value: "Patio Home Detached", text: "Patio Homes Only"}
    ];
}

export function getLotFeatures() {
    var flst = getDDList('lotfeature');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "Beach Access", text: "Beach Access"},
        {value: "City or Village", text: "City or Village"},
        {value: "Commercial Zoning", text: "Commercial Zoning"},
        {value: "Corner Lot", text: "Corner Lot"},
        {value: "Cul De Sac", text: "Cul De Sac"},
        {value: "Deed Restriction", text: "Deed Restriction"},
        {value: "Flood Zone", text: "Flood Zone"},
        {value: "Green Area", text: "Green Area"},
        {value: "Home Office Zoning", text: "Home Office Zoning"},
        {value: "Horses Permitted", text: "Horses Permitted"},
        {value: "Industrial", text: "Industrial"},
        {value: "Near Bus Line", text: "Near Bus Line"},
        {value: "Neighborhood Street", text: "Neighborhood Street"},
        {value: "On Golf Course", text: "On Golf Course"},
        {value: "On Reservation", text: "On Reservation"},
        {value: "Preservation District", text: "Preservation District"},
        {value: "Private Road", text: "Private Road"},
        {value: "Recreational", text: "Recreational"},
        {value: "Residential", text: "Residential"},
        {value: "Right of Way", text: "Right of Way"},
        {value: "Rural", text: "Rural"},
        {value: "Secluded", text: "Secluded"},
        {value: "Ski in and out", text: "Ski in and out"},
        {value: "Slope View", text: "Slope View"},
        {value: "Subdivision", text: "Subdivision"}
    ];
}

export function getPropertyStyles() {
    var flst = getDDList('propertystyle');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "2 Story", text: "2 Story"},
        {value: "A-Frame", text: "A-Frame"},
        {value: "Bungalow", text: "Bungalow"},
        {value: "Cabin", text: "Cabin"},
        {value: "Cape Cod", text: "Cape Cod"},
        {value: "Carriage House", text: "Carriage House"},
        {value: "Chalet", text: "Chalet"},
        {value: "Colonial", text: "Colonial"},
        {value: "Contemporary", text: "Contemporary"},
        {value: "Cottage", text: "Cottage"},
        {value: "Farmhouse", text: "Farmhouse"},
        {value: "Foursquare", text: "Foursquare"},
        {value: "Greek Revival", text: "Greek Revival"},
        {value: "Hillside", text: "Hillside"},
        {value: "Historic", text: "Historic"},
        {value: "Log Home", text: "Log Home"},
        {value: "Mediterranean/Spanish", text: "Mediterranean/Spanish"},
        {value: "Mobile Manu - Double Wide", text: "Mobile Manu - Double Wide"},
        {value: "Mobile Manu Home Park", text: "Mobile Manu Home Park"},
        {value: "Mobile Manu Home With Land", text: "Mobile Manu Home With Land"},
        {value: "Mobile Manu - Single Wide", text: "Mobile Manu - Single Wide"},
        {value: "Modular", text: "Modular"},
        {value: "Old Style", text: "Old Style"},
        {value: "Patio", text: "Patio"},
        {value: "Raised Ranch", text: "Raised Ranch"},
        {value: "Ranch", text: "Ranch"},
        {value: "Rehab Needed", text: "Rehab Needed"},
        {value: "Seasonal", text: "Seasonal"},
        {value: "Split Level", text: "Split Level"},
        {value: "Traditional", text: "Traditional"},
        {value: "Transitional", text: "Transitional"},
        {value: "Tudor", text: "Tudor"},
        {value: "Victorian", text: "Victorian"}
    ];
}

export function getRooms() {
    var flst = getDDList('roompref');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "1st Floor Bedroom", text: "1st Floor Bedroom"},
        {value: "1st Floor Master Bedroom", text: "1st Floor Master Bedroom"},
        {value: "1st Floor Master Suite", text: "1st Floor Master Suite"},
        {value: "Basement / Rec Room", text: "Basement / Rec Room"},
        {value: "Bonus Room", text: "Bonus Room"},
        {value: "Den/Study", text: "Den/Study"},
        {value: "Family Room", text: "Family Room"},
        {value: "Florida/Sun Room- Heated", text: "Florida/Sun Room- Heated"},
        {value: "Florida/Sun Room- Unheated", text: "Florida/Sun Room- Unheated"},
        {value: "Foyer/Entry Hall", text: "Foyer/Entry Hall"},
        {value: "Garage Apartment", text: "Garage Apartment"},
        {value: "Great Room", text: "Great Room"},
        {value: "Guest Suite", text: "Guest Suite"},
        {value: "In-Law - see Remarks", text: "In-Law - see Remarks"},
        {value: "Laundry-1st Floor", text: "Laundry-1st Floor"},
        {value: "Laundry-2nd Floor", text: "Laundry-2nd Floor"},
        {value: "Laundry-Basement", text: "Laundry-Basement"},
        {value: "Laundry-Common", text: "Laundry-Common"},
        {value: "Laundry-None", text: "Laundry-None"},
        {value: "Library", text: "Library"},
        {value: "Living Room", text: "Living Room"},
        {value: "Loft", text: "Loft"},
        {value: "Master Bedroom Bath", text: "Master Bedroom Bath"},
        {value: "Morning Room", text: "Morning Room"},
        {value: "Office", text: "Office"},
        {value: "Porch - Enclosed", text: "Porch - Enclosed"},
        {value: "Porch - Open", text: "Porch - Open"},
        {value: "Porch - Screened", text: "Porch - Screened"},
        {value: "Studio", text: "Studio"},
        {value: "Workshop", text: "Workshop"}
    ];
}

export function getAccessibilities() {    
    var flst = getDDList('accessibility');
    if(flst && flst.length > 0) return flst;

    return [
        {value: "32 Inch Doors - some", text: "32 Inch Doors - some"},
        {value: "Accessible/ANSI - some", text: "Accessible/ANSI - some"},
        {value: "Adaptable Home - some", text: "Adaptable Home - some"},
        {value: "Bath - Removable Cabinets", text: "Bath - Removable Cabinets"},
        {value: "Built to ANSI standards", text: "Built to ANSI standards"},
        {value: "Chairlift - see Remarks", text: "Chairlift - see Remarks"},
        {value: "Elevator - ANSI standard", text: "Elevator - ANSI standard"},
        {value: "Entry Level Bedroom", text: "Entry Level Bedroom"},
        {value: "Handicap Doors - Some", text: "Handicap Doors - Some"},
        {value: "Kitchen - Adj Cabinets", text: "Kitchen - Adj Cabinets"},
        {value: "No Interior Steps", text: "No Interior Steps"},
        {value: "No Step Entry", text: "No Step Entry"},
        {value: "No Step Laundry", text: "No Step Laundry"},
        {value: "No Step Shower", text: "No Step Shower"},
        {value: "Ramp", text: "Ramp"},
        {value: "Visual Alert System", text: "Visual Alert System"},
        {value: "Wheelchair Bathroom", text: "Wheelchair Bathroom"},
        {value: "Wheelchair Hall - 4 ft plus", text: "Wheelchair Hall - 4 ft plus"},
        {value: "Wheelchair Kitchen", text: "Wheelchair Kitchen"}
    ];
}

export function getEntryDays() {

    var flst = getDDList('entrydayold');
    if(flst && flst.length > 0) return flst;

    return [
        {value: '1', text: 'Show only new listings in the past 1 day' },
        {value: '2', text: 'Show only new listings in the past 2 days' },
        {value: '3', text: 'Show only new listings in the past 3 days' },
        {value: '7', text: 'Show only new listings in the past 1 week' },
    ];
}

export function getImageRoomTypes() {
    return [
        {value: 'exterior', text: 'Exterior' },
        {value: 'kitchen', text: 'Kitchen' },
        {value: 'bathroom', text: 'Bathroom' },
        {value: 'bedroom', text: 'Bedroom' },
        /* {value: 'animals', text: 'Animals' }, */
        /* {value: 'backyard', text: 'Backyard' }, */
        {value: 'cinema_room', text: 'Cinema Room' },
        {value: 'community_amenities', text: 'Community Amenities' },
        /* {value: 'corridor', text: 'Corridor' }, */
        {value: 'details', text: 'Details' },
        {value: 'dining_area', text: 'Dining Area' },
        /* {value: 'empty_room', text: 'Empty Room' }, */
        {value: 'foyer_entrance', text: 'Foyer Entrance' },
        {value: 'front_house', text: 'Front House' },
        {value: 'garage', text: 'Garage' },
        {value: 'gym', text: 'Gym' },        
        {value: 'laundry_room', text: 'Laundry Room' },
        {value: 'living,living_room', text: 'Living' },
        {value: 'map_location', text: 'Map Location' },
        {value: 'misc_room', text: 'Misc Room' },
        {value: 'mountain_view', text: 'Mountain View' },
        /* {value: 'non_related', text: 'Non Related' }, */
        {value: 'office', text: 'Office' },
        {value: 'outdoor_building', text: 'Outdoor Building' },
        {value: 'patio_terrace', text: 'Patio Terrace' },
        {value: 'plan', text: 'Plan' },
        {value: 'pool', text: 'Pool' },
        {value: 'sauna', text: 'Sauna' },
        {value: 'sport_court', text: 'Sport Court' },
        {value: 'stairs', text: 'Stairs' },
        {value: 'utility_room', text: 'Utility Room' },
        {value: 'walk_in_closet_pantry', text: 'Walk In Closet Pantry' },
        {value: 'water_view', text: 'Water View' },
        {value: 'wine_room', text: 'Wine Room' },
    ];
}

export function getImageExteriorTypes() {
    return [
        {value: 'art_deco', text: 'Art Deco' },
        {value: 'cape_cod', text: 'Cape Cod' },
        {value: 'colonial', text: 'Colonial' },
        {value: 'contemporary', text: 'Contemporary' },
        {value: 'craftsman', text: 'Craftsman' },
        {value: 'farmhouse', text: 'Farmhouse' },
        {value: 'italianate', text: 'Italianate' },
        {value: 'log', text: 'Log' },
        {value: 'mediterranean_spanish', text: 'Mediterranean Spanish' },
        {value: 'neoclassical_greek_revival', text: 'Neoclassical Greek Revival' },
        {value: 'oriental', text: 'Oriental' },
        {value: 'prairie', text: 'Prairie' },
        {value: 'pueblo_revival', text: 'Pueblo Revival' },
        {value: 'ranch', text: 'Ranch' },
        {value: 'tudor', text: 'Tudor' },
        {value: 'victorian', text: 'Victorian' },        
    ];
}

export function toProperCase(str) {
    var sentence = str.toLowerCase().replace(/_/g," ").split(" ");
    for(var i = 0; i < sentence.length; i++){
       sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    } 
    return sentence.join(" ");
}

export function getMyAgentTabs() {
    return ["suggestions", "listings", "contact", "invitations"];
}
export function getMyAgentTabText(index) {
    var lst = getMyAgentTabs();
    if(index < 1 || index > lst.length) return "";
    return getMyAgentTabs()[index - 1];
}

export function getMyAgentTabIndex(tab) {
    var idx = getMyAgentTabs().findIndex((e) => {return e === tab});
    if(idx < 0) return 1;
    return (idx + 1);
}

export function IsMLSNumber(text) {
    return new RegExp('^[BbRrSs][0-9]{1,7}$').test(text);
}

export function IsMultiZip(text) {
    return (/^(\d{5}(\s*[,]\s*\d{5})+)$/.test(text.trim().replace(/(^,)|(,$)/g, "").trim()));
}

export function IsSingleZip(text) {
    return (/^(\d{5})$/.test(text.trim()));
}

export function getAdTypes() {
    return [
        {value: "custom", text: "Custom Ad"},
        {value: "googletag", text: "Google Tag"},
        {value: "adpersistence", text: "Ad Persistence"},
    ];
}

export function getAdSizes() {
    return [
        {value: "home_banner_300x50", text: "Home Banner Ad (300x50)", desc: "Banner Ads to be displayed on home page"},
        {value: "property_card_270x285", text: "Property Card Ad (270x285)", desc: "Ads to be displayed on property cards"},
        {value: "footer_728x90", text: "Footer Ad (728x90)", desc: "Ads to be displayed in the footer"},
        {value: "mortgage_banner_700x90", text: "Mortgage Banner Ad (700x90)", desc: "Mortgage Banner Ads to be displayed on property detail page"},
        {value: "mortgage_card_280x280", text: "Mortgage Card Ad (280x280)", desc: "Mortgage Card Ads to be displayed on property detail page"},
        {value: "large_banner_921x450", text: "Large Banner Ad (921x450)", desc: "Large Banner Ads to be displayed on open houses page"},
    ];
}

export function getHomeBannerAdId() {
    return getAdSizes()[0].value;
}

export function getPropertyCardAdId() {
    return getAdSizes()[1].value;
}

export function getFooterAdId() {
    return getAdSizes()[2].value;
}

export function getMortgageBannerAdId() {
    return getAdSizes()[3].value;
}

export function getMortgageCardAdId() {
    return getAdSizes()[4].value;
}

export function getLargeBannerAdId() {
    return getAdSizes()[5].value;
}

export function getAdDesc(val) {
    var lst = getAdSizes();
    lst = lst.filter(t => t.value.toString() === val.toString());
    return ((lst && lst.length > 0) ? lst[0].desc : "");
}

export function isValueChanged(val, origVal, isNumber, defaultVal) {
    if(defaultVal !== undefined && defaultVal !== null) {
        if(isNumber){        
            if(val !== undefined && val !== null && (origVal === undefined || origVal === null || parseInt(origVal,10) === 0) &&
                parseInt(val,10) !== parseInt(defaultVal,10)){
                return true;
            }
            else if(val !== undefined && val !== null && origVal !== undefined && origVal !== null &&
                parseInt(val,10) !== parseInt(defaultVal,10) && parseInt(val,10) !== parseInt(origVal,10)){
                return true;
            }
        }
        else if(!isNumber){            
            if(val !== undefined && val !== null && (origVal === undefined || origVal === null) &&
                val !== defaultVal){
                return true;
            }
            else if(val !== undefined && val !== null && origVal !== undefined && origVal !== null &&
                val !== origVal){
                return true;
            }
            else if(val !== origVal && val !== defaultVal){
                return true;
            }
        }
    }
    else {
        if(((val === undefined || val === null) && origVal !== undefined && origVal !== null) ||
            (val !== undefined && val !== null && (origVal === undefined || origVal === null))){
            return true;
        }
        else if((val !== undefined && val !== null && origVal !== undefined && origVal !== null) &&
            isNumber && parseInt(val,10) !== parseInt(origVal,10)){
            return true;    
        }
        else if((val !== undefined && val !== null && origVal !== undefined && origVal !== null) &&
            !isNumber && val !== origVal){
            return true;    
        }
    }    
    
    return false;
}

export function getGUID() {
    var lut = []; 
    for (var i=0; i<256; i++) { lut[i] = (i<16?'0':'')+(i).toString(16); }
    var d0 = Math.random()*0xffffffff|0;
    var d1 = Math.random()*0xffffffff|0;
    var d2 = Math.random()*0xffffffff|0;
    var d3 = Math.random()*0xffffffff|0;
    // eslint-disable-next-line
    return lut[d0&0xff]+lut[d0>>8&0xff]+lut[d0>>16&0xff]+lut[d0>>24&0xff]+'-'+lut[d1&0xff]+lut[d1>>8&0xff]+'-'+lut[d1>>16&0x0f|0x40]+lut[d1>>24&0xff]+'-'+lut[d2&0x3f|0x80]+lut[d2>>8&0xff]+'-'+lut[d2>>16&0xff]+lut[d2>>24&0xff]+lut[d3&0xff]+lut[d3>>8&0xff]+lut[d3>>16&0xff]+lut[d3>>24&0xff];
}

export function getSlotsOptions(cnt) {
    var slotsList = [];
    if(cnt){
        slotsList.push({value: "", text: "All Slots"});
        for(var i = 1; i <= cnt; i++){
            slotsList.push({value: i.toString(), text: "Slot "+ i.toString()});
        }
    }
    return slotsList;
}

export function getDDList(name) {
    if(process.env.REACT_APP_SiteCustomizationOptions !== undefined && process.env.REACT_APP_SiteCustomizationOptions !== null && process.env.REACT_APP_SiteCustomizationOptions !== ""){
        var jsonStr = JSON.parse(process.env.REACT_APP_SiteCustomizationOptions);
        if(jsonStr["CommonOptions"] !== undefined && jsonStr["CommonOptions"] !== null && jsonStr["CommonOptions"] !== ""){
            if(jsonStr["CommonOptions"]["DDLists"] !== undefined && jsonStr["CommonOptions"]["DDLists"] !== null && 
                jsonStr["CommonOptions"]["DDLists"] !== "" && jsonStr["CommonOptions"]["DDLists"].length > 0){
                var flst = jsonStr["CommonOptions"]["DDLists"].filter(f => f.Name === name);
                if(flst && flst.length > 0 && flst[0].List && flst[0].List.length > 0){ 
                    return flst[0].List;
                }
            }
        }
    }

    return [];
}

export function getNumberOfDaysWeeks(num) {
    if(num && num !== "" && parseInt(num, 10) > 0){
        const inum = parseInt(num, 10);
        if(inum === 365){
            return "1 year";
        }
        else if(inum === 180){
            return "6 months";
        }
        else if(inum === 150){
            return "5 months";
        }
        else if(inum === 120){
            return "4 months";
        }
        else if(inum === 90){
            return "3 months";
        }
        else if(inum === 60){
            return "2 months";
        }
        else if(inum === 30){
            return "1 month";
        }
        else if(inum === 21){
            return "3 weeks";
        }
        else if(inum === 14){
            return "2 weeks";
        }
        else if(inum === 7){
            return "1 week";
        }
        else if(inum === 1){
            return "1 day";
        }
        else{
            return inum + " days";
        }
    }

    return num;
}