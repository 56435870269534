import { CALL_API, Schemas } from '../store/api';

export const INVITATIONS_REQUEST = 'INVITATIONS_REQUEST';
export const INVITATIONS_SUCCESS = 'INVITATIONS_SUCCESS';
export const INVITATIONS_DELETE_REQUEST = 'INVITATIONS_DELETE_REQUEST';
export const INVITATIONS_DELETE_SUCCESS = 'INVITATIONS_DELETE_SUCCESS';
export const INVITATIONS_FAILURE = 'INVITATIONS_FAILURE';

export const fetchInvitations = (params) => (dispatch, getState) => {
    const { Id, type } = getState().session.audience;

    var bParams = params;
    if(!bParams || bParams.onlypending === undefined || bParams.onlypending === null){
        bParams = {onlypending: true};
    }

    return dispatch({
        [CALL_API]: {
            types: [INVITATIONS_REQUEST, INVITATIONS_SUCCESS, INVITATIONS_FAILURE],
            method: 'POST',
            body: bParams,
            endpoint: `${type}s/${Id}/invites`,
            schema: Schemas.INVITATION_ITEM_ARRAY
        }
    })
};

export const deleteInvitation = id => (dispatch, getState) => {
    /* const { Id, type } = getState().session.audience;

    return dispatch({
        [CALL_API]: {
            types: [INVITATIONS_DELETE_REQUEST, INVITATIONS_DELETE_SUCCESS, INVITATIONS_FAILURE],
            method: 'DELETE',
            endpoint: `${type}s/${Id}/invitations`,
            id: id
        }
    }); */

    /* return {
        types: INVITATIONS_DELETE_SUCCESS,
        id: id
    }; */

    const { invitations } = getState();

    return {
        ...invitations,
        isFetching: false,
        ids: invitations.ids.filter(n => n !== id)
    };
};