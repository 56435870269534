import React from 'react';

class Loader extends React.Component{
    render(){
        let styleOptions = {
                width: this.props.width ? this.props.width + "px" : "30px",
                height: this.props.height ? this.props.height + "px" : "30px"
            };
        let msg = this.props.message ? (<div className="loader-text">{this.props.message}</div>) : "";
        let cls = this.props.message ? "col-sm-12 loader-container" : "col-sm-12 loader-container";

        let loaderHtml = this.props.inline ?
            (<span>
                <div className="loader" style={styleOptions}></div>
            </span>) :
            (<div>
                <div className="clear-both"></div>
                <div className="row">
                    <div className={cls}>
                        <div className="loader" style={styleOptions}></div>
                        {msg}
                    </div>
                </div>
            </div>)

        return(<span>{loaderHtml}</span>);
    }
}

export default Loader;