import React from 'react';
import { connect } from 'react-redux';
import { GetSectionCustomizationOptions } from 'components/util';
import FacebookIcon from 'img/facebook.png';
import InstagramIcon from 'img/instagram.png';
import BlogIcon from 'img/blog.png';
import TwitterIcon from 'img/twitter.png';
//import FooterLogo from 'img/footer_logo.jpg';
//import PrintPiece from 'img/print_piece.jpg';
//import ReisMlsLogo from 'img/reis_mls_logo.png';
import PowerByLogo from 'img/tlcenginelogo.png';
//import RealtorMls from 'img/realtor_mls.png';
import LinkedInIcon from 'img/linkedin.png';
import YouTubeIcon from 'img/youtube.png';
import 'css/contact_agent.css';

const FooterCustomizationOptions = GetSectionCustomizationOptions("FooterOptions");
const AdminCustomizationOptions = GetSectionCustomizationOptions("AdminOptions");

class FooterStyleStandard extends React.Component{

    constructor(props) {
        super(props);

        this.state = {  };
    }

    render(){

        var crText = null;
        if(FooterCustomizationOptions && FooterCustomizationOptions.CopyRightTextInFooter && FooterCustomizationOptions.CopyRightTextInFooter !== ""){
            crText = FooterCustomizationOptions.CopyRightTextInFooter;
            crText = crText.replace(/COPYRYEAR/g,new Date().getFullYear());
            var crArr = crText.split('COPYRSYMBOL');
            if(crArr && crArr.length > 0){
                crText = [];
                crArr.map((s, i) => {
                    if(s && s !== ""){
                        crText.push(<span key={i}>{s}</span>);
                    }
                    if(i !== (crArr.length - 1)){
                        crText.push(<span key={'sq_' + i}>&copy;</span>);
                    }
                    return null;
                });
            }
        }

        var crSubText = null;
        if(FooterCustomizationOptions && FooterCustomizationOptions.CopyRightSubTextInFooter && FooterCustomizationOptions.CopyRightSubTextInFooter !== ""){
            crSubText = FooterCustomizationOptions.CopyRightSubTextInFooter;
            crSubText = crSubText.replace(/COPYRYEAR/g,new Date().getFullYear());
            var crsArr = crSubText.split('COPYRSYMBOL');
            if(crsArr && crsArr.length > 0){
                crSubText = [];
                crsArr.map((s, i) => {
                    if(s && s !== ""){
                        crSubText.push(<span key={i}>{s}</span>);
                    }
                    if(i !== (crsArr.length - 1)){
                        crSubText.push(<span key={'sq_' + i}>&copy;</span>);
                    }
                    return null;
                });
            }
        }

        var showPlaceAnAdLink = true;
        if(FooterCustomizationOptions && FooterCustomizationOptions.HidePlaceAnAdLinkInFooter){
            showPlaceAnAdLink = false;
        }
        var showContactUsLink = true;
        if(FooterCustomizationOptions && FooterCustomizationOptions.HideContactUsLinkInFooter){
            showContactUsLink = false;
        }
        var showTermsOfUseLink = true;
        if(FooterCustomizationOptions && FooterCustomizationOptions.HideTermsOfUseLinkInFooter){
            showTermsOfUseLink = false;
        }
        
        var termsOfUseUrl = "#"; //"https://s3.amazonaws.com/2findyourhome.com/media/2fyh-tou.pdf";
        if(FooterCustomizationOptions && FooterCustomizationOptions.TermsOfUseUrlInFooter && FooterCustomizationOptions.TermsOfUseUrlInFooter !== ""){
            termsOfUseUrl = FooterCustomizationOptions.TermsOfUseUrlInFooter;
        }

        
        return(
            <div className="fluid row footer-content">	
                    
                <div className="col-sm-12 col-xs-12">
                    <div className="row">
                        <div className="col-md-7 col-sm-7 col-xs-12 mb_footer_full">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <div className="">
                                        <div className="col-sm-2 col-xs-12 pd-lft-0 mb-tru-center search_ft_logo" style={{minHeight: 40}}>
                                            {FooterCustomizationOptions && FooterCustomizationOptions.FooterLogoUrl && FooterCustomizationOptions.FooterLogoUrl !== "" &&
                                                <a href="/"><img className="footer_logo" src={FooterCustomizationOptions.FooterLogoUrl} alt="Logo" /></a>
                                            }
                                        </div>
                                        <div className="col-sm-10 col-xs-12 mb-tru-center pd-lft-0 search_ft_copyright_tx" style={{minHeight: 40}}>
                                            {(FooterCustomizationOptions && FooterCustomizationOptions.FooterImages && FooterCustomizationOptions.FooterImages.length > 0 &&  FooterCustomizationOptions.FooterImages[0] && FooterCustomizationOptions.FooterImages[0].ImageUrl && FooterCustomizationOptions.FooterImages[0].ImageUrl !== "") ?
                                                ((FooterCustomizationOptions.FooterImages[0].NavigationUrl && FooterCustomizationOptions.FooterImages[0].NavigationUrl !== "") ? 
                                                    (<span className="display-ib mg-btm-8"><a href={FooterCustomizationOptions.FooterImages[0].NavigationUrl} target="_blank" rel="noopener noreferrer"><img  className="print_piece" src={FooterCustomizationOptions.FooterImages[0].ImageUrl} alt="Logo" /></a></span>) :
                                                    (<span className="display-ib mg-btm-8"><img  className="print_piece" src={FooterCustomizationOptions.FooterImages[0].ImageUrl} alt="Logo" /></span>)
                                                ) : null
                                            }
                                            {(crText || crSubText)  &&
                                            <div className="copyright_text">
                                                {crText  && <p>{crText}</p>}
                                                {crSubText  && <p>{crSubText}</p>}
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="footer_address"><strong>{FooterCustomizationOptions.FooterCompanyName || ""}</strong> {FooterCustomizationOptions.FooterAddress || ""}</div>
                                    <nav className="footer_nav">
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/search">Property Search</a></li>
                                            {showPlaceAnAdLink && <li><span className="flink anchor" onClick={this.props.openPAAModal}>Place an Ad</span></li>}
                                            {showContactUsLink && <li><span className="flink anchor support-item-email" onClick={this.props.openCUModal}>Contact Us</span></li>}
                                            {showTermsOfUseLink && <li><span className="tour-terms-use tour-terms-use"><a href={termsOfUseUrl} rel="noopener noreferrer" target="_blank" >Terms of Use</a></span></li>}
                                            {FooterCustomizationOptions && FooterCustomizationOptions.ShowFeedbackLinkInFooter &&
                                            <li><a href="/feedback">Feedback</a></li>
                                            }
                                            {(AdminCustomizationOptions && AdminCustomizationOptions.AllowManage && this.props.profile && this.props.profile.IsSuperAdmin) &&
                                                <li><a href="/manage">Manage</a></li>
                                            }
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-5 col-xs-12 mb_footer_full">
                            <div className="row">
                                <div className="col-md-5 col-sm-5 col-xs-12 mb-tru-center mb_footer_full">
                                    {(FooterCustomizationOptions && FooterCustomizationOptions.FooterImages && FooterCustomizationOptions.FooterImages.length > 1 &&  FooterCustomizationOptions.FooterImages[1] && FooterCustomizationOptions.FooterImages[1].ImageUrl && FooterCustomizationOptions.FooterImages[1].ImageUrl !== "") ?
                                        ((FooterCustomizationOptions.FooterImages[1].NavigationUrl && FooterCustomizationOptions.FooterImages[1].NavigationUrl !== "") ? 
                                        (<a href={FooterCustomizationOptions.FooterImages[1].NavigationUrl} alt="" target="_blank" rel="noopener noreferrer"><img className="reis_mls_logo" src={FooterCustomizationOptions.FooterImages[1].ImageUrl} alt="Logo" /></a>) : 
                                        (<img className="reis_mls_logo" src={FooterCustomizationOptions.FooterImages[1].ImageUrl} alt="Logo" />)
                                        ) : null
                                    }
                                    <div className="ft_powerby_logo mg-top-15 mg-btm-8 ">
                                        <span className="display-bk mg-btm-10">Powered by</span>
                                        <span className="display-ib">
                                            <a href="https://www.tlcengine.com" target="_blank" rel="noopener noreferrer">
                                                <img className="powerby_logo" src={PowerByLogo} alt="PowerByLogo" />
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 col-xs-12 flt-right text_right mb-tru-center mb_footer_full">
                                    <h4>Follow us on social media</h4>
                                    <ul className="ft_social_icons">
                                        {FooterCustomizationOptions && FooterCustomizationOptions.FacebookPageUrl && 
                                            <li><a href={FooterCustomizationOptions.FacebookPageUrl} target="_blank" rel="noopener noreferrer"><img src={FacebookIcon} alt="Facebook" /></a></li>
                                        }
                                        {FooterCustomizationOptions && FooterCustomizationOptions.TwitterPageUrl && 
                                            <li><a href={FooterCustomizationOptions.TwitterPageUrl} target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="Twitter" /></a></li>
                                        }
                                        {FooterCustomizationOptions && FooterCustomizationOptions.InstagramPageUrl && 
                                            <li><a href={FooterCustomizationOptions.InstagramPageUrl} target="_blank" rel="noopener noreferrer"><img src={InstagramIcon} alt="Instagram" /></a></li>
                                        }
                                        {(FooterCustomizationOptions && FooterCustomizationOptions.LinkedInPageUrl) &&
                                            <li><a href={FooterCustomizationOptions.LinkedInPageUrl} target="_blank" rel="noopener noreferrer"><img src={LinkedInIcon} alt="LinkedIn" /></a></li>
                                        }
                                        {(FooterCustomizationOptions && FooterCustomizationOptions.YouTubePageUrl) &&
                                            <li><a href={FooterCustomizationOptions.YouTubePageUrl} target="_blank" rel="noopener noreferrer"><img src={YouTubeIcon} alt="Youtube" /></a></li>
                                        }
                                        {(FooterCustomizationOptions && FooterCustomizationOptions.ExternalBlogPageUrl) &&
                                            <li><a href={FooterCustomizationOptions.ExternalBlogPageUrl} target="_blank" rel="noopener noreferrer"><img src={BlogIcon} alt="Blog" /></a></li>
                                        }
                                    </ul>
                                    {(FooterCustomizationOptions && FooterCustomizationOptions.FooterImages && FooterCustomizationOptions.FooterImages.length > 2 &&  FooterCustomizationOptions.FooterImages[2] && FooterCustomizationOptions.FooterImages[2].ImageUrl && FooterCustomizationOptions.FooterImages[2].ImageUrl !== "") ?
                                        ((FooterCustomizationOptions.FooterImages[2].NavigationUrl && FooterCustomizationOptions.FooterImages[2].NavigationUrl !== "") ?
                                        (<span className="mb_footer_full display-ib mg-top-12"><a href={FooterCustomizationOptions.FooterImages[2].NavigationUrl} target="_blank" rel="noopener noreferrer"><img className="realtor_mls_logo" src={FooterCustomizationOptions.FooterImages[2].ImageUrl} alt="Logo" /></a></span>) :
                                        (<span className="mb_footer_full display-ib mg-top-12"><img className="realtor_mls_logo" src={FooterCustomizationOptions.FooterImages[2].ImageUrl} alt="Logo" /></span>)
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="publisher_notice">
                        {FooterCustomizationOptions && FooterCustomizationOptions.PublishersNoticeInFooter && FooterCustomizationOptions.PublishersNoticeInFooter !== "" &&
                            <p>{FooterCustomizationOptions.PublishersNoticeInFooter}</p>
                        }
                        {FooterCustomizationOptions && FooterCustomizationOptions.LicenseTextInFooter && FooterCustomizationOptions.LicenseTextInFooter !== "" &&
                            <p>{FooterCustomizationOptions.LicenseTextInFooter}</p>
                        }
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    profile: state.profile.mine || {},
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterStyleStandard);