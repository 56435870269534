import React, { Component } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import profilephoto from '../img/default-photo.png';
import DocumentTitle from 'react-document-title';
import { PageTitle } from '../components/util';
import Loader from '../components/loader';
//import AgentListImage from '../img/agent_list.jpg';
import 'css/Agent_office.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GetMetaTagsProperties, GetSectionCustomizationOptions } from '../components/util';
import * as agentActions from 'actions/agents';

//const SiteCustomizationOptions = GetSiteCustomizationOptions();
const AdminCustomizationOptions = GetSectionCustomizationOptions("AdminOptions");
const AgentOfficePageCustomizationOptions = GetSectionCustomizationOptions("AgentOfficePageOptions");
const ProfilePageCustomizationOptions = GetSectionCustomizationOptions("ProfilePageOptions");
const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");
const MetaTagsProperties = GetMetaTagsProperties();

class AgentOfficePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: 'Agent/Office Search',
            selected: 1,
            firstName: '',
            lastName: '',
            city: '',
            zip: '',
            results: [],
            resultsPage: [],
            officeAgents: [],
            fetchingResult: false,
            message: ''
        };
        //this.sizePerPage = 27;
        this.sizePerPage = (AdminCustomizationOptions && AdminCustomizationOptions.FeaturedAgentsSlotsInManage) ? parseInt(AdminCustomizationOptions.FeaturedAgentsSlotsInManage,10) : 25;
    }

    componentDidMount(){

        window.scrollTo(0, 0);
        
        if (!this.props.showHeader) {
            this.props.onShowHideHeader(true);
        }
        if (!this.props.showFooter) {
            this.props.onShowHideFooter(true);
        }

        if(AgentOfficePageCustomizationOptions && AgentOfficePageCustomizationOptions.ShowFeaturedAgents){
            /* if(CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers && this.props.agentActions){
                this.props.agentActions.fetchTeamAgents();
            }
            else {
                this.props.agentActions.fetchFeaturedAgents();
            } */
            this.props.agentActions.fetchFeaturedAgents();
        }
    }

    componentWillReceiveProps(nextProps) {
        if(AgentOfficePageCustomizationOptions && AgentOfficePageCustomizationOptions.ShowFeaturedAgents){
            if (nextProps.featuredagents && nextProps.featuredagents.length > 0 && this.state.selected === 1 && this.state.results.length === 0) {
                this.setState({ results: nextProps.featuredagents, fetchingResult: false }, () => this.onPageChange(1));
            }
        }
    }

    handleTabChange = (selected) => {
        this.setState({ selected: selected, results: [], resultsPage:[], message:'', errorMessage: null })
        var elements = document.getElementsByTagName("input");
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].type === "text") {
                this.setState({ [elements[i].name]: ''})
            }
        }
        if(AgentOfficePageCustomizationOptions && AgentOfficePageCustomizationOptions.ShowFeaturedAgents && (selected === 1)){
            /* if(CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers && this.props.agentActions){
                this.props.agentActions.fetchTeamAgents();
            }
            else {
                this.props.agentActions.fetchFeaturedAgents();
            } */
            this.props.agentActions.fetchFeaturedAgents();
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    onKeyPress = (e, office) => {
        if(e.which === 13) {
          //this.doSearch(e, office);
          this.validateInput(e, office);
        }
    }

    validateInput = (e, office) => {
        var flagContinue = false;
        if (office) {
            if((this.state.officeName && this.state.officeName.trim() !== "") ||
                (this.state.officeCity && this.state.officeCity.trim() !== "") ||
                (this.state.officeZip && this.state.officeZip.trim() !== "")){
                flagContinue = true;
            }
        }
        else {            
            if((this.state.firstName && this.state.firstName.trim() !== "") ||
                (this.state.lastName && this.state.lastName.trim() !== "") ||
                (this.state.city && this.state.city.trim() !== "") ||
                (this.state.zip && this.state.zip.trim() !== "")){
                flagContinue = true;
            }
        }
        if(flagContinue){
            this.setState({errorMessage: null},() => {
                this.doSearch(e, office);
            });            
        }
        else{
            this.setState({errorMessage: 'Please fill out one or more search fields'});
        }
    }

    doSearch = (event, office, officeId) => {
        if (!officeId) { // searching for agent or office
            this.setState({ fetchingResult: true, results: [], resultsPage:[], message: '' });
        }
        else if (officeId && (event.target.className === 'fa fa-arrow-down' || 
            (event.target.hasChildNodes() && event.target.children[0].className === 'fa fa-arrow-down'))) { // collapsing office agents
            this.setState({ officeAgents: []})
            return;
        }
        var that = this;
        var requestBody = {MemberFirstName: this.state.firstName ? this.state.firstName.trim() : this.state.firstName,
            MemberLastName: this.state.lastName ? this.state.lastName.trim() : this.state.lastName,
            MemberAddress1: this.state.street ? this.state.street.trim() : this.state.street,
            //MemberPostalCode: this.state.zip,
            //MemberCity: this.state.city,
            OfficePostalCode: this.state.zip ? this.state.zip.trim() : this.state.zip,
            OfficeCity: this.state.city ? this.state.city.trim() : this.state.city,
            MemberStateOrProvince: this.state.state ? this.state.state.trim() : this.state.state
        }
        var url = window.sessionStorage.getItem("RootAPI") + "agents/agentsearch/";
        if (office) {
            requestBody = {OfficeName: this.state.officeName ? this.state.officeName.trim() : this.state.officeName,
                OfficeCity: this.state.officeCity ? this.state.officeCity.trim() : this.state.officeCity,
                OfficePostalCode: this.state.officeZip ? this.state.officeZip.trim() : this.state.officeZip
            } 
            url = window.sessionStorage.getItem("RootAPI") + "agents/officesearch/";
        }
        else if (officeId){
            requestBody = { OfficeId: officeId}
        }

        fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + window.sessionStorage.getItem("APIKey")
                },
                method: "POST",
                body: JSON.stringify(requestBody)
            })
            .then(response => {
                if (response.ok) return response.json()
                else throw Error(response.statusText)
            })
            .then(json => {
                if (officeId){
                    that.setState({ officeAgents: json.Clients });
                }
                else {
                    var results = office ? json.Offices: json.Clients;
                    that.setState({ results: results, fetchingResult: false }, () => this.onPageChange(1));
                }
            })
            .catch(function (data) {
                console.log(data);
                that.setState({ results: [], errorMessage: 'Search failed', fetchingResult: false});
            });

    }

 

    

    onPageChange = (page) => {
        var pageStart = (page - 1) * this.sizePerPage;
        this.setState({ currentPage:page, resultsPage: this.state.results.slice(pageStart, pageStart + this.sizePerPage)});
    }

    buildAgentCard = (agent, index) => {

        var lnk = "#";
        var lnkTget = "";
        if(agent.MemberMlsId && agent.MemberMlsId !== ""){
            lnk = this.props.purlPrefix.url + "/agentprofile/" + agent.MemberMlsId;
        }
        else if(agent.URL && agent.URL !== ""){
            lnk = agent.URL;
            lnkTget = "_blank";
        }

        var ele = (
            <div className="result_boxs">
                {(lnkTget !== "") ?
                    <div className="profile_photobox">
                        <a href={lnk} target={lnkTget} alt="" rel="noopener noreferrer">
                            <img src={getProfilePhoto(agent)} alt="" />
                        </a>
                    </div> :
                    <div className="profile_photobox">
                        <Link to={lnk}><img src={getProfilePhoto(agent)} alt="" /></Link>
                    </div>
                }
                <div className="result_right_info">
                    {(agent.MemberFullName !== null && agent.MemberFullName.length > 0) ?
                        <div className="short_name">
                            {/* (lnkTget !== "") ?
                                <a href={lnk} target={lnkTget} alt="" rel="noopener noreferrer">
                                    {toCapitalizedWords(agent.MemberFullName.toLowerCase())}
                                </a> :
                                <Link to={lnk}>{toCapitalizedWords(agent.MemberFullName.toLowerCase())}</Link>
                             */}
                             {(lnkTget !== "") ?
                                <a href={lnk} target={lnkTget} alt="" rel="noopener noreferrer">
                                    {agent.MemberFullName}
                                </a> :
                                <Link to={lnk}>{agent.MemberFullName}</Link>
                            }
                        </div>
                        : 
                        <div className="short_name">
                            {/* toCapitalizedWords(agent.MemberFirstName.toLowerCase() + " " + agent.MemberLastName.toLowerCase()) */}
                            {agent.MemberFirstName + " " + agent.MemberLastName}
                            {/* (lnkTget !== "") ?
                                <a href={lnk} target={lnkTget} alt="" rel="noopener noreferrer">
                                    {toCapitalizedWords(agent.MemberFirstName.toLowerCase() + " " + agent.MemberLastName.toLowerCase())}
                                </a> :
                                <Link to={lnk}>{toCapitalizedWords(agent.MemberFirstName.toLowerCase() + " " + agent.MemberLastName.toLowerCase())}</Link>
                             */}
                            {(lnkTget !== "") ?
                                <a href={lnk} target={lnkTget} alt="" rel="noopener noreferrer">
                                    {agent.MemberFirstName + " " + agent.MemberLastName}
                                </a> :
                                <Link to={lnk}>{agent.MemberFirstName + " " + agent.MemberLastName}</Link>
                            }
                        </div>
                    }
                    {(CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers && agent.Designation && agent.Designation !== "") &&
                        <div className="phone_number">
                            <div className="number_box">{agent.Designation}</div>
                        </div>
                    }
                    {((!CommonCustomizationOptions || !CommonCustomizationOptions.EnableTeamMembers) && (agent.OfficeFullStreetAddress || agent.OfficeName)) &&
                    <div className="address_box">
                        <div className="address_icon"><i className="fa fa-building"></i></div>
                        <div className="address_name">
                            {agent.OfficeFullStreetAddress ? toCapitalizedWords(agent.OfficeFullStreetAddress.toLowerCase()) : ""}
                            {(((!agent.OfficeFullStreetAddress || agent.OfficeFullStreetAddress === "") && agent.OfficeName && agent.OfficeName !== "") ? toCapitalizedWords(agent.OfficeName.toLowerCase()) : "")}
                        </div>
                    </div>
                    }
                    <div className="phone_number">
                        {(agent.OfficePhone && agent.OfficePhone !== "") ? 
                            (<div className="number_box"><i className="fa fa-phone"></i><a href={"tel:" + agent.OfficePhone.replace(/ /g,"").replace(/-/g,"").replace(/\(/g,"").replace(/\)/g,"")} style={{display: "inline-block"}}>{agent.OfficePhone}</a></div>) :
                        ((agent.MemberPreferredPhone && agent.MemberPreferredPhone !== "") ? 
                            (<div className="number_box"><i className="fa fa-phone"></i><a href={"tel:" + agent.MemberPreferredPhone.replace(/ /g,"").replace(/-/g,"").replace(/\(/g,"").replace(/\)/g,"")} style={{display: "inline-block"}}>{agent.MemberPreferredPhone}</a></div>) : null
                        )}
                        {(agent.MemberMobilePhone && agent.MemberMobilePhone !== "") && <div className="number_box"><i className="fa fa-mobile"></i><a href={"tel:" + agent.MemberMobilePhone.replace(/ /g,"").replace(/-/g,"").replace(/\(/g,"").replace(/\)/g,"")} style={{display: "inline-block"}}>{agent.MemberMobilePhone}</a></div>}
                    </div>
                    {(((agent.URL && agent.URL !== "") || (CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers)) && agent.MemberEmail && agent.MemberEmail !== "") &&
                    <div className="contact_agent">
                        <i className="contact_icon fa fa-envelope"></i>
                        <a href={"mailto:" + agent.MemberEmail} alt=""><span className="anchor-link cursor_pointer">Email Agent</span></a>
                    </div>
                    }
                </div>
            </div>
        )

        /* if(lnkTget !== "") {
            return (
                <div key={index} className="col-md-4 col-sm-6 col-xs-12 col_two">
                    <a href={lnk} target={lnkTget} alt="" rel="noopener noreferrer">
                        {ele}
                    </a>
                </div>
            )
        }
        else{
            return ( 
                <div key={index} className="col-md-4 col-sm-6 col-xs-12 col_two">
                    <Link to={lnk}>
                        {ele}
                    </Link>
                </div>
            )
        } */    
        
        return ( 
            <div key={index} className="col-md-4 col-sm-6 col-xs-12 col_two">
                {ele}
            </div>
        )
    }
    
    render() {
        const {selected, fetchingResult, message, currentPage, results, resultsPage, officeAgents} = this.state
        const pageCount = results.length > 0 ? (selected === 2 ? (results[0].OfficeId ? Math.ceil(results.length / this.sizePerPage) : 0) : Math.ceil(results.length / this.sizePerPage)) : 0;
        var pages = [];
        var iStart = 1;
        var iEnd = pageCount;
        if(pageCount > 10){
            iStart = currentPage;
            if((currentPage + 9) <= pageCount){
                iEnd = (currentPage + 9);
            }
            else{
                iStart = (pageCount - 9);
            }
        }
        for (var i = iStart; i <= iEnd; i++) {
            let ii = i;
            pages.push(<li key={i} onClick={() => this.onPageChange(ii)} className={i === currentPage ? 'active page-item' :'page-item'} 
                         title={i}><span className="page-link" style={{minWidth: 31, textAlign: "center"}}>{i}</span></li>)
        }
        const loader = fetchingResult ? <div style={{ padding: '10px'}}><Loader inline="true" width="25" height="25" /></div> : 
                                            <span>{message}</span>

        var showSearch = true;
        if(AgentOfficePageCustomizationOptions && AgentOfficePageCustomizationOptions.HideSearchOnAgentOfficePage){
            showSearch = false;
        }

        const ogTitle = (MetaTagsProperties && MetaTagsProperties.realtor && MetaTagsProperties.realtor.title) ? MetaTagsProperties.realtor.title : "";
        const ogDesc = (MetaTagsProperties && MetaTagsProperties.realtor && MetaTagsProperties.realtor.desc) ? MetaTagsProperties.realtor.desc : "";
        const ogImage = (MetaTagsProperties && MetaTagsProperties.realtor && MetaTagsProperties.realtor.img) ? MetaTagsProperties.realtor.img : "";
        const ogUrl = window.location.toString().substr(0);

        return (
            <DocumentTitle title={ PageTitle.replace('{title}', this.state.title) }>
                <div>
                    <Helmet>
                        <meta name="title" content={ogTitle} />
                        <meta name="description" content={ogDesc} />
                        <meta itemProp="name" content={ogTitle} />
                        <meta itemProp="description" content={ogDesc} />
                        <meta itemProp="image" content={ogImage} />
                        <meta name="twitter:title" content={ogTitle} />
                        <meta name="twitter:description" content={ogDesc} />
                        <meta name="twitter:image:src" content={ogImage} />
                        <meta property="og:title" content={ogTitle} />
                        <meta property="og:type" content="article" />
                        <meta property="og:url" content={ogUrl} />
                        <meta property="og:image" content={ogImage} />
                        <meta property="og:description" content={ogDesc} />
                    </Helmet>
                    <div className="fluid row agent_office_search common_h2_style">
                        <div className="col-sm-12">
                            {showSearch &&
                            <div className="agent_office_bg">
                                <div className="col-sm-12 no-padding row fluid">
                                    <div className="buttons_box">
                                        <ToggleButtonGroup type="radio" name="OwnTheHouse" onChange={this.handleTabChange} defaultValue={1}>
                                            <ToggleButton value={1}  className="btn-toggle"><i className="fa fa-user"></i>Agent</ToggleButton>
                                            <ToggleButton value={2} className="btn-toggle"><i className="fa fa-building"></i>Office</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </div>
                                {selected === 1 &&
                                <div>
                                    {this.state.errorMessage && this.state.errorMessage !== "" &&
                                        <div className="row">
                                            <div className="col-md-12 mg-btm-10 text-center" style={{color: "#ED1C24"}}>{this.state.errorMessage}</div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-md-3">
                                            <input onKeyPress={ this.onKeyPress } className="form-control textbox textbox-large extra_mag" name="firstName" placeholder="First Name" onChange={this.handleInputChange} />
                                        </div>
                                        <div className="col-md-3">
                                            <input onKeyPress={ this.onKeyPress } className="form-control textbox textbox-large extra_mag" name="lastName" placeholder="Last Name" onChange={this.handleInputChange} />
                                        </div>
                                        <div className="col-md-3">
                                            <input onKeyPress={ this.onKeyPress } className="form-control textbox textbox-large extra_mag" name="city" placeholder="Office City" onChange={this.handleInputChange} />
                                        </div>
                                        <div className="col-md-3">
                                            <input onKeyPress={ this.onKeyPress } className="form-control textbox textbox-large extra_mag" name="zip" placeholder="Office Postal code" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="agent_office_search">
                                                <button className="btn btn-default" ref="btnSubmit" type="button" onClick={this.validateInput}>
                                                    <span><i className="fa fa-search"></i> Search</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {selected === 2 &&
                                    <div>
                                        {this.state.errorMessage && this.state.errorMessage !== "" &&
                                            <div className="row">
                                                <div className="col-md-12 mg-btm-10 text-center" style={{color: "#ED1C24"}}>{this.state.errorMessage}</div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-md-4 extra_mag">
                                                <input onKeyPress={ (e) => this.onKeyPress(e, true) } className="form-control textbox textbox-large" name="officeName" placeholder="Office Name" onChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-md-4 extra_mag">
                                                <input onKeyPress= { (e) => this.onKeyPress(e, true) } className="form-control textbox textbox-large" name="officeCity" placeholder="City" onChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-md-4 extra_mag">
                                                <input onKeyPress= { (e) => this.onKeyPress(e, true) } className="form-control textbox textbox-large" name="officeZip" placeholder="Postal code" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="agent_office_search">
                                                    <button className="btn btn-default" ref="btnSubmit" type="button" onClick={(e) => this.validateInput(e,true)}>
                                                        <span><i className="fa fa-search"></i> Search</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            }
                            {showSearch ? <h2>Agent <span className="heading-nomal">/Office</span></h2> :
                            ((selected === 1 && resultsPage.length > 0) ? <h1 style={{marginTop: 20}}>{AgentOfficePageCustomizationOptions.TitleText || "Featured Agents"}</h1> : null)}
                        </div> 
                        <div className="col-sm-12">
                                <div className="search_result">
                                    <div className="row">
                                    <div className="col-sm-12">
                                        {selected === 1 && resultsPage.length > 0 &&
                                            resultsPage.map((result, i) => {
                                                return this.buildAgentCard(result, i)
                                            })
                                        }
                                        {/* selected === 1 && resultsPage.length === 0 &&
                                            <div>
                                                <img src={AgentListImage} alt="" />
                                            </div>
                                        */}
                                        {selected === 2 && resultsPage.length > 0 &&
                                            <div className="result_offices container">
                                            {resultsPage.map((result, i) => {
                                                if(result.OfficeId){
                                                    return (
                                                        <div key={i} className="result_office row" style={{ marginBottom: '5px' }}>
                                                            <div style={{ fontSize: "16px", fontWeight: '500' }} className="office_name col-md-4">
                                                                {result.OfficeName ? toCapitalizedWords(result.OfficeName.toLowerCase()) : ""}
                                                            </div>
                                                            <div className="col-md-1"></div>
                                                            <div className="office_address col-md-6 col-xs-9">{result.OfficeFullAddress ? toCapitalizedWords(result.OfficeFullAddress.toLowerCase()) : ""}
                                                                <span style={{ whiteSpace: 'nowrap' }}>
                                                                    <i style={{ padding: '5px' }} className="fa fa-phone"></i>
                                                                    {result.OfficePhone}
                                                                </span>
                                                            </div>
                                                            <div className="col-md-1 col-xs-3">
                                                                <button onClick={(e) => this.doSearch(e, false, result.OfficeId)} style={{ float: 'right' }} className="btn btn-default" ref="btnSubmit" type="button">
                                                                {officeAgents.length > 0 && officeAgents[0].OfficeId === result.OfficeId &&
                                                                    <span className="fa fa-arrow-down"></span>
                                                                }
                                                                {(officeAgents.length === 0 || officeAgents[0].OfficeId !== result.OfficeId) &&
                                                                    <span className="fa fa-arrow-right"></span>
                                                                }
                                                                </button>
                                                            </div>
                                                            {officeAgents.length > 0 && officeAgents[0].OfficeId === result.OfficeId &&
                                                                <div className="office_agents">
                                                                    {officeAgents.map((agent, ix) => {
                                                                        return this.buildAgentCard(agent, ix)
                                                                    })}
                                                                </div>
                                                            }
                                                        </div>
                                                    );    
                                                }
                                                else{
                                                    return null;
                                                }
                                                
                                            })}
                                        </div>
                                        }
                                        {(pageCount === 0 || fetchingResult) &&
                                            <div className="col-md-4 col-sm-6 col-xs-12 col_two">
                                                {loader}
                                            </div>
                                        }
                                    </div>
                                </div>
                                    <div className="row">
                                        <div className="col-sm-12 office_pagination">
                                            <div className="col-sm-12 pull-right">
                                            {pageCount > 0 &&
                                                <ul className="pagination">
                                                    {this.state.currentPage > 1 &&
                                                        <li onClick={() => this.onPageChange(currentPage - 1)} className="page-item" title=""><span className="page-link" style={{minWidth: 31, textAlign: "center"}}><i className="fa fa-angle-left"></i></span></li>
                                                    }
                                                    {pages}
                                                    {this.state.currentPage < pageCount &&
                                                        <li onClick={() => this.onPageChange(currentPage + 1)} className="page-item" title=""><span className="page-link" style={{minWidth: 31, textAlign: "center"}}><i className="fa fa-angle-right"></i></span></li>
                                                    }
                                                    {/* <li className="page-item" title="5"><a className="#" href="#"><i className="fa fa-angle-double-right"></i></a></li> */}
                                                </ul>
                                            }
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                        </div> 
                    </div>
                </div>
            </DocumentTitle>    
        );
    }
}

const mapStateToProps = (state, props) => ({
    purlPrefix: state.session.purlPrefix || { url: ''},
    //featuredagents: (CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers) ? (state.session.teamagents || []) : (state.session.featuredagents || []),
    featuredagents: state.session.featuredagents || [],
});

const mapDispatchToProps = dispatch => ({
    agentActions: bindActionCreators(agentActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentOfficePage);

export function getProfilePhoto(profile) {
    var result = profilephoto;
    if (profile) {
        //var profileObj = JSON.parse(JSON.stringify(profile));
        var profileObj = profile;
        if(ProfilePageCustomizationOptions && ProfilePageCustomizationOptions.AllowAgentToUpdateProfile){
            if(profileObj && profileObj.UseMemberPhotoURL) {
                if(profileObj.MemberPhotoURL !== undefined &&
                    profileObj.MemberPhotoURL !== null && profileObj.MemberPhotoURL !== "") {
                        result = profileObj.MemberPhotoURL;
                }
            }
            else{
                if (profileObj && profileObj.Profile && JSON.parse(profileObj.Profile).ProfileImageName){
                    result = JSON.parse(profileObj.Profile).ProfileImageName;
                }
            }
        }
        else{
            if (profileObj.Profile && JSON.parse(profileObj.Profile).ProfileImageName){
                result = JSON.parse(profileObj.Profile).ProfileImageName;
            }
        }
    }
    return result;
}

export function toCapitalizedWords(name) {
    var words = name.match(/[A-Za-z0-9][a-z0-9]*/g) || [];
    return words.map(capitalize).join(" ");
}

export function capitalize(word) {
    if (word.length === 2) return word.toUpperCase(); // e.g NJ
    return word.charAt(0).toUpperCase() + word.substring(1);
} 