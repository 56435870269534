import union from 'lodash/union';
import {
  MANAGE_FETCH_REQUEST,
  MANAGE_FETCH_SUCCESS,
  MANAGE_FETCH_FAILURE,
  MANAGE_LOOKUP_REQUEST,
  MANAGE_LOOKUP_SUCCESS,
  MANAGE_LOOKUP_FAILURE,
  MANAGE_ADD_REQUEST,
  MANAGE_ADD_SUCCESS,
  MANAGE_ADD_FAILURE,
  MANAGE_UPDATE_REQUEST,
  MANAGE_UPDATE_SUCCESS,
  MANAGE_UPDATE_FAILURE,
  MANAGE_DELETE_REQUEST,
  MANAGE_DELETE_SUCCESS,
  MANAGE_DELETE_FAILURE,
  MANAGE_PP_REQUEST, 
  MANAGE_PP_SUCCESS, 
  MANAGE_PP_FAILURE,
  EVENT_REQUEST, 
  EVENT_SUCCESS, 
  EVENT_FAILURE,
  UPDATE_SUCCESS
  
} from 'actions/manage';

const updateState = (state = {
  isFetching: false,
  isAdding: false,
  isAdded: false,
  isUpdating: false,
  isUpdated: false,
  isDeleting: false,
  isDeleted: false,
  isLookingUp: false,
  isLookedUp: false,
  ids: [],
  suggestions: [],
  lookupProp: {},
  error: undefined
}, action) => {
  switch (action.type) {
    case MANAGE_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case MANAGE_FETCH_SUCCESS:      
      const newIds =  union([], action.response);
      
      return {
        ...state,
        isFetching: false,
        //ids: defaultLst,
        ids: newIds,
        error: undefined
      }
    case MANAGE_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case MANAGE_LOOKUP_REQUEST:
      return {
        ...state,
        isLookingUp: true,
        isLookedUp: false,
        lookupProp: {}
      };
    case MANAGE_LOOKUP_SUCCESS:
        return {
          ...state,
          isLookingUp: false,
          isLookedUp: true,
          //suggestions: action.response.result,
          lookupProp: action.response,
          error: undefined
        }
    case MANAGE_LOOKUP_FAILURE:
        return {
          ...state,
          isLookingUp: false,
          error: action.error
        };
    case MANAGE_ADD_REQUEST:
      return {
        ...state,
        isAdding: true,
        isAdded: false,
      };
    case MANAGE_ADD_SUCCESS:
        return {
          ...state,
          isAdding: false,
          isAdded: true,
          error: undefined
        }
    case MANAGE_ADD_FAILURE:
        return {
          ...state,
          isAdding: false,
          error: action.error
        };
    case MANAGE_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case MANAGE_UPDATE_SUCCESS:
        return {
          ...state,
          isUpdating: false,
          isUpdated: true,
          error: undefined
        }
    case MANAGE_UPDATE_FAILURE:
        return {
          ...state,
          isUpdating: false,
          error: action.error
        };
    case MANAGE_DELETE_REQUEST:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false,
      };
    case MANAGE_DELETE_SUCCESS:
        return {
          ...state,
          isDeleting: false,
          isDeleted: true,
          error: undefined
        }
    case MANAGE_DELETE_FAILURE:
        return {
          ...state,
          isDeleting: false,
          error: action.error
        };
    case MANAGE_PP_REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case MANAGE_PP_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        error: undefined
      }
    case MANAGE_PP_FAILURE:
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
        error: action.error
      };
    case EVENT_REQUEST:
      return {
        ...state
      };
    case EVENT_SUCCESS: 
      if(action.key === "shared" && action.response){
        return {
          ...state,
          ids: action.response.sharelink
        }
      }
      return {
        ...state
      }
    case EVENT_FAILURE:
      return {
        ...state
      };
    case UPDATE_SUCCESS: {
        var rVal = JSON.parse(JSON.stringify(state));      
        if(action.listToUpdate){
          delete rVal["ids"];
          rVal.ids = [];
          var uLst = null;
          state.ids.map((currAd) => {
              uLst = action.listToUpdate.filter(u => u.Id === currAd.Id);
              if(uLst && uLst.length > 0){
                rVal.ids.push(JSON.parse(JSON.stringify(uLst[0])));
              }
              else{
                rVal.ids.push(JSON.parse(JSON.stringify(currAd)));
              }
              return true;
          });
        }
        return JSON.parse(JSON.stringify(rVal));
      }
    default:
      return state;
  }
}

const manage = (state = {}, action) => {
  switch (action.type) {
    case MANAGE_FETCH_REQUEST:
    case MANAGE_FETCH_SUCCESS:
    case MANAGE_FETCH_FAILURE: 
    case MANAGE_LOOKUP_REQUEST:
    case MANAGE_LOOKUP_SUCCESS:
    case MANAGE_LOOKUP_FAILURE: 
    case MANAGE_ADD_REQUEST:
    case MANAGE_ADD_SUCCESS:
    case MANAGE_ADD_FAILURE: 
    case MANAGE_UPDATE_REQUEST:
    case MANAGE_UPDATE_SUCCESS:
    case MANAGE_UPDATE_FAILURE: 
    case MANAGE_DELETE_REQUEST:
    case MANAGE_DELETE_SUCCESS:
    case MANAGE_DELETE_FAILURE:
    case MANAGE_PP_REQUEST:
    case MANAGE_PP_SUCCESS:
    case MANAGE_PP_FAILURE:
    case EVENT_REQUEST:
    case EVENT_SUCCESS:
    case EVENT_FAILURE:
    case UPDATE_SUCCESS: {

      const key = action.key;

      return {
        ...state,
        [key]: updateState(state[key], action)
      };
    }
    default: {
      return state;
    }
  }
}

export default manage;