import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from 'react-bootstrap-dialog';
import PrivacyPolicy from 'components/privacypolicy'
import CommonAdComponent from 'components/commonadcomponent';
import { Button, Modal } from 'react-bootstrap';
import SelectBox from 'components/select-box';
import ReCAPTCHA from "react-google-recaptcha";
import { getFooterAdId, GetSectionCustomizationOptions } from 'components/util';
import FooterStyleStandard from 'components/footerstandard';
import FooterStyleList from 'components/footerstylelist';
import FooterStyleCompact from 'components/footerstylecompact';
import * as manageActions from 'actions/manage';
import 'css/contact_agent.css';


const FooterCustomizationOptions = GetSectionCustomizationOptions("FooterOptions");
const CommonCustomizationOptions = GetSectionCustomizationOptions("CommonOptions");
const SITE_KEY = process.env.REACT_APP_GoogleRecaptchaSiteKey;
const recaptchaRef = React.createRef();

class Footer extends React.Component{

    constructor(props) {
        super(props);

        this.state = { paaValues: {}, cuValues: {} };

        window.sessionStorage.removeItem("RefreshFooterAd");
    }

    componentWillReceiveProps(nextProps) {
        if(FooterCustomizationOptions && FooterCustomizationOptions.ShowCustomAds){
            var refreshFlag = window.sessionStorage.getItem("RefreshFooterAd");
            if(!refreshFlag || refreshFlag !== window.location.href){
                window.sessionStorage.setItem("RefreshFooterAd",window.location.href);               
                
                var adObj = {ads:[]};
                adObj.ads.push({adsize: getFooterAdId(), count: 1});
                var self = this;
                setTimeout(() => {
                    self.props.manageActions.fetchFrontEndAds("footerads", adObj);    
                },2000);                
            }            
            
            if(JSON.stringify(this.props.pAdsList) !== JSON.stringify(nextProps.pAdsList)){
                
                var adsList = [].concat(((JSON.stringify(this.props.pAdsList) !== JSON.stringify(nextProps.pAdsList)) ? nextProps.pAdsList : []));
                adsList = adsList.filter(a => a.AdType !== "googletag" && !a.SkipEvent);                
                
                if(adsList && adsList.length > 0){
                    this.props.manageActions.trackEvent("post_impress", { 
                        EventName: "post_impress",
                        UserId: this.props.audience.Id,
                        AdModel: adsList
                    });
                }
            }
        }
    }

    openPolicyDialog() {
        Dialog.setOptions({
            defaultCancelLabel: 'Close'
        });
        this.refs.dialogRef.show({
            body: <PrivacyPolicy />,
            bsSize: 'large',
            actions: [
                Dialog.CancelAction(),

            ]
        })
    }

    handleInputChange = (event, param) => {
        var values = Object.assign({},this.state);
        const target = event.target;
        const value = target.value;
        const name = target.name;
        values[param][name] = value;
        values.paaErrorMessage = '';
        values.cuErrorMessage = '';
        
        this.setState(values);
    }

    paaSubmit = () => {
        const { paaValues } = this.state;

        var paaErrorMessage = "";
        const recaptchaValue = recaptchaRef.current.getValue();

        if(!paaValues.SenderName || paaValues.SenderName === ""){
            paaErrorMessage = "Please enter your name";
        }
        else if(!paaValues.SenderEmail || paaValues.SenderEmail === ""){
            paaErrorMessage = "Please enter your email";
        }
        // eslint-disable-next-line
        else if(paaValues.SenderEmail && /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(paaValues.SenderEmail) === false){
            paaErrorMessage = "Please enter a valid email";
        }        
        else if(!paaValues.EmailFor || paaValues.EmailFor === ""){
            paaErrorMessage = "Please select type";
        }
        else if(!paaValues.Comment || paaValues.Comment === ""){
            paaErrorMessage = "Please enter message";
        }
        else if(!recaptchaValue){
            paaErrorMessage = "Oops, you have to check the captcha!";
        }

        if(paaErrorMessage === ""){
            var self = this;
            var url = window.sessionStorage.getItem("RootAPI") + "contactforplacead";
            
            var mBody = JSON.parse(JSON.stringify(paaValues));
            if(self.props.isAnonymous){
                mBody.SenderType = 'ANON';
            }
            else if(self.props.audience && self.props.audience.type && self.props.audience.type !== ""){
                mBody.SenderType = self.props.audience.type.toUpperCase();
                if(self.props.audience && self.props.audience.Id && self.props.audience.Id !== ""){
                    mBody.SenderId = self.props.audience.Id;
                }
            }
            mBody.EmailType = "PLACEANAD";

            self.setState({sendingPAA: true});
            fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + window.sessionStorage.getItem("APIKey")
                },
                method: "POST",
                body: JSON.stringify(mBody)
            })
            .then(response => response.json())
            .then(json => {
                self.setState({ showPAAModal: false, paaErrorMessage: '', sendingPAA: false });
                if(self.props.showNotification){
                    self.props.showNotification(
                        "success", 
                        "", 
                        "Thanks for your interest. We will contact you soon",
                        5000
                    );
                }  
            })
            .catch(function(data){
                console.log(data);
                self.setState({sendingPAA: false});
                if(self.props.showNotification){
                    self.props.showNotification(
                        "error", 
                        "", 
                        "Message sending failed. Please try again later",
                        5000
                    );
                } 
            });
            
        }
        else{
            this.setState({ paaErrorMessage: paaErrorMessage });
        }        
    }

    showPAAModal = () => {
        return <Modal className="client_mg_popup register_login" show={this.state.showPAAModal} bsSize="large" aria-labelledby="contained-modal-title-lg">
            <Modal.Body>
                <div>
                    <h3 className="mg-top-0">Place an Ad</h3>
                    <div className="mg-btm-10">For inquiries regarding the purchase of a featured property, featured luxury property, featured open house or featured agent,{CommonCustomizationOptions && CommonCustomizationOptions.PlaceAdContactDetails && CommonCustomizationOptions.PlaceAdContactDetails.Name && CommonCustomizationOptions.PlaceAdContactDetails.Name !== "" ? " contact:" : ""}</div>
                    {CommonCustomizationOptions && CommonCustomizationOptions.PlaceAdContactDetails && CommonCustomizationOptions.PlaceAdContactDetails.Name && 
                        <div className="mg-btm-10">
                            {CommonCustomizationOptions.PlaceAdContactDetails.Name}
                            {CommonCustomizationOptions && CommonCustomizationOptions.PlaceAdContactDetails && CommonCustomizationOptions.PlaceAdContactDetails.MoreInfoUrl && CommonCustomizationOptions.PlaceAdContactDetails.MoreInfoUrl !== "" ? <span className="flt-right"><a href={CommonCustomizationOptions.PlaceAdContactDetails.MoreInfoUrl} target="_blank" rel="noopener noreferrer" className="primary-color">{CommonCustomizationOptions.PlaceAdContactDetails.MoreInfoText && CommonCustomizationOptions.PlaceAdContactDetails.MoreInfoText !== "" ? CommonCustomizationOptions.PlaceAdContactDetails.MoreInfoText : "[Download Media Kit (pdf)]"}</a></span> : ""}
                        </div>
                    }
                    <div className="mg-btm-10">
                        {CommonCustomizationOptions && CommonCustomizationOptions.PlaceAdContactDetails && CommonCustomizationOptions.PlaceAdContactDetails.Phone && <span className="mg-rt-20">Direct: {CommonCustomizationOptions.PlaceAdContactDetails.Phone}</span>}
                        {CommonCustomizationOptions && CommonCustomizationOptions.PlaceAdContactDetails && CommonCustomizationOptions.PlaceAdContactDetails.Email && <span className="mg-rt-20">Email: {CommonCustomizationOptions.PlaceAdContactDetails.Email}</span>}
                    </div>                    
                    <div className="mg-btm-10">{CommonCustomizationOptions && CommonCustomizationOptions.PlaceAdContactDetails && CommonCustomizationOptions.PlaceAdContactDetails.Name && CommonCustomizationOptions.PlaceAdContactDetails.Name !== "" ? "Or " : ""}fill in the form below and we'll contact you</div>
                </div>
               <div>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 mg-btm-10">
                            <input className="form-control textbox textbox-large" name="SenderName" placeholder="Name" onChange={(e) => this.handleInputChange(e, 'paaValues')} />
                        </div>
                        <div className="col-md-3 col-sm-12 mg-btm-10">
                            <input className="form-control textbox textbox-large" name="SenderEmail" placeholder="Email" onChange={(e) => this.handleInputChange(e, 'paaValues')} />
                        </div>
                        <div className="col-md-3 col-sm-12 mg-btm-10">
                            <input className="form-control textbox textbox-large" name="PhoneNumber" placeholder="Phone" onChange={(e) => this.handleInputChange(e, 'paaValues')} />
                        </div>
                        <div className="col-md-3 col-sm-12 mg-btm-10">
                            <SelectBox
                                label="Type"
                                name="EmailFor"
                                className="tru-dropdown tru-homesquare"
                                value={(this.state.paaValues && this.state.paaValues.EmailFor) ? this.state.paaValues.EmailFor : ""}
                                onChange={(e) => this.handleInputChange({target: {name: 'EmailFor', value: e}}, 'paaValues')}>
                                <option value="Advertising">Advertising</option>
                                <option value="Technical">Technical</option>
                                <option value="Distribution">Distribution</option>
                            </SelectBox>
                        </div>
                        <div className="col-md-12 col-sm-12 mg-btm-10">
                            <textarea className="form-control textbox textbox-large pd-top-20 pd-bot-20" name="Comment" placeholder="Message" rows="4" onChange={(e) => this.handleInputChange(e, 'paaValues')}></textarea>
                        </div>
                        <div className="col-sm-12 mg-btm-1 agent_recaptcha">
                            <ReCAPTCHA
                                sitekey={SITE_KEY}
                                ref={recaptchaRef}
                                style={{transform:'scale(0.97)', WebkitTransform:'scale(0.92)', TransformOrigin:'0 0', WebkitTransformOrigin:'0 0'}}
                            />
                        </div>
                        <div className="col-md-12 col-sm-12 mg-btm-10" style={this.state.paaErrorMessage ? {} : { display: 'none'}}>
                            <div style={{ color: 'red', fontWeight: '500'}}>{ this.state.paaErrorMessage }</div>
                        </div>
                    </div>                    
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="client_mg_btn">                    
                    <Button className="comn_button" onClick={() => this.setState({ showPAAModal: false, paaErrorMessage: '', paaValues: {} })}>Cancel</Button>
                    {this.state.sendingPAA ?
                        <Button className="comn_button">Sending</Button> :
                        <Button className="comn_button" onClick={this.paaSubmit}>Send</Button>
                    }
                </div>
            </Modal.Footer>
        </Modal>            
    }

    cuSubmit = () => {
        const { cuValues } = this.state;

        var cuErrorMessage = "";
        const recaptchaValue = recaptchaRef.current.getValue();

        if(!cuValues.SenderName || cuValues.SenderName === ""){
            cuErrorMessage = "Please enter your name";
        }
        else if(!cuValues.SenderEmail || cuValues.SenderEmail === ""){
            cuErrorMessage = "Please enter your email";
        }
        // eslint-disable-next-line
        else if(cuValues.SenderEmail && /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(cuValues.SenderEmail) === false){
            cuErrorMessage = "Please enter a valid email";
        }        
        else if(!cuValues.EmailFor || cuValues.EmailFor === ""){
            cuErrorMessage = "Please select type";
        }
        else if(!cuValues.Comment || cuValues.Comment === ""){
            cuErrorMessage = "Please enter message";
        }
        else if(!recaptchaValue)
            cuErrorMessage = "Oops, you have to check the captcha!";

        if(cuErrorMessage === ""){
            var self = this;
            var url = window.sessionStorage.getItem("RootAPI") + "contactus";
            
            var mBody = JSON.parse(JSON.stringify(cuValues));
            if(self.props.isAnonymous){
                mBody.SenderType = 'ANON';
            }
            else if(self.props.audience && self.props.audience.type && self.props.audience.type !== ""){
                mBody.SenderType = self.props.audience.type.toUpperCase();
                if(self.props.audience && self.props.audience.Id && self.props.audience.Id !== ""){
                    mBody.SenderId = self.props.audience.Id;
                }
            }
            mBody.EmailType = "CONTACTUS";

            self.setState({sendingCU: true});
            fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + window.sessionStorage.getItem("APIKey")
                },
                method: "POST",
                body: JSON.stringify(mBody)
            })
            .then(response => response.json())
            .then(json => {
                self.setState({ showCUModal: false, cuErrorMessage: '', sendingCU: false });
                if(self.props.showNotification){
                    self.props.showNotification(
                        "success", 
                        "", 
                        "Thanks for your message. We will contact you soon",
                        5000
                    );
                }  
            })
            .catch(function(data){
                console.log(data);
                self.setState({sendingCU: false});
                if(self.props.showNotification){
                    self.props.showNotification(
                        "error", 
                        "", 
                        "Message sending failed. Please try again later",
                        5000
                    );
                } 
            });
                      
        }
        else{
            this.setState({ cuErrorMessage: cuErrorMessage });
        }        
    }

    showCUModal = () => {
        return <Modal className="client_mg_popup register_login" show={this.state.showCUModal} bsSize="large" aria-labelledby="contained-modal-title-lg">
            <Modal.Body>
                <div>
                    <h3 className="mg-top-0">Contact Us</h3>
                    <div className="mg-btm-10">Linda Doane</div>
                    <div className="mg-btm-10">
                        <span className="mg-rt-20">Direct: 716-636-9000 ext 122</span>
                        <span className="mg-rt-20">Email: ldoane@bnar.org</span>
                    </div>                    
                    <div className="mg-btm-10">Open "Place an Ad" link from the Footer for more information about advertising on our Website</div>
                </div>
               <div>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 mg-btm-10">
                            <input className="form-control textbox textbox-large" name="SenderName" placeholder="Name" onChange={(e) => this.handleInputChange(e,'cuValues')} />
                        </div>
                        <div className="col-md-3 col-sm-12 mg-btm-10">
                            <input className="form-control textbox textbox-large" name="SenderEmail" placeholder="Email" onChange={(e) => this.handleInputChange(e, 'cuValues')} />
                        </div>
                        <div className="col-md-3 col-sm-12 mg-btm-10">
                            <input className="form-control textbox textbox-large" name="PhoneNumber" placeholder="Phone" onChange={(e) => this.handleInputChange(e, 'cuValues')} />
                        </div>
                        <div className="col-md-3 col-sm-12 mg-btm-10">
                            <SelectBox
                                label="Type"
                                name="EmailFor"
                                className="tru-dropdown tru-homesquare"
                                value={(this.state.cuValues && this.state.cuValues.EmailFor) ? this.state.cuValues.EmailFor : ""}
                                onChange={(e) => this.handleInputChange({target: {name: 'EmailFor', value: e}}, 'cuValues')}>
                                <option value="Advertising">Advertising</option>
                                <option value="Technical">Technical</option>
                                <option value="Distribution">Distribution</option>
                            </SelectBox>
                        </div>
                        <div className="col-md-12 col-sm-12 mg-btm-10">
                            <textarea className="form-control textbox textbox-large pd-top-20 pd-bot-20" name="Comment" placeholder="Message" rows="4" onChange={(e) => this.handleInputChange(e, 'cuValues')}></textarea>
                        </div>
                        <div className="col-sm-12 mg-btm-1 agent_recaptcha">
                            <ReCAPTCHA
                                sitekey={SITE_KEY}
                                ref={recaptchaRef}
                                style={{transform:'scale(0.97)', WebkitTransform:'scale(0.92)', TransformOrigin:'0 0', WebkitTransformOrigin:'0 0'}}
                            />
                        </div>
                        <div className="col-md-12 col-sm-12 mg-btm-10" style={this.state.cuErrorMessage ? {} : { display: 'none'}}>
                            <div style={{ color: 'red', fontWeight: '500'}}>{ this.state.cuErrorMessage }</div>
                        </div>
                    </div>                    
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="client_mg_btn">                    
                    <Button className="comn_button" onClick={() => this.setState({ showCUModal: false, cuErrorMessage: '', cuValues: {} })}>Cancel</Button>
                    {this.state.sendingCU ?
                        <Button className="comn_button">Sending</Button> :
                        <Button className="comn_button" onClick={this.cuSubmit}>Send</Button>
                    }
                    
                </div>
            </Modal.Footer>
        </Modal>            
    }

    openPAAModal = () => {
        this.setState({ showPAAModal: true, paaValues: {} });
    }

    openCUModal = () => {
        this.setState({ showCUModal: true, cuValues: {} });
    }

    render(){
        
        this.openPolicyDialog = this.openPolicyDialog.bind(this);

        const { showPAAModal, showCUModal } = this.state;

        var footerClass = "footer";
        var subfooter = (
            <FooterStyleStandard openPAAModal={this.openPAAModal} openCUModal={this.openCUModal} />
        );
        if(FooterCustomizationOptions && FooterCustomizationOptions.FooterStyle 
            && FooterCustomizationOptions.FooterStyle !== ""){
            footerClass = footerClass + " " + FooterCustomizationOptions.FooterStyle;

            if(FooterCustomizationOptions.FooterStyle === "footerstylelist"){                
                subfooter = (
                    <FooterStyleList openPAAModal={this.openPAAModal} openCUModal={this.openCUModal} />
                );
            }
            else if(FooterCustomizationOptions.FooterStyle === "footerstylecompact"){
                subfooter = (
                    <FooterStyleCompact openPAAModal={this.openPAAModal} openCUModal={this.openCUModal} />
                );
            }
        }

        return(
            <footer className={footerClass}>
                
                {(FooterCustomizationOptions && FooterCustomizationOptions.ShowCustomAds) &&
                <div className="footer_ads_frame hidden-xs hidden-sm">
                    <div className="ads_frame_box">                    
                        <CommonAdComponent adObj={(this.props.pAdsList && this.props.pAdsList.length > 0) ? this.props.pAdsList[0] : null} />                    
                    </div>
                </div>
                }

                {subfooter}                
                
                <Dialog ref='dialogRef' />
                {showPAAModal &&
                    this.showPAAModal()
                }
                {showCUModal &&
                    this.showCUModal()
                }
            </footer>

        );
    }
}

const mapStateToProps = (state, props) => ({
    profile: state.profile.mine || {},
    isAnonymous: state.session.isAnonymous,
    audience: state.session.audience,
    pAdsList:  (state.manage.footerads && state.manage.footerads.ids) ? state.manage.footerads.ids : [],
});

const mapDispatchToProps = dispatch => ({
    manageActions: bindActionCreators(manageActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);