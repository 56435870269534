import { CALL_API } from '../store/api';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const AGENT_PROFILE_REQUEST = 'AGENT_PROFILE_REQUEST';
export const AGENT_PROFILE_SUCCESS = 'AGENT_PROFILE_SUCCESS';
export const AGENT_PROFILE_FAILURE = 'AGENT_PROFILE_FAILURE';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';

export const fetchProfile = () => (dispatch, getState) => {
    const { Id, type } = getState().session.audience;

    return dispatch({
        [CALL_API]: {
            types: [PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_FAILURE],
            method: 'GET',
            endpoint: `${type}s/${Id}`
        }
    });
}

export const fetchMyAgentProfile = () => (dispatch, getState) => {
    const { Id } = getState().session.audience;

    return dispatch({
        [CALL_API]: {
            types: [AGENT_PROFILE_REQUEST, AGENT_PROFILE_SUCCESS, AGENT_PROFILE_FAILURE],
            method: 'GET',
            endpoint: `clients/${Id}/agent`
        }
    })
}

export const updateProfile = body => (dispatch, getState) => {
    const { Id, type } = getState().session.audience;

    return dispatch({
        [CALL_API]: {
            types: [PROFILE_REQUEST, PROFILE_UPDATE_SUCCESS, PROFILE_FAILURE],
            method: 'PUT',
            body,
            endpoint: `${type}s/${Id}`
        }
    });
};

export const setProfileSuccess = (successMsg) => (dispatch, getState) => {
    return dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        response: { Message: successMsg }
    });
}

export const setProfileError = (errMsg) => (dispatch, getSelection) => {
    return dispatch({
        type: PROFILE_FAILURE,
        error: errMsg 
    })
}
