//import union from 'lodash/union';
import {
  MO_FETCH_REQUEST,
  MO_FETCH_SUCCESS,
  MO_FETCH_FAILURE
  
} from 'actions/ads';

const updateState = (state = {
  isFetching: false,
  ids: [],
  error: undefined
}, action) => {
  switch (action.type) {
    case MO_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case MO_FETCH_SUCCESS:      
      
      var newIds = [];
      if(action.response) newIds = action.response;
      
      if(action.key === "addetail"){
        if(action.currentAdd !== undefined){
          newIds = action.currentAdd;
        }
        else{
          newIds = {};
        }
      }

      return {
        ...state,
        isFetching: false,
        ids: newIds,
        error: undefined
      }
    case MO_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
}

const ads = (state = {}, action) => {
  switch (action.type) {
    case MO_FETCH_REQUEST:
    case MO_FETCH_SUCCESS:
    case MO_FETCH_FAILURE: {

      const key = action.key;

      return {
        ...state,
        [key]: updateState(state[key], action)
      };
    }
    default: {
      return state;
    }
  }
}

export default ads;