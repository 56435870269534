import union from 'lodash/union';
import {
    CLIENTS_REQUEST,
    CLIENTS_FETCH_SUCCESS,
    CLIENTS_SUCCESS,
    CLIENT_UPDATE_SUCCESS,
    CLIENT_IGNORE_SUCCESS,
    CLIENT_SELECT,
    CLIENT_DESELECT,
    CLIENTS_FAILURE,
    CLIENT_DELETE,
    ALL_CLIENTS_REQUEST, 
    ALL_CLIENTS_SUCCESS, 
    ALL_CLIENTS_FAILURE
    /*CLIENT_DELETE_REQUEST,
    CLIENT_DELETE_SUCCESS,
    CLIENT_DELETE_FAILURE */
} from 'actions/clients';

export default (state = {
  isFetching: false,
  ids: [],
  selectedClient: null,
  error: undefined,
  notificationMessage: undefined,
  allClients: []
}, action) => {
  switch (action.type) {
    case CLIENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
        notificationMessage: undefined
      };
    case CLIENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined
      };
    case CLIENT_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notificationMessage: action.response.Message
      };
    case CLIENT_IGNORE_SUCCESS:
      return {
        ...state,
        isFetching: false // response message ignored 
        //notificationMessage: action.response.Message
      };
    case CLIENTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ids: union(state.ids, action.response.result.Clients)
      };
    case CLIENT_SELECT:
        return {
            ...state,
            isFetching: false,
            //selectedClient: action.id
            selectedClient: action.response.result
        }
    case CLIENT_DESELECT:
        return {
            ...state,
            isFetching: false,
            selectedClient: null
        }
    case CLIENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    /* case CLIENT_DELETE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
        notificationMessage: undefined
      };
    case CLIENT_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ids: state.ids.filter(n => n !== action.id),
        notificationMessage: 'Client deleted successfully'
      };
    case CLIENT_DELETE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }; */
    case CLIENT_DELETE:
      return {
        ...state,
        isFetching: false,
        ids: state.ids.filter(n => n !== action.id),
        selectedClient: null
      };
    case ALL_CLIENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
        notificationMessage: undefined
      };
    case ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        allClients: action.response,
        error: undefined
      };
    case ALL_CLIENTS_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: action.error
        };
    default:
      return state;
  }
};
