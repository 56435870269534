import React from 'react';
import { Link } from 'react-router-dom';
import Loader from './loader';
//import arrow from '../img/svg/Arrow.svg';
import DefaultPhoto from '../img/default-photo.png';


export default class SelectClient extends React.Component {
    componentDidMount() {
        if (!this.props.selectedClient) {
            this.props.clientActions.fetchClients();
        }
    }

    onSelectClient = evt => {
        this.props.clientActions.selectClient(evt.target.id);
        //if(this.props.closeSelectClientPopup && window.innerWidth <= 736){
            this.props.closeSelectClientPopup();
        //}
    }

    onRemoveSelectedClient = evt => {
        this.props.clientActions.deselectClient();
        if(this.props.closeSelectClientPopup){
            this.props.closeSelectClientPopup();
        }
    }

    render() {
        const { selectedClient, clients, isFetching } = this.props;

        var clientsEl = [];
        if(isFetching) {
            clientsEl.push(
                <Loader width="20" height="20" message={'Loading...'} key="loader" className="select-client-popover_item" />
            );
        }
        if(!isFetching && selectedClient) {
            clientsEl.push(
                <div key={"scname"} >
                    <div className="select-client-popover_item top-action" style={{ borderBottom: "1px solid #ddd" }}>
                        <Link id="client_pr_name" to={'/agent/clients/' + selectedClient.Id}
                            onClick={this.props.closeSelectClientPopup}
                            style={{ textDecoration: 'none' }}>
                            {selectedClient.FirstName + " " + selectedClient.LastName}
                        </Link>
                        <i className="icon-xout-selectclient client-close-icon" onClick={this.onRemoveSelectedClient}></i>
                    </div>
                </div>
            );
        }
        // var divStyle = {};
        
        // if(this.props.selectedClient && this.props.selectedClient.Id && this.props.selectedClient.Id > 0){
        //     divStyle = {maxHeight: "220px", overflow: "auto", minWidth: "200px"};
        // }
        // else {
        //     divStyle = {maxHeight: "220px", overflow: "auto"};
        // }
        if(!isFetching && !selectedClient/*  && clients.length > 0 */){
            clientsEl.push(
                <div key={"addclient"} >
                    <div className="select-client-popover_item top-action" style={{ borderBottom: "1px solid #ddd" }}>
                        <Link id="addnew_client" to='/agent/client/add' style={{ textDecoration: "none" }}
                            onClick={this.props.closeSelectClientPopup}>
                            Add a client <i className="icon-plus"></i>
                        </Link>
                    </div>
                </div>
            );
            clientsEl.push(
                // <div key="pLst" style={divStyle}>
                <div key="pLst">
                { clients.map(client =>
                    <div key={client.Id} id={client.Id} className="select-client-popover_item" onClick={ this.onSelectClient }>
                        {client.FirstName} {client.LastName}
                    </div>
                )}
                </div>
            );
        }
        if(!isFetching && !selectedClient && clients.length === 0) {
            clientsEl.push(
                <div key="norecords" className="select-client-popover_item" style={{textAlign: "center", cursor: "default"}}>No Client found</div>
            );
        }

        if(selectedClient){

            var menuSelectionUrl = '/agent/clients/' + selectedClient.Id;

            clientsEl.push(
                <div key='cliMenus'>
                    <div className="select-client-popover_item">
                        <Link id="profile_link" to={menuSelectionUrl}
                            onClick={this.props.closeSelectClientPopup}
                            style={{ textDecoration: 'none' }}>
                            Profile
                        </Link>
                    </div>
                    <div className="select-client-popover_item">
                        <Link id="saved_searches_link" to={menuSelectionUrl + "/1"}
                            onClick={this.props.closeSelectClientPopup}
                            style={{ textDecoration: 'none' }}>
                            Saved searches
                        </Link>
                    </div>
                    <div className="select-client-popover_item">
                        <Link id="favorites_link" to={menuSelectionUrl + "/2"}
                            onClick={this.props.closeSelectClientPopup}
                            style={{ textDecoration: 'none' }}>
                            Favorites
                        </Link>
                    </div>
                    <div className="select-client-popover_item">
                        <Link id="bookmarks_link" to={menuSelectionUrl + "/3"}
                            onClick={this.props.closeSelectClientPopup}
                            style={{ textDecoration: 'none' }}>
                            Bookmarks
                        </Link>
                    </div>
                    <div className="select-client-popover_item">
                        <Link id="removed_link" to={menuSelectionUrl + "/7"}
                            onClick={this.props.closeSelectClientPopup}
                            style={{ textDecoration: 'none' }}>
                            Removed
                        </Link>
                    </div>
                    {/* <ClientMenuDetail
                        closeSelectClientPopup={this.props.closeSelectClientPopup}
                        navigateTo={menuSelectionUrl} 
                        iconClass='fa fa-user' 
                        titleText='Profile' />
                    <ClientMenuDetail
                        closeSelectClientPopup={this.props.closeSelectClientPopup}
                        navigateTo={menuSelectionUrl + "/1"} 
                        iconClass='fa fa-search' 
                        titleText='Saved Searches' />
                    <ClientMenuDetail
                        closeSelectClientPopup={this.props.closeSelectClientPopup}
                        navigateTo={menuSelectionUrl + "/2"} 
                        iconClass='fa fa-heart' 
                        titleText='Favorties' />
                    <ClientMenuDetail
                        closeSelectClientPopup={this.props.closeSelectClientPopup}
                        navigateTo={menuSelectionUrl + "/3"} 
                        iconClass='fa fa-bookmark' 
                        titleText='Bookmarks' />
                    <ClientMenuDetail
                        closeSelectClientPopup={this.props.closeSelectClientPopup}
                        navigateTo={menuSelectionUrl + "/7"} 
                        iconClass='fa fa-times' 
                        titleText='Removed Listings' /> */}
                </div>
            );
        }



        return (<div>{clientsEl}</div>);
    }
}

export class SelectedClientName extends React.Component {
    render() {
        const { selectedClient } = this.props;

        var uPhoto = (
            <img src={DefaultPhoto} alt="" />
        );        

        if(selectedClient) {
            uPhoto = (
                <div className="tru-oval">
                    <div className="tru-oval_text">{selectedClient.FirstName.substring(0, 1) + selectedClient.LastName.substring(0, 1)}</div>
                </div>
            );

            if(selectedClient.Profile &&
                selectedClient.Profile.BasicData &&
                selectedClient.Profile.BasicData.ProfileImageUrl !== undefined &&
                selectedClient.Profile.BasicData.ProfileImageUrl !== null &&
                selectedClient.Profile.BasicData.ProfileImageUrl !== "") {
                uPhoto = (<img src={selectedClient.Profile.BasicData.ProfileImageUrl} alt="" />);
            }

            return (
                <span className="select-client" title={"Client: " + selectedClient.FirstName + " " + selectedClient.LastName}>
                    <span>
                        <span className="select-title">Client:
                            <span className="pd-lft-5 tru-bold">{selectedClient.FirstName + " " + selectedClient.LastName}</span> 
                        </span>
                        <span className="select-client-photo">
                            {uPhoto}
                        </span>
                        <span className="caret"></span>
                        {/* <span className="flt-right"><img src={arrow} style={{ width: '14px', margin: '0 5px' }} alt="" /></span> */}
                    </span>
                </span>
            );
        }

        return (
            <span className="select-client" title={"Select Client"}>
                <span>
                    <span className="select-client-photo">
                        {uPhoto}
                    </span>
                    <span className="select-title">Select Client</span> 
                    <span className="caret"></span>
                    {/* <span className="flt-right"><img src={arrow} style={{ width: '14px', margin: '0 5px' }} alt="" /></span> */}
                </span>
            </span>
        );
    }
}

// class ClientMenuDetail extends React.Component {

//     render(props){
//         var clientMenuStyle = {
//             padding: "0 0 5px",
//             cursor: "pointer",
//             textDecoration: "none",
//             listStyle: 'none'
//         };

//         var clientMenuIcon = {
//             width: '15px',
//             textAlign: 'center',
//             marginRight: '6px'
//         };

//         return (
//             <Link
//                 to={this.props.navigateTo}
//                 onClick={this.props.closeSelectClientPopup}
//                 style={{textDecoration:'none'}}>
//                 <li style={clientMenuStyle}>
//                     <span className={this.props.iconClass}
//                             style={clientMenuIcon}>
//                     </span>
//                     {this.props.titleText}
//                 </li>
//             </Link>
//         )
//     }
// }
