import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
//import Dialog from 'react-bootstrap-dialog';
//import TermsNConditions from '../components/termsconditions';
//import PrivacyPolicy from '../components/privacypolicy';
import queryString from 'query-string';
import DocumentTitle from 'react-document-title';
import { PageTitle, GetSectionCustomizationOptions } from '../components/util';
import '../scss/_login.scss';
//import LogoNavBar from '../components/logonavbar';
//import SecondaryFooter from 'components/secondaryfooter';

const LoginPageCustomizationOptions = GetSectionCustomizationOptions("LoginPageOptions");

class ResetPasswordPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        var params = queryString.parse(props.location.search);
        const token = (params && params.token) ? params.token : null;

        this.state = {
            password: '',
            confirmPassword: '',
            errorMessage: '',
            successMessage: '',
            fetchingDetails: false,
            resettingPassword: false,
            passwordReset: false,
            title: 'Reset Password',
            token: token
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    componentDidMount() {
        if (process.env.REACT_APP_AllowAnonymous === "true"){
            if (!this.props.showHeader) {
                this.props.onShowHideHeader(true);
            }
        }
        else {
            if (this.props.showHeader) {
                this.props.onShowHideHeader(false);
            }
        }
        if (!this.props.showFooter) {
            this.props.onShowHideFooter(true);
        }
    }

    /*openTermsDialog = () => {
        Dialog.setOptions({
            defaultCancelLabel: 'Close'
        });
        this.refs.dialogRef.show({
            body: <TermsNConditions />,
            bsSize: 'large',
            actions: [
                Dialog.CancelAction(),
            ]
        })
    }
    openPolicyDialog = () => {
        Dialog.setOptions({
            defaultCancelLabel: 'Close'
        });
        this.refs.dialogRef.show({
            body: <PrivacyPolicy />,
            bsSize: 'large',
            actions: [
                Dialog.CancelAction(),
            ]
        })
    }*/

    resetPassword() {

         var self = this;

         
        // Call API to get subscription details
        var url = window.sessionStorage.getItem("RootAPI") + "accesstokens/resetpassword";
        //https://trueapi-uat.tlcengine.com/v3/api/true/accesstokens/setpassword
        var requestBody = {
            token: self.state.token,
            password: self.state.password
        }
        //If there is default agent then pass default agent id to use default agent specific email template
        if (process.env.REACT_APP_DefaultAgentId !== undefined && 
            process.env.REACT_APP_DefaultAgentId !== null && 
            process.env.REACT_APP_DefaultAgentId !== "")
        {
            requestBody.BrokerAgentId = process.env.REACT_APP_DefaultAgentId;
        }
        //---

        if(self.refs.btnResetPassword) self.refs.btnResetPassword.setAttribute("disabled", "disabled");
        self.setState({creatingAccount: true});
        fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(requestBody)
            })
            .then(response =>  response.json())
            .then(json => {
                if (json) {
                    if(self.refs.btnResetPassword) self.refs.btnResetPassword.removeAttribute("disabled");
                    var accountCreated = false;
                    var successMessage = "", errorMessage = "";
                    if(json.Message){
                        if(self.props.showNotification)
                        {
                            self.props.showNotification(
                                "success",
                                "",
                                "Your Password has been reset successfully.",
                                5000);
                        }
                        self.props.history.push(`/`);
                    }
                    else if(json.length > 0 && json[0].ErrorMessage){
                        errorMessage = json[0].ErrorMessage;
                    }

                    if(self.refs.resetPasswordRef)
                    {
                        self.setState({
                            creatingAccount: false, accountCreated: accountCreated,
                            errorMessage: errorMessage, successMessage: successMessage, resettingPassword: false
                        });
                    }
                }
                else {
                    if(self.refs.btnResetPassword) self.refs.btnResetPassword.removeAttribute("disabled");
                    self.setState({
                        creatingAccount: false,
                        errorMessage: "There was an error trying to reset your password. Please contact support@truebytlc.com"
                    });
                }
            })
            .catch(function (data) {
                console.log(data);
                if(self.refs.btnSignup) self.refs.btnSignup.removeAttribute("disabled");
                self.setState({creatingAccount: false, errorMessage: "There was an error trying to reset your password. Please try again later."});
            }); 
    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    validatePassword = (newPassword) => {
        return new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$').test(newPassword);
    }
    
    validateCapsPassword = (newPassword) => {
        return new RegExp('^(?=.*[A-Z])').test(newPassword);
    }
    
    validateNumericPassword = (newPassword) => {
        return new RegExp('^(?=.*\\d)').test(newPassword);
    }
    
    validateLowerPassword(newPassword) 
    {
        return new RegExp('^(?=.*[a-z])').test(newPassword);
    }

    handleSubmit(event) {

        var errorMessage = '';
        if (this.state.password === null || this.state.password === ''){
            errorMessage = "Please enter new password. ";
        }
        else if(this.state.password.length < 8){
            errorMessage = "New password should be at least 8 characters. ";
        }
        else if(!this.validateLowerPassword(this.state.password)){
            errorMessage = "New password should contain at least one lowercase letter. ";
        }
        else if(!this.validateCapsPassword(this.state.password)){
            errorMessage = "New password should contain at least one UPPERCASE letter. ";
        }
        else if(!this.validateNumericPassword(this.state.password)){
            errorMessage = "New password should contain at least one numeric digit. ";
        }
        else if (this.state.confirmPassword === null || this.state.confirmPassword === ''){
            errorMessage = "Please confirm new password. ";
        }
        else if (this.state.password !== this.state.confirmPassword){
            errorMessage = "New password and confirm password should match. ";
        }

        if (errorMessage.length === 0) {
            this.setState({resettingPassword: true}, function() {
                this.resetPassword();
            });            
        }
        else
        {
            if(this.refs.resetPasswordRef)
            {
                this.setState({errorMessage: errorMessage});
            }
        }

        event.preventDefault();
        return false;
    }

    render() {
        return (
            <DocumentTitle title={ PageTitle.replace('{title}', this.state.title) } ref='resetPasswordRef'>
                <div className="position-relative user_access_login">
                    {/* !process.env.REACT_APP_AllowAnonymous && <LogoNavBar /> */}
                    <div className="blue_gd_bg" style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageBackgroundColor) ? {background: LoginPageCustomizationOptions.LoginPageBackgroundColor} : {}}>&nbsp;</div> 
                    <div className="container position-relative pd-bot-50 mb-pd-bot-30">
                        <div className="row">
                            <div className="float-none col-md-5 col-sm-7 mx-auto">
                                <div className="pd-top-25 pd-bot-25  large-heading mb-pd-top-15 mb-pd-bot-15">
                                <h2 style={(LoginPageCustomizationOptions && LoginPageCustomizationOptions.LoginPageTitleTextColor) ? {color: LoginPageCustomizationOptions.LoginPageTitleTextColor} : {}}><span>A new home search</span> experience—starting with what really matters to you.</h2>
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {this.state.errorMessage && this.state.errorMessage !== "" && <div className="error_meg">{this.state.errorMessage}</div>}
                                            {this.state.successMessage && this.state.successMessage !== "" && <div className="success_meg">{this.state.successMessage}</div>}

                                            {!this.state.passwordReset &&                                                
                                            <div>
                                                <div className="group">
                                                    <input name="password" className="tru-input large" id="password"
                                                        value={this.state.password}
                                                        type="password" placeholder="New Password" 
                                                        onChange={this.handleInputChange}/>
                                                </div>
                                                <div className="group mg-btm-25">
                                                    <input name="confirmPassword" className="tru-input large" id="confirmPassword"
                                                            value={this.state.confirmPassword}
                                                            type="password" placeholder="Confirm Password"
                                                            onChange={this.handleInputChange}/>                                                       
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 col-xs-12">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-xs-12 mg-btm-20">
                                                                <input type="submit" ref="btnResetPassword" className="tru-btn tru-btn-big tru-btn-default full-large" value={this.state.resettingPassword ? 'Processing...' : 'Reset Password'}/>
                                                            </div>
                                                            <div className="col-sm-12 col-xs-12"><Link  className="ft-sz-12 tru-bold link-txt" to={'/login'}>Sign in</Link></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {this.state.passwordReset &&
                                            <div className="col-xs-12 text-center margin-top-section">
                                                <Link to={'/login'} className="comn_button" style={{float: "none"}}>Sign in</Link>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </form> 
                            </div>
                        </div>
                    </div>
                    {/* <div className="login_footer">
                        <div className="row fluid">
                            <LargeAdComponent classname="footer_ads_frame hidden-xs hidden-sm" index={1} />
                            <div className="col-sm-12 col-xs-12 mg-btm-5">By signing in/up you agree to the <span className="tru-med text-decor text-cursor" onClick={this.openTermsDialog}>Terms of Use</span> and <span className="tru-med text-decor text-cursor " onClick={this.openPolicyDialog}>Privacy Policy</span></div>
                            <div className="col-sm-12 col-xs-12">&copy;{new Date().getFullYear()} 2FindYourHome. All Rights Reserved.</div>
                        </div>    
                    </div>
                    <Dialog ref='dialogRef' /> */} 
                    {/*<SecondaryFooter />*/}
                </div>
            </DocumentTitle>
        );
    }
}

export default ResetPasswordPage;