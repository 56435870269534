import React from 'react';
import { connect } from 'react-redux';
import { IndexLinkContainer } from 'react-router-bootstrap'
import { Nav, Navbar, NavItem, NavDropdown} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getProfilePhoto } from '../containers/AgentOfficePage';
import logo from '../img/svg/truebytlc-logo.svg';

class NavbarBuilder extends React.Component {

    constructor(props) {        
        super(props);
        this.state = { 
            isMobile: false, 
            navExpanded: false,
            menuItems: [
                {
                  "title": "Dashboard",
                  "url": "http://truebytlc/dashboard"
                },
                {
                  "title": "Find Homes",
                  "url": "http://truebytlc/search"
                },  
                {
                  "title": "About Us",
                  "url": "http://truebytlc/aboutus"
                }
              ]
        }

        this.truePlaceholder = 'http://truebytlc';
        this.trueCmsPlaceholder = 'http://cms.truebytlc';
    }

    componentDidMount() {
        if (window.innerWidth < 768) { 
            if(this.mainElement){
                this.setState({ isMobile: false });
            }
        }
        
        window.addEventListener("resize", this.updateIsMobile);
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.updateIsMobile);
    }

    updateIsMobile = () => {
        if(this.mainElement){
            //this.setState({ isMobile: window.innerWidth < 768});
        }
    }

    hideAllSubmenus = () => {
        var submenus = document.getElementsByClassName('navbar-submenu');
        for (var i = 0; i < submenus.length; i++)
            submenus[i].style.display = 'none';
    }
    
    handleToggle = (expanded) => {
        if(this.mainElement){
            this.setState({ navExpanded: expanded });
        }
    }

    closeNav = () => {
        if(this.mainElement){
            this.setState({ navExpanded: false });
        }
    }

    goTo = (url) => {
        this.props.history.push(url);
        this.closeNav();
    }    

    contains = (url, key) => { return url.includes(key) }
    
    toUrl = (url) => { 
        var result = url.replace(this.truePlaceholder, '').replace(this.trueCmsPlaceholder, '/info');        
        if (this.props.purlPrefix && this.props.purlPrefix.url)
            result = this.props.purlPrefix.url + result;
        return result;
    }

    buildUrlWithMapping = (item) => {
        var result = item.url 
        if (item.url.includes(this.truePlaceholder) || item.url.includes(this.trueCmsPlaceholder)) {
            result = this.toUrl(item.url);
        }
        return result;
    }

    subMenuOnClick = (subChild) => {
        var url = this.buildUrlWithMapping(subChild);
        if (url !== subChild.url)
            this.goTo(url);
        else
            window.open(url)
    }

    toggleSideMenu = (i) => {  
        if(this.mainElement){
            this.setState({ isMobile: !this.state.isMobile});
        }
    }

    buildDropDown = (item, i) => {
        const hasPermission = this.buildIndexLink(item, i) // if returns null means top level dropdown is true page that's hidden
        if (!hasPermission)
            return null;

        if(window.innerWidth <= 1025){
            return (
                <NavDropdown key={i} eventKey={i} id={"dropDown_" + i} className="dropDown" onMouseLeave={this.hideAllSubmenus} 
                    title={item.title} open={this.state.isMobile} onToggle={() => {}} // onToggle required to use open but we are using css hover
                    onClick={() => this.toggleSideMenu(i)}>
                    {item.children.map((child, i) => {
                        return this.buildIndexLink(child, i);
                    })}
                </NavDropdown>
            );
        }

        return (
            <NavDropdown key={i} eventKey={i} id={"dropDown_" + i} className="dropDown" onMouseLeave={this.hideAllSubmenus} 
                title={item.title}>
                {item.children.map((child, i) => {
                    return this.buildIndexLink(child, i);
                })}
            </NavDropdown>
        );
    }

    buildIndexLink = (item, i, isNestedLink) => {
        const { isAnonymous, isAuthenticated } = this.props;
        switch (true) {
            case this.contains(item.url,`${this.truePlaceholder}/dashboard`):
                if (isAuthenticated && !isAnonymous){
                    return (
                        <IndexLinkContainer key={i} to={this.toUrl(item.url)} className="menu-item-dashboard">
                            <NavItem>Dashboard</NavItem>
                        </IndexLinkContainer>
                    );
                }
                else return null
            case this.contains(item.url, this.truePlaceholder):
                return (
                    <IndexLinkContainer key={i} to={this.toUrl(item.url)} className="menu-item-aboutus">
                        <NavItem>{item.title}</NavItem>
                    </IndexLinkContainer>
                );
            default:
                return <NavItem componentClass={isNestedLink ? 'span' : undefined} key={i}><span onClick={() => this.subMenuOnClick(item)}>{item.title}</span></NavItem>        
        }
    }

    render() {

        var siteLogo = logo;

        if (process.env.REACT_APP_WebsiteLogo !== undefined && process.env.REACT_APP_WebsiteLogo !== null &&
            process.env.REACT_APP_WebsiteLogo !== ""){
            siteLogo = process.env.REACT_APP_WebsiteLogo;
        }

        var logoClass = "logo-img";
        if (process.env.REACT_APP_LargeLogo === "true"){
            logoClass = "large-logo-img";
        }

        var purlAgentPhoto = '';
        const { purlPrefix } = this.props;
        const { agent } = purlPrefix;
        if (agent && agent.Profile && purlPrefix.url)
            purlAgentPhoto = getProfilePhoto(agent);
        const logoImage = !purlAgentPhoto ? <img className={logoClass} id="logo" src={siteLogo} alt="" /> : 
                            (<div>
                                <div className="profile_photobox purl">
                                    <img id="logo" src={purlAgentPhoto} alt="" />
                                </div>
                                <img className={logoClass} id="logo" src={siteLogo} alt="" />
                                
                            </div>);
        
        var menuItems = [];
        if(this.state.menuItems && this.state.menuItems.length > 0){
            menuItems = [].concat(this.state.menuItems);
        }
        if(process.env.REACT_APP_WebsiteMenu !== undefined && process.env.REACT_APP_WebsiteMenu !== null &&
            process.env.REACT_APP_WebsiteMenu !== ""){
            menuItems = JSON.parse(process.env.REACT_APP_WebsiteMenu);
        }

        return (
            <Navbar ref={(ref) => this.mainElement = ref} inverse className={(purlPrefix && purlPrefix.url && purlPrefix.url !== "") ? "new_stylemenu purl_agent" : "new_stylemenu"} expanded={this.state.navExpanded} onToggle={(e) => this.handleToggle(e)}>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to={(purlPrefix && purlPrefix.url && purlPrefix.url !== "") ? purlPrefix.url : "/"}>
                            {logoImage}
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle /> 
                </Navbar.Header>
                <div className="mobile_navbar">
                    <Navbar.Collapse>
                        <Nav className="new_leftemenu" onSelect={this.closeNav}>
                            {this.props.uPhoto}
                            {this.props.loginLink}
                            {this.props.registerLink}
                            {window.location.pathname !== '/personalize' && menuItems && menuItems.map((item, i) => {
                                if (item.children && item.children.length > 0){
                                    return this.buildDropDown(item, i)
                                }
                                else {
                                    return this.buildIndexLink(item, i)
                                }
                            })}                            
                        </Nav>                        
                    </Navbar.Collapse>
                </div>
                {this.props.children}
            </Navbar>
        );
    }
}

const mapStateToProps = (state, props) => ({
    purlPrefix: state.session.purlPrefix || { url: ''},
    isAuthenticated: state.session.isAuthenticated || false
});

export default connect(mapStateToProps)(NavbarBuilder);