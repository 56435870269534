import { CALL_API, Schemas } from '../store/api';
import { normalize } from 'normalizr';
export const ACTIVITY_REQUEST = 'ACTIVITY_REQUEST';
export const ACTIVITY_SUCCESS = 'ACTIVITY_SUCCESS';
export const ACTIVITY_FAILURE = 'ACTIVITY_FAILURE';
export const ACTIVITY_FILTER = 'ACTIVITY_FILTER';
export const ACTIVITIES_ADD_ENTITIES = 'ACTIVITIES_ADD_ENTITIES';

const fetchActivityItems = (key, endpoint, body) => ({
    [CALL_API]: {
        types: [ACTIVITY_REQUEST, ACTIVITY_SUCCESS, ACTIVITY_FAILURE],
        method: 'POST',
        body,
        endpoint,
        schema: Schemas.ACTIVITY_ITEM_ARRAY
    },
    key
})

// nextPage: false, checkForNewActivity: true - App.js (setFeedTimer)
// nextPage: true, checkForNewActivity: undefined - quickbar.js (loadMore/recievedprops)
// key is 'mine' or selectedClient.id
export const loadActivity = (key, nextPage, checkForNewActivity) => (dispatch, getState) => {
    const { session, activity, entities } = getState();
    const { type, Id } = session.audience;
    const {
        pageCount = 0,
        ids = [],
        unreadIds = [],
        moreItems = true
    } = activity[key] || {};

    if(checkForNewActivity){
        window.sessionStorage.setItem("checkForNewActivity", "true");
    }
    else{
        window.sessionStorage.removeItem("checkForNewActivity");
    }

    // Cached results, don't fetch new items
    if (!checkForNewActivity && pageCount > 0 && !nextPage) {
        return null;
    }
    // Don't fetch more when there are no more items
    if (nextPage && !moreItems) {
        return null;
    }

    let count = 10;
    let lastDate;
    let lastReadId;
    const activityItems = ids.map(id => entities.activities[id]).concat(unreadIds.map(id => entities.activities[id]));

    // Calculate count and lastDate for pagination (loadMore/receivedprops)
    // This is a hack because we are getting records by DateTime instead of TrackAction.id
    if (!checkForNewActivity && activityItems.length > 0) {
        // lastDate is the oldest Date we have in the activity feed
        //lastDate = new Date(Math.min(...activityItems.map(item => new Date(item.ActivityOn.replace('T',' ') + ' UTC'))));
        lastDate = new Date(Math.min(...activityItems.map(item => new Date(item.ActivityOn + 'Z'))));

        // Increase the count because we have {totalwithDate} records that match this datetime already
        //const totalwithDate = activityItems.filter(item => (new Date(item.ActivityOn.replace('T',' ') + ' UTC')).valueOf() === lastDate.valueOf());
        const totalwithDate = activityItems.filter(item => (new Date(item.ActivityOn + 'Z')).valueOf() === lastDate.valueOf());
        count += totalwithDate.length;
    }
    // called by setFeedTimer
    else {
        if (activityItems.length > 0) {
            lastReadId = Math.max.apply(Math, activityItems.map(function (a) { return a.Id; }))
            count = undefined;
        }
    }

    let endpoint;

    // Determine endpoint by checking session audience type and the 'key' passed into action
    if (type === 'agent') {
        endpoint = `agents/${Id}`;

        if (key !== 'mine') {
            endpoint +=  `/clients/${key}`;
        }
    }
    else {
        endpoint = `clients/${Id}`;
    }

    endpoint += '/trackfeeds';

    const body = {
        Count: count,
        startDate: lastDate,
        LastReadId: lastReadId
    };

    return dispatch(fetchActivityItems(key, endpoint, body));
}

export const addActivitiesToEntities = (key, activities) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const response = normalize(activities, Schemas.ACTIVITY_ITEM_ARRAY);
        // this does the same as calling API by using entities reducer
        dispatch({type: ACTIVITIES_ADD_ENTITIES, response: response, key: key}); 
        // you shouldn't have to call ACTIVITY_SUCCESS but there's logic in the reducer :[
        dispatch({type: ACTIVITY_SUCCESS, response: response, key: key})
        resolve();
    });
}

export const filterUnreadIds = (key, lastReadId) => (dispatch, getState) => {
    return dispatch({type: ACTIVITY_FILTER, lastReadId: lastReadId, key: key })
}